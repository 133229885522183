import React from 'react';
import { Badge } from '@spglobal/react-components';
import LocationCard from '../LocationCard';
import { HeadingXs, TextBodyMd } from '../shared';

export default function LocationInfoTable({ asset }) {
    return (
        <div className="spg-p-md">
            <table
                className="spg-w-100"
                aria-label="a dense table of location info"
                id="locationTable"
                data-testid="location-table"
            >
                <tbody>
                    {asset && (
                        <>
                            <tr>
                                <td>
                                    <HeadingXs>Asset Value</HeadingXs>
                                </td>
                                <td data-testid="asset-value">
                                    <TextBodyMd>
                                        {`${new Intl.NumberFormat(
                                            'en-US',
                                            { style: 'currency', currency: 'USD' },
                                            { maximumSignificantDigits: 2 }
                                        ).format(asset.assetValue / 1000000)}m`}
                                    </TextBodyMd>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <HeadingXs>Asset Emissions</HeadingXs>
                                </td>

                                <td data-testid="asset-emissions">
                                    <TextBodyMd>
                                        {asset.emissions !== null
                                            ? `${
                                                  asset.emissions ? asset.emissions.toFixed(6) : 0.0
                                              } million tons CO₂-equivalent`
                                            : 'N/A'}
                                    </TextBodyMd>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <HeadingXs>Asset Tags</HeadingXs>
                                </td>
                                <td>
                                    {asset.assetsTags && asset.assetsTags.length > 0 ? (
                                        asset.assetsTags.map((tag, index) => (
                                            <Badge className="spg-p-sm" key={`tag-${index}`}>
                                                {tag}
                                            </Badge>
                                        ))
                                    ) : (
                                        <TextBodyMd><em>no tags present</em></TextBodyMd>
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <HeadingXs>Asset Reference ID</HeadingXs>
                                </td>
                                <td data-testid="asset-ref-name">
                                    <TextBodyMd>
                                        {asset.refId || <em>refName not present</em>}
                                    </TextBodyMd>
                                </td>
                            </tr>
                        </>
                    )}
                    {asset &&
                        asset.locations &&
                        asset.locations.length > 0 &&
                        asset.locations
                            .sort((a, b) => a.id - b.id)
                            .map((location, locationIndex) => (
                                <React.Fragment key={`location-tableItem-${locationIndex}`}>
                                    <LocationCard
                                        key={`location-card-${location.id}`}
                                        location={location}
                                        locationIndex={locationIndex}
                                    />
                                </React.Fragment>
                            ))}
                </tbody>
            </table>
        </div>
    );
}
