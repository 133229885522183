import React from 'react';
import { Card, H5, TableGrid, Button, IconButton, Icon } from '@spglobal/react-components';
import { Purpose, Size } from '@spglobal/koi-helpers';
import { PLUS, TRASH } from '@spglobal/koi-icons';
import { Controller } from 'react-hook-form';

export default function AssignedImpactFunctions({
    fields,
    control,
    toggleImpactFunctionsList,
    selectedRiskFactor,
    handleRemoveButton,
    updateRiskFactorDefaultImpactFunction,
}) {
    const ifs = fields.map((f, index) => ({
        ...f,
        index,
    }));
    const selectedRiskFactorImpactFunctions = ifs.filter(
        (impactFunction) => impactFunction.riskFactorId === selectedRiskFactor.id
    );

    const columnDefs = [
        {
            title: 'Name',
            path: 'name.value',
            cellProps: {
                width: '40%',
            },
        },
        {
            title: 'Hazard Metric',
            path: 'hazardMetric.value',
            cellProps: {
                width: '35%',
            },
        },
        {
            title: 'Is Default',
            path: 'isDefault',
            cellProps: {
                width: '15%',
            },
        },
        {
            title: 'Remove',
            path: 'remove',
            cellProps: {
                width: '10%',
            },
        },
    ];

    const assignedImpactFunctions = selectedRiskFactorImpactFunctions.map((impactFunction) => ({
        name: {
            value: impactFunction.name,
        },
        hazardMetric: {
            value: impactFunction.hazardName,
        },
        isDefault: (
            <span>
                {impactFunction.isDefault === 1 ? (
                    'Selected as Default'
                ) : (
                    <Button
                        type="button"
                        purpose={Purpose.LINK}
                        onClick={() =>
                            updateRiskFactorDefaultImpactFunction(
                                impactFunction.id,
                                selectedRiskFactor.id
                            )
                        }
                    >
                        Select As Default
                    </Button>
                )}
            </span>
        ),
        remove: (
            <IconButton
                type="button"
                icon={TRASH}
                onClick={() => handleRemoveButton(impactFunction.index)}
            />
        ),
    }));

    return (
        <Card hasBorder hasRoundedCorner>
            {selectedRiskFactorImpactFunctions.length === 0 && (
                <div className="spg-d-flex spg-flex-column spg-justify-center">
                    <div className="spg-row spg-justify-center spg-align-center spg-m-md">
                        <H5 className="spg-justify-center">No Impact Functions Assigned</H5>
                    </div>
                    <div className="spg-row spg-w-100 smg-m-md spg-justify-center">
                        <Button
                            type="button"
                            data-testid="assign-impact-function-button"
                            purpose={Purpose.PRIMARY}
                            size={Size.LARGE}
                            onClick={toggleImpactFunctionsList}
                        >
                            Assign
                        </Button>
                    </div>
                </div>
            )}

            {selectedRiskFactorImpactFunctions.length !== 0 && (
                <div>
                    <div className="spg-row spg-w-100">
                        <div className="spg-row spg-w-100 spg-mb-md">
                            <div className="spg-col-6"> </div>
                            <div className="spg-col-6 spg-d-flex spg-justify-end">
                                <Button
                                    type="button"
                                    data-testid="assign-more-impact-function-button"
                                    purpose={Purpose.SECONDARY}
                                    onClick={toggleImpactFunctionsList}
                                    leftIcon={<Icon icon={PLUS} />}
                                >
                                    Assign More Impact Functions
                                </Button>
                            </div>
                        </div>
                        <TableGrid
                            name="impactFunctions"
                            columns={columnDefs}
                            data={assignedImpactFunctions}
                        />
                    </div>
                    {fields.map((impactFunction, index) => (
                        <div key={`div[${index}]`} className="spg-d-hidden">
                            <Controller
                                render={() => <span />}
                                name={`impactFunctions[${index}].name`}
                                key={`impactFunctions[${index}].name`}
                                control={control}
                                defaultValue={impactFunction.name}
                            />
                            <Controller
                                render={() => <span />}
                                name={`impactFunctions[${index}].hazardName`}
                                key={`impactFunctions[${index}].hazardName`}
                                control={control}
                                defaultValue={impactFunction.hazardName}
                            />
                            <Controller
                                render={() => <span />}
                                name={`impactFunctions[${index}].isDefault`}
                                key={`impactFunctions[${index}].isDefault`}
                                control={control}
                                defaultValue={impactFunction.isDefault}
                            />
                            <Controller
                                render={() => <span />}
                                name={`impactFunctions[${index}].riskFactorId`}
                                key={`impactFunctions[${index}].riskFactorId`}
                                control={control}
                                defaultValue={impactFunction.riskFactorId}
                            />
                            <Controller
                                render={() => <span />}
                                name={`impactFunctions[${index}].id`}
                                key={`impactFunctions[${index}].id`}
                                control={control}
                                defaultValue={impactFunction.id}
                            />
                        </div>
                    ))}
                </div>
            )}
        </Card>
    );
}
