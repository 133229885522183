import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { Tabs, Tab } from '@spglobal/react-components';
import AccessTokenContext from 'context/AccessTokenContext';
import folderService from 'services/folderService';
import viewService from 'services/viewService';
import FoldersDataTable from 'components/Folders/FoldersDataTable';
import GroupsDataTable from 'components/Groups/GroupsDataTable';
import CustomHeader from 'components/Header/CustomHeader';
import pathParser from 'utils/pathParser';
import * as constants from 'utils/constants';
import { CONSENT_REQUIRED_ERROR } from 'utils/constants';
import { DEFAULT_PAGE_DATA, DEFAULT_PAGE_SIZE } from '../components.constants';

const TABS_CONFIG = [
    { label: 'Folders', id: 'Folders-tab' },
    { label: 'Groups', id: 'Groups-tab' },
];

export default function CreateEditGroupTabs({
    handleRowsSelection,
    selectedFolderIDs,
    selectedViewIDs,
    selectedRow,
}) {
    const { pathname } = useLocation();
    const { getTokenAndTryAgain } = useContext(AccessTokenContext);
    const apiOptions = {
        scope: `${constants.CUSTOMER_READ_HIERARCHY} ${constants.CUSTOMER_EDIT_HIERARCHY}`,
    };
    const { customers: customerId } = pathParser.getPathComponents(pathname);

    const [foldereDataLoaded, setFoldereDataLoaded] = useState(false);
    const [viewsDataLoaded, setViewsDataLoaded] = useState(false);
    const [foldersTableData, setFoldersTableData] = useState([]);
    const [viewsTableData, setViewsTableData] = useState([]);
    const [foldersTotalCount, setFoldersTotalCount] = useState(0);
    const [viewsTotalCount, setViewsTotalCount] = useState(0);
    const [foldersPageData, setFoldersPageData] = React.useState({
        ...DEFAULT_PAGE_DATA,
        searchText: '',
    });
    const [viewsPageData, setViewsPageData] = React.useState({
        ...DEFAULT_PAGE_DATA,
        searchText: '',
    });
    const [selectedTabId, setSelectedTabId] = React.useState(TABS_CONFIG[0].id);

    const getFoldersData = async (offset = 0, limit = DEFAULT_PAGE_SIZE, searchTextVal) => {
        const reqData = { offset, limit };
        if (searchTextVal?.length) {
            reqData.asset_folder_name = searchTextVal;
        }
        async function fetchData() {
            try {
                const response = await folderService.getAll(customerId, reqData);
                setFoldereDataLoaded(true);
                if (response) {
                    setFoldersTableData(response?.result);
                    setFoldersTotalCount(response?.count + 1);
                }
                return response?.result;
            } catch (error) {
                setFoldereDataLoaded(true);
                if (error.error === CONSENT_REQUIRED_ERROR) {
                    return getTokenAndTryAgain(apiOptions);
                }
                return error;
            }
        }
        return fetchData();
    };

    const getViewsData = async (offset = 0, limit = DEFAULT_PAGE_SIZE, searchTextVal) => {
        const reqData = { offset, limit };
        if (searchTextVal?.length) reqData.view_name = searchTextVal;
        async function fetchData() {
            try {
                const response = await viewService.getAll(customerId, reqData);
                setViewsDataLoaded(true);
                if (response) {
                    setViewsTableData(response?.results);
                    setViewsTotalCount(response?.total_views);
                }
                return response?.results;
            } catch (error) {
                setViewsDataLoaded(true);
                if (error.error === CONSENT_REQUIRED_ERROR) {
                    return getTokenAndTryAgain(apiOptions);
                }
                return error;
            }
        }
        return fetchData();
    };

    useEffect(() => {
        (async function fetchFoldersData() {
            await getFoldersData(0, DEFAULT_PAGE_SIZE, '');
            await getViewsData(0, DEFAULT_PAGE_SIZE, '');
        })();
    }, []);

    useEffect(() => {
        (async function updateData() {
            if (foldersPageData?.clicked) {
                const offset = foldersPageData?.page * foldersPageData?.rowsPerPage;
                getFoldersData(offset, foldersPageData?.rowsPerPage, foldersPageData?.searchText);
            }
        })();
    }, [foldersPageData]);

    useEffect(() => {
        (async function updateData() {
            if (viewsPageData?.clicked) {
                const offset = viewsPageData?.page * viewsPageData?.rowsPerPage;
                getViewsData(offset, viewsPageData?.rowsPerPage, viewsPageData?.searchText);
            }
        })();
    }, [viewsPageData]);

    const handleFoldersTableSearchSubmit = (searchVal) => {
        getFoldersData(0, DEFAULT_PAGE_SIZE, searchVal);
        setFoldersPageData({
            ...foldersPageData,
            ...DEFAULT_PAGE_DATA,
            searchText: searchVal,
        });
    };

    const handleViewsTableSearchSubmit = (searchVal) => {
        getViewsData(0, DEFAULT_PAGE_SIZE, searchVal);
        setViewsPageData({
            ...viewsPageData,
            ...DEFAULT_PAGE_DATA,
            searchText: searchVal,
        });
    };

    const onTabChange = (newTabId) => {
        setSelectedTabId(newTabId);
        setFoldersPageData({ ...foldersPageData, clicked: false });
        setViewsPageData({ ...viewsPageData, clicked: false });
    };

    return (
        <>
            <Tabs selectedTabId={selectedTabId} onChange={onTabChange}>
                <Tab id={TABS_CONFIG[0].id} title={TABS_CONFIG[0].label}>
                    <CustomHeader
                        searchTextPrev={foldersPageData?.searchText}
                        searchPlaceholder="Folder Name"
                        handleSearchSubmit={handleFoldersTableSearchSubmit}
                    />
                    <FoldersDataTable
                        tableData={foldersTableData}
                        isLoading={!foldereDataLoaded}
                        totalCount={foldersTotalCount}
                        isViewCreateEdit={true}
                        handleRowsSelection={handleRowsSelection}
                        selectedRowIDs={selectedFolderIDs}
                        onPageDataChange={setFoldersPageData}
                        pageData={foldersPageData}
                        hideActions={true}
                    />
                </Tab>
                <Tab id={TABS_CONFIG[1].id} title={TABS_CONFIG[1].label}>
                    <CustomHeader
                        searchTextPrev={viewsPageData?.searchText}
                        searchPlaceholder="Group Name"
                        handleSearchSubmit={handleViewsTableSearchSubmit}
                    />
                    <GroupsDataTable
                        tableData={viewsTableData}
                        isLoading={!viewsDataLoaded}
                        totalCount={viewsTotalCount}
                        isViewCreateEdit={true}
                        handleRowsSelection={handleRowsSelection}
                        selectedRowIDs={selectedViewIDs}
                        onPageDataChange={setViewsPageData}
                        pageData={viewsPageData}
                        selectedRow={selectedRow}
                        hideActions={true}
                    />
                </Tab>
            </Tabs>
        </>
    );
}
