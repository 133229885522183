import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Link } from '@spglobal/react-components';
import { GoogleMap, Marker, InfoWindow, useLoadScript } from '@react-google-maps/api';
import MapLegend from './MapLegend';

const libraries = ['places'];

const mapContainerStyle = {
    width: '100%',
    height: '400px',
    marginBottom: '1rem',
};

const defaultCenter = { lat: 0, lng: 0 };
const getRandomInt = (max) => Math.floor(Math.random() * Math.floor(max));

const LocationMap = ({ locations, displayLocationNumbers, displayLegend, googleAPIKey }) => {
    const { customerId } = useParams();
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: googleAPIKey,
        libraries,
    });
    const [infoOpen, setInfoOpen] = useState(false);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [markerMap, setMarkerMap] = useState({});
    const [zoom, setZoom] = useState(3);
    const [mapRef, setMapRef] = useState(false);

    useEffect(() => {
        if (mapRef && locations.length) {
            const bounds = new window.google.maps.LatLngBounds();
            locations.forEach((location) => {
                bounds.extend(
                    new window.google.maps.LatLng(location.lat, location.lng || location.long),
                );
            });
            mapRef.fitBounds(bounds);
            if (locations.length === 1) {
                setTimeout(() => mapRef.setZoom(15), 1000);
            }
        }
    }, [locations, mapRef]);

    const markerClickHandler = (event, location) => {
        // Remember which location was clicked
        setSelectedLocation(location);

        // Required so clicking a 2nd marker works as expected
        if (infoOpen) {
            setInfoOpen(false);
        }

        setInfoOpen(true);

        // If you want to zoom in a little on marker click
        setZoom(15);
    };

    // We have to create a mapping of our locations to actual Marker objects
    const markerLoadHandler = (marker, location) => {
        const locId = location.id || getRandomInt(100000);

        return setMarkerMap((prevState) => ({ ...prevState, [locId]: marker }));
    };

    if (loadError) return <div>Error loading map</div>;
    if (!isLoaded) return <div>Loading map</div>;

    return (
        <GoogleMap
            id="zoomed-map"
            mapContainerStyle={mapContainerStyle}
            options={{ streetViewControl: false }}
            defaultCenter={defaultCenter}
            defaultZoom={zoom}
            onLoad={(map) => {
                setMapRef(map);
                if (displayLegend) {
                    const legend = document.getElementById('legend');
                    map.controls[window.google.maps.ControlPosition.LEFT_BOTTOM].push(legend);
                }
            }}
        >
            {displayLegend && <MapLegend />}
            {locations.map((location, index) => (
                <Marker
                    key={index}
                    position={{ lat: location.lat, lng: location.lng || location.long }}
                    icon={{
                        path: window.google.maps.SymbolPath.CIRCLE,
                        fillColor: location.color || '#0000ff',
                        fillOpacity: 0.5,
                        strokeWeight: 1,
                        scale: 7,
                        label: index,
                    }}
                    label={displayLocationNumbers && `${index + 1}`}
                    onClick={(event) => markerClickHandler(event, location)}
                    onLoad={(marker) => markerLoadHandler(marker, location, index)}
                />
            ))}
            {infoOpen && selectedLocation && (
                <InfoWindow
                    anchor={markerMap[selectedLocation.id]}
                    onCloseClick={() => setInfoOpen(false)}
                    position={{
                        lat: parseFloat(selectedLocation?.lat),
                        lng: parseFloat(selectedLocation?.lng || selectedLocation?.long),
                    }}
                >
                    <div style={{ paddingRight: 12 }}>
                        <Link
                            href={`/real-assets/${customerId}/assets/${selectedLocation.asset_id}`}
                            style={{ fontSize: 16 }}
                        >
                            {selectedLocation?.name ||
                                selectedLocation?.asset_name ||
                                selectedLocation?.locationName ||
                                selectedLocation?.address ||
                                selectedLocation?.entity_name}
                        </Link>
                    </div>
                </InfoWindow>
            )}
        </GoogleMap>
    );
};

export default LocationMap;
