const formatCurrency = (riskValue, precision) => {
    let formattedValue = '';
    formattedValue = `${new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: precision,
        maximumFractionDigits: precision,
    }).format(riskValue)}m`;

    return formattedValue;
};

export default formatCurrency;
