import { httpClient } from '../utils/httpClient';
import { API_URL } from '../utils/constants';

const ENDPOINT = 'api/customer';
const VIEW_ENDPOINT = 'api/view';
const getAll = async (customerId, params) => {
    const result = await httpClient.get(`${API_URL}/${ENDPOINT}/${customerId}/views`, {
        params: {
            ...params,
        },
        contentType: 'application/json',
    });
    return result.data;
};

const deleteView = async (customerID, viewID) => {
    const result = await httpClient.delete(`${API_URL}/${ENDPOINT}/${customerID}/view/${viewID}`);
    return result?.data;
};

const renameView = async (customerID, viewID, params) => {
    const result = await httpClient.put(
        `${API_URL}/${ENDPOINT}/${customerID}/view/${viewID}?rename=true`,
        { ...params }
    );
    return result?.data;
};

const editView = async (customerID, viewID, params) => {
    const result = await httpClient.put(`${API_URL}/${ENDPOINT}/${customerID}/view/${viewID}`, {
        ...params,
    });
    return result?.data;
};

const createView = async (customerID, params) => {
    const result = await httpClient.post(`${API_URL}/${ENDPOINT}/${customerID}/views`, {
        ...params,
    });
    return result?.data;
};

const getViewDetails = async (customerID, viewID) => {
    const result = await httpClient.get(`${API_URL}/${ENDPOINT}/${customerID}/view/${viewID}`);
    return result?.data;
};

const getViewHierarchy = async (reqBody) => {
    let VIEW_HIERARCHY_ENDPOINT = `${API_URL}/api/customer/${reqBody.customerId}/view/${reqBody.viewID}/viewHierarchy`;

    if (reqBody.action === 'edit') {
        VIEW_HIERARCHY_ENDPOINT = `${VIEW_HIERARCHY_ENDPOINT}?user_id=${reqBody.UserId}`;
    }

    const result = await httpClient.get(VIEW_HIERARCHY_ENDPOINT);

    return result?.data;
};

export default {
    getAll,
    deleteView,
    renameView,
    createView,
    editView,
    getViewDetails,
    getViewHierarchy,
};
