import React, { useState, useEffect } from 'react';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { uniqBy } from 'lodash';
import { getTagByCustomerId } from 'services/tagService';
import {
    Button,
    CapsuleSelect,
    FormGroup,
    InputField,
    Spinner,
    Select,
    Link,
    SelectCustomOption,
} from '@spglobal/react-components';
import { Purpose } from '@spglobal/koi-helpers';
import assetTypeService from '../../services/assetTypeService';
import folderService from '../../services/folderService';
import HazardToImpactSelector from '../HazardToImpactSelector';
import LocationForm from '../LocationForm';
import { DEFAULT_SPINNER_SIZE } from '../components.constants';
import { ErrorTextBodySm } from '../shared';
import { transformDataObjForKoiSelect } from '../../utils/koiIntegrationUtils';
import { UNCATEGORIZED } from '../../utils/constants';

export default function AssetForm({
    cancelClicked,
    submitClicked,
    action,
    defaultFormValues,
    customerId,
}) {
    const [assetTypes, setAssetTypes] = useState({});
    const [customerTagOptions, setCustomerTagOptions] = useState([]);
    const [tags, setTags] = useState([]); // string[]
    const [selectedAssetType, setSelectedAssetType] = useState([]);
    const [defaultAssetType, setDefaultAssetType] = useState([]);
    const [assetTypeOptions, setAssetTypeOptions] = useState([]);
    const [assetTypeNames, setAssetTypeNames] = useState([]);
    const [hazardsForAssetType, setHazardsForAssetType] = useState({});
    const [impactFunctionsForAssetType, setImpactFunctionsForAssetType] = useState([]);
    const [folderList, setFolderList] = useState([]);
    const [defaultFolderList, setDefaultFolderList] = useState([]);
    const { riskFactorHazardImpacts, locations } = defaultFormValues || {
        riskFactorHazardImpacts: [],
        locations: [],
    };
    (riskFactorHazardImpacts || []).sort((a, b) => (a.riskFactorId > b.riskFactorId ? 1 : -1));
    const uniqRiskFactor = uniqBy(riskFactorHazardImpacts, 'riskFactorId');
    const {
        handleSubmit,
        formState: { errors },
        control,
        setValue,
        getValues,
        watch,
    } = useForm({
        defaultValues: {
            ...defaultFormValues,
            riskFactorHazardImpacts: uniqRiskFactor,
            locations,
        },
    });

    // TAGS SELECTOR
    const [tagsSearchText, setTagsSearchText] = React.useState('');
    const [isTagDuplicate, setIsTagDuplicate] = React.useState(false);
    const [tagOptions, setTagOptions] = React.useState([]); // IOption[]
    const [defaultTagValues, setDefaultTagValues] = React.useState(undefined); // IOption[]
    const [closeTagsOnSelection, setCloseTagsOnSelection] = React.useState(false);

    const { fields, append, insert, remove } = useFieldArray({
        control,
        name: 'locations',
    });

    const watchAssetType = watch('assetTypeId');
    const watchLocations = watch('locations');
    const watchTags = watch('tags');

    useEffect(() => {
        // Get Tags Customer has access to
        getTagByCustomerId(customerId)
            .then((customerTags) => {
                setCustomerTagOptions(customerTags);

                const opts =
                    customerTags?.map((option) =>
                        transformDataObjForKoiSelect(option, {
                            id: 'id',
                            label: 'name',
                            value: 'id',
                        }),
                    ) || [];

                setTagOptions(opts);
                const defTags =
                    opts
                        .filter((o) => defaultFormValues.tags.find((df) => df === o.label))
                        .map((o) => ({ ...o, isActive: true })) || [];
                setDefaultTagValues(defTags);
                setValue('tags', defTags);
            })
            .catch(() => {
                setDefaultTagValues([]);
                setValue('tags', []);
            });
    }, [defaultFormValues?.tags]);

    useEffect(() => {
        if (watchTags.length !== tags.length) {
            setValue('tags', tags, { shouldValidate: true });
        }
    }, [watchTags]);

    useEffect(() => {
        if (watchLocations.length !== fields.length) {
            setValue('locations', fields, { shouldValidate: true });
        }
    }, [watchLocations]);

    useEffect(() => {
        async function fetchData() {
            try {
                //  GET THE FOLDERS
                const response = await folderService.getAll(customerId, {});
                let folders = response?.result ? response?.result : [];
                folders = folders
                    .sort((a, b) => {
                        const nameA = a.asset_folder_name.toUpperCase(); // ignore upper and lowercase
                        const nameB = b.asset_folder_name.toUpperCase(); // ignore upper and lowercase
                        if (nameA < nameB) return -1;
                        if (nameA > nameB) return 1;
                        return 0;
                    })
                    .map((f) => ({
                        ...transformDataObjForKoiSelect(f, {
                            id: 'asset_folder_id',
                            label: 'asset_folder_name',
                            value: 'asset_folder_id',
                        }),
                        disabled: f.is_assigned === false,
                    }));

                setFolderList(folders);

                updateDefaultFolderList(folders, defaultFormValues?.asset_folder_id, setDefaultFolderList);

                // set the folder in folder list
                if (action === 'Create Asset') {
                    if (!defaultFormValues.asset_folder_id) {
                        // FOR CREATE ASSET : SET DEFAULT FOLDER VALUE TO UNCATEGORIZED
                        const UncategorizedFolder = folders.find(
                            (item) => item.label === UNCATEGORIZED,
                        );
                        const asset_folder_id = UncategorizedFolder?.id
                            ? UncategorizedFolder.id
                            : undefined;
                        setValue('asset_folder_id', asset_folder_id, { shouldValidate: true });

                        updateDefaultFolderList(folders, asset_folder_id, setDefaultFolderList);
                    }
                }

                // GET ASSET TYPES
                const resAssetTypes = await assetTypeService.getAllDetails({});
                const resAssetTypesData =
                    typeof resAssetTypes === 'object' && Object.keys(resAssetTypes).length
                        ? resAssetTypes
                        : {};

                setAssetTypes(resAssetTypesData);
                handleAssetTypeNames(resAssetTypesData);

                if (action === 'Create Asset') {
                    // SET DEFAULT ASSETTYPE AS FIRST OPTION
                    if (Object.keys(resAssetTypesData).length) {
                        setValue('assetTypeId', Object.keys(resAssetTypesData)[0], {
                            shouldValidate: true,
                        });
                    }
                } else if (action === 'edit') {
                    if (Object.keys(resAssetTypesData).length) {
                        setValue('assetTypeId', defaultFormValues.assetTypeId, {
                            shouldValidate: true,
                        });

                        // SET THE RISK FACTOR AND HAZARD
                        const { assetTypeId } = defaultFormValues;

                        const riskFactorCandidates =
                            resAssetTypesData && assetTypeId && resAssetTypesData[assetTypeId];
                        setSelectedAssetType(
                            riskFactorCandidates ? riskFactorCandidates.defaultImpactFunctions : [],
                        );
                        setImpactFunctionsForAssetType(
                            riskFactorCandidates ? riskFactorCandidates.defaultImpactFunctions : {},
                        );
                        setHazardsForAssetType(
                            riskFactorCandidates ? riskFactorCandidates.defaultImpactFunctions : {},
                        );
                        setValue('riskFactorDetails', riskFactorCandidates);
                    }
                }
            } catch (err) {
                console.log('error', err);
            }
        }
        fetchData();
    }, []);

    useEffect(() => {
        const assetTypeId = watchAssetType;

        const riskFactorCandidates = assetTypes && assetTypeId && assetTypes[assetTypeId];
        setSelectedAssetType(
            riskFactorCandidates ? riskFactorCandidates.defaultImpactFunctions : [],
        );
        setImpactFunctionsForAssetType(
            riskFactorCandidates ? riskFactorCandidates.defaultImpactFunctions : {},
        );
        setHazardsForAssetType(
            riskFactorCandidates ? riskFactorCandidates.defaultImpactFunctions : {},
        );

        setValue('riskFactorDetails', riskFactorCandidates);
        return () => assetTypeId;
    }, [watchAssetType]);

    const updateDefaultFolderList = (folders, assetFolderId, setDefaultFolder) => {
        if(folders && folders.length && assetFolderId) {
            const defaultFolder = folders.find((folder) => folder.id === Number(assetFolderId));
            setDefaultFolder(defaultFolder ? [defaultFolder] : []);
        } else {
            setDefaultFolder([]);
        }
    };

    const handleAssetTypeNames = (assetTypesObject) => {
        let assetTypeNameArray = [];
        if (assetTypesObject === null) return;
        if (Object.keys(assetTypesObject || {}).length > 0) {
            assetTypeNameArray = Object.keys(assetTypesObject).map((val) => ({
                id: parseInt(val, 10),
                name: assetTypesObject[val].name,
                is_active: assetTypesObject[val].is_active,
            }));
        }
        const activeAssetTypes = assetTypeNameArray
            ?.filter(
                (assetTypeName) =>
                    assetTypeName.is_active ||
                    parseInt(getValues('assetTypeId'), 10) === assetTypeName.id,
            )
            .sort((a, b) => {
                if (a.name < b.name) {
                    return -1;
                }
                if (a.name > b.name) {
                    return 1;
                }
                return 0;
            });
        setAssetTypeNames(activeAssetTypes);

        const assetTypesOptions = activeAssetTypes.map((o) =>
            transformDataObjForKoiSelect(o, {
                id: 'id',
                label: 'name',
                value: 'id',
            }),
        );
        setAssetTypeOptions(assetTypesOptions.sort((a, b) => a.id - b.id));

        const defAssetType = assetTypesOptions.find(
            (o) => o.id === defaultFormValues.asset_type_id,
        );

        setDefaultAssetType(defAssetType ? [defAssetType] : [assetTypesOptions[0]]);
    };

    const onSearchTextChange = (searchValue) => {
        const optionExists = tagOptions.find(
            (option) => option.label.toLowerCase() === searchValue.toLowerCase(),
        );
        setIsTagDuplicate(optionExists !== undefined);
        setTagsSearchText(searchValue);
    };

    const handleCreate = () => {
        const newOption = {
            label: tagsSearchText,
            value: tagsSearchText,
        };
        setTagOptions((options) => [newOption, ...options]);
        setDefaultTagValues((defaultValue) => [...defaultValue, newOption]);
        setCloseTagsOnSelection(true);
    };

    const renderNewTagDOM = React.useMemo(
        () =>
            !isTagDuplicate && tagsSearchText.trim() ? (
                <SelectCustomOption onClick={handleCreate}>
                    {`Create New Tag '${tagsSearchText}'`}
                </SelectCustomOption>
            ) : (
                <></>
            ),
        [tagsSearchText, isTagDuplicate],
    );

    return (
        <form noValidate="noValidate" onSubmit={handleSubmit(submitClicked)}>
            <div className="spg-row">
                <div className="spg-col-6">
                    <FormGroup label="Asset Name" labelFor="name" required>
                        <Controller
                            render={({ field }) => <InputField {...field} label="Asset Name" />}
                            autoFocus
                            id="name"
                            name="name"
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: 'Asset Name is required',
                                },
                                maxLength: {
                                    value: 128,
                                    message: 'Asset Name exceed max length.',
                                },
                            }}
                        />
                        <ErrorMessage
                            errors={errors}
                            name="name"
                            render={({ message }) => (
                                <ErrorTextBodySm>{message || null}</ErrorTextBodySm>
                            )}
                        />
                    </FormGroup>
                </div>

                <div className="spg-col-6">
                    {folderList?.length && (
                        <FormGroup label="Folder" data-testid="folder-id-select" required>
                            <Controller
                                render={({ field: { onChange } }) => (
                                    <Select
                                        options={folderList}
                                        isMulti={false}
                                        defaultValue={defaultFolderList}
                                        isSearchable={false}
                                        onChange={(values) => {
                                            onChange(values[0].id);
                                        }}
                                        closeOnSelection
                                    ></Select>
                                )}
                                control={control}
                                name="asset_folder_id"
                                id="asset_folder_id"
                                rules={{
                                    required: 'Folder is required',
                                }}
                            />
                            <ErrorMessage
                                errors={errors}
                                name="asset_folder_id"
                                render={({ message }) => <ErrorTextBodySm>{message}</ErrorTextBodySm>}
                            />
                        </FormGroup>
                    )}

                </div>
            </div>
            <div className="spg-row">
                <div className="spg-col-3">
                    <FormGroup
                        label="Asset Value"
                        labelFor="assetValue"
                        feedbackText="millions of US Dollars"
                        required
                    >
                        <Controller
                            render={({ field }) => <InputField {...field} type="number" />}
                            id="assetValue"
                            name="assetValue"
                            control={control}
                            rules={{
                                required: 'Asset Value is required',
                                pattern: {
                                    value: /[+-]?([0-9]*[.])?[0-9]+/,
                                    message: 'Must be numeric',
                                },
                                validate: (value) => value > 0 || 'Value must be greater than 0',
                            }}
                        />
                        <ErrorMessage
                            errors={errors}
                            name="assetValue"
                            render={({ message }) => <ErrorTextBodySm>{message}</ErrorTextBodySm>}
                        />
                    </FormGroup>
                </div>
                <div className="spg-col-3">
                    <FormGroup
                        label="Asset Emissions"
                        labelFor="emissions"
                        feedbackText="million tons CO₂-equivalent"
                        required
                    >
                        <Controller
                            render={({ field }) => <InputField {...field} type="number" />}
                            id="emissions"
                            name="emissions"
                            control={control}
                            rules={{
                                required: 'Asset Emissions is required',
                                pattern: {
                                    value: /[+-]?([0-9]*[.])?[0-9]+/,
                                    message: 'Must be numeric',
                                },
                            }}
                        />
                        <ErrorMessage
                            errors={errors}
                            name="emissions"
                            render={({ message }) => <ErrorTextBodySm>{message}</ErrorTextBodySm>}
                        />
                    </FormGroup>
                </div>
                <div className="spg-col-3">
                    <FormGroup label="Asset Reference ID" labelFor="ref_id" required>
                        <Controller
                            render={({ field }) => <InputField {...field} />}
                            autoFocus
                            id="ref_id"
                            name="ref_id"
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: 'Asset Reference ID is required',
                                },
                                maxLength: {
                                    value: 100,
                                    message: 'Asset Reference ID exceed max length.',
                                },
                            }}
                        />
                        <ErrorMessage
                            errors={errors}
                            name="ref_id"
                            render={({ message }) => (
                                <ErrorTextBodySm>{message || null}</ErrorTextBodySm>
                            )}
                        />
                    </FormGroup>
                </div>
            </div>
            <div className="spg-row">
                <div className="spg-col-6">
                    {assetTypes && assetTypeNames.length > 0 ? (
                        <FormGroup label="Asset Type" data-testid="asset-type-select" required>
                            <Controller
                                render={({ field: { onChange } }) => (
                                    <Select
                                        options={assetTypeOptions}
                                        isMulti={false}
                                        defaultValue={defaultAssetType}
                                        onChange={(values) => {
                                            onChange(values[0].id);
                                        }}
                                        closeOnSelection
                                    />
                                )}
                                control={control}
                                name="assetTypeId"
                                id="assetTypeId"
                                rules={{
                                    required: 'Asset Type is required',
                                }}
                            />
                            <ErrorMessage
                                errors={errors}
                                name="assetTypeId"
                                render={({ message }) => (
                                    <ErrorTextBodySm>{message}</ErrorTextBodySm>
                                )}
                            />
                            <Link href="/asset-types" target="_blank">
                                Go to the Climanomics Asset Type library for descriptions of the
                                asset types.
                            </Link>
                        </FormGroup>
                    ) : (
                        <Spinner size={DEFAULT_SPINNER_SIZE} />
                    )}
                </div>

                <div className="spg-col-6">
                    {defaultTagValues && (
                        <FormGroup label="Tags" labelFor="tags">
                            <Controller
                                id="tags"
                                name="tags"
                                control={control}
                                render={({ field: { onChange } }) => (
                                    <CapsuleSelect
                                        isMulti
                                        options={tagOptions}
                                        defaultValue={defaultTagValues}
                                        customControlButtons={renderNewTagDOM}
                                        onSearchTextChange={onSearchTextChange}
                                        onTagAdd={(values) => {
                                            onChange(values.map((to) => to.label));
                                            setCloseTagsOnSelection(false);
                                            setDefaultTagValues(values);
                                            setTags(values.map((to) => to.label));
                                        }}
                                        onTagRemove={(values) => {
                                            onChange(values.map((to) => to.label));
                                            setCloseTagsOnSelection(false);
                                            setDefaultTagValues(values);
                                            setTags(values.map((to) => to.label));
                                        }}
                                    />
                                )}
                            />
                        </FormGroup>
                    )}
                </div>
            </div>
            <div>
                <FormGroup data-testid="asset-form-assetId" className="spg-d-hidden">
                    <Controller
                        render={({ field }) => <InputField {...field} disabled />}
                        name="assetId"
                        key="assetId"
                        control={control}
                    />
                </FormGroup>
                <FormGroup data-testid="asset-form-riskFactorDetails" className="spg-d-hidden">
                    <Controller
                        render={({ field }) => <InputField {...field} disabled />}
                        name="riskFactorDetails"
                        key="riskFactorDetails"
                        variant="outlined"
                        control={control}
                        disabled
                    />
                </FormGroup>
                <FormGroup data-testid="asset-form-refId" className="spg-d-hidden">
                    <Controller
                        render={({ field }) => <InputField {...field} />}
                        name="refId"
                        key="refId"
                        control={control}
                        disabled
                    />
                </FormGroup>
                <FormGroup data-testid="asset-form-refName" className="spg-d-hidden">
                    <Controller
                        render={({ field }) => <InputField {...field} />}
                        name="refName"
                        key="refName"
                        control={control}
                        disabled
                    />
                </FormGroup>
            </div>

            {selectedAssetType.length > 0 ? (
                <HazardToImpactSelector
                    AssetTypeDetails={selectedAssetType}
                    hazardsForAssetType={hazardsForAssetType}
                    impactFunctionsForAssetType={impactFunctionsForAssetType}
                    control={control}
                    getValues={getValues}
                    errors={errors}
                />
            ) : (
                <Spinner size={DEFAULT_SPINNER_SIZE} />
            )}
            <div>
                <LocationForm
                    fields={fields}
                    control={control}
                    setValue={setValue}
                    insert={insert}
                    remove={remove}
                    append={append}
                    errors={errors}
                    displayAddLocation={true}
                />
            </div>
            <div className="spg-row spg-d-flex spg-justify-end">
                <Button type="submit" purpose={Purpose.PRIMARY} id="submitAssetButton">
                    {`${action === 'edit' ? 'Update' : 'Save'} Asset`}
                </Button>
                <Button
                    type="button"
                    className="spg-ml-md"
                    onClick={cancelClicked}
                    purpose={Purpose.SECONDARY}
                >
                    Cancel
                </Button>
            </div>
        </form>
    );
}
