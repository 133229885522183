export const addDynamicStyles = (data, isSummaryReportTable) => {
    data?.forEach((element) => {
        switch (element.processing_status?.toLowerCase()) {
            case 'queued':
                element.className = 'spg-text-warning';
                element.processing_status_text = 'Queued';
                break;
            case 'in progress':
                element.className = 'spg-text-warning';
                element.processing_status_text = 'In progress';
                break;
            case 'failed':
                element.className = 'spg-text-error';
                element.processing_status_text = 'Failed';
                break;
            case 'completed':
                element.className = 'spg-text-success';
                element.processing_status_text = element.ready_for_download
                    ? 'Download'
                    : 'Completed';
                break;
            default:
                element.className = 'spg-text-error';
                element.processing_status_text = !isSummaryReportTable ? 'Data Not Found' : 'Error';
        }
    });
    return data;
};

export const getLocalDateTime = (rowData) => {
    if (rowData && rowData.created_at) {
        return `${new Date(rowData?.created_at)?.toLocaleDateString()} ${new Date(
            rowData?.created_at
        )?.toLocaleTimeString()}`;
    }
};
