import React, { useContext, useEffect } from 'react';

import { IconButton } from '@spglobal/react-components';
import { RESTORE } from '@spglobal/koi-icons';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { Size } from '@spglobal/koi-helpers';
import { getRole } from 'utils/user';
import UserContext from 'context/UserContext';
import ScoringDetailsDialog from './ScoringDetailsDialog';
import DataService from '../../service/data.service';
import DataTableGrid from '../../components/DataTable/DataTableGrid/DataTableGrid';
import { UPLOAD_STATUS_TABLE_ACTION } from '../constants/import.constants';
import UploadStatusBadgeCellRenderer from './UploadStatusBadge';
import { TableDataContext } from '../../components/shared';
import { DEFAULT_PAGE_DATA } from '../../components/components.constants';

export default function UploadStatusTable(props) {
    const { totalCount, onPageDataChange } = props;
    const [pageData, setPageData] = React.useState(DEFAULT_PAGE_DATA);
    const [openModal, setOpenModal] = React.useState(false);
    const [selectedColumnData, setSelectedColumnData] = React.useState({});
    const { user: userContextDetails } = React.useContext(UserContext);
    const role = getRole(
        userContextDetails && userContextDetails.SPGGroups ? userContextDetails.SPGGroups : [],
    );

    const service = new DataService();

    const devOnlyStatuses = [
        'RESCORING_INPROGRESS',
        'RESCORING_FAILED',
        'RESCORING_SUCCEEDED',
        'RESCORING_PARTIALLY_SUCCEEDED',
        'RESCORING_AGGREGATION_INPROGRESS',
        'RESCORING_AGGREGATION_FAILED',
    ];

    const calculateOffset = () => pageData.page * pageData.rowsPerPage;

    const handlePageChange = (newPageIndex) => {
        const pageInfo = {
            page: newPageIndex,
            clicked: true,
            rowsPerPage: pageData.rowsPerPage,
        };
        setPageData(pageInfo);
        onPageDataChange(pageInfo);
    };

    const handleChangeRowsPerPage = (newPageSize) => {
        const pageInfo = {
            page: 0,
            clicked: true,
            rowsPerPage: newPageSize,
        };
        setPageData(pageInfo);
        onPageDataChange(pageInfo);
    };

    const actionHandler = async (rowData, actionSelected, tableData) => {
        if (actionSelected !== UPLOAD_STATUS_TABLE_ACTION.REFRESH) {
            setOpenModal(true);
            rowData.actionSelected = actionSelected;
            setSelectedColumnData(rowData);
        } else {
            const reqData = {
                file_id: rowData.file_id,
                customer_id: rowData.customer_id,
            };
            const fileUploadData = await service.getFileUploadProcessingStatus(reqData);
            tableData.forEach((element) => {
                if (element.id === rowData.id) {
                    element.status = fileUploadData.data.status;
                    element.number_of_asset = fileUploadData.data.asset_count;
                    element.total_errored = fileUploadData.data.total_errored;
                    element.total_processed = fileUploadData.data.total_processed;
                    element.download_details.download_count = fileUploadData.data.download_count;
                    element.download_details.last_download_date =
                        fileUploadData.data.download_last_download_date;
                    element.download_details.user_name = fileUploadData.data.download_user_name;
                }
            });
            const offset = calculateOffset();
            const updatedTable = await props.getUploadProcessingStatus(
                offset,
                pageData.rowsPerPage,
                tableData,
            );
            props.updateTableState(updatedTable);
        }
    };

    const handleClose = () => {
        setOpenModal(false);
    };

    useEffect(() => setPageData(DEFAULT_PAGE_DATA), [props.searchData]);

    const ActionsCellRenderer = (params) => {
        const { exportTableData } = useContext(TableDataContext);
        return (
            <div>
                <IconButton
                    icon={faExclamationCircle}
                    title="Scoring Status"
                    onClick={() =>
                        actionHandler(params.data, UPLOAD_STATUS_TABLE_ACTION.VIEW_SCORING_DETAILS)
                    }
                    disabled={!params.data.is_scoring_error}
                    size={Size.SMALL}
                />
                <IconButton
                    icon={RESTORE}
                    title="Refresh"
                    onClick={() =>
                        actionHandler(
                            params.data,
                            UPLOAD_STATUS_TABLE_ACTION.REFRESH,
                            exportTableData,
                        )
                    }
                    size={Size.SMALL}
                />
            </div>
        );
    };

    const columnDefs = [
        {
            headerName: 'Upload Date',
            field: 'upload_date_display',
            tooltipField: 'upload_time',
        },
        {
            headerName: 'File Name',
            field: 'original_filename',
            tooltipField: 'original_filename',
        },
        {
            headerName: '#Assets',
            field: 'number_of_asset',
            tooltipField: 'number_of_asset',
        },
        {
            headerName: 'User Name',
            field: 'username',
            tooltipField: 'username',
        },
        {
            headerName: 'Processing Status',
            cellRenderer: 'processingStatus',
            cellRendererParams: (params) => ({
                rowData: params.data,
            }),
            cellClass: 'cli-action-cell',
        },
        {
            headerName: 'Actions',
            cellRenderer: 'actionsRenderer',
            width: 80,
            maxWidth: 80,
            cellStyle: { alignItems: 'baseline' },
            cellClass: 'cli-action-cell',
        },
    ];

    const frameworkComponents = {
        processingStatus: UploadStatusBadgeCellRenderer,
        actionsRenderer: ActionsCellRenderer,
    };

    return (
        <>
            <DataTableGrid
                rowData={props.tableData.filter((row) => {
                    if (
                        role &&
                        role !== 'GRP_CLIM_DEVELOPER' &&
                        devOnlyStatuses.includes(row.status)
                    ) {
                        return false;
                    }
                    return true;
                })}
                columnDefs={columnDefs}
                totalItemsCount={totalCount}
                frameworkComponents={frameworkComponents}
                onPageChange={(pageIndex) => handlePageChange(pageIndex)}
                onPageSizeChange={(newPageSize) => handleChangeRowsPerPage(newPageSize)}
            />
            <ScoringDetailsDialog
                {...props}
                open={openModal}
                onClose={handleClose}
                selectedColumnData={selectedColumnData}
                getUploadProcessingStatus={props.getUploadProcessingStatus}
                updateTableState={props.updateTableState}
            />
        </>
    );
}
