import { httpClient } from '../utils/httpClient';
import { API_URL } from '../utils/constants';

const ENDPOINT = 'api/customers';
const ENDPOINT_BC = 'api/customers/hierarchy';

const getAll = async (params = {}) => {
    try {
        const result = await httpClient.get(`${API_URL}/${ENDPOINT}`, { params });
        return result.data;
    } catch (e) {
        console.log('Error in fetching list of customers :', e);
    }
};

const getAllManageCustomers = async (params = {}) => {
    try {
        const result = await httpClient.get(`${API_URL}/${ENDPOINT}`, { params });
        return result.data;
    } catch (e) {
        console.log('Error in fetching list of manage customers :', e);
    }
};

const getCustomerById = async (customerId) => {
    const API_SOURCE = API_URL;
    try {
        const { data } = await httpClient.get(`${API_SOURCE}/${ENDPOINT}/${customerId}`);
        return data;
    } catch (e) {
        console.log('Error in fetching customer data :', e);
    }
};

const getCustomerByIdManage = async (customerId) => {
    try {
        const { data } = await httpClient.get(`${API_URL}/${ENDPOINT}/${customerId}?manage=true`);
        return data;
    } catch (e) {
        console.log('Error in fetching manage customer data :', e);
    }
};

const createCustomer = async (customerName) => {
    try {
        const param = { customerName };
        const result = await httpClient.post(`${API_URL}/${ENDPOINT}`, param);
        return result.data;
    } catch (e) {
        console.log('Error in creating customer data :', e);
    }
};

const createCustomerManage = async (param) => {
    const result = await httpClient.post(`${API_URL}/${ENDPOINT}`, param);
    return result;
};

const updateCustomer = async (customerId, updatedCustomerName) => {
    try {
        const param = { updatedCustomerName };
        const result = await httpClient.put(`${API_URL}/${ENDPOINT}/${customerId}`, param);
        return result.data;
    } catch (e) {
        console.log('Error in updating customer data :', e);
    }
};

const updateCustomerManage = async (customerId, param) => {
    const result = await httpClient.put(`${API_URL}/${ENDPOINT}/${customerId}`, param);
    return result;
};

const getAllBreadcrumbs = async (entityType, entityId) => {
    try {
        const API_SOURCE = API_URL;
        const result = await httpClient.get(
            `${API_SOURCE}/${ENDPOINT_BC}?entityType=${entityType}&entityId=${entityId}`
        );
        return result.data;
    } catch (e) {
        console.log('Error in fetching breadcrumb data :', e);
    }
};

export default {
    getAll,
    getAllManageCustomers,
    createCustomer,
    createCustomerManage,
    updateCustomer,
    updateCustomerManage,
    getAllBreadcrumbs,
    getCustomerById,
    getCustomerByIdManage,
};
