import React, { useState, useEffect, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import AccessTokenContext from '../../context/AccessTokenContext';
import BreadcrumbTrail from '../BreadcrumbTrail';
import MessageBox from '../MessageBox';
import ImpactFunctionForm from '../ImpactFunctionForm';
import impactFunctionService from '../../services/impactFunctionService';
import impactFunctionTransformer from '../../utils/impactFunctionTransformer';
import pathParser from '../../utils/pathParser';
import googleAnalyticsEvent from '../../utils/googleAnalyticsEvent';
import * as constants from '../../utils/constants';
import { ParagraphLg } from '../shared';

const SUCCESS_MSG = 'Successfully updated impact function.';
const ERROR_UPDATING_MSG = 'Error updating impact function';

const breadcrumbs = [
    {
        title: 'Impact Functions',
        linkDetails: '/impact-functions',
    },
    {
        title: 'Impact Function Details',
        linkDetails: null,
    },
    {
        title: 'Edit Impact Function',
        linkDetails: null,
    },
];

export default function EditImpactFunction() {
    const history = useHistory();
    const { pathname } = useLocation({});
    const { getTokenAndTryAgain } = useContext(AccessTokenContext);
    const apiOptions = {
        scope: `${constants.CUSTOMER_READ_IMPACT_FUNCTION} ${constants.GLOBAL_EDIT_IMPACT_FUNCTION}`,
    };
    const { 'impact-functions': impactFunctionId } = pathParser.getPathComponents(pathname);
    const displayHeader = 'Edit Impact Function';

    const [impactFunctionData, setImpactFunctionData] = useState({});
    const [shouldDisplayMessageBox, setShouldDisplayMessageBox] = useState(false);
    const [messageBoxText, setMessageBoxText] = useState('');

    useEffect(() => {
        let isSubscribed = true;
        async function fetchData() {
            try {
                const result = await impactFunctionService.getImpactFunction(impactFunctionId);
                if (isSubscribed) {
                    setImpactFunctionData({
                        impactFunctionName: result.name,
                        impactFunctionDescription: result.description,
                        hazard: result.hazardId,
                        riskFactor: result.riskFactorId,
                        xMetric: result.xMetric,
                        xMetricUnits: result.xUnits,
                        xAxisLabel: result.xAxisLabel || '',
                        yMetric: result.yMetric || 'productivity',
                        yMetricUnits: result.yUnits || 'percent',
                        yAxisLabel: result.yAxisLabel || '',
                        impactPathways:
                            result.impactPathways && result.impactPathways.length > 0
                                ? impactFunctionTransformer.parseImpactPathways(result.impactPathways)
                                : [],
                    });

                    breadcrumbs[1].linkDetails = `/impact-functions/${result.id}`;
                    breadcrumbs[1].title = result.name;
                }
            } catch (error) {
                if (error.error === 'consent_required') {
                    return await getTokenAndTryAgain(apiOptions);
                }
                throw new Error(error);
            }
        }
        fetchData();
        return () => (isSubscribed = false);
    }, []);

    const messageBoxDismissed = async (okClicked) => {
        setShouldDisplayMessageBox(false);
        if (messageBoxText === SUCCESS_MSG) {
            history.push(`/impact-functions/${impactFunctionId}`);
        }
    };

    const cancelFunction = () => {
        history.push(`/impact-functions/${impactFunctionId}`);
    };

    const onSubmit = async (data, e) => {
        const formatted = impactFunctionTransformer.transformImpactFunction(data);

        try {
            await impactFunctionService.editImpactFunction(impactFunctionId, formatted);
            googleAnalyticsEvent('Update Entity', 'Impact Function', 'success');
            setMessageBoxText(SUCCESS_MSG);
        } catch (error) {
            if (error.error === 'consent_required') {
                await getTokenAndTryAgain(apiOptions);
            }
            googleAnalyticsEvent('Update Entity', 'Impact Function', 'failure');
            setMessageBoxText(`${ERROR_UPDATING_MSG}: ${error.response.data?.message}`);
        }
        setShouldDisplayMessageBox(true);
    };

    return (
        <>
            <MessageBox
                messageBoxIsVisible={shouldDisplayMessageBox}
                messageBoxTitle="Impact Function"
                messageBoxText={messageBoxText}
                messageBoxCallback={messageBoxDismissed}
                cancelText=""
                okText="Ok"
            />
            <BreadcrumbTrail
                rootTitle="Impact Functions"
                manageParent="/impact-functions"
                manageChild="Edit Impact Function"
            />
            <div className="spg-w-100 spg-p-md">
                {impactFunctionData && impactFunctionData.impactPathways ? (
                    <ImpactFunctionForm
                        cancelClicked={cancelFunction}
                        submitClicked={onSubmit}
                        displayHeader={displayHeader}
                        defaultFormValues={impactFunctionData}
                    />
                ) : (
                    <ParagraphLg className="spg-d-flex spg-justify-center">Loading Edit Form...</ParagraphLg>
                )}
            </div>
        </>
    );
}
