import { httpClient } from '../utils/httpClient';
import { API_URL } from '../utils/constants';

export const getDecadalStates = async (hazardMetricId, scenarioId, pointLocId, riskFactorName) => {
    const API_SOURCE = API_URL;

    const response = await httpClient.get(`${API_SOURCE}/api/hazardProfile`, {
        params: {
            hazardId: hazardMetricId,
            scenarioId,
            riskType: riskFactorName,
            locationId: pointLocId,
        },
    });
    const originalResponse = mapResponseToOldKeys(response.data.result);
    return originalResponse;
};

const mapResponseToOldKeys = (data) => {
    const mappedData = data.map((element) => ({
        decadalData: {
            decade: element?.decadal_data?.decade,
            hazardMetricMean: element?.decadal_data?.hazard_mean.toFixed(4),
        },
        locationId: element?.location_id,
        baselineText: element?.baseline_text,
        yLabel: element?.yLabel,
        description: element?.description,
        units: element?.units,
    }));
    return mappedData;
};

export default {
    getDecadalStates,
};
