import * as constants from './constants';

function sortRiskAndOpportunities(formattedTableData) {
    const risk = [];
    const opp = [];
    formattedTableData?.forEach((ele) => {
        // 1 = Transition Risk, 2 = Physical Risk, 3 = Opportunities
        if (ele.tcfdId > 2) {
            opp.push(ele);
        } else {
            risk.push(ele);
        }
    });
    // 1 = All Risks, 2 = Opportunities
    return {
        [constants.RISKS]: risk,
        [constants.OPPORTUNITIES]: opp,
    };
}

export default sortRiskAndOpportunities;
