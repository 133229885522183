export const oktaStorage = JSON.parse(localStorage.getItem('okta-token-storage'));

export const getUserEmail = () => {
    const { idToken } = oktaStorage || {};
    return idToken?.claims?.email;
};

export const isUserEditorOrViewer = () => {
    const { idToken } = oktaStorage || {};
    const userRoles = idToken?.claims?.SPGGroups;
    return userRoles?.includes('CLIM_CLIENT_EDITORS') || userRoles?.includes('CLIM_VIEWER');
};

export const isUserAdmin = () => {
    const { idToken } = oktaStorage || {};
    const userRoles = idToken?.claims?.SPGGroups;
    return userRoles?.includes('CLIM_ADMIN');
};

export const getPortfolioResourceKey = (customerType) =>
    customerType === 'active'
        ? 'ActiveCustomerPortfolios'
        : customerType === 'trial'
        ? 'TrialCustomerPortfolios'
        : 'DemoAndSamplePortfolios';

export const getUserDetails = () => {
    const { idToken } = oktaStorage || {};
    return idToken?.claims || {};
};
