const getPathComponents = (curPath) => {
    const pathComponents = {};
    if (!curPath) {
        return pathComponents;
    }
    const pathParts = curPath.split('/');
    for (let x = 1; x < pathParts.length; x += 2) {
        const curPart = pathParts[x] === 'manage' ? 'customers' : pathParts[x];
        let curVal = '';
        if (x + 1 < pathParts.length) {
            curVal = pathParts[x + 1];
        }
        pathComponents[curPart] = curVal;
    }
    return pathComponents;
};

export default { getPathComponents };
