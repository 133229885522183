import React from 'react';
import styled from '@emotion/styled';
import ProductHeader from '../ProductHeader';
import ProductCard from '../ProductCard';
import ProductAlert from '../ProductAlert';

const ListItem = styled.li`
    list-style: none;
    padding: 0;
`;

export default function ProductList({ productType, products }) {
    return (
        <>
            {products?.length > 0 && (
                <ListItem key={productType} data-testid="product-list-container">
                    <ProductHeader headline={productType} />
                    {productType === 'Trial Products' && (
                        <ProductAlert data-testid="product-list-alert" />
                    )}
                    <ul className="spg-list" data-testid="product-list-products-list">
                        {products.map((product) => (
                            <ListItem key={product.productName} className="spg-mt-md">
                                <ProductCard product={product} />
                            </ListItem>
                        ))}
                    </ul>
                </ListItem>
            )}
        </>
    );
}
