import React from 'react';

export const accessToken = '';

const AccessTokenContext = React.createContext({
    accessToken,
    getTokenAndTryAgain: () => {},
    retrieveAccessToken: () => {},
});

export const AccessTokenProvider = AccessTokenContext.Provider;
export const AccessTokenConsumer = AccessTokenContext.Consumer;

export default AccessTokenContext;
