import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import formatCurrency from 'utils/formatCurrency';
import convertToUSDMillion from 'utils/convertToUSDMillion';
import sortRiskAndOpportunities from 'utils/sortRiskAndOpportunities';
import { Badge, Card, Divider, Button, Icon, Spinner } from '@spglobal/react-components';
import { ANGLE_LEFT, ANGLE_RIGHT } from '@spglobal/koi-icons';
import Hazard from './Hazard';
import { HeadingSm, TextBodyLg } from '../shared';
import { DEFAULT_SPINNER_SIZE } from '../components.constants';

const riskFactorCategories = ['Transition Risks', 'Physical Risks', 'Opportunities'];

export default function HazardImpactViewer({
    hazardImpactChartData,
    riskDataFilters,
    handleDetailButtonClicked,
    navigateToPeerEntity,
    peerEntities,
    hasEmissionData,
    viewType,
}) {
    const [filteredRiskData, setFilteredRiskData] = useState([]);
    const [tcfdCategoryIds, setTcfdCategoryIds] = useState([2, 1]);
    const [hazardOnlyData, setHazardOnlyData] = useState([]);
    const [isMounted, setIsMounted] = useState(false);

    const hazardOnlyMessage =
        'The relative and absolute MAAL values for this hazard are not yet available as impact functions are still in the process of being developed. This hazard is not yet included in the aggregated MAAL values represented by the line graph above. MAAL values will become available once impact functions are implemented. Click on the chart icon to view the projected hazard values relative to the baseline for 8 decades for this asset.';

    useEffect(() => {
        const fetchData = async () => {
            setIsMounted(false);
            const sortRiskAndOppFormatted = sortRiskAndOpportunities(hazardImpactChartData);
            const risks = sortRiskAndOppFormatted[riskDataFilters.analysisType].filter(
                (risk) => risk.hazardOnly === false,
            );

            const hazardOnly = sortRiskAndOppFormatted[riskDataFilters.analysisType]
                .filter((risk) => risk.hazardOnly === true)
                .map((h) => ({
                    ...h,
                    dataAvailabilityMessage: hazardOnlyMessage,
                }));
            setFilteredRiskData(risks);
            setHazardOnlyData(hazardOnly);
            const tcfdCategories = [...new Set(risks.map((riskFactor) => riskFactor.tcfdId))].sort(
                (a, b) => b - a,
            );
            setTcfdCategoryIds(tcfdCategories);
            setIsMounted(true);
        };
        fetchData();
    }, [hazardImpactChartData, riskDataFilters]);
    if (riskDataFilters.riskFactor[1].length === 0 && riskDataFilters.riskFactor[2].length === 0) {
        return (
            <TextBodyLg id="riskGraphRisksUnavailable">
                There are currently no
                {riskDataFilters.analysisType === 1 ? ' risks ' : ' opportunities '}
                to display.
            </TextBodyLg>
        );
    }

    return (
        <Card hasBorder hasRoundedCorner clasName="spg-w-100 spg-mb-md">
            {!isMounted && <Spinner size={DEFAULT_SPINNER_SIZE} />}
            {isMounted && filteredRiskData && filteredRiskData.length > 0 && tcfdCategoryIds && (
                <ul id="risk-factors-list" className="spg-list spg-list--unstyled">
                    {tcfdCategoryIds.map((riskCategoryId) => {
                        const riskCategoryFactors = filteredRiskData.filter(
                            (riskFactor) => riskFactor.tcfdId === riskCategoryId,
                        );

                        const hazardOnlyCategory = hazardOnlyData.filter(
                            (hazard) => hazard.tcfdId === riskCategoryId,
                        );

                        const enabledRiskFactors =
                            riskDataFilters.riskFactor[riskDataFilters.analysisType];

                        const relativeRiskSumTotal = riskCategoryFactors.reduce(
                            (acc, currentRiskFactor) => {
                                if (
                                    !currentRiskFactor.relativeValue ||
                                    Number.isNaN(currentRiskFactor.relativeValue) ||
                                    !enabledRiskFactors.includes(currentRiskFactor.riskFactorName)
                                ) {
                                    return acc;
                                }
                                return acc + currentRiskFactor.relativeValue;
                            },
                            0,
                        );
                        const absoluteRiskSumTotal = riskCategoryFactors.reduce(
                            (acc, currentRiskFactor) => {
                                if (
                                    !currentRiskFactor.absoluteValue ||
                                    Number.isNaN(currentRiskFactor.absoluteValue) ||
                                    !enabledRiskFactors.includes(currentRiskFactor.riskFactorName)
                                ) {
                                    return acc;
                                }
                                return acc + currentRiskFactor.absoluteValue;
                            },
                            0,
                        );
                        return (
                            <React.Fragment
                                key={`filteredRiskData-tcfd-${riskCategoryId}-riskCategory`}
                            >
                                <li
                                    key={`riskCategory-${riskCategoryId}`}
                                    className="spg-d-flex"
                                    disabled
                                >
                                    <HeadingSm className="spg-mr-md">
                                        {riskFactorCategories[riskCategoryId - 1]}
                                    </HeadingSm>
                                    <Badge className="spg-p-sm">{`${relativeRiskSumTotal.toFixed(
                                        riskDataFilters.riskValuePrecision,
                                    )}%`}</Badge>
                                    <Badge className="spg-p-sm">
                                        {formatCurrency(
                                            convertToUSDMillion(absoluteRiskSumTotal),
                                            riskDataFilters.riskValuePrecision,
                                        )}
                                    </Badge>
                                </li>
                                {riskDataFilters &&
                                    riskCategoryFactors.map((risk, index) => {
                                        const isDisabledRiskFactor = !enabledRiskFactors.find(
                                            (erf) => erf === risk.riskFactorName,
                                        );
                                        return (
                                            <Hazard
                                                key={`${risk.riskFactorId}-${index}`}
                                                riskFactorId={risk.riskFactorId}
                                                riskDataFilters={riskDataFilters}
                                                riskFactorName={risk.riskFactorName}
                                                hazardMetricId={risk.hazardMetricId}
                                                hazardMetricName={risk.hazardMetricName}
                                                impactFunctionId={risk.impactFunctionId}
                                                impactFunctionName={risk.impactFunctionName}
                                                absoluteValue={risk.absoluteValue}
                                                relativeValue={risk.relativeValue}
                                                handleDetailButtonClicked={
                                                    handleDetailButtonClicked
                                                }
                                                hasEmissionData={hasEmissionData}
                                                dataAvailableAllLocations={
                                                    viewType === 'asset'
                                                        ? risk.dataAvailableAllLocations || true
                                                        : true
                                                }
                                                dataAvailableSomeLocations={
                                                    viewType === 'asset'
                                                        ? risk.dataAvailableSomeLocations || true
                                                        : true
                                                }
                                                dataAvailabilityMessage={
                                                    viewType === 'asset'
                                                        ? risk.dataAvailabilityMessage || ''
                                                        : ''
                                                }
                                                viewType={viewType}
                                                isDisabledRiskFactor={
                                                    isDisabledRiskFactor ||
                                                    risk.relativeValue === null ||
                                                    (risk.riskFactorId === 1 &&
                                                        !hasEmissionData &&
                                                        viewType === 'asset')
                                                }
                                                hazardOnly={false}
                                            />
                                        );
                                    })}
                                {riskDataFilters &&
                                    viewType === 'asset' &&
                                    hazardOnlyCategory.map((hazard, index) => (
                                        <Hazard
                                            key={`${hazard.hazardId}-${index}`}
                                            riskDataFilters={riskDataFilters}
                                            hazardMetricId={hazard.hazardId}
                                            handleDetailButtonClicked={handleDetailButtonClicked}
                                            dataAvailabilityMessage={
                                                viewType === 'asset'
                                                    ? hazard.dataAvailabilityMessage || ''
                                                    : ''
                                            }
                                            viewType={viewType}
                                            hazard={hazard}
                                            hazardOnly={hazard.hazardOnly}
                                        />
                                    ))}
                            </React.Fragment>
                        );
                    })}
                </ul>
            )}
            {isMounted && navigateToPeerEntity && peerEntities && peerEntities.length > 0 && (
                <div>
                    <Divider />
                    <div className="spg-d-flex spg-justify-between">
                        <Button
                            onClick={(event) => {
                                event.preventDefault();
                                navigateToPeerEntity(-1);
                            }}
                            leftIcon={<Icon icon={ANGLE_LEFT} />}
                            disabled={peerEntities && peerEntities.length < 2}
                        >
                            {`Previous ${viewType}`}
                        </Button>
                        <Button
                            onClick={(event) => {
                                event.preventDefault();
                                navigateToPeerEntity(1);
                            }}
                            rightIcon={<Icon icon={ANGLE_RIGHT} />}
                            disabled={peerEntities && peerEntities.length < 2}
                        >
                            {`Next ${viewType}`}
                        </Button>
                    </div>
                </div>
            )}
        </Card>
    );
}

HazardImpactViewer.propTypes = {
    entityId: PropTypes.string,
    entityType: PropTypes.number,
    handleDetailButtonClicked: PropTypes.func,
    hasEmissionData: PropTypes.bool,
    investmentFolderId: PropTypes.number || PropTypes.string,
    locations: PropTypes.array,
    navigateToPeerEntity: PropTypes.func,
    peerEntities: PropTypes.array,
    riskDataFilters: PropTypes.object,
    viewType: PropTypes.string,
};
