import React, { useState, useEffect } from 'react';

import { Capsule, FormGroup, Select } from '@spglobal/react-components';
import { FORM_ACTIONS, RBAC_USERS_ROLES_TYPES } from '../../../utils/constants';
import { transformDataObjForKoiSelect } from '../../../utils/koiIntegrationUtils';
import { TextBodyLg } from '../../shared';

export default function AssignCustomerDropdown({
    userData,
    user,
    users,
    customersData,
    onCustomersSelection,
    action,
    type,
    editUserId,
}) {
    const [selectedCustomers, setSelectedCustomers] = useState([]); // IOption[]
    const [customers, setCustomers] = useState([]); // IOption[]

    useEffect(() => {
        setCustomers(
            customersData.map((c) =>
                transformDataObjForKoiSelect(c, { id: 'id', label: 'name', value: 'id' }),
            ),
        );
        if (action === FORM_ACTIONS.EDIT && type === 'internal' && editUserId) {
            const curUser = users.filter((item) => item.uid === editUserId);
            let curCustomers = [];
            if (Array.isArray(curUser) && curUser.length) {
                const useIds = curUser[0].customer.map((item) => item.id);
                curCustomers = customersData.filter((custItem) =>
                    useIds.some((id) => id === custItem.id),
                );
                setSelectedCustomers(
                    curCustomers.map((c) =>
                        transformDataObjForKoiSelect(c, { id: 'id', label: 'name', value: 'id' }),
                    ),
                );
                onCustomersSelection(curCustomers);
            }
        }
        if (action === FORM_ACTIONS.EDIT && type === 'external' && editUserId) {
            const curUser = users.filter((item) => item.uid === editUserId);
            let curCustomers = [];
            if (Array.isArray(curUser) && curUser.length) {
                const useIds = curUser.map((item) => item.customer.id);
                curCustomers = customersData.filter((custItem) =>
                    useIds.some((id) => id === custItem.id),
                );
                setSelectedCustomers(
                    curCustomers.map((c) =>
                        transformDataObjForKoiSelect(c, { id: 'id', label: 'name', value: 'id' }),
                    ),
                );
                onCustomersSelection(curCustomers);
            }
        }
    }, [editUserId, customersData]);

    const handleChange = (selectedValues) => {
        const selectedIds = selectedValues.map((v) => v.id);
        const customersSelection = customersData.filter((c) => selectedIds.includes(c.id));

        setSelectedCustomers(selectedValues);
        onCustomersSelection(customersSelection);
    };

    const handleDelete = (customer) => () => {
        const newSelectedCust = selectedCustomers.filter((item) => item.id !== customer.id);

        const selectedIds = newSelectedCust.map((v) => v.id);
        const customersSelection = customersData.filter((c) => selectedIds.includes(c.id));

        setSelectedCustomers(newSelectedCust);
        onCustomersSelection(customersSelection);
    };

    const isCustomerDisabled = (chip) => {
        const roleName =
            user && user.role && Array.isArray(user.role) && user.role[0] ? user.role[0] : '';

        if (!roleName) {
            return 'NA';
        }

        if (RBAC_USERS_ROLES_TYPES.OKTA_ROLE_ALL.some((item) => item === roleName)) {
            return chip === 'chip' ? 'All Customers' : true;
        }
        if (RBAC_USERS_ROLES_TYPES.OKTA_ROLE_NA.some((item) => item === roleName)) {
            return chip === 'chip' ? 'NA' : true;
        }
        return false;
    };

    return (
        <>
            <div>
                <FormGroup label="Select Customers" labelFor="customer-checkbox">
                    <Select
                        id="customer-checkbox"
                        disabled={!!isCustomerDisabled(user)}
                        isMulti
                        options={customers}
                        defaultValue={selectedCustomers}
                        onChange={handleChange}
                        isSearchable
                    />
                </FormGroup>
            </div>
            <div className="spg-row spg-p-md">
                {!isCustomerDisabled() && (
                    <div>
                        {selectedCustomers.map((customer, i) => (
                            <Capsule onClose={handleDelete(customer)} key={i} className="spg-mr-xs">
                                {customer.label}
                            </Capsule>
                        ))}
                        <TextBodyLg className="spg-mt-md">
                            Total Customers : {selectedCustomers.length}
                        </TextBodyLg>
                    </div>
                )}
                {isCustomerDisabled() && (
                    <div className="spg-p-md">
                        <Capsule>{isCustomerDisabled('chip')}</Capsule>
                    </div>
                )}
            </div>
        </>
    );
}
