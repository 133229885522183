import React, { useState, useEffect, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import AccessTokenContext from 'context/AccessTokenContext';
import UserContext from 'context/UserContext';
import viewService from 'services/viewService';
import CustomHeader from 'components/Header/CustomHeader';
import DeleteDialog from 'components/DeleteDialogTemplate/DeleteDialogTemplate';
import CreateUpdateEntityDialog from 'components/CreateUpdateEntityDialog';
import pathParser from 'utils/pathParser';
import googleAnalyticsEvent from 'utils/googleAnalyticsEvent';
import * as constants from 'utils/constants';
import useGetPermission from 'hooks/useGetPermission';
import CreateEditGroupDialog from 'components/CreateEditGroupDialog';
import { NotificationType, useNotification } from '@spglobal/react-components';
import { CONSENT_REQUIRED_ERROR } from 'utils/constants';
import GroupsDataTable from './GroupsDataTable';
import { DEFAULT_PAGE_DATA, DEFAULT_PAGE_SIZE } from '../components.constants';

const { CUSTOMER_EDIT_HIERARCHY, CUSTOMER_READ_HIERARCHY, VIEW, RENAME, EDIT, NEW_GROUP, DELETE } =
    constants;

const Groups = ({ pageData, onPageDataChange, resourceType }) => {
    const history = useHistory();
    const { pathname } = useLocation();
    const { getTokenAndTryAgain } = useContext(AccessTokenContext);
    const { user, isLoggedIn } = useContext(UserContext);
    const apiOptions = {
        scope: `${CUSTOMER_READ_HIERARCHY} ${CUSTOMER_EDIT_HIERARCHY}`,
    };
    const { customers: customerId } = pathParser.getPathComponents(pathname);
    const userPermission =
        user && Object.keys(user).length > 0 && useGetPermission(user, isLoggedIn, resourceType);
    const userPrivilege = userPermission?.action;
    const [dataLoaded, setDataLoaded] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [displayDialogType, setDisplayDialogType] = useState('');
    const [dialogText, setDialogText] = useState({ title: '', message: '' });
    const { addNotification } = useNotification();
    const [viewNameText, setViewNameText] = useState('');
    const [selectedRow, setSelectedRow] = useState(null);
    const [totalCount, setTotalCount] = useState(0);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [errorHelperText, setErrorHelperText] = useState('');

    const getViewsData = async (offset = 0, limit = DEFAULT_PAGE_SIZE, searchTextVal) => {
        const reqData = { offset, limit };
        if (searchTextVal?.length) reqData.view_name = searchTextVal;
        async function fetchData() {
            try {
                const response = await viewService.getAll(customerId, reqData);
                setDataLoaded(true);
                if (response) {
                    setTableData(response?.results);
                    setTotalCount(response?.total_views);
                }
                return response?.results;
            } catch (error) {
                setDataLoaded(true);
                if (error.error === CONSENT_REQUIRED_ERROR) {
                    return getTokenAndTryAgain(apiOptions);
                }
                return error;
            }
        }
        return fetchData();
    };

    useEffect(() => {
        (async function fetchViewsData() {
            const offset = pageData?.page * pageData?.rowsPerPage;
            await getViewsData(offset, pageData?.rowsPerPage, pageData?.searchText);
        })();
    }, []);

    useEffect(() => {
        (async function updateData() {
            if (pageData?.clicked) {
                const offset = pageData?.page * pageData?.rowsPerPage;
                await getViewsData(offset, pageData?.rowsPerPage, pageData?.searchText);
            }
        })();
    }, [pageData]);

    const handleTableSearchSubmit = (searchVal) => {
        getViewsData(0, DEFAULT_PAGE_SIZE, searchVal);
        onPageDataChange({
            ...pageData,
            ...DEFAULT_PAGE_DATA,
            searchText: searchVal,
        });
    };

    const handleViewActions = (actionType, rowDetails = {}) => {
        setSelectedRow(rowDetails);
        if (actionType === VIEW) {
            history.push({
                pathname: `/manage/${customerId}/views/${rowDetails.id}`,
            });
        } else if (actionType === NEW_GROUP) {
            setDisplayDialogType(actionType);
            setDialogText({ title: 'New Group' });
        } else if (actionType === EDIT) {
            setDisplayDialogType(actionType);
            setDialogText({ title: `Edit Group - ${rowDetails?.view_name}` });
        } else if (actionType === RENAME) {
            setViewNameText(rowDetails?.view_name);
            setDialogText({ title: 'Rename Group' });
            setDisplayDialogType(actionType);
        } else if (actionType === DELETE) {
            setShowDeleteDialog(true);
        }
    };

    const handleViewDelete = async (isDeleteConfirmed) => {
        setShowDeleteDialog(false);
        if (isDeleteConfirmed) {
            let msg = 'Group successfully deleted';
            let type = NotificationType.SUCCESS;
            try {
                await viewService.deleteView(customerId, selectedRow.id);
                await getViewsData(0, DEFAULT_PAGE_SIZE, pageData?.searchText);
                setSelectedRow(null);
                googleAnalyticsEvent('Delete Entity', 'View', 'success');
            } catch (error) {
                if (error.error === CONSENT_REQUIRED_ERROR) {
                    return getTokenAndTryAgain(apiOptions);
                }
                msg =
                    error?.response?.status === 401
                        ? `Error: ${error?.response?.data}`
                        : 'Error: View delete failed.';
                type = NotificationType.ERROR;
            }
            onPageDataChange({
                ...pageData,
                ...DEFAULT_PAGE_DATA,
            });
            addNotification(msg, type);
        }
        return 1;
    };

    const isViewNameInvalid = () => {
        let showErr = false;
        let errText = '';
        if (viewNameText?.length === 0) {
            showErr = true;
            errText = 'Please enter group name between length 1 to 256';
        } else if (
            tableData.some(
                (folder) =>
                    folder?.view_name?.toLowerCase() === viewNameText?.trim()?.toLowerCase(),
            )
        ) {
            showErr = true;
            errText = 'Group name already exists';
        } else if (viewNameText?.length > 256) {
            showErr = true;
            errText = 'Group name length cannot exceed 256 characters';
        }
        if (showErr) {
            setShowErrorMessage(showErr);
            setErrorHelperText(errText);
        }
        return showErr;
    };

    const handleCreateView = async (selectedFolders, selectedViews, newViewName) => {
        const requestBody = {
            view_name: newViewName?.trim(),
        };
        if (selectedFolders?.length) requestBody.folder_ids = selectedFolders;
        if (selectedViews?.length) requestBody.view_ids = selectedViews;
        let msg = 'Group successfully created';
        let type = NotificationType.SUCCESS;
        try {
            setDisplayDialogType('');
            await viewService.createView(customerId, requestBody);
            await getViewsData(0, DEFAULT_PAGE_SIZE, pageData?.searchText);
            googleAnalyticsEvent('Update Entity', 'View', 'success');
        } catch (error) {
            if (error.error === CONSENT_REQUIRED_ERROR) {
                return getTokenAndTryAgain(apiOptions);
            }
            msg = error.response
                ? `Error: ${error.response.data.message}`
                : `Error: ${error.message}`;
            type = NotificationType.ERROR;
        }
        onPageDataChange({
            ...pageData,
            ...DEFAULT_PAGE_DATA,
        });
        addNotification(msg, type);
        return 1;
    };

    const handleEditView = async (selectedFolders, selectedViews) => {
        const requestBody = {};
        if (selectedFolders?.length) requestBody.folder_ids = selectedFolders;
        if (selectedViews?.length) requestBody.view_ids = selectedViews;
        let msg = 'Group successfully Updated';
        let type = NotificationType.SUCCESS;
        try {
            setDisplayDialogType('');
            await viewService.editView(customerId, selectedRow?.id, requestBody);
            await getViewsData(0, DEFAULT_PAGE_SIZE, pageData?.searchText);
            googleAnalyticsEvent('Update Entity', 'View', 'success');
        } catch (error) {
            if (error.error === CONSENT_REQUIRED_ERROR) {
                return getTokenAndTryAgain(apiOptions);
            }
            msg = error.response
                ? `Error: ${error.response.data.message}`
                : `Error: ${error.message}`;
            type = NotificationType.ERROR;
        }
        onPageDataChange({
            ...pageData,
            ...DEFAULT_PAGE_DATA,
        });
        addNotification(msg, type);
        return 1;
    };

    const handleRenameView = async () => {
        if (!isViewNameInvalid()) {
            let msg = 'Group successfully renamed';
            let type = NotificationType.SUCCESS;
            try {
                setDisplayDialogType('');
                await viewService.renameView(customerId, selectedRow?.id, {
                    view_name: viewNameText?.trim(),
                });
                await getViewsData(0, DEFAULT_PAGE_SIZE, pageData?.searchText);
                setSelectedRow(null);
                googleAnalyticsEvent('Update Entity', 'View', 'success');
                setViewNameText('');
            } catch (error) {
                if (error.error === CONSENT_REQUIRED_ERROR) {
                    return getTokenAndTryAgain(apiOptions);
                }
                msg = error.response
                    ? `Error: ${error.response.data.message}`
                    : `Error: ${error.message}`;
                type = NotificationType.ERROR;
            }
            onPageDataChange({
                ...pageData,
                ...DEFAULT_PAGE_DATA,
            });
            addNotification(msg, type);
        }
        return 1;
    };

    const dismissDialog = () => {
        setViewNameText('');
        setDisplayDialogType('');
        setShowErrorMessage(false);
        setErrorHelperText('');
        setSelectedRow(null);
    };

    return (
        <>
            <CustomHeader
                searchTextPrev={pageData?.searchText}
                searchPlaceholder="Group Name"
                handleActionClick={handleViewActions}
                handleSearchSubmit={handleTableSearchSubmit}
                actionsArr={[NEW_GROUP]}
                hideActions={userPrivilege !== constants.ACTION.ACTION_WRITE}
            />
            <GroupsDataTable
                tableData={tableData}
                pageData={pageData}
                onPageDataChange={onPageDataChange}
                actionCallback={handleViewActions}
                isLoading={!dataLoaded}
                totalCount={totalCount}
                hideActions={userPrivilege !== constants.ACTION.ACTION_WRITE}
            />
            <CreateUpdateEntityDialog
                dialogIsVisible={displayDialogType === RENAME}
                dialogDismissed={dismissDialog}
                dialogOkClicked={handleRenameView}
                dialogText={dialogText}
                textToDisplay={viewNameText}
                setTextToDisplay={setViewNameText}
                showErrorMessage={showErrorMessage}
                helpText={errorHelperText}
                setShowErrorMessage={setShowErrorMessage}
                setErrorHelperText={setErrorHelperText}
                primaryButtonLabel="Rename"
            />
            <DeleteDialog
                isDialogOpen={showDeleteDialog}
                primaryMessage={`"${selectedRow?.view_name}" will be deleted.`}
                handleDialogAction={handleViewDelete}
                selectedRow={selectedRow}
            />
            {(displayDialogType === NEW_GROUP || displayDialogType === EDIT) && (
                <CreateEditGroupDialog
                    data={tableData}
                    dialogIsVisible={displayDialogType === NEW_GROUP || displayDialogType === EDIT}
                    dialogText={dialogText}
                    dialogDismissed={dismissDialog}
                    onCreateViewSubmit={handleCreateView}
                    onEditViewSubmit={handleEditView}
                    selectedRow={selectedRow}
                />
            )}
        </>
    );
};

export default Groups;
