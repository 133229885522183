import React, { useState } from 'react';
import UserList from './UserList';
import { DialogClientAdmin } from '../../DialogAdmins';

export function UserTab(props) {
    const { getUsersData, tableData = [], dataLoaded = false, customerId = '' } = props;

    const [isOpen, setIsOpen] = useState(false);
    const [action, setAction] = useState('');
    const [editUser, setEditUser] = useState('');

    const dialogParam = {
        handleClose: () => {
            setEditUser('');
            setIsOpen(false);
        },
        open: isOpen,
        type: 'user',
        action,
        users: tableData.length ? tableData : [],
        editUser,
        getUsersData,
        customerId: customerId ? parseInt(customerId) : '',
    };
    return (
        <>
            {<DialogClientAdmin {...dialogParam} />}
            <UserList
                tableData={tableData}
                dataLoaded={dataLoaded}
                isOpen={setIsOpen}
                action={setAction}
                editUser={setEditUser}
            />
        </>
    );
}

export default UserTab;
