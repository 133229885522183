import { httpClient } from 'utils/httpClient';
import { API_URL } from 'utils/constants';

const ENDPOINT = 'api/map';

const getMapKeys = async () => {
    const result = await httpClient.get(`${API_URL}/${ENDPOINT}`);
    return result?.data;
}

export default { getMapKeys };
