import React from 'react';
import { Footer as FooterKoi, FooterLink } from '@spglobal/react-components';

export default function Footer() {
    const year = new Date().getFullYear();

    return (
        <FooterKoi>
            &copy;{year} S&P Global
            &nbsp;&nbsp;
            <FooterLink href="https://www.spglobal.com/en/terms-of-use" target="_blank">
                Terms of use
            </FooterLink>
            &nbsp;&nbsp;&nbsp;
            <FooterLink href="https://www.spglobal.com/en/privacy" target="_blank">
                Privacy Policy
            </FooterLink>
            &nbsp;&nbsp;&nbsp;
            <FooterLink href="https://www.spglobal.com/en/cookie-notice" target="_blank">
                Cookie Notice
            </FooterLink>
            &nbsp;&nbsp;&nbsp;
            <FooterLink href="https://more.spglobal.com/DoNotSell" target="_blank">
                Do Not Sell My Personal Information
            </FooterLink>
        </FooterKoi>
    );
}
