import React from 'react';
import GenerateReportModal from '../components/GenerateReportModal';
import { SCENARIO_OPTIONS } from '../utils/constants';

export default function SummaryReportModal(props) {
    const {
        selectedAsset,
        selectedAnalysisType,
        selectedCustomer,
        selectedDecades,
        selectedFolders,
        selectedReportType,
        selectedScenarios,
        onConfirm,
    } = props;
    const getSummaryReportDetails = () => {
        const isPortfolioReportType = selectedReportType[0]?.label === 'Portfolio';
        const scenarioOptionsOrder = SCENARIO_OPTIONS.map(option => option.label);

        return [
            { label: 'Customer:', value: selectedCustomer?.label },
            {
                label: 'Selected Report Type:',
                value: selectedReportType?.map((item) => item?.label).join(', ')
            },
            {
                label: isPortfolioReportType ? 'Selected Folders:' : 'Selected Asset:',
                value: isPortfolioReportType ? selectedFolders?.map((item) => item?.label).join(', ') : selectedAsset?.name
            },
            {
                label: 'Selected Analysis Type:',
                value: selectedAnalysisType?.map((item) => item?.label).join(', ')
            },
            {
                label: 'Selected Scenarios:',
                value: selectedScenarios?.map((item) => item?.label).sort((a, b) => {
                    return scenarioOptionsOrder?.indexOf(a) - scenarioOptionsOrder?.indexOf(b);
                }).join(', '),
            },
            {
                label: 'Selected Decade:',
                value: selectedDecades?.map((item) => item?.label).join(', '),
            },
        ];
    };

    return (
        <GenerateReportModal tableOptions={getSummaryReportDetails()} onConfirm={onConfirm} />
    );
};
