import React, { useMemo, useState } from 'react';
import { FormGroup, Select } from '@spglobal/react-components';
import { isEmptyObject, transformDataObjForKoiSelect } from '../../utils/koiIntegrationUtils';
import { getUserDetails } from '../../utils/rbacUtils';
import { SP_EMAIL_DOMAIN } from '../../utils/constants';

export function CustomerSelect({
    customers,
    selectedCustomer,
    setSelectedCustomer,
    setSelectedFolders,
    setAssetTags,
    setSelectedAssetTags,
    setAssetsList,
    setSelectedAsset,
    setSearchAssetText,
    disableSelect = false,
}) {
    const [isDisabled, setIsDisabled] = useState(false);
    const { email } = getUserDetails();
    const isInternalUser = email.indexOf(SP_EMAIL_DOMAIN) !== -1;

    const customersOptions = useMemo(() => {
        return customers?.results?.map((option) =>
            transformDataObjForKoiSelect(option, { id: 'id', label: 'name', value: 'id' })
        );
    }, [customers]);

    const defaultSelectedCustomer = useMemo(() => {
        if (disableSelect && !isInternalUser && customersOptions?.length === 1) {
            setSelectedCustomer(customersOptions[0]);
            setIsDisabled(true);
            return customersOptions;
        }
        return !isEmptyObject(selectedCustomer) ? [selectedCustomer] : [];
    }, [customersOptions, disableSelect]);

    const handleCustomerChange = (values) => {
        setSelectedCustomer(values[0]);
        setSelectedFolders([]);
        setAssetTags?.([]);
        setSelectedAssetTags?.([]);
        setAssetsList?.([]);
        setSelectedAsset?.({});
        setSearchAssetText?.('');
    };

    return (
        <FormGroup label="Select Customer Name" labelFor="customer-select" required>
            <Select
                id="customer-select"
                defaultValue={defaultSelectedCustomer}
                options={customersOptions}
                onChange={handleCustomerChange}
                isMulti={false}
                closeOnSelection
                isSearchable
                isSearchTextRemovable
                placeholder=""
                disabled={isDisabled}
            />
        </FormGroup>
    );
}
