import * as React from 'react';
import { Modal, ModalContent, ModalFooter, ModalHeader, Button } from '@spglobal/react-components';
import { Purpose, Size } from '@spglobal/koi-helpers';
import GroupHierarchyTreeView from './GroupHierarchyTreeView';

export default function DialogboxGroupHierarchy({
    open,
    handleClose,
    selectedViewTree,
    editUser,
    action,
}) {
    return (
        <div>
            <Modal
                isOpen={open}
                onClose={handleClose}
                size={Size.SMALL}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <ModalHeader
                    id="alert-dialog-title"
                    title={selectedViewTree.selectedViewTree.view_name}
                />
                <ModalContent>
                    <GroupHierarchyTreeView
                        selectedViewTree={selectedViewTree}
                        editUser={editUser}
                        action={action}
                    />
                </ModalContent>
                <ModalFooter>
                    <Button purpose={Purpose.PRIMARY} onClick={handleClose} autoFocus>
                        Done
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}
