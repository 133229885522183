import { httpClient } from '../../utils/httpClient';
import { API_URL } from '../../utils/constants';

const ENDPOINT = 'api/customer';

export const generateSummaryReport = async (customerID, params = {}) => {
    const result = await httpClient.post(`${API_URL}/${ENDPOINT}/${customerID}/reports/summary-report`, params);
    return result.data;
};

export const getSummaryReports = async (params) => {
    try {
        const result = await httpClient.post(
            `${API_URL}/${ENDPOINT}/${params.customerId}/reports/summary-report`,
            params
        );
        return result.data;
    } catch (e) {
        console.log('Error in getting summary report data :', e);
    }
};

export const downloadSummaryReport = async (file_id, params) => {
    try {
        const result = await httpClient.get(
            `${API_URL}/${ENDPOINT}/${params.customer_id}/reports/summary-report/${file_id}/download`,
            { params }
        );
        return result.data;
    } catch (e) {
        console.log('Error in downloading report :', e);
    }
};

export const getSingleSummaryReport = async (params) => {
    try {
        const result = await httpClient.get(
            `${API_URL}/${ENDPOINT}/${params.customer_id}/reports/summary-report/${params.file_id}`
        );
        return result.data;
    } catch (e) {
        console.log('Error in getting single summary report:', e);
    }
};

export const getSummaryReportDownloadHistory = async (params) => {
    try {
        const result = await httpClient.get(
            `${API_URL}/${ENDPOINT}/${params.customer_id}/reports/summary-report/${params.file_id}/download`,
            { params }
        );
        return result.data;
    } catch (e) {
        console.log('Error in getting summary report download history :', e);
    }
};
