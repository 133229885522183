import React from 'react';
import {
    Icon,
    IconButton,
    IconSize,
    Tooltip,
    TooltipPlacement,
    TooltipTriggerEvent,
} from '@spglobal/react-components';
import styled from '@emotion/styled';
import { CHARTING_LINE } from '@spglobal/koi-icons';

export const ChartingLineIcon = styled(Icon)`
    cursor: pointer;

    .spg-button svg {
        fill: var(--color-icon-charting-line);
    }
`;

const ChartingLineIconButtonStyled = styled(IconButton)`
    cursor: pointer;

    .spg-button svg {
        fill: var(--color-icon-charting-line);
    }

    &[disabled] {
        cursor: not-allowed;
    }
`;

export function ChartingLineIconButton(props) {
    return <ChartingLineIconButtonStyled icon={CHARTING_LINE} {...props} />;
}

export function ChartingLineTooltip({ iconClass, children }) {
    const triggerElement = (
        <ChartingLineIcon size={IconSize.XSMALL} icon={CHARTING_LINE} className={iconClass} />
    );
    return (
        <Tooltip
            triggerElement={triggerElement}
            triggerEvent={TooltipTriggerEvent.CLICK}
            width="30vw"
            isSecondary
            contentPadding={0}
            placement={TooltipPlacement.BOTTOM}
        >
            {children}
        </Tooltip>
    );
}
