import React, { useState, useEffect, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import AccessTokenContext from '../../context/AccessTokenContext';
import BreadcrumbTrail from '../BreadcrumbTrail';
import MessageBox from '../MessageBox';
import HazardForm from '../HazardForm';
import hazardService from '../../services/hazardService';
import pathParser from '../../utils/pathParser';
import googleAnalyticsEvent from '../../utils/googleAnalyticsEvent';
import * as constants from '../../utils/constants';
import { ParagraphLg } from '../shared';
import { CONSENT_REQUIRED_ERROR } from '../../utils/constants';

const SUCCESS_MSG = 'Successfully updated hazard';
const ERROR_UPDATING_MSG = 'Error updating hazard';
const ERROR_FETCHING_DATA_MSG = 'Error fetching necessary data';

export default function EditHazard() {
    const history = useHistory();
    const { pathname } = useLocation({});
    const { getTokenAndTryAgain } = useContext(AccessTokenContext);
    const apiOptions = {
        scope: `${constants.GLOBAL_EDIT_IMPACT_FUNCTION}`,
    };
    const { hazards: hazardId } = pathParser.getPathComponents(pathname);
    const displayHeader = 'Edit Hazard';
    const [messageBoxText, setMessageBoxText] = useState('');
    const [shouldDisplayMessageBox, setShouldDisplayMessageBox] = useState(false);
    const [hazardData, setHazardData] = useState({});

    useEffect(() => {
        let isSubscribed = true;
        async function fetchData() {
            try {
                const result = await hazardService.getHazard(hazardId);
                const resultObj = { ...result };

                if (isSubscribed) {
                    setHazardData({
                        hazardId: resultObj.id,
                        hazardName: resultObj.name,
                        hazardLabel: resultObj.hazardLabel || '',
                        hazardDescription: resultObj.description,
                        yAxisLabel: resultObj.yLabel,
                        yAxisUnits: resultObj.yAxisUnits,
                        unavailableMessage: resultObj.unavailableMessage,
                    });
                }
            } catch (error) {
                if (error.error === 'consent_required') {
                    await getTokenAndTryAgain(apiOptions);
                }

                setMessageBoxText(`${ERROR_FETCHING_DATA_MSG}. ${error.message}`);
                setShouldDisplayMessageBox(true);
            }
        }
        fetchData();
        return () => (isSubscribed = false);
    }, [hazardId]);

    const messageBoxDismissed = async () => {
        setShouldDisplayMessageBox(false);
        if (messageBoxText === SUCCESS_MSG) {
            history.push('/hazards');
        }
    };

    const cancelFunction = () => {
        history.push('/hazards');
    };

    const onSubmit = async (formData) => {
        try {
            const response = await hazardService.editHazard(hazardId, formData);

            if (response.status === 200) {
                googleAnalyticsEvent('Update Entity', 'Hazard', 'success');
                setMessageBoxText(SUCCESS_MSG);
            } else {
                googleAnalyticsEvent('Update Entity', 'Hazard', 'failure');
                setMessageBoxText(`${ERROR_UPDATING_MSG}: ${response.message}`);
            }
        } catch (error) {
            if (error.error === CONSENT_REQUIRED_ERROR) {
                await getTokenAndTryAgain(apiOptions);
            }

            googleAnalyticsEvent('Update Entity', 'Hazard', 'error');
            setMessageBoxText(
                `${ERROR_UPDATING_MSG}: ${error.response.data?.message || error.message}`
            );
        }
        setShouldDisplayMessageBox(true);
    };

    return (
        <>
            <MessageBox
                messageBoxIsVisible={shouldDisplayMessageBox}
                messageBoxTitle="Edit Hazard"
                messageBoxText={messageBoxText}
                messageBoxCallback={messageBoxDismissed}
                cancelText=""
                okText="Ok"
            />
            <BreadcrumbTrail
                rootTitle="Hazards"
                manageParent="/hazards"
                manageChild="Edit Hazard"
            />
            <div className="spg-w-100 spg-p-md">
                {hazardData && hazardData.hazardId ? (
                    <HazardForm
                        cancelClicked={cancelFunction}
                        submitClicked={onSubmit}
                        displayHeader={displayHeader}
                        defaultFormValues={hazardData}
                    />
                ) : (
                    <ParagraphLg>Loading Edit Form...</ParagraphLg>
                )}
            </div>
        </>
    );
}
