import React from 'react';
import * as constants from '../utils/constants';

export const defaultRiskFilters = {
    year: 2020,
    riskFactor: {
        [constants.RISKS]: constants.ALL_RISK_FACTORS,
        [constants.OPPORTUNITIES]: [],
    },
    scenario: constants.SCENARIO_60_ID,
    analysisType: constants.RISKS, // or 2 ; 1=Risks 2=Opportunities
    tags: [],
    currentCustomerId: 0,
    riskValuePrecision: 1,
};

const RiskFilterContext = React.createContext({
    riskDataFilters: defaultRiskFilters,
    handleFilterChange: () => {},
});

export const RiskFilterProvider = RiskFilterContext.Provider;
export const RiskFilterConsumer = RiskFilterContext.Consumer;

export default RiskFilterContext;
