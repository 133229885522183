import React from 'react';
import { Icon } from '@spglobal/react-components';
import { CIRCLE_INFO } from '@spglobal/koi-icons';
import { Size } from '@spglobal/koi-helpers';
import { TextBodyLg } from '../shared';

export default function Disclaimer() {
    return (
        <div>
            <TextBodyLg className="disclaimer-text">
                <Icon icon={CIRCLE_INFO} size={Size.SMALL}/>
                <span>
                    {' '}
                    The composition of the S&amp;P 500 is as of the March 22, 2021 rebalance.
                </span>
            </TextBodyLg>
        </div>
    );
}
