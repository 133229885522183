import React, { useContext } from 'react';
import { SideNav, SideNavItem } from '@spglobal/react-components';
import { Link as RouterLink } from 'react-router-dom';
import UserContext from '../../context/UserContext';
import { getRole } from '../../utils/user';
import {
    ALLOWED_CLIENT_ADMIN,
    ALLOWED_SUPER_ADMIN,
    GroupAndResourceMapping,
    NOT_ALLOWED,
    RBAC_ROLES,
} from '../../utils/constants';
import ClimanomicsPDF from '../Methodology/assets/Climanomics_Methodology_22Aug_2024.pdf';

/**
 * Component with left side navigation
 * @returns {JSX.Element}
 * @constructor
 */
export default function MainDrawer() {
    const { user } = useContext(UserContext);

    // Full URL
    //const fullUrl = window.location.href;

    const allowedSidebarItem = (sidebar) => {
        const role = getRole(user && user.SPGGroups ? user.SPGGroups : []);

        if (!role || !RBAC_ROLES[role]) {
            return false;
        }

        const allowedGroupsResourceList = GroupAndResourceMapping()[role];
        let isAllow = false;

        if (sidebar === 'customer-management') {
            allowedGroupsResourceList.forEach((resourceObj) => {
                if (
                    resourceObj.access !== NOT_ALLOWED &&
                    resourceObj.resource &&
                    resourceObj.resource.length
                ) {
                    resourceObj.resource.forEach((resource) => {
                        if (resource === 'Customers') {
                            isAllow = true;
                        }
                    });
                }
            });
        } else if (sidebar === 'super-admin') {
            isAllow = ALLOWED_SUPER_ADMIN.includes(role);
        } else if (sidebar === 'client-admin') {
            isAllow = ALLOWED_CLIENT_ADMIN.includes(role);
        } else if (sidebar === 'exports' || sidebar === 'summary-report') {
            allowedGroupsResourceList.forEach((resourceObj) => {
                if (
                    resourceObj.access !== NOT_ALLOWED &&
                    resourceObj.resource &&
                    resourceObj.resource.length
                ) {
                    resourceObj.resource.forEach((resource) => {
                        if (
                            resource === 'ExportLibrary' ||
                            resource === 'ActiveCustomerExport' ||
                            resource === 'TrialCustomerExport' ||
                            resource === 'DemoCustomerExport'
                        ) {
                            isAllow = true;
                        }
                    });
                }
            });
        } else if (sidebar === 'dashboard') {
            allowedGroupsResourceList.forEach((resourceObj) => {
                if (
                    resourceObj.access !== NOT_ALLOWED &&
                    resourceObj.resource &&
                    resourceObj.resource.length
                ) {
                    resourceObj.resource.forEach((resource) => {
                        if (resource === 'ProcessingStatusDashboard') isAllow = true;
                    });
                }
            });
        } else {
            return false;
        }

        return isAllow;
    };

    return (
        <SideNav hidePins hideSearch>
            <li className="spg-sidenav-dropdown__item spg-sidenav-dropdown__item--primary">
                <RouterLink className="spg-sidenav-dropdown__link" to="/real-assets">
                    <span className="spg-sidenav-dropdown__text">
                        <span>Real Assets</span>
                    </span>
                </RouterLink>
            </li>
            <SideNavItem title="Manage" isPrimary>
                <li className="spg-sidenav-dropdown__item">
                    <RouterLink className="spg-sidenav-dropdown__link" to="/manage">
                        <span className="spg-sidenav-dropdown__text">
                            <span>All Assets</span>
                        </span>
                    </RouterLink>
                </li>
                <li className="spg-sidenav-dropdown__item">
                    <RouterLink className="spg-sidenav-dropdown__link" to="/asset-types">
                        <span className="spg-sidenav-dropdown__text">
                            <span>Asset Types</span>
                        </span>
                    </RouterLink>
                </li>
                <li className="spg-sidenav-dropdown__item">
                    <RouterLink className="spg-sidenav-dropdown__link" to="/impact-functions">
                        <span className="spg-sidenav-dropdown__text">
                            <span>Impact Functions</span>
                        </span>
                    </RouterLink>
                </li>
                <li className="spg-sidenav-dropdown__item">
                    <RouterLink className="spg-sidenav-dropdown__link" to="/hazards">
                        <span className="spg-sidenav-dropdown__text">
                            <span>Hazards</span>
                        </span>
                    </RouterLink>
                </li>
                {allowedSidebarItem('dashboard') && (
                    <li className="spg-sidenav-dropdown__item">
                        <RouterLink
                            className="spg-sidenav-dropdown__link"
                            to="/bulk-import-track-file"
                        >
                            <span className="spg-sidenav-dropdown__text">
                                <span>Import</span>
                            </span>
                        </RouterLink>
                    </li>
                )}
                {allowedSidebarItem('exports') && (
                    <li className="spg-sidenav-dropdown__item">
                        <RouterLink className="spg-sidenav-dropdown__link" to="/export">
                            <span className="spg-sidenav-dropdown__text">
                                <span>Export</span>
                            </span>
                        </RouterLink>
                    </li>
                )}
                {allowedSidebarItem('summary-report') && (
                    <li className="spg-sidenav-dropdown__item">
                        <RouterLink className="spg-sidenav-dropdown__link" to="/summary-report">
                            <span className="spg-sidenav-dropdown__text">
                                <span>Summary Reports</span>
                            </span>
                        </RouterLink>
                    </li>
                )}
                {allowedSidebarItem('customer-management') && (
                    <li className="spg-sidenav-dropdown__item">
                        <RouterLink className="spg-sidenav-dropdown__link" to="/manageCustomer">
                            <span className="spg-sidenav-dropdown__text">
                                <span>Customers</span>
                            </span>
                        </RouterLink>
                    </li>
                )}
            </SideNavItem>
            <li className="spg-sidenav-dropdown__item spg-sidenav-dropdown__item--primary">
                <RouterLink className="spg-sidenav-dropdown__link" to="/whatsnew">
                    <span className="spg-sidenav-dropdown__text">
                        <span>Release Notes</span>
                    </span>
                </RouterLink>
            </li>
            <SideNavItem title="Real Assets Modeling Methodology" isPrimary>
                <li className="spg-sidenav-dropdown__item">
                    <a
                        className="spg-sidenav-dropdown__link"
                        href={ClimanomicsPDF}
                        download="Climanomics_Methodology_22Aug_2024.pdf"
                    >
                        <span className="spg-sidenav-dropdown__text">
                            <span>Modeling Methodology Download</span>
                        </span>
                    </a>
                </li>
            </SideNavItem>
            {allowedSidebarItem('super-admin') && (
                <li className="spg-sidenav-dropdown__item spg-sidenav-dropdown__item--primary">
                    <RouterLink className="spg-sidenav-dropdown__link" to="/rbac-super-admin">
                        <span className="spg-sidenav-dropdown__text">
                            <span>Role Based Access Control</span>
                        </span>
                    </RouterLink>
                </li>
            )}
            {allowedSidebarItem('client-admin') && (
                <li className="spg-sidenav-dropdown__item spg-sidenav-dropdown__item--primary">
                    <RouterLink className="spg-sidenav-dropdown__link" to="/rbac-client-admin">
                        <span className="spg-sidenav-dropdown__text">
                            <span>Role Based Access Control</span>
                        </span>
                    </RouterLink>
                </li>
            )}
        </SideNav>
    );
}
