import React, { useState, useEffect } from 'react';
import {
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
    Line,
    CartesianGrid,
    ComposedChart,
} from 'recharts';
import { Button, Icon, Card } from '@spglobal/react-components';
import { ANGLE_LEFT, ANGLE_RIGHT } from '@spglobal/koi-icons';
import { Purpose } from '@spglobal/koi-helpers';
import CheckboxList from '../CheckboxList';
import { formatDecadalStatesForGraph } from '../../utils/formatDecadalStatesForGraph';
import { getDecadalStates } from '../../services/decadalStatesService';
import { HeadingSm, TextBodyLg, TextBodyMd } from '../shared';

const noDecadalStatesPresentMessage =
    'There are no Hazard Metric Mean values available for the selected Hazard.';

const getHistoricalBaseline = (hazardData) => {
    if (!hazardData || hazardData.length < 1) {
        return [];
    }
    if (hazardData.length > 1) {
        const singleHazardData = hazardData[0];
        return [
            { key: 'Projected Value', fill: '#CC3F3E', name: 'Projected Value' },
            { key: 'Historical Baseline', fill: '#000000', name: singleHazardData.baselineText },
        ];
    }
};

export default function DecadalHazardGraph({
    riskDataFilters,
    hazardMetricId,
    riskFactorName,
    minWidth,
    locations,
}) {
    const [dataPoints, setDataPoints] = useState([]);
    const [hazardData, setHazardData] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState(locations[0]);
    const [selectedLocationNumber, setSelectedLocationNumber] = useState(1);
    const [decdalStates, setDecadalStates] = useState([]);
    const [activeDecadalStates, setActiveDecadalStates] = useState([]);

    useEffect(() => {
        async function fetchData() {
            try {
                const data = await getDecadalStates(
                    hazardMetricId,
                    riskDataFilters.scenario,
                    selectedLocation.id || 1,
                    riskFactorName,
                );
                setHazardData(data);
            } catch (error) {
                console.error('Error occurred in decadal hazard api:', error);
                setHazardData([]);
            }
        }
        fetchData();
    }, [riskDataFilters, hazardMetricId, selectedLocation]);

    useEffect(() => {
        const formattedDataPoints = formatDecadalStatesForGraph(
            hazardData.map((result) => result.decadalData),
        );

        const historicalNameFill = getHistoricalBaseline(hazardData);
        setDecadalStates(historicalNameFill);
        setActiveDecadalStates(historicalNameFill);
        setDataPoints(formattedDataPoints);
    }, [hazardData]);

    const navigateToPeerLocation = (direction) => {
        const currentLocationIndex = locations.findIndex(
            (location) => location.id === selectedLocation.id,
        );
        let newLocationIndex = 0;
        if (currentLocationIndex > -1) {
            newLocationIndex =
                (currentLocationIndex + direction + locations.length) % locations.length;

            setSelectedLocation(locations[newLocationIndex]);
            setSelectedLocationNumber(newLocationIndex + 1);
        }
    };

    const handleActiveDecadalStatesToggle = (value) => () => {
        const currentIndex = activeDecadalStates.findIndex(
            (activeDecadalState) => activeDecadalState.name === value.name,
        );
        const newActiveDecadalStates = [...activeDecadalStates];

        if (currentIndex === -1) {
            newActiveDecadalStates.push(value);
        } else {
            newActiveDecadalStates.splice(currentIndex, 1);
        }
        setActiveDecadalStates(newActiveDecadalStates);
    };
    return (
        <Card hasBorder hasRoundedCorner>
            {hazardData?.length > 0 ? (
                <>
                    <div>
                        <HeadingSm>Hazard</HeadingSm>
                        {hazardData && (
                            <TextBodyMd>
                                {hazardData && hazardData.length > 0 && hazardData[0].description}
                            </TextBodyMd>
                        )}
                        {dataPoints && dataPoints.length > 0 ? (
                            <ResponsiveContainer
                                minHeight={260}
                                minWidth={minWidth}
                                className="spg-text spg-mt-xs"
                            >
                                <ComposedChart
                                    data={dataPoints}
                                    margin={{
                                        top: 0,
                                        right: 40,
                                        bottom: 25,
                                        left: 40,
                                    }}
                                    type="monotone"
                                >
                                    <CartesianGrid strokeDasharray="3 3" horizontal={false} />
                                    <XAxis
                                        dataKey="decade"
                                        label={{ value: 'Decade', offset: 2, position: 'bottom' }}
                                        type="number"
                                        tickCount={dataPoints.length}
                                        domain={['dataMin', 'dataMax']}
                                    />
                                    <YAxis
                                        label={{
                                            value:
                                                `${
                                                    hazardData &&
                                                    hazardData.length > 0 &&
                                                    hazardData[0]?.yLabel
                                                } (${
                                                    hazardData &&
                                                    hazardData.length > 0 &&
                                                    hazardData[0]?.units
                                                })` || 'Hazard Metric Mean',
                                            angle: -90,
                                            position: 'insideBottomLeft',
                                            offset: 0,
                                            wordWrap: true,
                                            width: 220,
                                            fontSize: 12,
                                        }}
                                    />

                                    {activeDecadalStates?.map(({ key, name, fill }) => (
                                        <Line
                                            dot={false}
                                            type="monotone"
                                            dataKey={key}
                                            stroke={fill}
                                            strokeWidth={2}
                                            activeDot={{ r: 2, fill }}
                                            name={key}
                                            key={`hazard-decadal-graph-${name}`}
                                        />
                                    ))}

                                    <Tooltip
                                        cursor={{ strokeDasharray: '3 3' }}
                                        formatter={(value, name, props) => Number(value).toFixed(4)}
                                    />
                                </ComposedChart>
                            </ResponsiveContainer>
                        ) : (
                            <TextBodyMd>No hazards available.</TextBodyMd>
                        )}
                        <CheckboxList
                            listItems={decdalStates}
                            checkedItems={activeDecadalStates}
                            handleToggle={handleActiveDecadalStatesToggle}
                        />
                    </div>
                    {selectedLocation && locations.length > 1 && (
                        <div className="spg-row spg-justify-between spg-mt-md spg-mb-md">
                            <div className="spg-col-4">
                                <Button
                                    purpose={Purpose.MINIMAL}
                                    onClick={(event) => navigateToPeerLocation(-1)}
                                    leftIcon={<Icon icon={ANGLE_LEFT} />}
                                    disabled={selectedLocationNumber === 1}
                                >
                                    {'Previous Location'}
                                </Button>
                            </div>
                            <div className="spg-col-4 spg-d-flex spg-align-center spg-justify-center">
                                <TextBodyMd>{`Location ${selectedLocationNumber}: ${
                                    selectedLocation.locationAddress ||
                                    selectedLocation.location_name
                                }`}</TextBodyMd>
                            </div>
                            <div className="spg-col-4 spg-d-flex spg-justify-end">
                                <Button
                                    onClick={(event) => navigateToPeerLocation(1)}
                                    rightIcon={<Icon icon={ANGLE_RIGHT} />}
                                    purpose={Purpose.MINIMAL}
                                    disabled={selectedLocationNumber === locations.length}
                                >
                                    {'Next Location'}
                                </Button>
                            </div>
                            <div className="spg-row">
                                {!selectedLocation.dataInModel && (
                                    <TextBodyMd>
                                        {selectedLocation.dataAvailabilityMessage}
                                    </TextBodyMd>
                                )}
                            </div>
                        </div>
                    )}
                </>
            ) : (
                <TextBodyLg>
                    {hazardData?.unavailableMessage || noDecadalStatesPresentMessage}
                </TextBodyLg>
            )}
        </Card>
    );
}
