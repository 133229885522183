import React, { useEffect, useState } from 'react';
import BreadcrumbTrail from '../components/BreadcrumbTrail';
import { TableDataContextProvider } from '../components/shared';
import { DEFAULT_PAGE_DATA } from '../components/components.constants';
import { addDynamicStyles } from '../utils/generateReportUtils';
import SummaryReportForm from './SummaryReportForm';
import SummaryReportTable from './SummaryReportTable';
import { getSummaryReports } from './services/summaryReportService';

export default function SummaryReportPage() {
    const [pageData, setPageData] = useState(DEFAULT_PAGE_DATA);
    const [tableData, setTableData] = useState([]);
    const [count, setCount] = useState(0);
    const [selectedCustomer, setSelectedCustomer] = useState({});

    const getReportList = async (offset, limit, refreshData, searchData) => {
        let res = [];
        const reqData = {
            offset,
            limit,
            customerId: selectedCustomer?.id,
            report_type: 'list_report',
        };
        if (searchData) reqData[`${searchData.searchtype}`] = searchData.searchText;
        if (refreshData) {
            res = refreshData;
            return addDynamicStyles(res, true);
        }
        res = await getSummaryReports(reqData);
        const totalCount = res?.count;
        setCount(totalCount);
        return addDynamicStyles(res?.results, true);
    };

    useEffect(() => {
        try {
            (async function retrieveData() {
                if (selectedCustomer?.id) {
                    const data = await getReportList(0, pageData.rowsPerPage);
                    setTableData(data);
                }
            })();
        } catch (e) {
            console.log('error occured in fetching export list: ', e);
        }
    }, [selectedCustomer]);

    const updateTableState = (data) => {
        // this we need to do because for a state with array, the component only re renders when we assign new array to the state,
        // instead of modifying the current array of the state.
        const updatedState = [...data];
        setTableData(updatedState);
    };

    return (
        <>
            <BreadcrumbTrail rootTitle="Create your Summary Report" />
            <div className="spg-w-100 spg-p-md">
                <div className="spg-text spg-text-paragraph">
                    Climanomics generates reports that visualize risk trends for your entire portfolio, a subset
                    of your portfolio, or a single asset. You can customize your report by selecting the parameters
                    that would become the basis for the report. The output report will be in Powerpoint format for
                    ease of use if you would like to add text or other images in the slide deck. The charts, graphs
                    and tables with data cannot be edited.
                </div>

                <SummaryReportForm
                    selectedCustomer={selectedCustomer}
                    setSelectedCustomer={setSelectedCustomer}
                    getReportList={getReportList}
                    updateTableState={updateTableState}
                    pageData={pageData}
                />
                <TableDataContextProvider exportTableData={tableData}>
                    <SummaryReportTable
                        selectedCustomer={selectedCustomer}
                        tableData={tableData}
                        getReportList={getReportList}
                        updateTableState={updateTableState}
                        count={count}
                        pageData={pageData}
                        setPageData={setPageData}
                    />
                </TableDataContextProvider>
            </div>
        </>
    );
}
