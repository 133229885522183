import * as React from 'react';
import viewService from 'services/viewService';
import TreeView, { flattenTree } from 'react-accessible-treeview';
import { Icon } from '@spglobal/react-components';
import { FOLDER } from '@spglobal/koi-icons';
import { Size } from '@spglobal/koi-helpers';
import { faFile } from '@fortawesome/free-solid-svg-icons';
import { FORM_ACTIONS } from '../../../utils/constants';
import { TreeViewStyled } from './GroupHierarchyTreeView.styles';

const parseHierarchicalResponse = (node) => {
    const copy = {
        id: parseInt(node.id.replace(/\D/g, ''), 10),
        name: node.name,
        expanded: true,
        selected: node.is_assigned,
    };

    if (node.children) {
        copy.children = node.children.map((child) => parseHierarchicalResponse(child));
    }

    return copy;
};

const DataTreeView = ({ selectedViewTree, editUser, action }) => {
    const [viewTreeData, setViewTreeData] = React.useState([]);
    const [dataReceived, setDataReceived] = React.useState(false);

    const getViewTree = async () => {
        async function fetchData() {
            try {
                const reqBody = {
                    action: selectedViewTree.action,
                    customerId: selectedViewTree.customerId,
                    viewID: selectedViewTree.selectedViewTree.id,
                };
                if (action === FORM_ACTIONS.EDIT) {
                    reqBody.UserId = editUser?.user_id || '';
                }

                const response = await viewService.getViewHierarchy(reqBody);
                if (response) {
                    setViewTreeData(flattenTree(parseHierarchicalResponse(response)));
                    setDataReceived(true);
                }
                return response;
            } catch (error) {
                return error;
            }
        }
        return fetchData();
    };

    React.useEffect(() => {
        (async function fetchViewTree() {
            await getViewTree();
        })();
    }, []);

    return (
        <TreeViewStyled>
            {dataReceived && (
                <TreeView
                    data={viewTreeData}
                    key={viewTreeData.length === 0 ? -1 : viewTreeData[0].id}
                    togglableSelect
                    clickAction="EXCLUSIVE_SELECT"
                    multiSelect
                    nodeRenderer={({
                           element,
                           isBranch,
                           getNodeProps,
                           level
                       }) => (
                        <div
                            {...getNodeProps()}
                            style={{
                                paddingLeft: 10 * (level - 1),
                                display: 'flex',
                            }}
                        >
                            {isBranch ? (
                                <Icon icon={FOLDER} size={Size.SMALL} />
                            ) : (
                                <Icon icon={faFile} size={Size.SMALL} />
                            )}
                            <p className="spg-text spg-ml-sm">{element.name}</p>
                        </div>
                    )}
                />
            )}
        </TreeViewStyled>
    );
};

export default DataTreeView;
