import React, { useState, useEffect } from 'react';
import { Button, Icon, NotificationType, useNotification } from '@spglobal/react-components';
import { useApiGet } from 'hooks/useApiGet';
import { getFoldersByCustomerID } from 'services/exportService';
import { getUserDetails } from 'utils/rbacUtils';
import { Purpose } from '@spglobal/koi-helpers';
import { EXPORT } from '@spglobal/koi-icons';
import { generateSummaryReport } from './services/summaryReportService';
import { getCustomerAssets } from '../services/assetService';
import {
    AnalysisTypeSelect,
    AssetsListSelect,
    CustomerSelect,
    DecadeSelect,
    FolderSelect,
    ReportTypeSelect,
    ScenarioSelect,
} from '../components/ReportSelects';
import SummaryReportModal from './SummaryReportModal';

export default function SummaryReportForm({
    selectedCustomer,
    setSelectedCustomer,
    getReportList,
    updateTableState,
    pageData,
}) {
    const [selectedAnalysisType, setSelectedAnalysisType] = useState([]);
    const [selectedScenarios, setSelectedScenarios] = useState([]);
    const [selectedFolders, setSelectedFolders] = useState([]);
    const [selectedDecades, setSelectedDecades] = useState([]);
    const [selectedReportType, setSelectedReportType] = useState([]);
    const [selectedAsset, setSelectedAsset] = useState({});

    const [foldersList, setFoldersList] = useState([]);
    const [assetsList, setAssetsList] = useState([]);
    const [searchAssetText, setSearchAssetText] = useState('');
    const [showSummaryModal, setShowSummaryModal] = useState(false);
    const { addNotification } = useNotification();

    const { data: customers } = useApiGet('api/customers', {});

    useEffect(() => {
        (async () => {
            if (selectedCustomer?.id) {
                const customerFolderIDs = await getFoldersByCustomerID(selectedCustomer?.id);
                const customerAssetsResponse = await getCustomerAssets(selectedCustomer?.id, {
                    offset: 0,
                    limit: 20,
                });

                const foldersArr = customerFolderIDs?.result?.filter((folder) =>
                    Object.prototype.hasOwnProperty.call(folder, 'is_assigned')
                        ? folder?.is_assigned
                        : true,
                );

                setFoldersList(foldersArr);

                if (customerAssetsResponse?.results) {
                    setAssetsList(customerAssetsResponse?.results);
                }
            }
        })();
    }, [selectedCustomer]);

    const validateIsSubmitDisabled = () => {
        let isDisabled = false;
        if (
            !selectedCustomer?.label ||
            !selectedAnalysisType?.length ||
            !selectedScenarios?.length ||
            !selectedDecades?.length ||
            !selectedReportType?.length ||
            (selectedReportType[0]?.value === 'portfolio_summary_report' &&
                !selectedFolders?.length) ||
            (selectedReportType[0]?.value === 'asset_summary_report' && !selectedAsset?.id)
        ) {
            isDisabled = true;
        }

        return isDisabled;
    };

    const handleFormSubmit = async (isConfirm) => {
        if (!isConfirm) {
            setShowSummaryModal(isConfirm);
        } else {
            const { name, email } = getUserDetails();
            const requestParams = {
                customer_name: selectedCustomer?.label,
                summary_report_type: selectedReportType[0]?.value,
                asset_id: selectedAsset?.id,
                asset_name: selectedAsset?.name,
                folder_ids: JSON.stringify([...selectedFolders?.map((item) => item?.id)]),
                scenario: JSON.stringify(
                    [...selectedScenarios?.map((item) => `${item?.value}`)].sort(
                        (a, b) => parseInt(b, 10) - parseInt(a, 10),
                    ),
                ),
                decade: JSON.stringify([selectedDecades[0]?.value]),
                risks: JSON.stringify([...selectedAnalysisType?.map((item) => `${item?.value}`)]),
                report_type: 'generate_report',
                user_name: name,
                user_email: email,
            };
            try {
                const response = await generateSummaryReport(selectedCustomer?.id, requestParams);
                const result = await getReportList(0, pageData.rowsPerPage);
                updateTableState(result);
                addNotification(response, NotificationType.SUCCESS);
            } catch (err) {
                addNotification(err?.message, NotificationType.ERROR);
            }

            setShowSummaryModal(false);
        }
    };

    return (
        <>
            <div className="spg-row">
                <div className="spg-col-4">
                    <CustomerSelect
                        customers={customers}
                        selectedCustomer={selectedCustomer}
                        setSelectedCustomer={setSelectedCustomer}
                        setSelectedFolders={setSelectedFolders}
                        setAssetsList={setAssetsList}
                        setSelectedAsset={setSelectedAsset}
                        setSearchAssetText={setSearchAssetText}
                        disableSelect
                    />
                </div>
                <div className="spg-col-4">
                    <ReportTypeSelect
                        selectedReportType={selectedReportType}
                        setSelectedReportType={setSelectedReportType}
                        setSelectedAsset={setSelectedAsset}
                        setSelectedFolders={setSelectedFolders}
                    />
                </div>
                {selectedReportType &&
                selectedReportType?.length &&
                selectedReportType[0]?.value === 'portfolio_summary_report' ? (
                    <div className="spg-col-4">
                        <FolderSelect
                            foldersList={foldersList}
                            selectedFolders={selectedFolders}
                            setSelectedFolders={setSelectedFolders}
                            isSummaryReportForm
                        />
                    </div>
                ) : null}
                {selectedReportType &&
                selectedReportType?.length &&
                selectedReportType[0]?.value === 'asset_summary_report' ? (
                    <div className="spg-col-4">
                        <AssetsListSelect
                            selectedCustomer={selectedCustomer}
                            assetsList={assetsList}
                            setAssetsList={setAssetsList}
                            setSelectedAsset={setSelectedAsset}
                            searchAssetText={searchAssetText}
                            setSearchAssetText={setSearchAssetText}
                        />
                    </div>
                ) : null}
            </div>
            <div className="spg-row">
                <div className="spg-col-4">
                    <AnalysisTypeSelect
                        selectedAnalysisType={selectedAnalysisType}
                        setSelectedAnalysisType={setSelectedAnalysisType}
                    />
                </div>
                <div className="spg-col-4">
                    <ScenarioSelect
                        selectedScenarios={selectedScenarios}
                        setSelectedScenarios={setSelectedScenarios}
                    />
                </div>
                <div className="spg-col-4">
                    <DecadeSelect
                        selectedDecades={selectedDecades}
                        setSelectedDecades={setSelectedDecades}
                        isMulti={false}
                    />
                </div>
            </div>

            <div className="spg-d-flex spg-justify-end spg-mt-md spg-mb-md">
                <Button
                    type="submit"
                    id="generateSummaryReportButton"
                    leftIcon={<Icon icon={EXPORT} disableAutomaticHoverState />}
                    purpose={Purpose.PRIMARY}
                    onClick={() => setShowSummaryModal(true)}
                    disabled={validateIsSubmitDisabled()}
                >
                    GENERATE REPORT
                </Button>
            </div>

            {showSummaryModal && (
                <SummaryReportModal
                    selectedCustomer={selectedCustomer}
                    selectedReportType={selectedReportType}
                    selectedFolders={selectedFolders}
                    selectedAsset={selectedAsset}
                    selectedAnalysisType={selectedAnalysisType}
                    selectedScenarios={selectedScenarios}
                    selectedDecades={selectedDecades}
                    onConfirm={(value) => handleFormSubmit(value)}
                />
            )}
        </>
    );
}
