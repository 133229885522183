import React, { useEffect, useState } from 'react';
import { NavLink, useRouteMatch, useLocation, useHistory } from 'react-router-dom';
import { Button, Link, Icon, Modal, ModalContent, ModalFooter } from '@spglobal/react-components';
import { ANGLE_LEFT } from '@spglobal/koi-icons';
import { Size } from '@spglobal/koi-helpers';
import customerService from '../../services/customerService';
import groupService from '../../services/groupService';
import folderService from '../../services/folderService';
import viewService from '../../services/viewService';
import investmentService from '../../services/investmentService';
import assetService from '../../services/assetService';
import pathParser from '../../utils/pathParser';
import { UNCATEGORIZED } from '../../utils/constants';
import { useHierarchyContext } from '../../context/HierarchyContext';
import BreadcrumbTrailContainer from './BreadcrumbTrail.styles';
import { ParagraphLg } from '../shared';

export default function BreadcrumbTrail({
    rootTitle,
    manageParent,
    manageChild,
    history,
    isBoldText,
}) {
    const [showDialog, setShowDialog] = useState(false);
    const route = useRouteMatch();
    const {
        customer,
        setCustomer,
        group,
        setGroup,
        folder,
        setFolder,
        investment,
        setInvestment,
        asset,
        setAsset,
        view,
        setView,
    } = useHierarchyContext();
    const { pathname } = useLocation();
    const regex = /^\D*/;
    const regexMatch = regex.exec(pathname);
    const urlSlug = regexMatch[0];

    const { customerId, groupId, folderId, investmentId, assetId, viewId } = route.params;
    const { views: viewIds } = pathParser.getPathComponents(pathname);
    const urlParams = new URLSearchParams(window.location.search);
    const viewHistory = urlParams.get('viewList');
    const [viewListRender, setViewListrender] = useState('');

    useEffect(() => {
        const fetchData = async () => {

            if (customerId) {
                const {
                    results: { customer_id: id, customer_name: name, customer_type },
                } = await customerService.getCustomerById(customerId);
                setCustomer({ id, name, customer_type });
            } else {
                setCustomer({});
            }
        };
        fetchData();
    }, [customerId]);

    useEffect(() => {
        const fetchData = async () => {
            if (groupId) {
                const { id, name } = await groupService.getGroupById(groupId);
                setGroup({ id, name });
            } else {
                setGroup({});
            }
        };
        fetchData();
    }, [groupId]);

    useEffect(() => {
        const fetchData = async () => {
            if (folderId) {
                try {
                    const {
                        results: { folder_id: id, folder_name: name },
                    } = await folderService.getFolderById(customerId, folderId);
                    const updatedName =
                        name === UNCATEGORIZED ? <>Uncategorized (Default)</> : name;
                    setFolder({ id, name: updatedName });
                } catch (error) {
                    setShowDialog(true);
                }
            } else {
                setFolder({});
            }
        };
        fetchData();
    }, [folderId]);

    useEffect(() => {
        const fetchData = async () => {

            if (viewId) {
                try {
                    const {
                        result: { view_name: name },
                    } = await viewService.getViewDetails(customerId, viewId);
                    setView({ id: viewId, name });
                } catch (error) {
                    setShowDialog(true);
                    return;
                }
            } else {
                setView({});
            }

            let resultArray = [];
            const viewList = viewHistory;

            if (viewList) {
                setViewListrender([]);
                resultArray = viewList.split(',');
                const previousViews = {};
                for (const element of resultArray) {
                    const viewData = await viewService.getViewDetails(customerId, element);
                    previousViews[element] = {
                        id: element,
                        name: viewData.result.view_name,
                    };
                }
                const renderHTML = [];
                resultArray.forEach((id, index) => {
                    const spliceArray = [...resultArray];
                    const viewListQueryParams = spliceArray.splice(0, index);
                    renderHTML.push(
                        <NavLink
                            exact
                            to={{
                                pathname:
                                    urlSlug.includes('manage') || urlSlug.includes('real-assets')
                                        ? `${urlSlug}${customer.id}/views/${id}`
                                        : `${urlSlug}${customer.id}/groups/${view.id}`,
                                search:
                                    viewList.length > 0
                                        ? `?${new URLSearchParams({
                                              viewList: viewListQueryParams,
                                          })}`
                                        : '',
                            }}
                            key={id}
                        >
                            &nbsp;/ {previousViews[id]?.name || 'default'}
                        </NavLink>,
                    );
                });
                setViewListrender(renderHTML);
            } else {
                setViewListrender([]);
            }
        };
        fetchData();
    }, [viewId]);

    useEffect(() => {
        const fetchData = async () => {
            if (investmentId) {
                const { id, investmentName } = await investmentService.getInvestment(investmentId);
                setInvestment({ id, name: investmentName });
            } else {
                setInvestment({});
            }
        };
        fetchData();
    }, [investmentId]);

    useEffect(() => {
        const fetchData = async () => {
            if (assetId) {
                const {
                    results: { asset_id: id, name },
                } = await assetService.getAssetById(customerId, assetId);
                setAsset({ id, name });
            } else {
                setAsset({});
            }
        };
        fetchData();
    }, [assetId]);

    const historyLink = useHistory();

    const handleOkClick = () => {
        setShowDialog(false);
        historyLink.goBack();
    };

    return (
        <>
            <BreadcrumbTrailContainer>
                {history && (
                    <Button
                        onClick={() => history.goBack()}
                        leftIcon={<Icon icon={ANGLE_LEFT} size={Size.XSMALL} />}
                    >
                        Back
                    </Button>
                )}
                <NavLink exact to={{ pathname: manageParent || urlSlug }}>
                    {rootTitle || 'All Portfolios'}
                </NavLink>
                {customer.id && (
                    <NavLink
                        exact
                        to={
                            urlSlug.includes('manage') || urlSlug.includes('real-assets')
                                ? {
                                      pathname: `${urlSlug}${customer.id}`,
                                      search: pathname.includes('views')
                                          ? '?activeTab=views'
                                          : pathname.includes('folders')
                                          ? '?activeTab=folders'
                                          : '?activeTab=allAssets',
                                  }
                                : { pathname: `${urlSlug}${customer.id}` }
                        }
                    >
                        {rootTitle && rootTitle.trim().length > 0 ? <span>&nbsp;/ </span> : <span>&nbsp;</span>}
                        <b>{customer.name}</b>
                    </NavLink>
                )}
                {viewListRender}
                {view.id && !folder.id && !asset.id && (
                    <NavLink
                        exact
                        to={{
                            pathname:
                                urlSlug.includes('manage') || urlSlug.includes('real-assets')
                                    ? `${urlSlug.replace('manage', 'real-assets')}${
                                          customer.id
                                      }/views/${view.id}`
                                    : `${urlSlug.replace('manage', 'real-assets')}${
                                          customer.id
                                      }/groups/${view.id}`,
                            search: viewHistory
                                ? `?${new URLSearchParams({ viewList: viewHistory })}`
                                : '',
                        }}
                    >
                        &nbsp;/ {view.name}
                    </NavLink>
                )}
                {folder.id && (
                    <NavLink
                        exact
                        to={{
                            pathname: (
                                urlSlug.includes('manage')
                                    ? `${urlSlug}${customer.id}/folders/${folder.id}/assets`
                                    : urlSlug.includes('real-assets')
                            )
                                ? `${urlSlug.replace('manage', 'real-assets')}${
                                      customer.id
                                  }/folders/${folder.id}`
                                : `${urlSlug.replace('manage', 'real-assets')}${
                                      customer.id
                                  }/groups/${group.id}/folders/${folder.id}`,
                        }}
                    >
                        &nbsp;/ {folder.name}
                    </NavLink>
                )}
                {asset.id && (
                    <NavLink
                        exact
                        to={{
                            pathname: urlSlug.includes('real-assets')
                                ? `${urlSlug}${customer.id}/assets/${asset.id}`
                                : `${urlSlug}${customer.id}/groups/${group.id}/folders/${folder.id}/investments/${investment.id}/assets/${asset.id}`,
                        }}
                    >
                        &nbsp;/ {asset.name}
                    </NavLink>
                )}
                {manageChild && (
                    <Link>
                        {!isBoldText ? (
                            <>&nbsp;/ {manageChild}</>
                        ) : (
                            <>
                                &nbsp;/ <b>{manageChild}</b>
                            </>
                        )}
                    </Link>
                )}
            </BreadcrumbTrailContainer>
            <Modal aria-labelledby="customized-dialog-title" isOpen={showDialog}>
                <ModalContent>
                    <ParagraphLg>
                        You don't have permission to view this folder or view, please contact your
                        administrator.
                    </ParagraphLg>
                </ModalContent>
                <ModalFooter>
                    <Button onClick={handleOkClick}>Ok</Button>
                </ModalFooter>
            </Modal>
        </>
    );
}
