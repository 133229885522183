const determineColor = (riskValue, riskContext) => {
    switch (riskContext) {
        case 'relative':
            return riskValue >= 15 ? 'red' : riskValue >= 10 ? 'orange' : 'green';
        case 'absolute':
            return riskValue >= 50 ? 'red' : riskValue >= 35 ? 'orange' : 'green';
        default:
            return 'green';
    }
};

export default { determineColor };
