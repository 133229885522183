import React from 'react';
import { Classes } from '@spglobal/koi-helpers';
import { FormGroup, Select } from '@spglobal/react-components';
import { InfoTooltip, TextBodySm } from '../shared';
import { transformDataObjForKoiSelect } from '../../utils/koiIntegrationUtils';

export function FolderSelect({
   foldersList,
   selectedFolders,
   setSelectedFolders,
   isSummaryReportForm,
}) {
    const selectFolderInfoTooltip = (
        <>
            <InfoTooltip iconClass="spg-ml-xs">
                <TextBodySm className="spg-p-md">
                    You will only be able to select folders that you have been granted
                    access to. Please contact your organization's administrator for
                    access.
                </TextBodySm>
            </InfoTooltip>
            <span className={`${Classes.LABEL_REQUIRED} spg-ml-xs`}>*</span>
        </>
    );

    const foldersOptions = foldersList?.map((option) => ({
        ...transformDataObjForKoiSelect(
            option,
            { id: 'asset_folder_id', label: 'asset_folder_name', value: 'asset_folder_id' }
        ),
        ...(isSummaryReportForm ? { disabled: option?.asset_count === 0 } : {}),
    }));

    const isNoFolders = !(foldersOptions && foldersOptions.length);

    return (
        <FormGroup
            label="Select Folder"
            labelFor="folder-select"
            labelInfo={selectFolderInfoTooltip}
        >
            <Select
                id="folder-select"
                data-testid="folder-select"
                options={foldersOptions}
                defaultValue={selectedFolders}
                onChange={setSelectedFolders}
                isMulti
                isSearchable={!isNoFolders}
                isSearchTextRemovable
                placeholder=""
            />
        </FormGroup>
    );
}
