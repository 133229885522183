import React, { useContext, useState, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { EDIT } from '@spglobal/koi-icons';
import { IconButton } from '@spglobal/react-components';
import { Size } from '@spglobal/koi-helpers';
import UserContext from '../../context/UserContext';
import assetTypeService from '../../services/assetTypeService';
import riskFactorsService from '../../services/riskFactorsService';
import Header from '../Header';
import BreadcrumbTrail from '../BreadcrumbTrail';
import useGetPermission from '../../hooks/useGetPermission';
import { API_URL, ACTION } from '../../utils/constants';
import DataService from '../../service/data.service';
import { transformDataObjForKoiSelect } from '../../utils/koiIntegrationUtils';
import { ParagraphLg } from '../shared';
import DataTableGrid from '../DataTable';

export default function AssetTypes() {
    const history = useHistory();
    const { user, isLoggedIn } = useContext(UserContext);
    const [assetTypes, setAssetTypes] = useState([]);
    const [tableData, setTableData] = useState(assetTypes);
    const [dataLoaded, setDataLoaded] = useState(false);

    const service = new DataService();
    const userAccess =
        user && Object.keys(user).length > 0
            ? useGetPermission(user, isLoggedIn, 'AssetTypesLibrary')
            : '';
    const actions =
        userAccess?.action === ACTION.ACTION_READ
            ? [ACTION.ACTION_READ]
            : userAccess?.action === ACTION.ACTION_WRITE
            ? [ACTION.ACTION_READ, ACTION.ACTION_EDIT]
            : [];

    useEffect(() => {
        let isSubscribed = true;
        async function fetchData() {
            try {
                const allRiskFactors = await riskFactorsService.getAll();
                const result = await assetTypeService.getAll('library');
                if (result && allRiskFactors) {
                    const totalRiskFactorCount = allRiskFactors.length;
                    const formattedResult = result.results.map((curAT) => {
                        const item = { ...curAT };
                        item.riskText = `${curAT.riskFactorCount} of ${totalRiskFactorCount}`;
                        return item;
                    });
                    if (isSubscribed) {
                        setAssetTypes(formattedResult);
                        setTableData(formattedResult);
                    }
                }
                setDataLoaded(true);
            } catch (exc) {
                setDataLoaded(true);
            }
        }
        fetchData();
        return () => (isSubscribed = false);
    }, []);

    const handleTableSearch = (selectedValues) => {
        setTableData(
            assetTypes.filter((assetType) => assetType.AssetTypeId === selectedValues[0].id),
        );
    };

    const handleDataTableAction = (entityId) => {
        if (entityId) {
            history.push({
                pathname: `/asset-types/${entityId}/edit`,
            });
        }
    };

    const exportToCSV = () => {
        const uri = `${API_URL}/api/download/assetTypes`;
        service.downloadFile(uri, 'assetTypes.csv');
    };

    const actionCellRenderer = (props) => {
        const assetTypeId = props?.data?.AssetTypeId;
        return (
            <div className="spg-d-flex spg-justify-end">
                <IconButton
                    icon={EDIT}
                    onClick={() => handleDataTableAction(assetTypeId)}
                    size={Size.SMALL}
                />
            </div>
        );
    };

    const getColumnDefs = () => {
        const columnDefs = [
            {
                headerName: 'Asset Type',
                field: 'name',
                sortable: true,
                headerTooltip: `The Asset Type groups several related SIC (Standard Industrial
                            Classification) categories, while the associated Asset Value is
                            representative of a wide range of asset values. S&amp;P Global
                            Climanomics plans to provide more accurate and granular asset types and
                            values over time.`,
                tooltipField: 'name',
                cellStyle: { display: 'block' },
            },
            {
                headerName: 'Risk Factors with Impact Functions assigned',
                field: 'riskText',
                sortable: true,
                cellStyle: { display: 'block' },
            },
            {
                headerName: 'Asset Type ID',
                field: 'AssetTypeId',
                sortable: true,
                cellStyle: { display: 'block' },
            },
            {
                headerName: 'Asset Type Description',
                field: 'description',
                sortable: true,
                tooltipField: 'description',
                cellStyle: { display: 'block' },
            },
        ];

        if (actions && actions.indexOf(ACTION.ACTION_EDIT) >= 0) {
            columnDefs.push({
                headerName: ' ',
                field: ' ',
                cellRenderer: 'actionsColumn',
                width: 28,
                maxWidth: 28,
                minWidth: 28,
                cellStyle: { alignItems: 'baseline' },
                cellClass: 'spg-p-0 cli-action-cell',
            });
        }

        return columnDefs;
    };

    const components = useMemo(
        () => ({
            actionsColumn: actionCellRenderer,
        }),
        [],
    );

    const values = assetTypes.map((v) =>
        transformDataObjForKoiSelect(v, { id: 'AssetTypeId', label: 'name', value: 'AssetTypeId' }),
    );

    return (
        <div>
            <BreadcrumbTrail rootTitle="Asset Types" />
            <div className="spg-w-100 spg-p-md">
                <Header
                    title="Asset Types"
                    type="Asset Type"
                    values={values}
                    onChangeFunction={handleTableSearch}
                    link="asset-type"
                    history={history}
                    hideAddButton={!actions.includes(ACTION.ACTION_EDIT)}
                    exportButton={true}
                    exportToCSV={exportToCSV}
                />
                {assetTypes.length > 0 && (
                    <ParagraphLg marginLg>
                        S&amp;P Global Climanomics platform currently offers {assetTypes.length}{' '}
                        available asset types that you can assign to your assets.
                    </ParagraphLg>
                )}
                <DataTableGrid
                    frameworkComponents={components}
                    rowData={tableData}
                    columnDefs={getColumnDefs()}
                    pagination
                    loading={!dataLoaded}
                />
            </div>
        </div>
    );
}
