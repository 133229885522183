import React from 'react';
import {
    Button,
    Modal,
    ModalHeader,
    ModalContent,
    InputField,
    FormGroup,
    ModalFooter
} from '@spglobal/react-components';
import { Purpose } from '@spglobal/koi-helpers';
import { TextBodyMd } from '../shared';

export default function GroupFolderDialog({
    dialogIsVisible,
    dialogText,
    dialogDismissed,
    dialogOkClicked,
    textToDisplay,
    setTextToDisplay,
    helpText,
    showErrorMessage,
    setShowErrorMessage,
    setErrorHelperText,
    primaryButtonLabel = 'OK',
}) {
    const handleClose = () => {
        dialogDismissed();
    };
    const handleOk = () => {
        dialogOkClicked();
    };

    return (
        <Modal
            isOpen={dialogIsVisible}
            aria-labelledby="folder-group-customer-dialog-title"
            zIndex={99999}
        >
            <ModalHeader
                id="folder-group-customer-dialog-title"
                title={dialogText?.title}
            />
            <ModalContent>
                {dialogText?.message && (
                    <TextBodyMd>{dialogText?.message}</TextBodyMd>
                )}

                <FormGroup
                    label="Name"
                    invalid={showErrorMessage}
                    feedbackText={showErrorMessage && helpText}
                    required
                >
                    <InputField
                        autoFocus
                        value={textToDisplay}
                        onChange={(e) => {
                            e.stopPropagation();
                            setTextToDisplay(e.target.value);
                            setShowErrorMessage(false);
                            setErrorHelperText('');
                        }}
                        customId="name"
                        type="text"
                    />
                </FormGroup>

            </ModalContent>
            <ModalFooter>
                <Button
                    onClick={handleOk}
                    purpose={Purpose.PRIMARY}
                    data-testid="create-customer-group-folder-ok"
                >
                    {primaryButtonLabel}
                </Button>
                <Button
                    onClick={handleClose}
                    purpose={Purpose.SECONDARY}
                    data-testid="create-group-folder-cancel"
                    className="spg-ml-sm"
                >
                    Cancel
                </Button>
            </ModalFooter>
        </Modal>
    );
}
