import React, { useState, useEffect, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import AccessTokenContext from '../../context/AccessTokenContext';
import MessageBox from '../MessageBox';
import BreadcrumbTrail from '../BreadcrumbTrail';
import AssetTypeForm from '../AssetTypeForm';
import assetTypeService from '../../services/assetTypeService';
import pathParser from '../../utils/pathParser';
import googleAnalyticsEvent from '../../utils/googleAnalyticsEvent';
import * as constants from '../../utils/constants';
import { ParagraphLg } from '../shared';

const SUCCESS_MSG = 'Successfully updated asset type';
const ERROR_UPDATING_MSG = 'Error updating asset type: ';

export default function EditAssetType() {
    const history = useHistory();
    const { pathname } = useLocation({});
    const { 'asset-types': assetTypeId } = pathParser.getPathComponents(pathname);
    const { getTokenAndTryAgain } = useContext(AccessTokenContext);
    const apiOptions = {
        scope: `${constants.GLOBAL_EDIT_ASSET_TYPE}`,
    };
    const displayHeader = 'Edit Asset Type';

    const [messageBoxText, setMessageBoxText] = useState('');
    const [shouldDisplayMessageBox, setShouldDisplayMessageBox] = useState(false);
    const [assetTypeData, setAssetTypeData] = useState({});

    useEffect(() => {
        let isSubscribed = true;
        async function fetchData() {
            try {
                const result = await assetTypeService.getAssetType(assetTypeId, true);
                if (isSubscribed) {
                    result.impactFunctions = result.impactfunctions;
                    delete result.impactfunctions;
                    const updatedMetadata = {};
                    result.metadata.forEach((element) => {
                        const objKey = Object.keys(element)[0];
                        updatedMetadata[objKey] = element[objKey];
                    });
                    Object.keys(updatedMetadata).forEach((key) => {
                        updatedMetadata[key] = {
                            ...updatedMetadata[key],
                            ...updatedMetadata[key].values[0],
                        };
                    });
                    result.metadata = updatedMetadata;
                    setAssetTypeData(result);
                }
            } catch (err) {
                if (err.error === 'consent_required') {
                    return getTokenAndTryAgain(apiOptions);
                }
                setMessageBoxText(err.response.data);
                setShouldDisplayMessageBox(true);
            }
        }
        fetchData();
        return () => (isSubscribed = false);
    }, []);

    const handleSetMessageBoxText = (msg) => {
        setMessageBoxText(msg);
    };

    const toggleShouldDisplayMessageBox = () => {
        setShouldDisplayMessageBox(!shouldDisplayMessageBox);
    };

    const onSubmit = async (formData, allAssetTypeMetaData) => {
        const metaDataCopy = {};
        Object.keys(formData?.metadata).forEach((currentkey) => {
            if (Number.isInteger(formData?.metadata[currentkey].metadataId)) {
                metaDataCopy[currentkey] = {
                    metadataId: formData?.metadata[currentkey].metadataId,
                    categoryId: allAssetTypeMetaData[currentkey].categoryId,
                };
            }
        });
        const requestData = { ...formData, metadata: metaDataCopy };
        try {
            await assetTypeService.editAssetType(assetTypeId, requestData);
            googleAnalyticsEvent('Update Entity', 'Asset Type', 'success');
            setMessageBoxText(SUCCESS_MSG);
        } catch (error) {
            googleAnalyticsEvent('Update Entity', 'Asset Type', 'failure');
            setMessageBoxText(`${ERROR_UPDATING_MSG} ${error.response.data?.message}`);
        }
        setShouldDisplayMessageBox(true);
    };

    const cancelFunction = () => {
        history.push('/asset-types');
    };

    const messageBoxDismissed = async () => {
        toggleShouldDisplayMessageBox();
        if (messageBoxText === SUCCESS_MSG) {
            history.goBack();
        }
    };

    return (
        <div>
            <MessageBox
                messageBoxIsVisible={shouldDisplayMessageBox}
                messageBoxTitle="Edit Asset Type"
                messageBoxText={messageBoxText}
                messageBoxCallback={messageBoxDismissed}
                cancelText=""
                okText="Ok"
            />
            <BreadcrumbTrail
                rootTitle='Asset Types'
                manageParent='/asset-types'
                manageChild='Edit Asset Type'
            />
            <div className="spg-w-100 spg-h-100 spg-p-md">
                {assetTypeData && assetTypeData.impactFunctions ? (
                    <AssetTypeForm
                        cancelClicked={cancelFunction}
                        submitClicked={onSubmit}
                        displayHeader={displayHeader}
                        handleSetMessageBoxText={handleSetMessageBoxText}
                        toggleShouldDisplayMessageBox={toggleShouldDisplayMessageBox}
                        defaultFormValues={assetTypeData}
                    />
                ) : (
                    <p className="text-center spg-w-100">Loading Edit Form...</p>
                )}
            </div>
        </div>
    );
}
