import React from 'react';
import { TextBodyLg, TextBodyMd } from '../../shared';
import { RiskFactor } from './RiskFactors.styles';

export default function RiskFactors({
    fields,
    allRiskFactors,
    selectedRiskFactor,
    handleListItemClick,
    riskCategoryId,
}) {
    return (
        <>
            {allRiskFactors?.length > 0 &&
                allRiskFactors.map((riskFactor, index) => (
                    <RiskFactor
                        className={`spg-p-md ${
                            riskFactor.id === selectedRiskFactor.id ? 'spg-selected' : ''
                        } ${riskFactor?.tcfd_id === 3 ? 'disabled' : ''}`}
                        onClick={(event) => handleListItemClick(event, index, riskFactor.tcfd_id)}
                        data-testid={`riskFactorListItem-${riskCategoryId}-${index}`}
                        key={`riskFactorListItem-${riskCategoryId}-${index}`}
                    >
                        <div>
                            <TextBodyLg>{riskFactor.name}</TextBodyLg>
                            <TextBodyMd>{`${
                                fields.filter(
                                    (impactFunction) =>
                                        impactFunction.riskFactorId === riskFactor.id
                                ).length || 0
                            } impact function(s) assigned`}</TextBodyMd>
                        </div>
                    </RiskFactor>
                ))}
        </>
    );
}
