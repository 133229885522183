import React, { useState, useEffect, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import AccessTokenContext from '../../context/AccessTokenContext';
import BreadcrumbTrail from '../BreadcrumbTrail';
import MessageBox from '../MessageBox';
import ImpactFunctionForm from '../ImpactFunctionForm';

import impactFunctionService from '../../services/impactFunctionService';
import impactFunctionTransformer from '../../utils/impactFunctionTransformer';
import googleAnalyticsEvent from '../../utils/googleAnalyticsEvent';
import * as constants from '../../utils/constants';
import { ParagraphLg } from '../shared';

const SUCCESS_MSG = 'Successfully created new impact function.';
const ERROR_CREATING_MSG = 'Error creating impact function:';

const defaultImpactPathway = [
    {
        name: '',
        xValues: ['100', '90', '90', '80', '80', '70'],
        yValues: ['', '', '', '', '', ''],
    },
];

const defaultFormValues = {
    impactFunctionName: '',
    impactFunctionDescription: '',
    hazard: '',
    riskFactor: '',
    xMetric: '',
    xMetricUnits: '',
    xAxisLabel: '',
    yMetric: 'productivity',
    yMetricUnits: 'percent',
    yAxisLabel: '',
    impactPathways: defaultImpactPathway,
};

export default function CreateImpactFunction() {
    const history = useHistory();
    const location = useLocation();
    const { getTokenAndTryAgain } = useContext(AccessTokenContext);
    const apiOptions = {
        scope: `${constants.GLOBAL_EDIT_IMPACT_FUNCTION} ${constants.CUSTOMER_READ_IMPACT_FUNCTION}`,
    };
    const displayHeader = 'New Impact Function';

    const [impactFunctionData, setImpactFunctionData] = useState({});
    const [shouldDisplayMessageBox, setShouldDisplayMessageBox] = useState(false);
    const [messageBoxText, setMessageBoxText] = useState('');

    useEffect(() => {
        let isSubscribed = true;
        async function fetchData() {
            const searchParams = new URLSearchParams(location.search);
            const impactFunctionToBeCopiedId = searchParams.get('copy');
            if (impactFunctionToBeCopiedId && !isNaN(impactFunctionToBeCopiedId)) {
                try {
                    const result = await impactFunctionService.getImpactFunction(
                        impactFunctionToBeCopiedId
                    );
                    if (isSubscribed) {
                        setImpactFunctionData({
                            impactFunctionName: `${result.name}-COPY`,
                            impactFunctionDescription: result.description,
                            hazard: result.hazardId,
                            riskFactor: result.riskFactorId,
                            xMetric: result.xMetric,
                            xMetricUnits: result.xUnits,
                            xAxisLabel: result.xAxisLabel || '',
                            yMetric: result.yMetric || 'productivity',
                            yMetricUnits: result.yUnits || 'percent',
                            yAxisLabel: result.yAxisLabel || '',
                            impactPathways: impactFunctionTransformer.parseImpactPathways(
                                result.impactPathways
                            ),
                        });
                    }
                } catch (error) {
                    if (error.error === 'consent_required') {
                        return await getTokenAndTryAgain(apiOptions);
                    }
                    throw new Error(error);
                }
            } else {
                setImpactFunctionData(defaultFormValues);
            }
        }
        fetchData();
        return () => (isSubscribed = false);
    }, []);

    const messageBoxDismissed = async (okClicked) => {
        setShouldDisplayMessageBox(false);
        if (messageBoxText === SUCCESS_MSG) {
            history.push('/impact-functions');
        }
    };

    const cancelFunction = () => {
        history.push('/impact-functions');
    };

    const onSubmit = async (data, e) => {
        const formatted = impactFunctionTransformer.transformImpactFunction(data);
        try {
            await impactFunctionService.createImpactFunction(formatted);
            googleAnalyticsEvent('Create Entity', 'Impact Function', 'success');
            setMessageBoxText(SUCCESS_MSG);
        } catch (error) {
            if (error.error === 'consent_required') {
                await getTokenAndTryAgain(apiOptions);
            }
            googleAnalyticsEvent('Create Entity', 'Impact Function', 'failed');
            const msg = error.response.data?.message || error.message;
            setMessageBoxText(`${ERROR_CREATING_MSG} ${msg}`);
        }
        setShouldDisplayMessageBox(true);
    };

    return (
        <>
            <MessageBox
                messageBoxIsVisible={shouldDisplayMessageBox}
                messageBoxTitle="Impact Function"
                messageBoxText={messageBoxText}
                messageBoxCallback={messageBoxDismissed}
                cancelText=""
                okText="Ok"
            />
            <BreadcrumbTrail
                rootTitle="Impact Functions"
                manageParent="/impact-functions"
                manageChild="New Impact Function"
            />
            <div className="spg-w-100 spg-p-md">
                {impactFunctionData && impactFunctionData.impactPathways ? (
                    <ImpactFunctionForm
                        cancelClicked={cancelFunction}
                        submitClicked={onSubmit}
                        displayHeader={displayHeader}
                        defaultFormValues={impactFunctionData}
                    />
                ) : (
                    <ParagraphLg className="spg-d-flex spg-justify-center">Loading Create Form...</ParagraphLg>
                )}
            </div>
        </>
    );
}
