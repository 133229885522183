import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { H1, InputField, FormGroup, TextArea, Select, Button, Icon } from '@spglobal/react-components';
import { Purpose } from '@spglobal/koi-helpers';
import { PLUS } from '@spglobal/koi-icons';
import ImpactPathwayForm from '../ImpactPathwayForm';
import hazardService from '../../services/hazardService';
import riskFactorsService from '../../services/riskFactorsService';
import { ErrorTextBodySm, HeadingMd, TextBodySm } from '../shared';
import { transformDataObjForKoiSelect } from '../../utils/koiIntegrationUtils';

export default function ImpactFunctionForm({
    cancelClicked,
    submitClicked,
    displayHeader,
    defaultFormValues,
}) {
    const {
        handleSubmit,
        formState: { errors, dirtyFields },
        control,
        setValue,
        getValues,
        watch,
    } = useForm({
        defaultValues: {
            ...defaultFormValues,
        },
    });

    // the watch functionality from react-hook-form allows us to watch changes to a form value
    const watchHazard = watch('hazard');
    const watchImpactFunctionDescription = watch('impactFunctionDescription');

    const [impactPathways, setImpactPathways] = useState(
        defaultFormValues && defaultFormValues.impactPathways
    );
    const [allHazards, setAllHazards] = useState([]);
    const [allRiskFactors, setAllRiskFactors] = useState([]);

    useEffect(() => {
        async function fetchData() {
            const { results: result } = await hazardService.getAll();
            const riskFactorResult = await riskFactorsService.getAll();
            setAllHazards(result);
            setAllRiskFactors(riskFactorResult);

            if (getValues && getValues('hazard') === '') {
                setValue('hazard', result[0].id);
                setValue('riskFactor', riskFactorResult[0].id);
                setValue('xMetric', result[0].name);
                setValue('xMetricUnits', result[0].unitOfMeasurement);
                setValue('xAxisLabel', `${result[0].yLabel} (${result[0].unitOfMeasurement})`);
            }
        }
        fetchData();
    }, []);

    useEffect(() => {
        const hazardValue = getValues('hazard');
        const hazardId = Array.isArray(hazardValue) ? hazardValue[0].id : hazardValue;
        const newHazardObj = allHazards.find((elem) => elem.id === hazardId);
        if (newHazardObj) {
            const { name, unitOfMeasurement, yLabel } = newHazardObj;
            if (dirtyFields.hasOwnProperty('hazard')) {
                setValue('xMetric', name);
                setValue('xMetricUnits', unitOfMeasurement);
                setValue('xAxisLabel', `${yLabel} (${unitOfMeasurement})`);
                setValue('yAxisLabel', '');
            } else {
                setValue('xMetric', name);
                setValue('xMetricUnits', unitOfMeasurement);
            }
        }
    }, [allHazards, watchHazard, setValue, getValues, dirtyFields]);

    const updateNumberOfPathways = (eventType, impactFunctionIndex) => {
        let updatedImpactPathways = [...getValues().impactPathways];
        if (eventType === 'append') {
            updatedImpactPathways.push({
                name: '',
                xValues: updatedImpactPathways[updatedImpactPathways.length - 1]?.xValues,
                yValues: Array.from(
                    updatedImpactPathways[updatedImpactPathways.length - 1]?.yValues,
                    (x) => ''
                ),
            });
        } else if (eventType === 'remove') {
            if (updatedImpactPathways.length > 1) {
                updatedImpactPathways = [
                    ...updatedImpactPathways.slice(0, impactFunctionIndex),
                    ...updatedImpactPathways.slice(impactFunctionIndex + 1),
                ];
            }
        }
        setImpactPathways(updatedImpactPathways);
        setValue('impactPathways', updatedImpactPathways);
    };

    const handleCoordButtonClicked = (buttonType) => {
        let updatedImpactPathways = [];
        if (buttonType === 'add') {
            updatedImpactPathways = [...getValues().impactPathways].map((impactPathway) => {
                impactPathway.xValues.push(impactPathway.xValues[impactPathway.xValues.length - 1]);
                impactPathway.yValues.push(impactPathway.yValues[impactPathway.yValues.length - 1]);

                return impactPathway;
            });
        } else if (buttonType === 'remove') {
            updatedImpactPathways = [...getValues().impactPathways].map((impactPathway) => {
                if (impactPathway.xValues.length > 1) {
                    impactPathway.xValues.pop(
                        impactPathway.xValues[impactPathway.xValues.length - 1]
                    );
                    impactPathway.yValues.pop(
                        impactPathway.yValues[impactPathway.yValues.length - 1]
                    );
                }
                return impactPathway;
            });
        }

        setImpactPathways(updatedImpactPathways);
        setValue('impactPathways', updatedImpactPathways);
    };

    const getTransformedData = (data, valueKeyMap, callback) => {
        if (data && data.length > 0) {
            return data.map((item) => {
                const transformedItem = transformDataObjForKoiSelect(item, valueKeyMap);
                const additionalProps = callback ? callback(item) : {};
                return {...transformedItem, ...additionalProps};
            });
        }
        return [];
    };

    const valueKeyMapForSelect = {id: 'id', label: 'name', value: 'id'};

    const allHazardsDataForSelect = getTransformedData(allHazards, valueKeyMapForSelect);
    const allRiskFactorsDataForSelect =
        getTransformedData(
            allRiskFactors,
            valueKeyMapForSelect,
            (item) => ({ disabled: (item.tcfd_id !== 2) })
        );

    const selectedHazardId = defaultFormValues && defaultFormValues.hazard;

    let selectedHazardObj;
    if (selectedHazardId) {
        selectedHazardObj = allHazardsDataForSelect && allHazardsDataForSelect.length > 0 &&
            allHazardsDataForSelect.find((hazard) => hazard.id === selectedHazardId);
    }

    const selectedRiskFactorId = defaultFormValues && defaultFormValues.riskFactor;

    let selectedRiskFactorObj;
    if (selectedRiskFactorId) {
        selectedRiskFactorObj = allRiskFactorsDataForSelect && allRiskFactorsDataForSelect.length > 0 &&
            allRiskFactorsDataForSelect.find((riskFactor) => riskFactor.id === selectedRiskFactorId);
    }

    return (
        <>
            <H1>{displayHeader || ''}</H1>
            <HeadingMd className={displayHeader ? 'spg-mt-md' : ''}>
                Impact Function Summary
            </HeadingMd>

            {allHazards && defaultFormValues && (
                <form onSubmit={handleSubmit(submitClicked)}>
                    <div className="spg-row">
                        <div className="spg-col-12 spg-mt-sm">
                            <FormGroup label="Impact Function Name" required>
                                <Controller
                                    render={({ field }) => (
                                        <InputField {...field} />
                                    )}
                                    id="impactFunctionName"
                                    name="impactFunctionName"
                                    control={control}
                                    rules={{
                                        required: 'Impact Function Name is required',
                                        maxLength: {
                                            value: 128,
                                            message: 'Impact Function name exceeds max length',
                                        },
                                    }}
                                    autoFocus
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="impactFunctionName"
                                    render={({ message }) => <ErrorTextBodySm>{message}</ErrorTextBodySm>}
                                />
                            </FormGroup>
                        </div>
                    </div>
                    <div className="spg-row">
                        <div className="spg-col-12">
                            <FormGroup label="Impact function description">
                                <Controller
                                    render={({ field }) => (
                                        <TextArea
                                            {...field}
                                            maxRows={8}
                                            style={{ width: '100%'}}
                                        />
                                    )}
                                    id="impactFunctionDescription"
                                    name="impactFunctionDescription"
                                    control={control}
                                    rules={{
                                        maxLength: {
                                            value: 3000,
                                            message:
                                                'Impact Function description exceeds max length',
                                        },
                                    }}
                                />
                                <TextBodySm className="spg-mt-xs">
                                    {`${getValues('impactFunctionDescription')?.length || 0} used of 3000 characters.`}
                                </TextBodySm>

                                <ErrorMessage
                                    errors={errors}
                                    name="impactFunctionDescription"
                                    render={({ message }) => <ErrorTextBodySm>{message}</ErrorTextBodySm>}
                                />
                            </FormGroup>
                        </div>
                    </div>
                    <div className="spg-row">
                        <div className="spg-col-6">
                            <FormGroup label="Hazard" required>
                                <Controller
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            options={allHazardsDataForSelect}
                                            isMulti={false}
                                            closeOnSelection
                                            defaultValue={selectedHazardObj ? [selectedHazardObj] : undefined}
                                        />
                                    )}
                                    control={control}
                                    name="hazard"
                                    rules={{ required: 'Hazard is required' }}
                                />
                            </FormGroup>
                        </div>
                        <div className="spg-col-6">
                            <FormGroup label="Risk Factor" required>
                                <Controller
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            options={allRiskFactorsDataForSelect}
                                            isMulti={false}
                                            closeOnSelection
                                            defaultValue={selectedRiskFactorObj ? [selectedRiskFactorObj] : undefined}
                                        />
                                    )}
                                    control={control}
                                    name="riskFactor"
                                    rules={{ required: 'Risk Factor is required' }}
                                />
                            </FormGroup>
                        </div>
                    </div>
                    <div className="spg-row">
                        <div className="spg-col-3">
                            <FormGroup label="X Metric">
                                <Controller
                                    render={({ field }) => (
                                        <InputField {...field} readOnly />
                                    )}
                                    name="xMetric"
                                    control={control}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="xMetric"
                                    render={({ message }) => <ErrorTextBodySm>{message}</ErrorTextBodySm>}
                                />
                            </FormGroup>
                        </div>
                        <div className="spg-col-3">
                            <FormGroup label="X Metric Units">
                                <Controller
                                    render={({ field }) => {
                                        const inputValue = !field.value && {value: ''};
                                        return (
                                            <InputField {...field} readOnly {...inputValue} />
                                        )}}
                                    name="xMetricUnits"
                                    control={control}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="xMetricUnits"
                                    render={({ message }) => <ErrorTextBodySm>{message}</ErrorTextBodySm>}
                                />
                            </FormGroup>
                        </div>
                        <div className="spg-col-6">
                            <FormGroup label="X Label">
                                <Controller
                                    render={({ field }) => (
                                        <InputField {...field} />
                                    )}
                                    name="xAxisLabel"
                                    control={control}
                                    rules={{ maxLength: 128 }}
                                />
                            </FormGroup>
                        </div>
                    </div>
                    <div className="spg-row">
                        <div className="spg-col-3">
                            <FormGroup label="Y Metric">
                                <Controller
                                    render={({ field }) => (
                                        <InputField {...field} readOnly />
                                    )}
                                    name="yMetric"
                                    control={control}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="yMetric"
                                    render={({ message }) => <ErrorTextBodySm>{message}</ErrorTextBodySm>}
                                />
                            </FormGroup>
                        </div>
                        <div className="spg-col-3">
                            <FormGroup label="Y Metric Units">
                                <Controller
                                    render={({ field }) => (
                                        <InputField {...field} readOnly />
                                    )}
                                    name="yMetricUnits"
                                    control={control}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="yMetricUnits"
                                    render={({ message }) => <ErrorTextBodySm>{message}</ErrorTextBodySm>}
                                />
                            </FormGroup>
                        </div>
                        <div className="spg-col-6">
                            <FormGroup label="Y Label">
                                <Controller
                                    render={({ field }) => (
                                        <InputField {...field} />
                                    )}
                                    name="yAxisLabel"
                                    control={control}
                                    rules={{ maxLength: 128 }}
                                />
                            </FormGroup>
                        </div>
                    </div>
                    <div className="spg-row">
                        <div className="spg-col-12">
                            <HeadingMd className="spg-mt-lg spg-mb-md">Impact Pathway(s)</HeadingMd>
                        </div>
                    </div>

                    {impactPathways &&
                            impactPathways.map((impactPathway, idx) => (
                                <div key={`grid-ipf-${idx}`}>
                                    <ImpactPathwayForm
                                        key={`ipf-${idx}`}
                                        ipIndex={idx}
                                        impactPathway={impactPathway}
                                        coordButtonClicked={(e, value) => {
                                            e.preventDefault();
                                            handleCoordButtonClicked(value);
                                        }}
                                        removePathwayButtonClicked={(event) => {
                                            event.preventDefault();
                                            updateNumberOfPathways('remove', idx);
                                        }}
                                        errors={errors}
                                        control={control}
                                        getValues={getValues}
                                    />
                                </div>
                            ))}

                    <div className="spg-d-flex spg-justify-start spg-mt-sm">
                        <Button
                            onClick={(event) => {
                                event.preventDefault();
                                updateNumberOfPathways('append', 1);
                            }}
                            purpose={Purpose.SECONDARY}
                            leftIcon={<Icon icon={PLUS} />}
                        >
                            Add Impact Pathway
                        </Button>
                    </div>
                    <div className="spg-d-flex spg-justify-end spg-mt-md">
                        <Button
                            id="saveIfButton"
                            purpose={Purpose.PRIMARY}
                            type="submit"
                        >
                            Save Impact Function
                        </Button>
                        <Button
                            onClick={cancelClicked}
                            purpose={Purpose.SECONDARY}
                            className="spg-ml-md"
                        >
                            Cancel
                        </Button>
                    </div>
                </form>
            )}
        </>
    );
}
