import React from 'react';

const defaultUser = {
    customerId: 0,
    roles: [],
};

const defaultUserContext = {
    user: defaultUser,
    isAuthenticated: false,
    disclaimerViewed: false,
    handleSetDisclaimerViewed: () => {},
    login: () => {},
    logout: () => {},
};

const UserContext = React.createContext(defaultUserContext);

export const UserProvider = UserContext.Provider;
export const UserConsumer = UserContext.Consumer;

export default UserContext;
