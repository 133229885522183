import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

export const useTracking = (trackingId = process.env.GA_MEASUREMENT_ID, userObject) => {
    const { listen } = useHistory();
    const parseTitle = (pathname) => {
        if (pathname === '/') {
            return 'home';
        }
        return pathname
            .slice(1)
            .split('/')
            .filter((item) => isNaN(+item))
            .map((item) => item.replace(/[^\w]/g, ' '))
            .join(' > ');
    };
    useEffect(() => {
        const unlisten = listen((location) => {
            if (!window.gtag) return;
            if (!trackingId) {
                console.log(
                    'Tracking not enabled, as `trackingId` was not given and there is no `GA_MEASUREMENT_ID`.'
                );
                return;
            }
            const gtagConfig = {
                page_path: location.pathname,
                page_title: parseTitle(location.pathname),
                page_location: location.pathname,
                user_id: userObject.sub || userObject.userId || '',
            };
            window.gtag('config', trackingId, gtagConfig);
        });

        return unlisten;
    }, [trackingId, userObject, listen]);
};
