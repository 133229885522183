import React from 'react';
import { Purpose } from '@spglobal/koi-helpers';
import {
    Modal,
    ModalContent,
    ModalFooter,
    ModalHeader,
    Button,
    LoadingIndicator,
    LoaderOverlay,
    LoaderPosition,
    LoaderType,
} from '@spglobal/react-components';
import allAssetsAPI from '../../api/all-assets';
import { TextBodyLg } from '../../components/shared';

export default function DialogBoxUpload(props) {
    const handleCancelUpload = async () => {
        try {
            await allAssetsAPI.deleteFileStatus(props.FileDetails);
            props.handleClose();
            props.cancelRequest();
        } catch (e) {
            console.log('error occurred:==>', e);
        }
    };

    return (
        <Modal
            isOpen={props.isOpen}
            onClose={props.handleClose}
            aria-labelledby="props.alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="file-upload-popup"
        >
            <ModalHeader id="alert-dialog-title" title="File Upload Progress" />
            <ModalContent>
                <TextBodyLg id="alert-dialog-description">
                    {'Do not close the browser until file upload is completed'}
                </TextBodyLg>
                    <LoadingIndicator className="spg-position-relative spg-w-100 spg-pt-md"
                        type={LoaderType.CENTER}
                        position={LoaderPosition.FIXED}
                        overlay={LoaderOverlay.BLURRED}
                    >
                        {props.progress}%
                    </LoadingIndicator>
            </ModalContent>
            <ModalFooter>
                <Button purpose={Purpose.SECONDARY} onClick={handleCancelUpload}>
                    Cancel Upload
                </Button>
            </ModalFooter>
        </Modal>
    );
}
