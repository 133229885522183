import { httpClient } from '../utils/httpClient';
import { API_URL } from '../utils/constants';

const ENDPOINT = 'api/riskFactors';

const getAll = async () => {
    const result = await httpClient.get(`${API_URL}/${ENDPOINT}?library=true`);
    return result.data;
};

export default { getAll };
