import styled from '@emotion/styled';
import { css } from '@emotion/react';

const fontSizeLg = css`
    font-size: var(--size-font-5);
`;

export const TextBody2xs = styled.div`
    font: var(--font-body-2xs);
`;

export const TextBodyXs = styled.div`
    font: var(--font-body-xs);
`;

export const TextBodySm = styled.div`
    font: var(--font-body-sm);
`;

export const TextBodyMd = styled.div`
    font: var(--font-body-md);
`;

export const TextBodyLg = styled.div`
    font: var(--font-body-lg);
    display: flex;
    align-items: center;
`;
export const TextBodyXl = styled.div`
    font: var(--font-body-xl);
`;

export const LogoText = styled(TextBodyXl)`
    font-size: var(--size-font-8);
`;

export const ParagraphLg = styled(TextBodyLg)`
    margin: var(--size-space-md) 0;

    ${({ marginLg }) =>
        marginLg &&
        css`
            && {
                margin: var(--size-space-lg) 0;
            }
        `}
`;

export const HeadingXs = styled.div`
    font: var(--font-heading-xs);
`;
export const HeadingSm = styled.div`
    font: var(--font-heading-sm);
`;

export const HeadingMd = styled.div`
    font: var(--font-heading-md);
`;

export const HeadingLg = styled.div`
    font: var(--font-heading-lg);
`;

export const HeadingXl = styled.div`
    font: var(--font-heading-xl);
`;

export const Heading2Xl = styled.div`
    font: var(--font-heading-2xl);
`;

export const InlineHeadingSm = styled.span`
    font: var(--font-heading-sm);
`;

export const UnorderedListLg = styled.ul`
    ${fontSizeLg};

    ${({ isStyleNone }) =>
        isStyleNone &&
        css`
            && {
                list-style: none;
            }
        `}
`;

export const OrderedListLg = styled.ol`
    ${fontSizeLg};
`;

export const TextBodyErrorMd = styled(TextBodyMd)`
    color: var(--color-text-error);
`;

export const ErrorTextBodySm = styled(TextBodySm)`
    color: var(--color-text-error);
    margin-top: var(--size-space-xs);
`;
