import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import folderService from 'services/folderService';
import viewService from 'services/viewService';
import BreadcrumbTrail from 'components/BreadcrumbTrail';
import CustomHeader from 'components/Header/CustomHeader';
import DeleteDialog from 'components/DeleteDialogTemplate/DeleteDialogTemplate';
import CreateUpdateEntityDialog from 'components/CreateUpdateEntityDialog';
import pathParser from 'utils/pathParser';
import googleAnalyticsEvent from 'utils/googleAnalyticsEvent';
import CreateEditViewDialog from 'components/CreateEditGroupDialog';
import { VIEW, NEW_ASSET, EDIT, RENAME, DELETE } from 'utils/constants';
import { NotificationType, useNotification } from '@spglobal/react-components';
import GroupDetailsDataTable from './GroupDetailsDataTable';

const Groups = () => {
    const history = useHistory();
    const { pathname } = useLocation();
    const { customers: customerId, views: viewId } = pathParser.getPathComponents(pathname);
    const urlParams = new URLSearchParams(window.location.search);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [viewData, setViewData] = useState();
    const [model, setModel] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [displayDialogType, setDisplayDialogType] = useState('');
    const [dialogText, setDialogText] = useState({ title: '', message: '' });
    const { addNotification } = useNotification();
    const [searchNameText, setSearchNameText] = useState('');
    const [selectedRow, setSelectedRow] = useState(null);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [errorHelperText, setErrorHelperText] = useState('');

    const getViewDetailsData = async () => {
        async function fetchData() {
            try {
                const response = await viewService.getViewDetails(customerId, viewId);
                setDataLoaded(true);
                if (response) {
                    setViewData(response?.result);
                }
                return response?.result;
            } catch (error) {
                setDataLoaded(true);
                return error;
            }
        }
        return fetchData();
    };

    useEffect(() => {
        (async function fetchViewsData() {
            await getViewDetailsData();
        })();
    }, [viewId]);

    useEffect(() => {
        if (viewData) {
            const { folders_list, views_list } = viewData;
            const foldersList = folders_list?.map((folder) => {
                const folderObj = { ...folder };
                folderObj.name = folder?.folder_name;
                folderObj.asset_folder_id = folder?.folder_id;
                folderObj.folders_count = folder?.no_of_folders;
                folderObj.isFolder = true;
                return folderObj;
            });
            const viewsList = views_list?.map((view) => {
                const viewObj = { ...view };
                viewObj.name = view?.view_name;
                viewObj.isFolder = false;
                return viewObj;
            });
            const tableDataList = [...foldersList].concat(viewsList);
            setTableData(tableDataList);
            setModel(tableDataList);
        }
    }, [viewData]);

    const handleTableSearchSubmit = (searchVal) => {
        if (searchVal?.length === 0) {
            setTableData(model);
        } else {
            const filteredTableData = model?.filter(
                (curItem) => curItem?.name?.toLowerCase()?.search(searchVal?.toLowerCase()) >= 0,
            );
            setTableData(filteredTableData);
        }
    };

    const handleViewActions = (actionType, rowDetails = {}) => {
        let viewList = urlParams.get('viewList');
        setSelectedRow(rowDetails);
        if (actionType === VIEW) {
            if (rowDetails?.isFolder) {
                const historyObject = {
                    pathname: `/manage/${customerId}/views/${viewId}/folders/${rowDetails?.folder_id}/assets`,
                };
                if (viewList) historyObject.search = `?${new URLSearchParams({ viewList })}`;
                history.push(historyObject);
            } else {
                if (viewList) {
                    viewList += `,${viewId}`;
                } else {
                    viewList = viewId;
                }
                const uri = `/manage/${customerId}/views/${rowDetails?.view_id}`;
                history.push({
                    pathname: uri,
                    search: `?${new URLSearchParams({ viewList })}`,
                });
            }
            dismissDialog();
        } else if (actionType === NEW_ASSET) {
            setDisplayDialogType(actionType);
        } else if (actionType === EDIT) {
            setDisplayDialogType(actionType);
            setDialogText({ title: 'Edit View' });
        } else if (actionType === RENAME) {
            const { isFolder } = rowDetails;
            setSearchNameText(rowDetails?.name);
            setDialogText({ title: isFolder ? 'Rename Folder' : 'Rename View' });
            setDisplayDialogType(actionType);
        } else if (actionType === DELETE) {
            setShowDeleteDialog(true);
        }
    };

    const handleViewOrFolderDelete = async (isDeleteConfirmed) => {
        const { isFolder, folder_id, view_id } = selectedRow || {};
        setShowDeleteDialog(false);
        if (isDeleteConfirmed) {
            let msg = `${isFolder ? 'Folder' : 'View'} successfully deleted`;
            let type = NotificationType.SUCCESS;
            try {
                if (isFolder) {
                    await folderService.deleteFolder(customerId, folder_id, searchNameText);
                } else {
                    await viewService.deleteView(customerId, view_id);
                }
                await getViewDetailsData();
                setSelectedRow(null);
                googleAnalyticsEvent('Delete Entity', 'View', 'success');
            } catch (error) {
                msg =
                    error?.response?.status === 401
                        ? `Error: ${error?.response?.data}`
                        : `Error: ${isFolder ? 'Folder' : 'View'} delete failed.`;
                type = NotificationType.ERROR;
            }
            addNotification(msg, type);
        }
    };

    const getDeletePrimaryMessage = () => {
        const { isFolder, name } = selectedRow || {};
        return isFolder
            ? `All Assets from "${name}" folder will be moved to Uncategorized folder.`
            : `"${name}" will be deleted.`;
    };

    const isNameInvalid = (isFolder) => {
        let showErr = false;
        let errText = '';
        const nameKey = isFolder ? 'folder_name' : 'view_name';
        if (model.some((folder) => folder?.[nameKey] === searchNameText)) {
            showErr = true;
            errText = `${isFolder ? 'Folder' : 'View'} name already exists`;
        }
        if (searchNameText?.length > 256) {
            showErr = true;
            errText = `${isFolder ? 'Folder' : 'View'} name length cannot exceed 256 characters`;
        }
        if (showErr) {
            setShowErrorMessage(showErr);
            setErrorHelperText(errText);
        }
        return showErr;
    };

    const handleEditView = async (selectedFolders, selectedViews) => {
        const requestBody = {};
        if (selectedFolders?.length) requestBody.folder_ids = selectedFolders;
        if (selectedViews?.length) requestBody.view_ids = selectedViews;
        let msg = 'View successfully Updated';
        let type = NotificationType.SUCCESS;
        try {
            setDisplayDialogType('');
            await viewService.editView(customerId, selectedRow?.view_id, requestBody);
            await getViewDetailsData();
            googleAnalyticsEvent('Update Entity', 'View', 'success');
        } catch (error) {
            msg = error.response
                ? `Error: ${error.response.data.message}`
                : `Error: ${error.message}`;
            type = NotificationType.ERROR;
        }
        addNotification(msg, type);
    };

    const hendleRenameViewOrFolder = async () => {
        const { isFolder } = selectedRow || {};
        if (isNameInvalid(isFolder)) return;

        let msg = `${isFolder ? 'Folder' : 'View'} successfully renamed`;
        let type = NotificationType.SUCCESS;
        try {
            setDisplayDialogType('');
            if (isFolder) {
                await folderService.update(customerId, selectedRow.folder_id, searchNameText);
            } else {
                await viewService.renameView(customerId, selectedRow?.view_id, {
                    view_name: searchNameText,
                });
            }
            await getViewDetailsData();
            setSelectedRow(null);
            googleAnalyticsEvent('Update Entity', 'View', 'success');
            setSearchNameText('');
        } catch (error) {
            msg = error.response
                ? `Error: ${error.response.data.message}`
                : `Error: ${error.message}`;
            type = NotificationType.ERROR;
        }
        addNotification(msg, type);
    };

    const dismissDialog = () => {
        setSearchNameText('');
        setDisplayDialogType('');
        setShowErrorMessage(false);
        setErrorHelperText('');
        setSelectedRow(null);
    };

    return (
        <>
            <BreadcrumbTrail
                className="common-breadcrumbs"
                rootTitle="Customers"
                history={history}
            />
            <div className="spg-w-100 spg-p-md">
                <CustomHeader
                    searchPlaceholder="Folder Name / Group Name"
                    handleActionClick={handleViewActions}
                    handleSearchSubmit={handleTableSearchSubmit}
                />
                <GroupDetailsDataTable
                    tableData={tableData}
                    actionCallback={handleViewActions}
                    updateViewsTableData={getViewDetailsData}
                    isLoading={!dataLoaded}
                    hideActions={true}
                />
                <CreateUpdateEntityDialog
                    dialogIsVisible={displayDialogType === RENAME}
                    dialogDismissed={dismissDialog}
                    dialogOkClicked={hendleRenameViewOrFolder}
                    dialogText={dialogText}
                    textToDisplay={searchNameText}
                    setTextToDisplay={setSearchNameText}
                    showErrorMessage={showErrorMessage}
                    helpText={errorHelperText}
                    setShowErrorMessage={setShowErrorMessage}
                    setErrorHelperText={setErrorHelperText}
                    primaryButtonLabel="Rename"
                />
                <DeleteDialog
                    isDialogOpen={showDeleteDialog}
                    primaryMessage={getDeletePrimaryMessage()}
                    handleDialogAction={handleViewOrFolderDelete}
                    selectedRow={selectedRow}
                    showDataTable={selectedRow?.isFolder}
                />
                {displayDialogType === EDIT && (
                    <CreateEditViewDialog
                        data={tableData}
                        dialogIsVisible={displayDialogType === EDIT}
                        dialogText={dialogText}
                        dialogDismissed={dismissDialog}
                        onEditViewSubmit={handleEditView}
                        selectedRow={selectedRow}
                    />
                )}
            </div>
        </>
    );
};

export default Groups;
