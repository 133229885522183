import React, { useEffect, useState } from 'react';

import { useLocation } from 'react-router-dom';
import { InlineNotification, NotificationType } from '@spglobal/react-components';
import DataService from '../../service/data.service';
import pathParser from '../../utils/pathParser';
import { TextBodyLg } from '../../components/shared';
import DataTableGrid from '../../components/DataTable/DataTableGrid/DataTableGrid';
import errorFrameworkComponents from '../utils/ReviewProcessCellRenderers.utils';

export default function ReviewProcess(props) {
    const [errorData, setErrorData] = useState([]);
    const [fileData, setFileData] = useState({});
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [count, setCount] = useState(0);
    const [offset, setOffset] = useState(0);
    const service = new DataService();
    const { pathname } = useLocation();
    const { 'bulk-import-file': customerId } = pathParser.getPathComponents(pathname);

    const getErrorData = () => {
        try {
            const reqData = { ...props, customerId, rowsPerPage, offset };
            const data = service.getErrorData(reqData);
            // !IMPORTANT: Please don't delete this commented out code - Hrishikesh

            // for (let element of data) {
            //   for (let key in element) {
            //     if (key === 'errors') {
            //       continue;
            //     }
            //     let errorColumn = Object.keys(element['errors']);
            //     if (errorColumns.includes(key)) {
            //       element[key] = element.errors[key];
            //     }
            //   }
            // }
            //   infoLog('Fetched File Validation Error List Successfully', 'upload-assets');
            return data;
        } catch (e) {
            //   errorLog(
            //     'Exception in fetching File Validation Error List: ' + JSON.stringify(e),
            //     'upload-assets'
            //   );
        }
    };

    const getFileData = async () => {
        const reqData = {
            file_id: props.fileId,
            customer_id: customerId,
        };
        const data = await service.getFileUploadProcessingStatus(reqData);
        return data.data;
    };

    useEffect(() => {
        (async function retrieveData() {
            const response = await getErrorData();
            setErrorData(response.result);
            setCount(response.count);
            const fileDetails = await getFileData();
            setFileData(fileDetails);
        })();
    }, []);

    const handlePageChange = (newPageIndex) => {
        setPage(newPageIndex);
        setOffset(page * rowsPerPage);
    };

    const handlePageSizeChange = (newPageSize) => {
        setRowsPerPage(newPageSize);
        setPage(0);
        setOffset(page * rowsPerPage);
    };

    const columnDefs = [
        {
            headerName: 'Upload Date',
            field: 'created_at',
            tooltipValueGetter: (params) => service.getLocalTime(params.data.created_at),
        },
        {
            headerName: 'Customer Name',
            field: 'customer_name',
            tooltipField: 'customer_name',
        },
        {
            headerName: 'Filename',
            field: 'file_name',
            tooltipField: 'file_name',
        },
        {
            headerName: '#Assets',
            field: 'asset_count',
            tooltipField: 'asset_count',
        },
        {
            headerName: 'User Name',
            field: 'user_name',
            tooltipField: 'user_name',
        },
    ];

    const rowData = [
        {
            created_at: new Date(fileData.created_at).toLocaleDateString(),
            customer_name: fileData.customer_name,
            file_name: fileData.file_name,
            asset_count: fileData.asset_count,
            user_name: fileData.user_name,
        },
    ];

    const errorColumnDefs = [
        {
            headerName: 'Asset Reference ID',
            field: 'ref_id',
            cellRenderer: 'assetRefIdCellRenderer',
            tooltipValueGetter: (params) =>
                Object.keys(params.data.error).toString().includes('ref')
                    ? `${params.data.error.ref_id || params.data.error.asset_reference_id} : ${
                          params.data.ref_id || params.data.asset_ref_id
                      }`
                    : undefined,
            flex: 1,
        },
        {
            headerName: 'Asset Name',
            field: 'name',
            cellRenderer: 'assetNameCellRenderer',
            tooltipValueGetter: (params) =>
                Object.keys(params.data.error).toString().includes('name')
                    ? `${params.data.error.name || params.data.error.asset_name} : ${
                          params.data.name || params.data.asset_name
                      }`
                    : undefined,
            flex: 1,
        },
        {
            headerName: 'Asset Type ID',
            cellRenderer: 'assetTypeCellRenderer',
            tooltipValueGetter: (params) =>
                Object.keys(params.data.error).toString().includes('asset_type_id')
                    ? params.data.error.asset_type_id || params.data.error.asset_type_id_string
                    : undefined,
            flex: 1,
        },
        {
            headerName: 'Asset Value',
            cellRenderer: 'assetValueCellRenderer',
            tooltipValueGetter: (params) =>
                Object.keys(params.data.error).toString().includes('value')
                    ? params.data.error.value ||
                      params.data.error.asset_value ||
                      params.data.error.asset_value_string
                    : undefined,
            flex: 1,
        },
        {
            headerName: 'Latitude',
            cellRenderer: 'latitudeCellRenderer',
            tooltipValueGetter: (params) =>
                Object.keys(params.data.error).toString().includes('lat')
                    ? params.data.error.lat || params.data.error.latitude
                    : undefined,
            flex: 1,
        },
        {
            headerName: 'Longitude',
            cellRenderer: 'longitudeCellRenderer',
            tooltipValueGetter: (params) =>
                Object.keys(params.data.error).toString().includes('long')
                    ? params.data.error.long || params.data.error.longitude
                    : undefined,
            flex: 1,
        },
        {
            headerName: 'Address',
            cellRenderer: 'addressCellRenderer',
            tooltipValueGetter: (params) =>
                Object.keys(params.data.error).toString().includes('address')
                    ? params.data.error.address
                    : undefined,
            flex: 1,
        },
        {
            headerName: 'Folder ID',
            cellRenderer: 'folderIDCellRenderer',
            tooltipValueGetter: (params) =>
                Object.keys(params.data.error).toString().includes('folder_id')
                    ? params.data.error.asset_folder_id
                    : undefined,
            flex: 1,
        },
    ];

    const errorRowData = errorData.map((row) => ({
        ref_id: row.ref_id,
        name: row.name,
        asset_type_id: row.asset_type_id,
        value: row.value,
        lat: row.lat,
        long: row.long,
        address: row.address,
        folder_id: row.folder_id,
        error: row.error,
    }));

    return (
        <div>
            <DataTableGrid columnDefs={columnDefs} rowData={rowData} pagination={false} />
            <InlineNotification isOpen closable={false} type={NotificationType.ERROR}>
                <div>
                    <TextBodyLg>
                        The data for the following asset reference IDs could not be validated
                    </TextBodyLg>
                </div>
            </InlineNotification>
            <DataTableGrid
                frameworkComponents={errorFrameworkComponents}
                columnDefs={errorColumnDefs}
                rowData={errorRowData}
                pagination
                totalItemsCount={count}
                onPageChange={handlePageChange}
                onPageSizeChange={handlePageSizeChange}
            />
        </div>
    );
}
