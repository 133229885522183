import allAssetsAPI from '../api/all-assets';
import uploadAssetAPI from '../api/upload-assets';
import config from '../config';

const oktaStorage: any = JSON.parse(localStorage.getItem('okta-token-storage') as string);

export default class DataService {
    async getUploadProcessingStatus(data?: any) {
        const result = await allAssetsAPI.getFileProcessingStatus(data);
        return result;
    }

    async getFileUploadProcessingStatus(data?: any) {
        const result = await allAssetsAPI.getAssetStatus(data);
        return result;
    }

    async uploadBulkAssetFile(data?: any) {
        const result = await uploadAssetAPI.uploadBulkAssetFile(data);
        return result;
    }

    downloadFile = async (uri: string, fileName: string, isPreSignedURL?: boolean) => {
        const response = isPreSignedURL
            ? await fetch(uri)
            : await fetch(uri, {
                  headers: { Authorization: oktaStorage?.idToken?.idToken || '' },
              });
        response
            .blob()
            .then((blob) => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = fileName;
                a.click();
            })
            .catch(() => {
                console.log('Error occurred with file download');
            });
    };

    getErrorData = async (reqData: any) => {
        const response = await uploadAssetAPI.getAssetValidationErrors(reqData);
        const { result } = response[1];
        const { count } = response[0];
        result.forEach((element: any) => {
            element.asset_value = element.value;
            element.asset_name = element.name;
            element.asset_ref_id = element.ref_id;
            element.lattitude = element.lat;
            element.longitude = element.long;
        });
        return { result, count };
    };

    getCustomers = async () => {
        const result = await uploadAssetAPI.getCustomers();
        return result;
    };

    getUserDetails = () => {
        const token: any = localStorage.getItem('okta-token-storage');
        if (token && token.includes('claims')) {
            const { idToken } = JSON.parse(token);
            return idToken;
        }
    };

    getLocalTime = (time: string) => {
        time += 'Z';
        return new Date(time).toLocaleString();
    };

    getFormattedDate = (date: string) => {
        date += 'Z';
        return new Date(date).toLocaleDateString();
    };
}
