import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { IconButton } from '@spglobal/react-components';
import { EDIT, EYE_OPEN } from '@spglobal/koi-icons';
import { Size } from '@spglobal/koi-helpers';
import * as constants from '../../utils/constants';
import hazardService from '../../services/hazardService';
import Header from '../Header';
import BreadcrumbTrail from '../BreadcrumbTrail';
import UserContext from '../../context/UserContext';
import useGetPermission from '../../hooks/useGetPermission';
import { ACTION } from '../../utils/constants';
import { transformDataObjForKoiSelect } from '../../utils/koiIntegrationUtils';
import DataTableGrid from '../DataTable/DataTableGrid/DataTableGrid';

export default function Hazards() {
    const history = useHistory();
    const { user, isLoggedIn } = useContext(UserContext);
    const [hazards, setHazards] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [actions, setUserActions] = useState([ACTION.ACTION_READ]);
    const [dataLoaded, setDataLoaded] = useState(false);

    const isSuperUser =
        isLoggedIn &&
        user &&
        user['https://www.climanomics.com/roles'] &&
        user['https://www.climanomics.com/roles'].some(
            (role) => Object.values(role)[0] === 'superuser',
        );

    useEffect(() => {
        let isSubscribed = true;
        async function fetchData() {
            try {
                const { results: response } = await hazardService.getAll();
                if (response && response.length > 0) {
                    setHazards(response);
                    setTableData(response);
                }
                const userAccess = useGetPermission(user, isLoggedIn, 'Hazards');
                const userPermissions = actions;
                if (userAccess.action.includes(ACTION.ACTION_WRITE)) {
                    userPermissions.push(ACTION.ACTION_EDIT);
                    setUserActions(userPermissions);
                }
                setDataLoaded(true);
            } catch (exc) {
                setDataLoaded(true);
            }
        }
        fetchData();
        return () => (isSubscribed = false);
    }, []);

    const handleTableSearch = (selectedValues) => {
        setTableData(hazards.filter((curItem) => curItem.id === selectedValues[0].id));
    };

    const values = hazards.map((v) =>
        transformDataObjForKoiSelect(v, { id: 'id', label: 'name', value: 'id' }),
    );

    const handleDataTableAction = (action, entityId) => {
        if (action === constants.VIEW || action === 'preview') {
            history.push({
                pathname: `/hazards/${entityId}`,
            });
        }
        if (action === constants.EDIT) {
            history.push({
                pathname: `/hazards/${entityId}/edit`,
            });
        }
    };

    const columnDefs = [
        {
            headerName: 'Hazard Name',
            field: 'name',
            sortable: true,
            maxWidth: 350,
            tooltipField: 'name',
            cellStyle: { display: 'block' },
        },
        {
            headerName: 'Hazards Description',
            field: 'description',
            sortable: true,
            tooltipField: 'description',
            cellStyle: { display: 'block' },
        },
        {
            headerName: ' ',
            field: ' ',
            cellRenderer: 'actionsColumn',
            maxWidth: 60,
            cellStyle: { justifyContent: 'center', alignItems: 'baseline' },
            cellClass: 'cli-action-cell',
        },
    ];

    const actionCellRenderer = (props) => {
        const hazardId = props?.data?.id;

        return (
            <>
                {actions && actions.indexOf(ACTION.ACTION_READ) >= 0 && (
                    <IconButton
                        size={Size.SMALL}
                        icon={EYE_OPEN}
                        onClick={() => handleDataTableAction(constants.VIEW, hazardId)}
                    />
                )}
                {actions && actions.indexOf(ACTION.ACTION_EDIT) >= 0 && (
                    <IconButton
                        size={Size.SMALL}
                        icon={EDIT}
                        onClick={() => handleDataTableAction(constants.EDIT, hazardId)}
                    />
                )}
            </>
        );
    };

    const frameworkComponents = {
        actionsColumn: actionCellRenderer,
    };

    return (
        <>
            <BreadcrumbTrail rootTitle="Hazards" />
            <div className="spg-w-100 spg-p-md">
                <Header
                    title="Hazards"
                    type=""
                    values={values}
                    onChangeFunction={handleTableSearch}
                    link="hazard"
                    history={history}
                />

                <DataTableGrid
                    frameworkComponents={frameworkComponents}
                    rowData={tableData}
                    columnDefs={columnDefs}
                    pagination
                    loading={!dataLoaded}
                />
            </div>
        </>
    );
}
