import styled from '@emotion/styled';
import { css } from '@emotion/react';

const tableStyles = css`
    width: 100%;
    color: var(--color-text-primary);

    tr td:first-of-type {
        width: 35%;
        font-weight: var(--font-weight-bold);
    }
`;

export const FiltersTooltipTable = styled.table`
    ${tableStyles};

    font-family: var(--font-family-base);
    word-break: break-word;
`;
