import React from 'react';
import {
    BULK_MOVE,
    NEW_GROUP,
    NEW_ASSET,
    NEW_FOLDER,
    BULK_DELETE,
    TAG_SELECT,
} from 'utils/constants';
import { IconButton, InputField, Button, Icon, Select } from '@spglobal/react-components';
import { SEARCH, PLUS } from '@spglobal/koi-icons';
import { Purpose, Size } from '@spglobal/koi-helpers';
import { SEARCH_INPUT_LABEL, INPUT_LENGTH } from '../components.constants';

const CustomHeader = (props) => {
    const {
        searchPlaceholder,
        handleSearchSubmit,
        handleActionClick,
        actionsArr,
        multipleRowsSelected,
        searchTextPrev = '',
        hideActions,
        tagsList,
        handleTagsFilter,
        customButtonText,
        handleCustomButtonClick,
        leftIconIsPlus,
        isActionButtonVisible = true,
    } = props || {};

    const [searchText, setSearchText] = React.useState(searchTextPrev);
    const [tagsValues, setTagsValues] = React.useState([]);

    const handleButtonClick = (event, actionType) => {
        event.preventDefault();
        handleActionClick(actionType);
    };

    const handleSearch = (event) => {
        const searchVal = event?.target?.value;
        if (searchVal?.length === 0) {
            handleSearchSubmit(searchVal);
        }
        setSearchText(searchVal);
    };

    const onSearchSubmit = (searchVal) => {
        if (searchVal?.length) handleSearchSubmit(searchVal);
    };

    const handleTagsChange = (optionsArray) => {
        setTagsValues(optionsArray);
        handleTagsFilter('', optionsArray);
    };

    const searchButton = (
        <IconButton
            icon={SEARCH}
            size={Size.SMALL}
            onClick={() => onSearchSubmit(searchText)}
        />
    );

    return (
        <div className="spg-row spg-w-100 spg-mt-lg spg-mb-xl">
            <div className="spg-col-4">
                <InputField
                    inFieldLabel={SEARCH_INPUT_LABEL}
                    maxLength={INPUT_LENGTH}
                    placeholder={searchPlaceholder}
                    value={searchText}
                    onChange={(e) => handleSearch(e)}
                    onKeyDown={(e) => {
                        if (e.keyCode === 13) onSearchSubmit(searchText);
                    }}
                    rightElement={searchButton}
                />
            </div>

            <div className="spg-d-flex spg-col-4 spg-col-offset-1">
                {actionsArr?.includes(TAG_SELECT) && (
                    <Select
                        placeholder="Select tags"
                        isMulti
                        onChange={handleTagsChange}
                        options={tagsList}
                        values={tagsValues}
                    />
                )}
            </div>

            {!hideActions && actionsArr?.length && (
                <div className="spg-d-flex spg-justify-end spg-col-2 spg-col-offset-1">
                    {!multipleRowsSelected && actionsArr?.includes(NEW_FOLDER) && (
                        <Button
                            onClick={(event) => handleButtonClick(event, NEW_FOLDER)}
                            leftIcon={<Icon icon={PLUS} />}
                            purpose={Purpose.SECONDARY}
                        >
                            NEW FOLDER
                        </Button>
                    )}
                    {!multipleRowsSelected && actionsArr?.includes(NEW_ASSET) && (
                        <Button
                            onClick={(event) => handleButtonClick(event, NEW_ASSET)}
                            id="newAssetButton"
                            purpose={Purpose.SECONDARY}
                            leftIcon={<Icon icon={PLUS} />}
                        >
                            NEW ASSET
                        </Button>
                    )}
                    {!multipleRowsSelected && actionsArr?.includes(NEW_GROUP) && (
                        <Button
                            onClick={(event) => handleButtonClick(event, NEW_GROUP)}
                            id="newViewButton"
                            purpose={Purpose.SECONDARY}
                            leftIcon={<Icon icon={PLUS} />}
                        >
                            NEW GROUP
                        </Button>
                    )}
                    {multipleRowsSelected && actionsArr?.includes(BULK_MOVE) && (
                        <Button
                            onClick={(event) => handleButtonClick(event, BULK_MOVE)}
                            id="bulkMoveButton"
                            purpose={Purpose.PRIMARY}
                        >
                            MOVE
                        </Button>
                    )}
                    {multipleRowsSelected && actionsArr?.includes(BULK_DELETE) && (
                        <Button
                            onClick={(event) => handleButtonClick(event, BULK_DELETE)}
                            id="bulkDeleteButton"
                            purpose={Purpose.SECONDARY}
                            className="spg-ml-md"
                        >
                            DELETE
                        </Button>
                    )}
                </div>
            )}

            {!hideActions && customButtonText && isActionButtonVisible && (
                <div className='spg-d-flex spg-justify-end spg-col-2 spg-col-offset-1'>
                    <Button
                        onClick={handleCustomButtonClick}
                        id='customButton'
                        purpose={Purpose.SECONDARY}
                        leftIcon={leftIconIsPlus && (<Icon icon={PLUS} />)}
                    >
                        {customButtonText}
                    </Button>
                </div>
            )}
        </div>
    );
};

export default CustomHeader;
