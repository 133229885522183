import styled from '@emotion/styled';
import { css } from '@emotion/react';

const tableStyles = css`
    width: 100%;
    color: var(--color-text-primary);

    tr td:first-of-type {
        width: 30%;
        font-weight: var(--font-weight-bold);
    }
`;

export const GenerateReportModalTable = styled.table`
    ${tableStyles};

    font: var(--font-body-lg);
    margin-top: var(--size-space-lg);
`;
