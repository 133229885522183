import React, { useContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Card } from '@spglobal/react-components';
import UserContext from '../../context/UserContext';
import CustomersDataTable from './CustomersDataTable';
import CustomHeader from '../Header/CustomHeader';
import BreadcrumbTrail from '../BreadcrumbTrail';
import { useApiGet } from '../../hooks/useApiGet';
import * as constants from '../../utils/constants';
import MessageBox from '../MessageBox';

export default function Customers({ route }) {
    const history = useHistory();
    const [model, setModel] = useState([]);
    const [tableData, setTableData] = useState([]);
    const { user, isLoggedIn } = useContext(UserContext);
    const url = 'api/customers';
    const params = {};

    const { data: customers, loading } = useApiGet(url, params);
    const [showInactiveUserDialog, setShowInactiveUserDialog] = useState(false);
    const isSuperUser =
        isLoggedIn &&
        user &&
        user['https://www.climanomics.com/roles'] &&
        user['https://www.climanomics.com/roles'].some(
            (role) => Object.values(role)[0] === 'superuser'
        );

    useEffect(() => {
        // const groupsWithLabels = createOptionLabel(groups);
        setModel(customers?.results);
        setTableData(customers?.results);
        if (customers?.results?.length === 1) {
            reRouteUser(customers?.results[0]);
        }
    }, [customers]);

    useEffect(() => {
        // const groupsWithLabels = createOptionLabel(groups);
        setModel(customers?.results);
        setTableData(customers?.results);
    }, [customers]);

    const handleTableSearch = (searchVal) => {
        if (searchVal?.length === 0) {
            return setTableData(model);
        }
        const filteredRows = model.filter((row) =>
            row?.name.toLowerCase().includes(searchVal.toLowerCase())
        );
        setTableData(filteredRows);
    };

    const handleDataTableAction = (rowData, actionType) => {
        if (rowData?.customer_type?.toLowerCase() === 'inactive') {
            setShowInactiveUserDialog(true);
            return;
        }
        // Callback actions will be one of the following: 'view', 'edit', or 'delete'
        const curSelection = tableData.find((entity) => entity?.id === rowData?.id);

        if (route) {
            return history.push({
                pathname: `${route}/${curSelection?.id}`,
            });
        }

        if (actionType === constants.VIEW) {
            reRouteUser(curSelection);
        }
    };

    const reRouteUser = (selectedCustomer) => {
        if (window.location.pathname.includes('real-assets')) {
            history.push({
                pathname: `/real-assets/${selectedCustomer?.id}`,
                search: '?activeTab=allAssets',
            });
        } else if (window.location.pathname.includes('bulk-import')) {
            history.push({
                pathname: `/bulk-import-track-file/${selectedCustomer?.id}`,
                search: `?${new URLSearchParams({ customerName: selectedCustomer?.name })}`,
            });
        } else if (window.location.pathname.includes('rbac-client-admin')) {
            history.push({
                pathname: `/rbac-client-admin/${selectedCustomer?.id}`,
                search: '',
            });
        } else {
            history.push({
                pathname: `/manage/${selectedCustomer?.id}`,
                search: '?activeTab=allAssets',
            });
        }
    };

    return (
        <div>
            <MessageBox
                messageBoxIsVisible={showInactiveUserDialog}
                messageBoxText="This Customer is Inactive. Please contact admin."
                messageBoxCallback={() => setShowInactiveUserDialog(false)}
                cancelText=""
                okText="Ok"
            />
            <BreadcrumbTrail className="common-breadcrumbs" rootTitle="Customers" />
            <Card>
                <div>
                    <CustomHeader
                        searchPlaceholder="Customer Name"
                        handleSearchSubmit={handleTableSearch}
                    />
                    <CustomersDataTable
                        tableData={tableData || []}
                        actionCallback={handleDataTableAction}
                        loading={loading}
                    />
                </div>
            </Card>
        </div>
    );
}
