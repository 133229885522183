import styled from '@emotion/styled';

const BreadcrumbTrailContainer = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    height: var(--size-height-lg);
    padding: var(--size-space-sm) var(--size-space-lg);
    box-shadow: 0 var(--size-space-2xs) 0 rgba(var(--rgb-base-black), 0.07);
    margin-bottom: var(--size-space-xs);

    && a {
        font: var(--font-body-lg);
        color: var(--color-text-primary);
    }

    & [data-link] {
        pointer-events: none;
    }
`;

export default BreadcrumbTrailContainer;
