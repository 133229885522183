/**
 * Constants used within Import module
 */
export const FILE_PROCESSING_STATUS = {
    FILE_UPLOAD_INPROGRESS: 'FILE_UPLOAD_INPROGRESS',
    FILE_UPLOAD_FAILED: 'FILE_UPLOAD_FAILED',
    FILE_UPLOAD_COMPLETED: 'FILE_UPLOAD_COMPLETED',
    ASSET_VALIDATION_INPROGRESS: 'ASSET_VALIDATION_INPROGRESS',
    ASSET_VALIDATION_FAILED: 'ASSET_VALIDATION_FAILED',
    PARSER_EXCEPTION: 'PARSER_EXCEPTION',
    ASSET_VALIDATION_COMPLETED: 'ASSET_VALIDATION_COMPLETED',
    RISK_SCORING_INPROGRESS: 'RISK_SCORING_INPROGRESS',
    RISK_SCORING_PARTIALLY_SUCCEEDED: 'RISK_SCORING_PARTIALLY_SUCCEEDED',
    RISK_SCORING_FAILED: 'RISK_SCORING_FAILED',
    RISK_SCORING_COMPLETED: 'RISK_SCORING_COMPLETED',
    AGGREGATION_INPROGRESS: 'AGGREGATION_INPROGRESS',
    AGGREGATION_FAILED: 'AGGREGATION_FAILED',
    RESCORING_INPROGRESS: 'RESCORING_INPROGRESS',
    RESCORING_FAILED: 'RESCORING_FAILED',
    RESCORING_SUCCEEDED: 'RESCORING_SUCCEEDED',
    RESCORING_PARTIALLY_SUCCEEDED: 'RESCORING_PARTIALLY_SUCCEEDED',
    RESCORING_AGGREGATION_INPROGRESS: 'RESCORING_AGGREGATION_INPROGRESS',
    RESCORING_AGGREGATION_FAILED: 'RESCORING_AGGREGATION_FAILED',
};

export const UPLOAD_STATUS_TABLE_ACTION = {
    CANCEL_UPLOAD: 1,
    VIEW_SCORING_DETAILS: 3,
    REFRESH: 4,
};

export const STEPS = ['Prepare your upload file', 'Upload File', 'Review & Process'];

export const STEP_INDEXES = {
    prepareFile: `${STEPS[0]}-tab`,
    uploadFile: `${STEPS[1]}-tab`,
    reviewTab: `${STEPS[2]}-tab`,
};
