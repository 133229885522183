import { Icon } from '@spglobal/react-components';
import { NO_CONNECTION } from '@spglobal/koi-icons';
import React from 'react';

const AssetRefIdCellRenderer = (params) => {
    if (Object.keys(params.data.error).toString().includes('ref')) {
        return (
            <div>
                <Icon icon={NO_CONNECTION} color="red" /> Error Field
            </div>
        );
    }
    return <div>{params.data.ref_id}</div>;
};

const AssetNameCellRenderer = (params) => {
    if (Object.keys(params.data.error).toString().includes('name')) {
        return (
            <div>
                <Icon icon={NO_CONNECTION} color="red" /> Error Field
            </div>
        );
    }
    return <div>{params.data.name}</div>;
};

const AssetTypeCellRenderer = (params) => {
    if (Object.keys(params.data.error).toString().includes('asset_type_id')) {
        return (
            <div>
                <Icon icon={NO_CONNECTION} color="red" /> Error Field
            </div>
        );
    }
    return <div>{params.data.asset_type_id}</div>;
};

const AssetValueCellRenderer = (params) => {
    if (Object.keys(params.data.error).toString().includes('value')) {
        return (
            <div>
                <Icon icon={NO_CONNECTION} color="red" /> Error Field
            </div>
        );
    }
    return <div>{params.data.value}</div>;
};

const LatitudeCellRenderer = (params) => {
    if (Object.keys(params.data.error).toString().includes('lat')) {
        return (
            <div>
                <Icon icon={NO_CONNECTION} color="red" /> Error Field
            </div>
        );
    }
    return <div>{params.data.lat}</div>;
};

const LongitudeCellRenderer = (params) => {
    if (Object.keys(params.data.error).toString().includes('long')) {
        return (
            <div>
                <Icon icon={NO_CONNECTION} color="red" /> Error Field
            </div>
        );
    }
    return <div>{params.data.long}</div>;
};
const AddressCellRenderer = (params) => {
    if (Object.keys(params.data.error).toString().includes('address')) {
        return (
            <div>
                <Icon icon={NO_CONNECTION} color="red" /> Error Field
            </div>
        );
    }
    return <div>{params.data.address}</div>;
};
const FolderIDCellRenderer = (params) => {
    if (Object.keys(params.data.error).toString().includes('folder_id')) {
        return (
            <div>
                <Icon icon={NO_CONNECTION} color="red" /> Error Field
            </div>
        );
    }
    return <div>{params.data.folder_id}</div>;
};

const errorFrameworkComponents = {
    assetRefIdCellRenderer: AssetRefIdCellRenderer,
    assetNameCellRenderer: AssetNameCellRenderer,
    assetTypeCellRenderer: AssetTypeCellRenderer,
    assetValueCellRenderer: AssetValueCellRenderer,
    latitudeCellRenderer: LatitudeCellRenderer,
    longitudeCellRenderer: LongitudeCellRenderer,
    addressCellRenderer: AddressCellRenderer,
    folderIDCellRenderer:FolderIDCellRenderer
};

export default errorFrameworkComponents;
