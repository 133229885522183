import { httpClient } from '../utils/httpClient';
import { API_URL } from '../utils/constants';

const ENDPOINT = 'api/customer';

const getAll = async (customerId, params) => {
    const result = await httpClient.get(`${API_URL}/${ENDPOINT}/${customerId}/folders`, {
        params: {
            ...params,
        },
        contentType: 'application/json',
    });
    return result.data;
};

const getFolderById = async (customerId, folderId) => {
    const API_SOURCE = API_URL;

    const result = await httpClient.get(
        `${API_SOURCE}/${ENDPOINT}/${customerId}/folder/${folderId}`
    );
    return result.data;
};

const create = async (customerID, folder_name) => {
    const params = {
        folder_name,
    };
    const result = await httpClient.post(`${API_URL}/${ENDPOINT}/${customerID}/folders`, params);
    return result.data;
};

const update = async (customerID, folderID, folder_name) => {
    const params = {
        folder_name,
    };
    const result = await httpClient.put(
        `${API_URL}/${ENDPOINT}/${customerID}/folder/${folderID}`,
        params
    );
    return result.data;
};

const deleteFolder = async (customerID, folderID) => {
    const result = await httpClient.delete(
        `${API_URL}/${ENDPOINT}/${customerID}/folder/${folderID}`
    );
    return result.data;
};

export default {
    getAll,
    create,
    update,
    deleteFolder,
    getFolderById,
};
