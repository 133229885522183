export const formatDecadalStatesForGraph = (decadalStates) => {
    if (!decadalStates || !Array.isArray(decadalStates)) {
        return [];
    }

    // we use the historical Average (denoted by decade 0) within each of the other decades
    const historicalAverage = decadalStates.find((state) => state.decade === 0);
    // default our historical Average hazard metric mean to zero if not present
    const histAvgHazardMetricMean = historicalAverage ? historicalAverage.hazardMetricMean : 0;

    const result = decadalStates.reduce((acc, { decade, hazardMetricMean }) => {
        // we do not need to capture the historical average within its own segment of our Area graph
        if (decade === 0) {
            return acc;
        }
        acc.push({
            decade,
            // assumes a zero bound for historical baseline of given hazardMetricMean
            'Historical Baseline': histAvgHazardMetricMean,
            // projectedChange: [histAvgHazardMetricMean, hazardMetricMean],
            'Projected Change': [histAvgHazardMetricMean, hazardMetricMean],
            'Projected Value': hazardMetricMean,
        });
        return acc;
    }, []);

    // sort for Graph to display values ordered by decade
    return result.sort((a, b) => a.decade - b.decade);
};

export default {
    formatDecadalStatesForGraph
};
