import React, { useEffect, useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import styled from '@emotion/styled';
import { Button, H2, Modal, ModalContent, ModalFooter } from '@spglobal/react-components';
import { Size } from '@spglobal/koi-helpers';
import ProductList from '../ProductList';
import customerService from '../../services/customerService';
import { getRole } from '../../utils/user';
import DataService from '../../service/data.service';
import { PRODUCT_MANAGER } from '../../utils/constants';
import { TextBodyMd } from '../shared';

const LandingPageContainer = styled.div`
    margin: 0 auto;
    padding-bottom: 150px;
    width: 1056px;
    font: var(--font-body-md);
`;

export default function LandingPage() {
    const { oktaAuth } = useOktaAuth();
    const [showDialog, setShowDialog] = useState(false);
    const service = new DataService();

    const products = {
        myProducts: [
            {
                productName: 'Real Assets',
                productDescription:
                    'Climate risk analysis of real estate, infrastructure, agriculture, mortgages, supply chain assets, and corporate assets.',
                path: '/real-assets',
                isSubscribed: true,
                details: false,
            },
        ],
        trialProducts: [],
    };

    const handleClose = () => {
        setShowDialog(false);
        oktaAuth.signOut();
    };

    useEffect(() => {
        try {
            (async function retrieveData() {
                const userDetails = service.getUserDetails();
                const role = getRole(
                    userDetails && userDetails?.claims?.SPGGroups
                        ? userDetails?.claims?.SPGGroups
                        : []
                );
                if (!role) {
                    setShowDialog(true);
                    return;
                }
                const customers = await getCustomers();
                let count = 0;
                if (customers && !customers?.results?.length) {
                    if (!(role === PRODUCT_MANAGER)) {
                        setShowDialog(true);
                    }
                } else if (customers && !customers?.results?.length > 0) {
                    customers?.results.forEach((customer) => {
                        if (customer?.customer_type?.toLowerCase() === 'inactive') {
                            count += 1;
                        }
                    });
                    if (count > 0 && count === customers?.results?.length) {
                        setShowDialog(true);
                    }
                }
            })();
        } catch (e) {
            console.log('error in retrieving customers:==>', e);
        }
    }, []);

    const getCustomers = async () => customerService.getAll();

    return (
        <>
            <div className="banner-image"></div>
            <LandingPageContainer>
                <H2 className="welcome-text">Welcome to S&amp;P Global Climanomics</H2>
                <ProductList productType="My Products" products={products.myProducts} />
            </LandingPageContainer>
            <Modal size={Size.SMALL} aria-labelledby="customized-dialog-title" isOpen={showDialog}>
                <ModalContent>
                    <TextBodyMd className="spg-mt-md">
                        You don&apos;t have active customers assigned, please contact your
                        administrator.
                    </TextBodyMd>
                    <TextBodyMd>Click Ok to Logout.</TextBodyMd>
                </ModalContent>
                <ModalFooter>
                    <Button purpose="primary" active={true} onClick={handleClose}>
                        Ok
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
}
