import React, { useContext } from 'react';
import { Size } from '@spglobal/koi-helpers';
import { Link, Modal, ModalContent, ModalFooter, Button } from '@spglobal/react-components';
import UserContext from '../../context/UserContext';
import { TextBodyMd } from '../shared';

export default function CustomizedDialogs() {
    const { disclaimerViewed, handleSetDisclaimerViewed } = useContext(UserContext);

    return (
        <div>
            <Modal
                size={Size.MEDIUM}
                aria-labelledby="customized-dialog-title"
                isOpen={!disclaimerViewed}
                ariaLabel="warning message"
            >
                <ModalContent>
                    <TextBodyMd className="spg-mt-md">
                        Our platform will be undergoing planned maintenance from Friday, February
                        11, 9:00pm ET to Sunday, February 13, 9:00pm ET. We will be using this time
                        to add more capacity to our infrastructure and enhance overall service.
                        During this maintenance window, it is expected that our user interface will
                        not be accessible. Please email Ti Chesley, Sr. Director of Customer
                        Success, at{' '}
                        <Link href="mailto: tchesley@theclimateservice.com">
                            tchesley@theclimateservice.com
                        </Link>{' '}
                        if you have any questions or concerns.
                    </TextBodyMd>
                </ModalContent>
                <ModalFooter>
                    <Button onClick={handleSetDisclaimerViewed} purpose="primary">
                        I understand
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}
