import React, { useState, useEffect } from 'react';

import { useLocation } from 'react-router-dom';
import pathParser from 'utils/pathParser';
import { HIERARCHY } from '@spglobal/koi-icons';
import { Capsule, FormGroup, IconButton, Select } from '@spglobal/react-components';
import { Size } from '@spglobal/koi-helpers';
import DialogboxGroupHierarchy from './DialogboxGroupHierarchy';
import { TextBodyLg } from '../../shared';
import { transformDataObjForKoiSelect } from '../../../utils/koiIntegrationUtils';
import { FORM_ACTIONS } from '../../../utils/constants';

export default function AssignGroupsDropdown({
    user,
    viewData,
    onViewsSelection,
    action,
    editUser,
}) {
    const { pathname } = useLocation();
    const { 'rbac-client-admin': urlCustomerId } = pathParser.getPathComponents(pathname);

    const [selectedViews, setSelectedViews] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [selectedViewTree, setSelectedViewTree] = React.useState({
        action: 'edit',
        customerId: urlCustomerId,
        selectedViewTree: {},
    });

    const [viewsDataOptions, setViewsDataOptions] = useState([]);

    useEffect(() => {
        const viewsOptions = viewData?.map((v) => ({
            ...transformDataObjForKoiSelect(v, {
                id: 'id',
                value: 'id',
            }),
            label: `${v.view_name} (#Folders:${v.total_folders_counts} , #Assets: ${v.asset_count})`,
            selectedLabel: v.view_name,
            renderElement: (
                <div key={v.view_name} className="spg-d-flex spg-justify-between spg-ml-xs">
                    <span>{`${v.view_name} (#Folders:${v.total_folders_counts} , #Assets: ${v.asset_count})`}</span>
                    <IconButton
                        icon={HIERARCHY}
                        size={Size.XSMALL}
                        onClick={(e) => {
                            e.stopPropagation();
                            setOpen(true);
                            setSelectedViewTree({
                                action,
                                customerId: urlCustomerId,
                                selectedViewTree: v,
                            });
                        }}
                    />
                </div>
            ),
        }));

        setViewsDataOptions(viewsOptions);

        if (action === FORM_ACTIONS.EDIT && editUser) {
            const editViews = editUser.view_names_list;

            const curViews = viewData.filter((viewItem) =>
                editViews.some((folder) => folder.view_id === viewItem.id),
            );
            setSelectedViews(
                curViews.map((v) => ({
                    ...transformDataObjForKoiSelect(v, {
                        id: 'id',
                        value: 'id',
                    }),
                    label: `${v.view_name} (#Folders:${v.total_folders_counts} , #Assets: ${v.asset_count})`,
                    selectedLabel: v.view_name,
                    renderElement: (
                        <div key={v.view_name} className="spg-d-flex spg-justify-between spg-ml-xs">
                            {`${v.view_name} (#Folders:${v.total_folders_counts} , #Assets: ${v.asset_count})`}
                            <IconButton
                                icon={HIERARCHY}
                                size={Size.XSMALL}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setOpen(true);
                                    setSelectedViewTree({
                                        action,
                                        customerId: urlCustomerId,
                                        selectedViewTree: v,
                                    });
                                }}
                            />
                        </div>
                    ),
                })),
            );
            onViewsSelection(curViews);
        }
    }, [editUser, viewData]);

    const handleChange = (selectedValues /* IOption[] */) => {
        const selectedIds = selectedValues.map((v) => v.id);
        const viewsSelection = viewData.filter((f) => selectedIds.includes(f.id));

        setSelectedViews(selectedValues);
        onViewsSelection(viewsSelection);
    };

    const handleDelete = (view) => () => {
        const newSelectedView = selectedViews.filter((item) => item.id !== view.id);
        setSelectedViews(newSelectedView);
        onViewsSelection(newSelectedView);
    };

    const isViewDisabled = () => !!(user && user.role && user.role === 'developer');

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const propsDialog = {
        handleClickOpen,
        handleClose,
        open,
        selectedViewTree,
        editUser,
        action,
    };

    return (
        <>
            <div>
                <DialogboxGroupHierarchy {...propsDialog} />
                <FormGroup label="Group Name" labelFor="view-checkbox">
                    <Select
                        isHighlightSearchMatches={false}
                        id="view-checkbox"
                        isMulti
                        isSearchable
                        options={viewsDataOptions}
                        defaultValue={selectedViews}
                        onChange={handleChange}
                        disabled={isViewDisabled(user)}
                        formatSelectedValues={(selectedValue) => selectedValue.selectedLabel}
                    />
                </FormGroup>
            </div>
            <div className="spg-row spg-p-md">
                {!isViewDisabled() && (
                    <div className="">
                        {selectedViews.map((view, i) => (
                            <Capsule onClose={handleDelete(view)} key={i} className="spg-m-2xs">
                                {view.selectedLabel}
                            </Capsule>
                        ))}
                        <TextBodyLg className="spg-mt-md">
                            Total Folders : {selectedViews.length}
                        </TextBodyLg>
                    </div>
                )}
                {isViewDisabled() && (
                    <div className="spg-p-md">
                        <Capsule>All Views</Capsule>
                    </div>
                )}
            </div>
        </>
    );
}
