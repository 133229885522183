import * as React from 'react';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { IconButton } from '@spglobal/react-components';
import { Size } from '@spglobal/koi-helpers';
import { EDIT as EditIcon, TRASH } from '@spglobal/koi-icons';
import { VIEW, EDIT, RENAME, DELETE } from '../../utils/constants';
import DataTableGrid from '../DataTable/DataTableGrid/DataTableGrid';

const getViewsTableColumns = (hideActions = false) => {
    // const folderTooltipCellRenderer = (rowDetails) => {
    //     const { folders, views } = rowDetails || {};
    //     return (
    //         <TextBodyMd>
    //             {!!folders?.length && (
    //                 <div className="spg-d-flex spg-flex-column">
    //                     <div>{`Folders (${folders?.length})`}</div>
    //
    //                     {folders?.map((name, index) => (
    //                         <div key={`folder-${index}`}>{name?.asset_folder_name}</div>
    //                     ))}
    //                 </div>
    //             )}
    //             {!!views?.length && (
    //                 <div className="spg-d-flex spg-flex-column">
    //                     <div>{`Groups (${views?.length})`}</div>
    //
    //                     {views?.map((view, index) => {
    //                         const viewName = Object.keys(view)?.[0];
    //                         const foldersArr = view[viewName];
    //                         return (
    //                             <div
    //                                 className="spg-d-flex spg-flex-column"
    //                                 key={`view-${index}`}
    //                             >
    //                                 <div>{viewName}</div>
    //                                 {foldersArr?.map((name, i) => (
    //                                     <div key={`view-folder-${i}`}>{name}</div>
    //                                 ))}
    //                             </div>
    //                         );
    //                     })}
    //                 </div>
    //             )}
    //         </TextBodyMd>
    //     );
    // };

    const columns = [
        {
            headerName: 'Folder / Group Names',
            field: 'name',
            sortable: false,
            flex: 1,
            cellRenderer: 'namesRenderer',
        },
        {
            headerName: '#Assets',
            field: 'asset_count',
            sortable: false,
            flex: 1,
            cellRenderer: 'assetsRenderer',
        },
        {
            headerName: 'Folder ID',
            field: 'folder_id',
            sortable: false,
            flex: 1,
            cellRenderer: 'folderIdRenderer',
        },
        {
            headerName: '#Folders',
            field: 'folders_count',
            sortable: false,
            flex: 1,
            cellRenderer: 'foldersCellRenderer',
            // tooltipValueGetter: (params) => folderTooltipCellRenderer(params?.data),
        },
    ];

    if (!hideActions) {
        columns.push({
            headerName: 'Actions',
            field: 'action',
            sortable: false,
            flex: 0.5,
            cellRenderer: 'actionsRenderer',
            width: 80,
            maxWidth: 80,
            cellStyle: { alignItems: 'baseline' },
            cellClass: 'cli-action-cell',
        });
    }
    return columns;
};

const GroupDetailsDataTable = (props) => {
    const { tableData, actionCallback, isLoading, hideActions } = props;

    const handleRowClick = (params) => {
        if (
            Object.prototype.hasOwnProperty.call(params?.data, 'is_assigned')
                ? params?.data?.is_assigned
                : true
        ) {
            actionCallback(VIEW, params?.data);
        }
    };

    const isRowDisabled = (rowData) =>
        Object.prototype.hasOwnProperty.call(rowData, 'is_assigned') && !rowData?.is_assigned;

    const namesCellRenderer = ({ data }) => {
        const rowData = data || {};

        return (
            <div
                className={
                    isRowDisabled(data) ? 'spg-text-secondary' : 'spg-align-center spg-flex-row'
                }
            >
                {rowData.name}
            </div>
        );
    };

    const assetsCellRenderer = ({ data }) => (
        <span className={isRowDisabled(data) ? 'spg-text-secondary' : ''}>{data.asset_count}</span>
    );

    const folderIdCellRenderer = ({ data }) => (
        <span className={isRowDisabled(data) ? 'spg-text-secondary' : ''}>{data.folder_id}</span>
    );

    const foldersCellRenderer = ({ data }) => {
        const disabledRow = isRowDisabled(data);

        return (
            <span
                className={disabledRow ? 'spg-text-secondary' : ''}
                style={disabledRow ? { pointerEvents: 'none' } : {}}
            >
                {data.folders_count}
            </span>
        );
    };

    const actionsCellRenderer = (actionsCellProps) => {
        const rowData = actionsCellProps?.data || {};
        const hideIcon = rowData?.isFolder;
        const handleActionsClick = (event, actionType, params) => {
            event.stopPropagation();
            actionCallback(actionType, params?.data);
        };

        return (
            <>
                {!hideIcon && (
                    <IconButton
                        style={hideIcon}
                        aria-label="edit-view"
                        size={Size.SMALL}
                        icon={EditIcon}
                        onClick={(e) => handleActionsClick(e, EDIT, actionsCellProps)}
                        title="Edit"
                    />
                )}

                <IconButton
                    aria-label="rename-view"
                    size={Size.SMALL}
                    icon={faEdit}
                    onClick={(e) => handleActionsClick(e, RENAME, actionsCellProps)}
                    title="Rename"
                />

                <IconButton
                    aria-label="delete-view"
                    size={Size.SMALL}
                    icon={TRASH}
                    onClick={(e) => handleActionsClick(e, DELETE, actionsCellProps)}
                    title="Delete"
                />
            </>
        );
    };

    const frameworkComponents = {
        namesRenderer: namesCellRenderer,
        assetsRenderer: assetsCellRenderer,
        folderIdRenderer: folderIdCellRenderer,
        foldersRenderer: foldersCellRenderer,
        // folderTooltipRenderer: folderTooltipCellRenderer,
        actionsRenderer: actionsCellRenderer,
    };

    const columnDefs = getViewsTableColumns(hideActions);

    return (
        <DataTableGrid
            frameworkComponents={frameworkComponents}
            rowData={tableData}
            columnDefs={columnDefs}
            onRowClicked={handleRowClick}
            loading={isLoading}
        />
    );
};

export default GroupDetailsDataTable;
