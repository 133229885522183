import React from 'react';
import { H3, Button, Link } from '@spglobal/react-components';
import { Purpose } from '@spglobal/koi-helpers';

export default function PageNotFound() {
    return (
        <div className="spg-w-75 spg-ml-auto spg-mr-auto">
            <H3>Oh no! We couldn&apos;t find that page for you...</H3>
            <Button purpose={Purpose.PRIMARY}>
                <Link href="/">Let&apos;s get home</Link>
            </Button>
        </div>
    );
}
