import React from 'react';
import { Badge, Icon } from '@spglobal/react-components';
import { useHistory } from 'react-router-dom';
import { UPLOAD_STATUS_BADGE_CONFIG_MAP } from '../constants/UploadStatusBadge.constants';
import { FILE_PROCESSING_STATUS } from '../constants/import.constants';

const BADGE_TEXT_SELECTOR = 'cli-badge-text';

/**
 * Visual component that represent File Upload status in a grid, using colored <Badge/> and <Icon/>. Acts as AgGrid cell renderer
 * @param params - CellRendererParams (read ag-grid docs)
 * @returns {JSX.Element}
 * @constructor
 */
export default function UploadStatusBadgeCellRenderer({ rowData }) {
    const history = useHistory();

    const redirectToUploadPage = (data) => {
        history.push({
            pathname: `/bulk-import-file/${data.customer_id}`,
            search: `?${new URLSearchParams({
                step:
                    data.processing_status === FILE_PROCESSING_STATUS.FILE_UPLOAD_FAILED
                        ? '1'
                        : '2',
                file_id: data.file_id,
                import_id: data.id,
                customerName: data.customer_name,
            })}`,
        });
    };

    const handleHover = (event) => {
        const badge = event.target;
        const badgeText = badge.querySelector(`.${BADGE_TEXT_SELECTOR}`);
        if (badgeText) {
            badgeText.innerText = 'View Error';
            badgeText.style['text-decoration'] = 'underline';
            badgeText.style.cursor = 'pointer';
        }
        badge.addEventListener('click', () => redirectToUploadPage(rowData));
    };

    const handleLeave = (event) => {
        const badge = event.target;
        const badgeText = badge.querySelector(`.${BADGE_TEXT_SELECTOR}`);
        if (badgeText) {
            badgeText.style['text-decoration'] = 'none';
            badgeText.style.cursor = 'none';
            badgeText.innerText = rowData.processing_status_text;
        }
    };

    const isError =
        rowData.processing_status_text.toLowerCase().includes('uploading failed') ||
        rowData.processing_status_text.toLowerCase().includes('validation error');

    return (
        <Badge
            purpose={UPLOAD_STATUS_BADGE_CONFIG_MAP[rowData.processing_status].badgeType}
            className="spg-p-md spg-d-flex spg-align-center spg-justify-around"
            width={150}
            onMouseEnter={(isError && handleHover) || undefined}
            onMouseLeave={(isError && handleLeave) || undefined}
        >
            {
                <Icon
                    icon={UPLOAD_STATUS_BADGE_CONFIG_MAP[rowData.processing_status].icon}
                    color={UPLOAD_STATUS_BADGE_CONFIG_MAP[rowData.processing_status].iconColor}
                />
            }
            <span className={BADGE_TEXT_SELECTOR}>{rowData.processing_status_text}</span>
        </Badge>
    );
}
