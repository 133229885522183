import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Tabs, Tab } from '@spglobal/react-components';
import pathParser from 'utils/pathParser';
import customerService from 'services/customerService';
import { getPortfolioResourceKey } from 'utils/rbacUtils';
import BreadcrumbTrail from 'components/BreadcrumbTrail';
import AllAssets from 'components/AllAssets';
import Folders from 'components/Folders';
import Groups from 'components/Groups/Groups';
import { DEFAULT_PAGE_DATA } from '../components.constants';

const initialPageData = {
    ...DEFAULT_PAGE_DATA,
    searchText: '',
};

const TABS_CONFIG = [
    { label: 'All Assets', id: 'allAssets' },
    { label: 'Folders', id: 'folders' },
    { label: 'Groups', id: 'views' },
];

export default function CustomerDetails() {
    const history = useHistory();
    const params = new URLSearchParams(window.location.search);
    const defaultActiveTab = params.get('activeTab');
    const [allAssetsPageData, setAllAssetsPageData] = React.useState(initialPageData);
    const [foldersPageData, setFoldersPageData] = React.useState(initialPageData);
    const [groupsPageData, setGroupsPageData] = React.useState(initialPageData);
    const [customerDetails, setCustomerDetails] = React.useState({});
    const [selectedTabId, setSelectedTabId] = React.useState(defaultActiveTab);
    const { pathname } = useLocation();
    const { customers: customerId } = pathParser.getPathComponents(pathname);
    const resourceType = getPortfolioResourceKey(customerDetails?.customer_type);

    history.listen(() => {
        const prms = new URLSearchParams(window.location.search);
        const activeTab = prms.get('activeTab');
        setSelectedTabId(activeTab);
    });

    useEffect(() => {
        const fetchData = async () => {
            if (customerId) {
                const response = await customerService.getCustomerById(customerId);
                setCustomerDetails(response?.results);
            }
        };
        fetchData();
    }, [customerId]);

    const onTabChange = (newTabId) => {
        const activeTab = newTabId !== undefined ? newTabId : TABS_CONFIG[0].id;
        history.push({ search: `?activeTab=${activeTab}` });
        setSelectedTabId(newTabId);
        setFoldersPageData({ ...foldersPageData, clicked: false });
        setGroupsPageData({ ...groupsPageData, clicked: false });
    };

    return (
        <div className="spg-h-100">
            <BreadcrumbTrail
                className="common-breadcrumbs"
                rootTitle="Customers"
                history={history}
            />
            <div className="spg-w-100 spg-p-md spg-h-100">
                <Tabs selectedTabId={selectedTabId} onChange={onTabChange} className="spg-h-100">
                    <Tab id={TABS_CONFIG[0].id} title={TABS_CONFIG[0].label}>
                        <AllAssets
                            resourceType={resourceType}
                            pageData={allAssetsPageData}
                            onPageDataChange={setAllAssetsPageData}
                        />
                    </Tab>
                    <Tab id={TABS_CONFIG[1].id} title={TABS_CONFIG[1].label}>
                        <Folders
                            resourceType={resourceType}
                            pageData={foldersPageData}
                            onPageDataChange={setFoldersPageData}
                        />
                    </Tab>
                    <Tab id={TABS_CONFIG[2].id} title={TABS_CONFIG[2].label}>
                        <Groups
                            resourceType={resourceType}
                            pageData={groupsPageData}
                            onPageDataChange={setGroupsPageData}
                        />
                    </Tab>
                </Tabs>
            </div>
        </div>
    );
}
