import React from 'react';
import PropTypes from 'prop-types';
import formatCurrency from 'utils/formatCurrency';
import convertToUSDMillion from 'utils/convertToUSDMillion';
import { H6, IconButton } from '@spglobal/react-components';
import { ALL_RISK_FACTOR_IDS } from 'utils/constants';
import { Size } from '@spglobal/koi-helpers';
import { LINE_CHART } from '@spglobal/koi-icons';
import { TextBodyLg, TextBodyMd, InfoTooltip, TextBodySm } from '../shared';

export default function Hazard({
    riskFactorId,
    riskFactorName,
    hazardMetricId,
    handleDetailButtonClicked,
    impactFunctionId,
    impactFunctionName,
    absoluteValue,
    relativeValue,
    dataAvailableAllLocations,
    dataAvailableSomeLocations,
    dataAvailabilityMessage,
    hasEmissionData,
    riskDataFilters,
    viewType,
    isDisabledRiskFactor,
    hazard,
    hazardOnly,
}) {
    const displayedDataAvailabilityMessage = dataAvailabilityMessage;
    let displayedAbsoluteValue = absoluteValue;
    let displayedRelativeValue = relativeValue;

    if (absoluteValue === 'null' || absoluteValue === null) {
        displayedAbsoluteValue = '';
    } else if (
        (dataAvailableSomeLocations && riskFactorId !== 1) ||
        (hasEmissionData && dataAvailableSomeLocations && riskFactorId === 1) ||
        (viewType === 'folder' && riskFactorId === 1)
    ) {
        displayedAbsoluteValue = formatCurrency(
            convertToUSDMillion(absoluteValue),
            riskDataFilters.riskValuePrecision,
        );
    }

    const dataUnavailableMessage =
        ALL_RISK_FACTOR_IDS.find((factor) => factor.riskFactorId === riskFactorId)
            ?.dataUnavailableMessage || 'Data unavailable';

    const formatRelativeValue = (value) => {
        if (typeof value === 'number') {
            return `${value.toFixed(riskDataFilters.riskValuePrecision)} %`;
        }
        return 'N/A';
    };

    if (
        Number.isNaN(relativeValue) ||
        (!hasEmissionData && riskFactorId === 1 && viewType === 'asset')
    ) {
        displayedRelativeValue = 'N/A';
        displayedAbsoluteValue = '';
    } else if (
        !dataAvailableAllLocations &&
        !dataAvailableSomeLocations &&
        !hasEmissionData &&
        riskFactorId === 1 &&
        viewType === 'asset'
    ) {
        displayedRelativeValue = 'N/A*';
        displayedAbsoluteValue = '';
    } else if (dataAvailableSomeLocations && !dataAvailableAllLocations) {
        displayedRelativeValue = `${formatRelativeValue(relativeValue)} %*`;
    } else {
        displayedRelativeValue = `${formatRelativeValue(relativeValue)}`;
    }

    if (hazardOnly) {
        return (
            <li
                key={`list-item-hazard-${hazardMetricId}`}
                data-testid={`list-item-hazard-${hazardMetricId}`}
            >
                <div
                    className={`spg-row spg-align-center ${isDisabledRiskFactor ? 'cli-disabled' : ''}`}
                >
                    <div className="spg-col-7">
                        <TextBodyLg key={`list-item-riskFactor-text-${hazardMetricId}`}>
                            {hazard.hazardName}{' '}
                            <InfoTooltip iconClass="spg-ml-xs">
                                <TextBodySm className="spg-p-md">
                                    The relative and absolute MAAL values for this hazard are not
                                    yet available as impact functions are still in the process of
                                    being developed. This hazard is not yet included in the
                                    aggregated MAAL values represented by the line graph above. MAAL
                                    values will become available once impact functions are
                                    implemented. Click on the chart icon to view the projected
                                    hazard values relative to the baseline for 8 decades for this
                                    asset.
                                </TextBodySm>
                            </InfoTooltip>
                            <TextBodyMd
                                className="spg-pr-sm spg-pl-sm"
                                style={{ paddingTop: '1px' }}
                            >
                                [BETA]
                            </TextBodyMd>
                        </TextBodyLg>
                    </div>
                    <div className="spg-col-1"></div>
                    <div className="spg-col-4 spg-d-flex">
                        <div className="spg-row spg-w-100 spg-d-flex spg-justify-between spg-align-center">
                            <div className="spg-col-9">
                                <H6>N/A</H6>
                            </div>
                            <div className="spg-col-3">
                                {viewType === 'asset' && (
                                    <IconButton
                                        aria-label="detail-button"
                                        data-testid={`hazard-detail-button-${hazardMetricId}`}
                                        size={Size.MEDIUM}
                                        icon={LINE_CHART}
                                        onClick={(e) => {
                                            if (handleDetailButtonClicked) {
                                                handleDetailButtonClicked(
                                                    e,
                                                    hazardMetricId,
                                                    null,
                                                    null,
                                                );
                                            }
                                        }}
                                        title="Click to view the Hazard and Vulnerability graphs"
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </li>
        );
    }

    return (
        <li
            key={`list-item-riskFactor-${riskFactorId}`}
            data-testid={`list-item-riskFactor-${riskFactorId}`}
        >
            <div className={`spg-row ${isDisabledRiskFactor ? 'cli-disabled' : ''}`}>
                <div className="spg-col-7">
                    <TextBodyLg key={`list-item-riskFactor-text-${riskFactorId}`}>
                        {riskFactorName}
                    </TextBodyLg>
                    {viewType === 'asset' && (
                        <TextBodyMd>
                            {isDisabledRiskFactor ? dataUnavailableMessage : impactFunctionName}
                        </TextBodyMd>
                    )}
                </div>
                <div className="spg-col-1"></div>

                <div className="spg-col-4 spg-d-flex">
                    <div className="spg-row spg-w-100 spg-d-flex spg-justify-between">
                        <div className="spg-col-9">
                            <H6 className={`${isDisabledRiskFactor ? 'cli-disabled' : ''}`}>
                                {displayedRelativeValue}
                            </H6>
                            <TextBodyMd>{displayedAbsoluteValue}</TextBodyMd>
                        </div>
                        <div className="spg-col-3">
                            {viewType === 'asset' && (
                                <IconButton
                                    data-testid={`hazard-detail-button-${riskFactorId}`}
                                    aria-label="detail-button"
                                    size={Size.MEDIUM}
                                    icon={LINE_CHART}
                                    onClick={(e) => {
                                        if (handleDetailButtonClicked) {
                                            handleDetailButtonClicked(
                                                e,
                                                hazardMetricId,
                                                impactFunctionId,
                                                riskFactorName,
                                            );
                                        }
                                    }}
                                    title="Click to View Hazard Data"
                                    disabled={isDisabledRiskFactor}
                                />
                            )}
                        </div>
                    </div>
                </div>
                {viewType === 'asset' &&
                    (!dataAvailableAllLocations || (riskFactorId === 1 && !hasEmissionData)) && (
                        <div className="spg-row">
                            <div className="spg-col-1"></div>
                            <div className="spg-col-10">
                                <TextBodyLg key={`list-item-no-data-message-${riskFactorId}`}>
                                    {`${displayedDataAvailabilityMessage}`}
                                </TextBodyLg>
                            </div>
                        </div>
                    )}
            </div>
        </li>
    );
}

Hazard.propTypes = {
    absoluteValue: PropTypes.number,
    dataAvailabilityMessage: PropTypes.string,
    dataAvailableAllLocations: PropTypes.bool,
    dataAvailableSomeLocations: PropTypes.bool,
    handleDetailButtonClicked: PropTypes.func,
    hasEmissionData: PropTypes.bool,
    hazardMetricId: PropTypes.number || PropTypes.string,
    impactFunctionId: PropTypes.number || PropTypes.string,
    impactFunctionName: PropTypes.string,
    relativeValue: PropTypes.number,
    riskDataFilters: PropTypes.object,
    riskFactorId: PropTypes.number || PropTypes.string,
    riskFactorName: PropTypes.string,
    viewType: PropTypes.string,
};
