import React from 'react';
import {
    Card,
    CardBody,
    Button,
    Accordion,
    AccordionItem,
    accordionAppearance,
    CardHeader,
    CardFooter,
    Link,
} from '@spglobal/react-components';
import styled from '@emotion/styled';
import { Size } from '@spglobal/koi-helpers';
import { TextBodyLg, TextBodyMd } from '../shared';
import IconBand from '../IconBand/IconBand';

const ProductItem = styled.div`
    display: flex;
    border: 1px solid var(--color-base-gray-11);
    border-radius: var(--size-space-sm);
    overflow: hidden;
`;

const ProductItemCard = styled(Card)`
    overflow: hidden;
    width: 100%;
`;

const NoSubscriptionText = styled(TextBodyMd)`
    font-style: italic;
`;

export default function ProductCard({ product }) {
    if (!product) {
        return <div data-testid="no-product"></div>;
    }
    const { productName, productDescription, path, isSubscribed, details } = product;

    return (
        <ProductItem>
            <IconBand iconType={productName} />
            <ProductItemCard hasRoundedCorner>
                <CardHeader data-testid="product-title" title={productName} />
                <CardBody>
                    <TextBodyLg data-testid="product-description">{productDescription}</TextBodyLg>
                    {/* TODO: Update text content in details OR refactor to come from product object. */}
                    {details && (
                        <Accordion
                            className="spg-mt-md"
                            appearance={accordionAppearance.light}
                            accordionSize={Size.MEDIUM}
                        >
                            <AccordionItem header="New Release &ndash; 9/15/2021">
                                <TextBodyMd>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                    Phasellus elit nisi, tempor at rhoncus id, porta tincidunt
                                    metus. Nulla dictum faucibus justo. Quisque non urna nec tortor
                                    cursus lobortis. Proin quis nunc nibh. Curabitur consequat
                                    gravida augue, vitae vestibulum sem eleifend ac. Maecenas
                                    facilisis molestie vehicula. Fusce pulvinar nisi a orci iaculis
                                    bibendum.
                                </TextBodyMd>
                            </AccordionItem>
                        </Accordion>
                    )}
                </CardBody>
                {/* TODO: Remove this isSubscribed conditional! It's handled within button itself once CS link is ready. */}
                {isSubscribed ? (
                    <CardFooter className="spg-text-right">
                        <Link href={path} data-testid="product-button-link">
                            <Button purpose="primary" className="btn-min-width">
                                {isSubscribed ? 'Launch' : 'Request Trial'}
                                <span className="sr-only">{productName}</span>
                            </Button>
                        </Link>
                    </CardFooter>
                ) : (
                    <CardFooter className="spg-text-right">
                        <NoSubscriptionText>
                            This product is in a limited-access beta release. Please{' '}
                            <Link href="mailto:support@theclimateservice.com">contact us</Link> for
                            trial access.
                        </NoSubscriptionText>
                    </CardFooter>
                )}
            </ProductItemCard>
        </ProductItem>
    );
}
