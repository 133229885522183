import * as React from 'react';
import { EDIT as EditIcon, TRASH } from '@spglobal/koi-icons';
import { faArrowCircleRight } from '@fortawesome/free-solid-svg-icons';
import { Size } from '@spglobal/koi-helpers';
import { IconButton } from '@spglobal/react-components';
import { MOVE, DELETE, EDIT, VIEW } from '../../utils/constants';
import DataTableGrid from '../DataTable/DataTableGrid/DataTableGrid';

const getAllAssetsTableColumns = (hideActions) => {
    const columns = [
        {
            headerName: 'Asset Name',
            checkboxSelection: !hideActions,
            headerCheckboxSelection: !hideActions,
            field: 'name',
            sortable: false,
            flex: 1,
            tooltipField: 'name',
            cellStyle: { display: 'block' },
        },
        {
            headerName: 'Asset Value (In USD millions)',
            field: 'value',
            sortable: false,
            flex: 1.5,
            cellRenderer: 'assetValueRenderer',
            cellStyle: { display: 'block' },
        },
        {
            headerName: 'Asset Type',
            field: 'asset_type_name',
            sortable: false,
            flex: 1,
            tooltipField: 'asset_type_name',
            cellStyle: { display: 'block' },
        },
        {
            headerName: 'Tags',
            field: 'tags',
            sortable: false,
            flex: 1,
            tooltipField: 'tags',
            cellStyle: { display: 'block' },
        },
        {
            headerName: 'Folder Name',
            field: 'folder_name',
            sortable: false,
            flex: 1,
            cellStyle: { display: 'block' },
        },
    ];

    if (!hideActions) {
        columns.push({
            headerName: 'Actions',
            field: ' ',
            sortable: false,
            flex: 0.5,
            cellRenderer: 'actionsRenderer',
            width: 80,
            maxWidth: 80,
            cellStyle: { justifyContent: 'center', alignItems: 'baseline' },
            cellClass: 'cli-action-cell',
        });
    }

    return columns;
};

const AllAssetsDataTable = (props) => {
    const {
        tableData,
        actionCallback,
        isLoading,
        totalCount,
        pageData,
        onPageDataChange,
        hideActions,
        handleRowsSelection,
    } = props;
    const handleRowClick = (params) => {
        actionCallback(VIEW, params.data);
    };

    const handlePageSizeChange = (newPageSize) => {
        const pageInfo = {
            ...pageData,
            page: 0,
            clicked: true,
            rowsPerPage: newPageSize,
        };
        onPageDataChange(pageInfo);
    };

    const handlePageChange = (newPageIndex) => {
        const pageInfo = {
            ...pageData,
            page: newPageIndex,
            clicked: true,
            rowsPerPage: pageData?.rowsPerPage,
        };
        onPageDataChange(pageInfo);
    };

    const onRowSelected = (item, isSelected, allSelectedItems) => {
        const formattedArray = allSelectedItems.map((objData) => objData.id);
        handleRowsSelection(formattedArray);
    };

    const assetValueCellRenderer = ({ data }) => {
        let transformedValue;

        if (data.value) {
            transformedValue = `${new Intl.NumberFormat(
                'en-US',
                { style: 'currency', currency: 'USD' },
                { maximumSignificantDigits: 2 },
            ).format(data.value / 1000000)}m`;
        }

        return transformedValue;
    };

    const actionsCellRenderer = (actionsCellProps) => {
        const handleActionsClick = (event, actionType, params) => {
            event.stopPropagation();
            actionCallback(actionType, params?.data);
        };

        return (
            <>
                <IconButton
                    aria-label="edit"
                    size={Size.SMALL}
                    icon={EditIcon}
                    onClick={(e) => handleActionsClick(e, EDIT, actionsCellProps)}
                    title="Edit"
                />

                <IconButton
                    aria-label="move"
                    size={Size.SMALL}
                    icon={faArrowCircleRight}
                    onClick={(e) => handleActionsClick(e, MOVE, actionsCellProps)}
                    title="Move"
                />

                <IconButton
                    aria-label="delete"
                    size={Size.SMALL}
                    icon={TRASH}
                    onClick={(e) => handleActionsClick(e, DELETE, actionsCellProps)}
                    title="Delete"
                />
            </>
        );
    };

    const frameworkComponents = {
        assetValueRenderer: assetValueCellRenderer,
        actionsRenderer: actionsCellRenderer,
    };

    const columnDefs = getAllAssetsTableColumns(hideActions);

    return (
        <DataTableGrid
            frameworkComponents={frameworkComponents}
            rowData={tableData}
            columnDefs={columnDefs}
            onRowClicked={handleRowClick}
            pagination
            totalItemsCount={totalCount}
            onPageChange={(pageIndex) => handlePageChange(pageIndex)}
            onPageSizeChange={(newPageSize) => handlePageSizeChange(newPageSize)}
            onRowCheckboxSelected={onRowSelected}
            loading={isLoading}
        />
    );
};

export default AllAssetsDataTable;
