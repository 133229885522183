import { httpClient } from '../utils/httpClient';
import { API_URL } from '../utils/constants';

const ENDPOINT = 'api/customer';

export const getReports = async (params) => {
    try {
        const result = await httpClient.post(
            `${API_URL}/${ENDPOINT}/${params.customerId}/reports`,
            params
        );
        return result.data;
    } catch (e) {
        console.log('Error in getting export data :', e);
    }
};

export const getSingleReport = async (params) => {
    try {
        const result = await httpClient.get(
            `${API_URL}/${ENDPOINT}/${params.customer_id}/reports/file/${params.file_id}`
        );
        return result.data;
    } catch (e) {
        console.log('Error in getting single export report:', e);
    }
};

export const downloadReport = async (file_id, params) => {
    try {
        const result = await httpClient.get(
            `${API_URL}/${ENDPOINT}/${params.customer_id}/reports/file/${file_id}/download`,
            { params }
        );
        return result.data;
    } catch (e) {
        console.log('Error in downloading report :', e);
    }
};

export const getDownloadHistory = async (params) => {
    try {
        const result = await httpClient.get(
            `${API_URL}/${ENDPOINT}/${params.customer_id}/reports/file/${params.file_id}/download`,
            { params }
        );
        return result.data;
    } catch (e) {
        console.log('Error in getting download history :', e);
    }
};

export const generateReport = async (customerID, params = {}) => {
    const result = await httpClient.post(`${API_URL}/${ENDPOINT}/${customerID}/reports`, params);
    return result.data;
};

export const getFoldersByCustomerID = async (customerID, params = {}) => {
    const result = await httpClient.get(`${API_URL}/${ENDPOINT}/${customerID}/folders`, params);
    return result.data;
};

export const getAssetTagsByCustomerID = async (customerID, params = {}) => {
    const result = await httpClient.get(`${API_URL}/${ENDPOINT}/${customerID}/assetTags`, params);
    return result.data;
};
