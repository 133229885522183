import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import MessageBox from '../MessageBox';
import BreadcrumbTrail from '../BreadcrumbTrail';
import AssetTypeForm from '../AssetTypeForm';
import assetTypeService from '../../services/assetTypeService';
import googleAnalyticsEvent from '../../utils/googleAnalyticsEvent';

const SUCCESS_MSG = 'Successfully created asset type';
const ERROR_CREATING_MSG = 'Error creating asset type:';

const defaultFormValues = {
    assetTypeName: '',
    assetTypeDescription: '',
    assetTypeActive: true,
    impactFunctions: [],
};

export default function CreateAssetType() {
    const history = useHistory();
    const displayHeader = 'Create New Asset Type';
    const [shouldDisplayMessageBox, setShouldDisplayMessageBox] = useState(false);
    const [messageBoxText, setMessageBoxText] = useState('');

    const handleSetMessageBoxText = (msg) => {
        setMessageBoxText(msg);
    };

    const toggleShouldDisplayMessageBox = () => {
        setShouldDisplayMessageBox(!shouldDisplayMessageBox);
    };

    const onSubmit = async (formData, allAssetTypeMetaData, e) => {
        Object.keys(formData?.metadata).forEach((currentkey) => {
            formData.metadata[currentkey].categoryId = allAssetTypeMetaData[currentkey].categoryId;
            if (!formData.metadata[currentkey].metadataId) delete formData.metadata[currentkey];
        });
        try {
            await assetTypeService.createAssetType(formData);
            googleAnalyticsEvent('Create Entity', 'Asset Type', 'success');
            setMessageBoxText(SUCCESS_MSG);
        } catch (err) {
            googleAnalyticsEvent('Create Entity', 'Asset Type', 'failed');
            setMessageBoxText(`${ERROR_CREATING_MSG} ${err.response.data?.message}`);
        }
        setShouldDisplayMessageBox(true);
    };

    const cancelFunction = () => {
        history.push('/asset-types');
    };

    const messageBoxDismissed = async () => {
        toggleShouldDisplayMessageBox();
        if (messageBoxText === SUCCESS_MSG) {
            history.goBack();
        }
    };

    return (
        <div>
            <MessageBox
                messageBoxIsVisible={shouldDisplayMessageBox}
                messageBoxTitle="Create Asset Type"
                messageBoxText={messageBoxText}
                messageBoxCallback={messageBoxDismissed}
                cancelText=""
                okText="Ok"
            />
            <BreadcrumbTrail
                rootTitle='Asset Types'
                manageParent='/asset-types'
                manageChild='Create Asset Type'
            />
            <div className="spg-w-100 spg-h-100 spg-p-md">
                <AssetTypeForm
                    cancelClicked={cancelFunction}
                    submitClicked={onSubmit}
                    displayHeader={displayHeader}
                    defaultFormValues={defaultFormValues}
                    handleSetMessageBoxText={handleSetMessageBoxText}
                    toggleShouldDisplayMessageBox={toggleShouldDisplayMessageBox}
                />
            </div>
        </div>
    );
}
