import React, { useState, useEffect } from 'react';
import AdminListList from './AdminList';
import rbacAdminService from '../../../services/rbacAdminService';

export function AdminListTab({ customerId }) {
    const [dataLoaded, setDataLoaded] = useState(false);

    const [tableData, setTableData] = useState([]);

    const fetchUserData = async () => {
        // GET USERS
        const reqUser = {
            customerId,
            list: true,
            external: true,
        };
        let resUsers = await rbacAdminService.getAllClientUsers(reqUser);

        if (resUsers) {
            // SHOW EXTERNAL USER EXEPT CLIM ADMIN AS IT HAS DEFAULT ACCESS TO ALL FOLDER AND GROUPS
            resUsers = resUsers.filter((user) => user.role === 'CLIM_ADMIN');
            setTableData(resUsers || []);
            setDataLoaded(true);
        }
    };

    useEffect(() => {
        fetchUserData();
    }, []);

    return <AdminListList tableData={tableData} dataLoaded={dataLoaded} />;
}

export default AdminListTab;
