import * as React from 'react';

import {
    Modal,
    ModalContent,
    ModalFooter,
    ModalHeader,
    IconButton,
    Button,
} from '@spglobal/react-components';
import { CLEAR } from '@spglobal/koi-icons';
import { Purpose, Size } from '@spglobal/koi-helpers';
import allAssetsAPI from '../../api/all-assets';
import config from '../../config';
import DataTableGrid from '../../components/DataTable/DataTableGrid/DataTableGrid';
import { TextBodyLg } from '../../components/shared';
import UploadStatusBadgeCellRenderer from './UploadStatusBadge';

// eslint-disable-next-line no-process-env
const demoURL = config.signedURI;

export default function ScoringDetailsDialog(props) {
    const [disableButton, setDisableButton] = React.useState(false);
    const downloadHandler = (data) => {
        fetch(demoURL)
            .then((response) => {
                response.blob().then((blob) => {
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = 'assets.csv';
                    a.click();
                    props.onClose();
                });
            })
            .catch(() => {
                console.log('Error occurred downloading file');
            });
    };
    const cancelHandler = async (data) => {
        setDisableButton(true);
        let isFileCancelled = false;
        let deleteResponse = {};
        try {
            props.cancelRequest();
            deleteResponse = await allAssetsAPI.deleteFileStatus(data);
            isFileCancelled = !!deleteResponse?.message.includes('Success');
            setDisableButton(false);
        } catch (e) {
            console.log('error occurred:==>', e);
            setDisableButton(false);
        }
        props.getUploadProcessingStatus(0, 10).then((result) => {
            props.updateTableState(result);
        });
        const toastMsg = !isFileCancelled
            ? deleteResponse?.message
            : `${deleteResponse?.message} File Name: ${data?.original_filename}`;
        props.toastHandler(true, toastMsg, isFileCancelled ? 'success' : 'error');
        props.onClose();
    };

    const columnDefs = [
        {
            headerName: 'Upload Date',
            field: 'upload_date_display',
            tooltipField: 'upload_time',
            maxWidth: 100,
        },
        {
            headerName: 'Customer Name',
            field: 'cust_name',
            tooltipField: 'cust_name',
        },
        {
            headerName: 'File Name',
            field: 'original_filename',
            tooltipField: 'original_filename',
        },
        {
            headerName: '#Assets',
            field: 'number_of_asset',
            tooltipField: 'number_of_asset',
            maxWidth: 100,
        },
        {
            headerName: 'Username',
            field: 'username',
            tooltipField: 'username',
        },
        {
            headerName: 'Processing Status',
            cellRenderer: 'processingStatus',
            cellRendererParams: (params) => {
                return {
                    rowData: params.data,
                };
            },
        },
        {
            field: 'processing_status',
            hide: true,
        },
    ];
    const processingDetailsColumnDefs = [
        {
            headerName: 'Total Assets Received',
            field: 'asset_count',
        },
        {
            headerName: 'Total Assets Processed',
            field: 'total_processed',
        },
        {
            headerName: 'Errors',
            field: 'total_errored',
        },
    ];
    const tableData = [
        {
            upload_time: props.selectedColumnData.upload_time,
            upload_date_display: props.selectedColumnData.upload_date_display,
            cust_name: props.selectedColumnData.cust_name,
            original_filename: props.selectedColumnData.original_filename,
            number_of_asset: props.selectedColumnData.number_of_asset,
            username: props.selectedColumnData.username,
            processing_status_text: props.selectedColumnData.processing_status_text,
            processing_status: props.selectedColumnData.processing_status,
            asset_count: props.selectedColumnData.asset_count,
            total_processed: props.selectedColumnData.total_processed,
            total_errored: props.selectedColumnData.total_errored,
        },
    ];
    const frameworkComponents = {
        processingStatus: UploadStatusBadgeCellRenderer,
    };
    return (
        <div>
            <Modal
                size={Size.LARGE}
                onClose={props.onClose}
                aria-labelledby="customized-dialog-title"
                isOpen={props.open}
                canOutsideClickClose
            >
                <ModalHeader title="" id="customized-dialog-title">
                    <IconButton aria-label="close" icon={CLEAR} onClick={props.onClose} />
                </ModalHeader>
                <ModalContent>
                    <DataTableGrid
                        columnDefs={columnDefs}
                        rowData={tableData}
                        pagination={false}
                        frameworkComponents={frameworkComponents}
                    />
                    {!props.selectedColumnData.is_cancel ? (
                        <>
                            <TextBodyLg>Processing Details</TextBodyLg>
                            <DataTableGrid
                                columnDefs={processingDetailsColumnDefs}
                                rowData={tableData}
                                pagination={false}
                            />
                        </>
                    ) : null}
                    {props.selectedColumnData.is_download
                        ? // <FormGroup>
                          //   <FormControlLabel
                          //     control={<Checkbox defaultChecked />}
                          //     label="Download Error Asset List"
                          //   />
                          //   <FormControlLabel
                          //     control={<Checkbox defaultChecked />}
                          //     label="Download Successfully Scored Assets"
                          //   />
                          // </FormGroup>
                          null
                        : null}
                </ModalContent>
                <ModalFooter>
                    {props.selectedColumnData.actionSelected === 2 ? (
                        <div className="spg-d-flex">
                            <Button
                                purpose={Purpose.SECONDARY}
                                onClick={() => downloadHandler(props.selectedColumnData)}
                            >
                                Download
                            </Button>
                        </div>
                    ) : null}
                    {props.selectedColumnData.is_cancel ? (
                        <div className="spg-d-flex">
                            <Button
                                purpose={Purpose.SECONDARY}
                                onClick={() => cancelHandler(props.selectedColumnData)}
                                disabled={disableButton}
                            >
                                Confirm
                            </Button>
                        </div>
                    ) : null}
                </ModalFooter>
            </Modal>
        </div>
    );
}
