import React, { useState, useEffect, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Button, H1, Icon } from '@spglobal/react-components';
import { EDIT } from '@spglobal/koi-icons';
import { Purpose } from '@spglobal/koi-helpers';
import BreadcrumbTrail from '../BreadcrumbTrail';
import hazardService from '../../services/hazardService';
import pathParser from '../../utils/pathParser';
import UserContext from '../../context/UserContext';
import useGetPermission from '../../hooks/useGetPermission';
import { ACTION } from '../../utils/constants';
import { InlineHeadingSm, ParagraphLg } from '../shared';

export default function HazardDetails() {
    const history = useHistory();
    const { pathname } = useLocation();
    const { user, isLoggedIn } = useContext(UserContext);
    const { hazards: hazardId } = pathParser.getPathComponents(pathname);
    const [hazardData, setHazardData] = useState({});
    const [actions, setUserActions] = useState([ACTION.ACTION_READ]);

    useEffect(() => {
        let isSubscribed = true;
        async function fetchData() {
            try {
                const userAccess = useGetPermission(user, isLoggedIn, 'AssetTypesLibrary');
                const userPermissions = actions;
                if (userAccess.action.includes(ACTION.ACTION_WRITE)) {
                    userPermissions.push(ACTION.ACTION_EDIT);
                    setUserActions(userPermissions);
                }
                const result = await hazardService.getHazard(hazardId);
                const resultObj = { ...result };
                if (isSubscribed) {
                    setHazardData({
                        hazardMetricId: resultObj.id,
                        hazardName: resultObj.name,
                        hazardLabel: resultObj.hazardLabel,
                        hazardDescription: resultObj.description,
                        yAxisLabel: resultObj.yLabel,
                        yAxisUnits: resultObj.unitOfMeasurement,
                        unavailableMessage: resultObj.unavailableMessage,
                    });
                }
            } catch (exc) {}
        }
        fetchData();
        return () => (isSubscribed = false);
    }, [hazardId]);

    const editClicked = (e) => {
        e.preventDefault();
        history.push(`/hazards/${hazardId}/edit`);
    };

    return (
        <>
            <BreadcrumbTrail
                rootTitle="Hazards"
                manageParent="/hazards"
                manageChild={hazardData.hazardName}
            />
            <div className="spg-w-100 spg-p-md">
                <div className="spg-row">
                    <div className="spg-col-9">
                        <H1 data-testid="hazard-details-name">
                            {hazardData && hazardData.hazardName}
                        </H1>
                    </div>
                    <div className="spg-d-flex spg-justify-end spg-col-3">
                        {actions.includes(ACTION.ACTION_EDIT) && (
                            <Button
                                leftIcon={<Icon icon={EDIT} />}
                                onClick={editClicked}
                                purpose={Purpose.SECONDARY}
                                data-testid="edit-hazard-button"
                                className="spg-mr-md"
                            >
                                Edit
                            </Button>
                            )}
                    </div>
                </div>
                <ParagraphLg>
                    <InlineHeadingSm>Hazard Name:</InlineHeadingSm>
                    &nbsp;{hazardData && hazardData.hazardName}
                </ParagraphLg>
                <ParagraphLg>
                    <InlineHeadingSm>Description:</InlineHeadingSm>
                    &nbsp;{hazardData && hazardData.hazardDescription}
                </ParagraphLg>
                <ParagraphLg>
                    <InlineHeadingSm>Label:</InlineHeadingSm>
                    &nbsp;{hazardData && hazardData.hazardLabel}
                </ParagraphLg>
                <ParagraphLg>
                    <InlineHeadingSm>Unavailable Message:</InlineHeadingSm>
                    &nbsp;{hazardData && hazardData.unavailableMessage}
                </ParagraphLg>
                <ParagraphLg>
                    <InlineHeadingSm>X Axis Label:</InlineHeadingSm>
                    &nbsp;Decade
                </ParagraphLg>
                <ParagraphLg>
                    <InlineHeadingSm>Y Axis Label:</InlineHeadingSm>
                    &nbsp;{hazardData && hazardData.yAxisLabel}
                </ParagraphLg>
                <ParagraphLg>
                    <InlineHeadingSm>Y Axis Units:</InlineHeadingSm>
                    &nbsp;{hazardData && hazardData.yAxisUnits}
                </ParagraphLg>
            </div>
        </>
    );
}
