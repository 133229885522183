import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import UploadStatusTable from './components/UploadStatusTable';
import config from '../config';
import DataService from '../service/data.service';
import AllAssetsHeader from './components/AllAssetsHeader';
import BreadcrumbTrail from '../components/BreadcrumbTrail';
import pathParser from '../utils/pathParser';
import { FILE_PROCESSING_STATUS } from './constants/import.constants';
import { TableDataContextProvider } from '../components/shared';
import { DEFAULT_PAGE_SIZE } from '../components/components.constants';

export function AllAssets(props) {
    const [tableData, setTableData] = useState([]);
    const [searchDataState, setSearchDataState] = useState({});
    const [count, setCount] = useState(0);
    const service = new DataService();
    const { pathname } = useLocation();
    const { 'bulk-import-track-file': customerId } = pathParser.getPathComponents(pathname);
    const urlParams = new URLSearchParams(window.location.search);
    const customerName = urlParams.get('customerName');

    const getUploadProcessingStatus = async (offset, limit, refreshData, searchData) => {
        let res = [];
        const reqData = {
            offset,
            limit,
            customer_id: customerId,
        };
        if (searchData) {
            reqData[`${searchData.searchtype}`] = searchData.searchText;
        }
        if (refreshData) {
            res = refreshData;
            return addDynamicStyles(res);
        }
        res = await service.getUploadProcessingStatus(reqData);
        const totalCount = res.count;
        setCount(totalCount);
        return addDynamicStyles(res.result);
    };

    useEffect(() => {
        try {
            (async function retrieveData() {
                const data = await getUploadProcessingStatus(0, DEFAULT_PAGE_SIZE);
                setTableData(data);
            })();
        } catch (e) {
            console.log(`Error in Fetching Upload Processing Status Records ${JSON.stringify(e)}`);
        }
    }, []);

    const addDynamicStyles = (data) => {
        const result = [];
        data.forEach((element) => {
            if (!config.showMockData) {
                element.file_id = element.file_id ? element.file_id : element.file_uuid;
                element.number_of_asset = element.asset_count;
                element.processing_status = element.status;
                element.no_of_downloads = element.download_details.download_count;
                element.username = element.user_name;
                element.cust_name = element.customer_name;
                element.upload_date_display = service.getFormattedDate(element.created_at);
                element.upload_time = service.getLocalTime(element.created_at);
                const local_time = service.getLocalTime(
                    element.download_details.last_download_date,
                );
                element.download_details.last_download_date =
                    local_time === 'Invalid Date'
                        ? element.download_details.last_download_date
                        : local_time;
            }

            switch (element.processing_status) {
                case FILE_PROCESSING_STATUS.FILE_UPLOAD_INPROGRESS:
                    element.is_cancel = true;
                    element.is_download = false;
                    element.is_scoring_error = false;
                    element.processing_status_text = 'Uploading';
                    break;
                case FILE_PROCESSING_STATUS.FILE_UPLOAD_FAILED:
                    element.is_cancel = false;
                    element.is_download = false;
                    element.is_scoring_error = false;
                    element.processing_status_text = 'Uploading Failed';
                    break;
                case FILE_PROCESSING_STATUS.FILE_UPLOAD_COMPLETED:
                    element.is_cancel = false;
                    element.is_download = false;
                    element.is_scoring_error = false;
                    element.processing_status_text = 'Validating';
                    break;
                case FILE_PROCESSING_STATUS.ASSET_VALIDATION_INPROGRESS:
                    element.is_cancel = false;
                    element.is_download = false;
                    element.is_scoring_error = false;
                    element.processing_status_text = 'Validating';
                    break;
                case FILE_PROCESSING_STATUS.ASSET_VALIDATION_FAILED:
                    element.is_cancel = false;
                    element.is_download = false;
                    element.is_scoring_error = false;
                    element.processing_status_text = 'Validation Error';
                    break;
                case FILE_PROCESSING_STATUS.PARSER_EXCEPTION:
                    element.is_cancel = false;
                    element.is_download = false;
                    element.is_scoring_error = false;
                    element.processing_status_text = 'Parser Exception';
                    break;
                case FILE_PROCESSING_STATUS.ASSET_VALIDATION_COMPLETED:
                    element.is_cancel = false;
                    element.is_download = false;
                    element.is_scoring_error = true;
                    element.processing_status_text = 'Scoring';
                    break;
                case FILE_PROCESSING_STATUS.RISK_SCORING_INPROGRESS:
                    element.is_cancel = false;
                    element.is_download = false;
                    element.is_scoring_error = true;
                    element.processing_status_text = 'Scoring';
                    break;
                case FILE_PROCESSING_STATUS.AGGREGATION_INPROGRESS:
                    element.is_cancel = false;
                    element.is_download = false;
                    element.is_scoring_error = true;
                    element.processing_status_text = 'Aggregating';
                    break;
                case FILE_PROCESSING_STATUS.RISK_SCORING_PARTIALLY_SUCCEEDED:
                    element.is_cancel = false;
                    element.is_download = element.ready_for_download;
                    element.is_scoring_error = true;
                    element.processing_status_text = 'Scoring Error';
                    break;
                case FILE_PROCESSING_STATUS.RISK_SCORING_FAILED:
                    element.is_cancel = false;
                    element.is_download = element.ready_for_download;
                    element.is_scoring_error = true;
                    element.processing_status_text = 'Scoring Error';
                    break;
                case FILE_PROCESSING_STATUS.AGGREGATION_FAILED:
                    element.is_cancel = false;
                    element.is_download = element.ready_for_download;
                    element.is_scoring_error = true;
                    element.processing_status_text = 'Aggregation Error';
                    break;
                case FILE_PROCESSING_STATUS.RISK_SCORING_COMPLETED:
                    element.is_cancel = false;
                    element.is_download = element.ready_for_download;
                    element.is_scoring_error = false;
                    element.processing_status_text = element.ready_for_download
                        ? 'Complete'
                        : 'Scoring Complete';
                    break;
                case FILE_PROCESSING_STATUS.RESCORING_INPROGRESS:
                    element.is_cancel = false;
                    element.is_download = false;
                    element.is_scoring_error = true;
                    element.processing_status_text = 'Rescoring';
                    break;
                case FILE_PROCESSING_STATUS.RESCORING_PARTIALLY_SUCCEEDED:
                    element.is_cancel = false;
                    element.is_download = element.ready_for_download;
                    element.is_scoring_error = true;
                    element.processing_status_text = 'Rescoring Error';
                    break;
                case FILE_PROCESSING_STATUS.RESCORING_FAILED:
                    element.is_cancel = false;
                    element.is_download = element.ready_for_download;
                    element.is_scoring_error = true;
                    element.processing_status_text = 'Rescoring Error';
                    break;
                case FILE_PROCESSING_STATUS.RESCORING_SUCCEEDED:
                    element.is_cancel = false;
                    element.is_download = element.ready_for_download;
                    element.is_scoring_error = false;
                    element.processing_status_text = element.ready_for_download
                        ? 'Complete'
                        : 'Rescoring Complete';
                    break;
                case FILE_PROCESSING_STATUS.RESCORING_AGGREGATION_INPROGRESS:
                    element.is_cancel = false;
                    element.is_download = false;
                    element.is_scoring_error = true;
                    element.processing_status_text = 'Rescore Aggregating';
                    break;
                case FILE_PROCESSING_STATUS.RESCORING_AGGREGATION_FAILED:
                    element.is_cancel = false;
                    element.is_download = element.ready_for_download;
                    element.is_scoring_error = true;
                    element.processing_status_text = 'Rescore Aggregation Failed';
                    break;
            }
            result.push(element);
        });
        return result;
    };
    const updateTableState = (newState) => {
        setTableData(newState);
    };

    const onPageDataChange = (pageInfo) => {
        getUploadProcessingStatus(pageInfo.page * pageInfo.rowsPerPage, pageInfo.rowsPerPage).then(
            (data) => {
                setTableData(data);
            },
        );
    };
    return (
        <div>
            <BreadcrumbTrail rootTitle="Importing" />
            <div className="spg-w-100 spg-p-md">
                <AllAssetsHeader
                    getUploadProcessingStatus={getUploadProcessingStatus}
                    updateTableState={updateTableState}
                    setSearchDataState={setSearchDataState}
                    customerId={customerId}
                    customerName={customerName}
                />
                <TableDataContextProvider exportTableData={tableData}>
                    <UploadStatusTable
                        {...props}
                        tableData={tableData}
                        searchData={searchDataState}
                        getUploadProcessingStatus={getUploadProcessingStatus}
                        updateTableState={updateTableState}
                        onPageDataChange={onPageDataChange}
                        totalCount={count}
                    />
                </TableDataContextProvider>
            </div>
        </div>
    );
}

export default AllAssets;
