import React, { useState } from 'react';
import { Button, Checkbox, IconButton, Modal, ModalContent, ModalFooter, ModalHeader } from '@spglobal/react-components';
import { CLEAR } from '@spglobal/koi-icons';
import { Purpose } from '@spglobal/koi-helpers';
import { TextBodyMd } from '../shared';

export default function GroupFolderDialog({
    messageBoxIsVisible,
    messageBoxTitle,
    messageBoxText,
    messageBoxCallback,
    okText,
    cancelText,
    checkboxText = '',
}) {
    const [checkedValue, setCheckedValue] = useState(false);

    const handleCheckboxChange = () => {
        setCheckedValue(!checkedValue);
    };

    const handleClose = () => {
        messageBoxCallback(false);
    };

    const handleOk = () => {
        messageBoxCallback(true, checkedValue);
    };

    return (
        <div>
            <Modal
                isOpen={messageBoxIsVisible}
                onClose={handleClose}
                aria-labelledby="mb-dialog-title"
                aria-describedby="mb-dialog-description"
            >
                <ModalHeader title={messageBoxTitle}>
                    <IconButton aria-label="close" icon={CLEAR} onClick={handleClose} />
                </ModalHeader>

                <ModalContent>
                    {messageBoxText && messageBoxText.split('\n').length !== 0 ? (
                        messageBoxText.split('\n').map((message, index) => (
                            <TextBodyMd key={`groupFolderMessageText-${index}`}>
                                {message}
                            </TextBodyMd>
                        ))
                    ) : (
                        <TextBodyMd id="mb-dialog-description">
                            {messageBoxText}
                        </TextBodyMd>
                    )}
                    {checkboxText && (
                        <Checkbox
                            checked={checkedValue}
                            onChange={handleCheckboxChange}
                            name="checkedF"
                            label={checkboxText}
                            className="spg-mt-sm"
                        />
                    )}
                </ModalContent>
                <ModalFooter>
                    <Button id="okMessageBoxButton" onClick={handleOk} purpose={Purpose.PRIMARY}>
                        {okText}
                    </Button>
                    {cancelText && (
                        <Button onClick={handleClose} purpose={Purpose.SECONDARY} className="spg-ml-sm">
                            {cancelText}
                        </Button>
                    )}
                </ModalFooter>
            </Modal>
        </div>
    );
}
