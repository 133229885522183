import React, { useState, useEffect } from 'react';
import { IconButton, Modal, ModalContent, ModalHeader } from '@spglobal/react-components';
import { CLEAR } from '@spglobal/koi-icons';
import DataTableGrid from '../DataTable/DataTableGrid/DataTableGrid';

export default function DownloadHistory(props) {
    const [pageData, setPageData] = useState({ clicked: false, page: 0, rowsPerPage: 10 });

    const handleChangePage = (newPage) => {
        const pageInfo = {
            page: newPage,
            clicked: true,
            rowsPerPage: pageData.rowsPerPage,
        };
        setPageData(pageInfo);
    };

    const handleChangeRowsPerPage = (newPageSize) => {
        const pageInfo = {
            page: 0,
            clicked: true,
            rowsPerPage: newPageSize,
        };
        setPageData(pageInfo);
    };

    const calculateOffset = () => pageData.page * pageData.rowsPerPage;

    useEffect(() => {
        (async function retrieveData() {
            if (pageData.clicked) {
                const offset = calculateOffset();
                const result = await props.getDownloadHistoryList(offset, pageData.rowsPerPage);
                props.setTableData(result);
            }
        })();
    }, [pageData]);

    const columnDefs = [
        {
            headerName: 'Sr No.',
            valueGetter: ({ node }) => node?.rowIndex + 1,
            sortable: false,
        },
        {
            headerName: 'Username',
            field: 'user_email',
            sortable: true,
        },
        {
            headerName: 'Date & Time',
            valueGetter: ({ data }) => new Date(`${data?.created_at}`).toLocaleString(),
            sortable: false,
        },
    ];

    return (
        <div>
            <Modal
                isOpen={props.open}
                onClose={props.onClose}
                aria-labelledby="customized-dialog-title"
            >
                <ModalHeader id="customized-dialog-title" title=''>
                    <IconButton aria-label="close" icon={CLEAR} onClick={props.onClose} />
                </ModalHeader>

                <ModalContent>
                    <DataTableGrid
                        rowData={props.tableData}
                        columnDefs={columnDefs}
                        pagination
                        totalItemsCount={props.count}
                        onPageChange={(pageIndex) => handleChangePage(pageIndex)}
                        onPageSizeChange={(newPageSize) => handleChangeRowsPerPage(newPageSize)}
                    />
                </ModalContent>
            </Modal>
        </div>
    );
}
