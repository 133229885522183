import React from 'react';
import './MapLegend.scss';
import { Icon } from '@spglobal/react-components';
import { Size } from '@spglobal/koi-helpers';
import { faCircle } from '@fortawesome/free-solid-svg-icons';

export default function MapLegend() {
    return (
        <div id="legend" className="spg-ml-md spg-mb-md spg-pl-xs spg-pr-xs spg-pb-md spg-pt-md">
            <p>% of Asset Value</p>

            <div className="spg-d-flex spg-align-center">
                <Icon icon={faCircle} size={Size.SMALL} className="first" />
                <span className="spg-ml-xs">0-10%</span>
            </div>
            <div className="spg-d-flex spg-align-center spg-mt-xs">
                <Icon icon={faCircle} size={Size.SMALL} className="next" />
                <span className="spg-ml-xs">11-15%</span>
            </div>
            <div className="spg-d-flex spg-align-center spg-mt-xs">
                <Icon icon={faCircle} size={Size.SMALL} className="last" />
                <span className="spg-ml-xs">16-100%</span>
            </div>
            <p>Asset Cluster</p>
            <div className="spg-d-flex spg-align-center">
                <Icon icon={faCircle} size={Size.SMALL} className="cluster" />
                <span className="spg-ml-xs"># of asset locations</span>
            </div>
        </div>
    );
}
