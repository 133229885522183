import React, { useState } from 'react';
import {
    Button,
    Modal,
    ModalHeader,
    ModalContent,
    ModalFooter,
    ModalManager,
    IconButton
} from '@spglobal/react-components';
import { CLEAR } from '@spglobal/koi-icons';
import CreateUpdateEntityDialog from 'components/CreateUpdateEntityDialog';
import { Purpose } from '@spglobal/koi-helpers';
import CreateEditGroupTabs from './CreateEditGroupTabs';
import { TextBodyMd } from '../shared';

export default function CreateEditGroupDialog({
    data,
    dialogIsVisible,
    dialogText,
    dialogDismissed,
    onCreateViewSubmit,
    onEditViewSubmit,
    selectedRow,
}) {
    const [selectedFolderIDs, setSelectedFolderIDs] = useState([]);
    const [selectedViewIDs, setSelectedViewIDs] = useState([]);
    const [showViewNameDialog, setShowViewNameDialog] = useState(false);
    const [viewNameText, setViewNameText] = useState('');
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [errorHelperText, setErrorHelperText] = useState('');
    const [isEdit, setIsEdit] = useState(false);

    React.useEffect(() => {
        if (selectedRow?.id) {
            const { folders, views } = selectedRow;
            const folderIDs = folders?.map((folder) => folder?.asset_folder_id);
            const viewIDs = views?.map((view) => view?.view_id);
            setSelectedFolderIDs(folderIDs);
            setSelectedViewIDs(viewIDs);
            setIsEdit(true);
        }
    }, [selectedRow]);

    const handleClose = () => {
        setSelectedFolderIDs([]);
        setSelectedViewIDs([]);
        setIsEdit(false);
        dialogDismissed();
    };

    const handleCreateViewClick = () => {
        if (isEdit) {
            onEditViewSubmit(selectedFolderIDs, selectedViewIDs);
            setSelectedFolderIDs([]);
            setSelectedViewIDs([]);
        } else {
            setShowViewNameDialog(true);
        }
    };

    const handleViewNameDismiss = () => {
        setShowViewNameDialog(false);
        setViewNameText('');
        setShowErrorMessage(false);
        setErrorHelperText('');
    };

    const isViewNameInvalid = () => {
        let showErr = false;
        let errText = '';
        if (viewNameText?.length === 0) {
            showErr = true;
            errText = 'Please enter group name between length 1 to 256';
        } else if (
            data.some(
                (view) => view?.view_name?.toLowerCase() === viewNameText?.trim()?.toLowerCase()
            )
        ) {
            showErr = true;
            errText = 'Group name already exists';
        } else if (viewNameText?.length > 256) {
            showErr = true;
            errText = 'Group name length cannot exceed 256 characters';
        }
        if (showErr) {
            setShowErrorMessage(showErr);
            setErrorHelperText(errText);
        }
        return showErr;
    };

    const handleCreateSubmit = () => {
        if (isViewNameInvalid()) return;
        handleViewNameDismiss();
        onCreateViewSubmit(selectedFolderIDs, selectedViewIDs, viewNameText);
        setSelectedFolderIDs([]);
        setSelectedViewIDs([]);
    };

    const handleRowsSelection = (type, selectedRowIDs) => {
        if (type === 'folders') {
            setSelectedFolderIDs(selectedRowIDs);
        } else if (type === 'views') {
            setSelectedViewIDs(selectedRowIDs);
        }
    };

    return (
        <ModalManager>
            <Modal
                isOpen={dialogIsVisible}
                onClose={handleClose}
                aria-labelledby="create-view-dialog-title"
            >
                <ModalHeader id="create-view-dialog-title" title={dialogText?.title}>
                    <IconButton icon={CLEAR} onClick={handleClose} />
                </ModalHeader>

                <ModalContent>
                    <CreateEditGroupTabs
                        handleRowsSelection={handleRowsSelection}
                        selectedFolderIDs={selectedFolderIDs}
                        selectedViewIDs={selectedViewIDs}
                        selectedRow={selectedRow}
                    />
                </ModalContent>

                <ModalFooter className="spg-d-flex spg-align-center spg-justify-between">
                    <div className="spg-d-flex spg-flex-column">
                        <TextBodyMd>
                            {`Folders Selected : ${
                                selectedFolderIDs?.length >= 0 ? selectedFolderIDs?.length : '-'
                            }`}
                        </TextBodyMd>
                        <TextBodyMd>
                            {`Groups Selected : ${
                                selectedViewIDs?.length >= 0 ? selectedViewIDs?.length : '-'
                            }`}
                        </TextBodyMd>
                    </div>

                    <div>
                        <Button
                            disabled={!!(selectedViewIDs?.length || selectedFolderIDs?.length)}
                            onClick={handleCreateViewClick}
                            purpose={Purpose.PRIMARY}
                            data-testid="create-group-folder-cancel"
                        >
                            {isEdit ? 'Make Empty Group' : 'Create Empty Group'}
                        </Button>
                        <Button
                            disabled={!(selectedViewIDs?.length || selectedFolderIDs?.length)}
                            onClick={handleCreateViewClick}
                            purpose={Purpose.SECONDARY}
                            data-testid="create-customer-group-folder-ok"
                            className="spg-ml-sm"
                        >
                            {isEdit ? 'Edit Group' : 'Create Group'}
                        </Button>
                    </div>
                </ModalFooter>

                <CreateUpdateEntityDialog
                    dialogIsVisible={showViewNameDialog}
                    dialogDismissed={handleViewNameDismiss}
                    dialogOkClicked={handleCreateSubmit}
                    dialogText={{
                        title:
                            selectedViewIDs?.length || selectedFolderIDs?.length
                                ? 'Group Name'
                                : 'Empty Group'
                    }}
                    textToDisplay={viewNameText}
                    setTextToDisplay={setViewNameText}
                    showErrorMessage={showErrorMessage}
                    helpText={errorHelperText}
                    setShowErrorMessage={setShowErrorMessage}
                    setErrorHelperText={setErrorHelperText}
                    primaryButtonLabel="Create"
                />
            </Modal>
        </ModalManager>
    );
}
