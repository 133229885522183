import React, { useState, useEffect, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import AccessTokenContext from '../../context/AccessTokenContext';
import BreadcrumbTrail from '../BreadcrumbTrail';
import MessageBox from '../MessageBox';
import AssetForm from '../AssetForm';
import { generateBreadcrumbs } from '../../utils/generateBreadcrumbs';
import pathParser from '../../utils/pathParser';
import * as constants from '../../utils/constants';
import assetService from '../../services/assetService';
import tagService from '../../services/tagService';
import googleAnalyticsEvent from '../../utils/googleAnalyticsEvent';
import { CONSENT_REQUIRED_ERROR } from '../../utils/constants';

const SUCCESS_MSG = 'Successfully created asset';
const ERROR_CREATING_MSG = 'Error creating asset';

const defaultBreadcrumbs = [
    {
        title: 'All Portfolios',
        linkDetails: '/',
    },
];

const defaultFormValues = {
    name: '',
    assetValue: '',
    emissions: '',
    assetTypeId: '',
    tags: [],
    riskFactorHazardImpacts: [],
    asset_folder_id: '',
    locations: [
        {
            locationName: '',
            lat: 35.994,
            lng: -78.8986,
            elevation: 123.139,
            long: -78.8986,
            street_address: '',
            country: '',
            locationId: 0,
            location_ref_id: '',
        },
    ],
};

export default function CreateAsset({ match, entityType }) {
    const history = useHistory();
    const { pathname } = useLocation();
    const { getTokenAndTryAgain } = useContext(AccessTokenContext);
    const apiOptions = {
        scope: `${constants.CUSTOMER_READ_ASSET}`,
    };
    const {
        customers: customerId,
        groups: groupId,
        folders: folderId,
        investments: investmentId,
    } = pathParser.getPathComponents(pathname);
    const action = 'Create Asset';
    const [breadcrumbs, setBreadcrumbs] = React.useState([
        ...defaultBreadcrumbs,
        { title: 'Loading' },
    ]);
    const [shouldDisplayMessageBox, setShouldDisplayMessageBox] = useState(false);
    const [messageBoxText, setMessageBoxText] = useState('');
    const [selectedFolder, setSelectedFolder] = useState('');

    if (!isNaN(folderId)) {
        defaultFormValues.asset_folder_id = folderId;
    } else {
        defaultFormValues.asset_folder_id = '';
    }

    useEffect(() => {
        async function fetchData() {
            try {
                const result = await assetService.getAll({
                    investmentId,
                    folderId,
                    getLocations: false,
                });

                setBreadcrumbs(
                    generateBreadcrumbs(
                        entityType,
                        {
                            [constants.CUSTOMER_ENTITY]: result.customer.name,
                            [constants.GROUP_ENTITY]: result.group.name,
                            [constants.FOLDER_ENTITY]: result.folder.name,
                            [constants.INVESTMENT_ENTITY]: result.investment.name,
                        },
                        match.params
                    )
                );
            } catch (err) {
                if (err.error === 'consent_required') {
                    await getTokenAndTryAgain(apiOptions);
                }
            }
        }
        fetchData();
    }, [customerId, folderId, groupId, investmentId]);

    const handleSetMessageBoxText = (msg) => {
        setMessageBoxText(msg);
    };

    const toggleShouldDisplayMessageBox = () => {
        setShouldDisplayMessageBox(!shouldDisplayMessageBox);
    };

    const messageBoxDismissed = async (okClicked) => {
        setShouldDisplayMessageBox(false);
        if (messageBoxText === SUCCESS_MSG) {
            history.push(`/manage/${customerId}/folders/${selectedFolder}/assets`);
        }
    };

    const cancelFunction = () => history.goBack();

    const onSubmit = async (data, e) => {
        const riskFactDetails =
            data &&
            data.riskFactorDetails &&
            data.riskFactorDetails.defaultImpactFunctions &&
            data.riskFactorDetails.defaultImpactFunctions.length
                ? data.riskFactorDetails.defaultImpactFunctions
                : [];

        const riskFactorHazardImpacts = riskFactDetails.map((riskFact) => {
            if (riskFact.risk_factor_id) {
                return {
                    hazardMetricId: riskFact.hazard_id,
                    impactFunctionId: riskFact.impact_function_id,
                    riskFactorId: riskFact.risk_factor_id,
                    riskFactorName: riskFact.risk_factor_name,
                };
            }
            return {};
        });

        if (data.locations) {
            data.locations = (data.locations || []).map((loc) => {
                if (loc.lng) {
                    loc.long = loc.lng;
                    delete loc.lng;
                }
                return loc;
            });
        }

        const reqData = {
            name: data.name,
            emissions: parseFloat(data.emissions),
            value: parseInt(data.assetValue * 1000000, 10).toString(),
            asset_type_id: parseInt(data.assetTypeId),
            asset_folder_id: data.asset_folder_id === 'NA' ? 0 : data.asset_folder_id,
            ref_id: data.ref_id,
            locations: data.locations,
            tags: data.tags,
            customerId: customerId || undefined,
            is_active: true,
            riskFactorHazardImpacts,
        };

        try {
            // Check if the reqData.location.ref_id is repeated or not
            const locationRefIds = reqData.locations.map((loc) => loc.location_ref_id);
            const isLocationRefIdRepeated = locationRefIds.some(
                (refId, index) => locationRefIds.indexOf(refId) !== index
            );

            if (isLocationRefIdRepeated) {
                setMessageBoxText(
                    'Location Reference Ids should be unique per Asset. Please check the location reference ids.'
                );
                setShouldDisplayMessageBox(true);
                return;
            }

            const responseAsset = await assetService.createAsset(reqData);

            if (data.tags && data.tags.length > 0) {
                const responseTag = await tagService.createTag(data.tags, customerId);
                const assignTags = await tagService.assignTagsToAsset(
                    customerId,
                    responseAsset.data.id,
                    data.tags
                );

                if (responseTag.status === 201 || responseTag.status === 200) {
                    googleAnalyticsEvent('Create Entity', 'Tag', 'success');
                } else {
                    googleAnalyticsEvent('Create Entity', 'Tag', 'failed');
                    setMessageBoxText(`${ERROR_CREATING_MSG}. ${responseTag.message}`);
                }

                if (assignTags.status === 201 || assignTags.status === 200) {
                    googleAnalyticsEvent('Assign Entity', 'Tag', 'success');
                } else {
                    googleAnalyticsEvent('Assign Entity', 'Tag', 'failed');
                    setMessageBoxText(`${ERROR_CREATING_MSG}. ${assignTags.message}`);
                }
            }

            // SET THE SELECTED FOLDER TO REDIRECT THAT PAGE
            setSelectedFolder(reqData.asset_folder_id);

            if (responseAsset.status === 201 || responseAsset.status === 200) {
                googleAnalyticsEvent('Create Entity', 'Asset', 'success');
                setMessageBoxText(SUCCESS_MSG);
            } else {
                googleAnalyticsEvent('Create Entity', 'Asset', 'failed');
                setMessageBoxText(`${ERROR_CREATING_MSG}. ${response.message}`);
            }
        } catch (err) {
            if (err.error === CONSENT_REQUIRED_ERROR) {
                await getTokenAndTryAgain(apiOptions);
            }
            googleAnalyticsEvent('Create Entity', 'Asset', 'error');
            const msg = err?.response?.data ? err?.response?.data : err.message;
            setMessageBoxText(`${ERROR_CREATING_MSG}. ${msg.message}`);
        }
        setShouldDisplayMessageBox(true);
    };

    return (
        <div>
            <MessageBox
                messageBoxIsVisible={shouldDisplayMessageBox}
                messageBoxTitle="Asset"
                messageBoxText={messageBoxText}
                messageBoxCallback={messageBoxDismissed}
                cancelText=""
                okText="Ok"
            />

            <BreadcrumbTrail rootTitle="Customers" manageChild="New Asset" />
            <div className="spg-p-md">
                <AssetForm
                    cancelClicked={cancelFunction}
                    submitClicked={onSubmit}
                    messageBoxDismissed={messageBoxDismissed}
                    toggleShouldDisplayMessageBox={toggleShouldDisplayMessageBox}
                    handleSetMessageBoxText={handleSetMessageBoxText}
                    action={action}
                    defaultFormValues={{ ...defaultFormValues }}
                    groupId={groupId}
                    customerId={customerId}
                />
            </div>
        </div>
    );
}
