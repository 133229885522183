import React from 'react';
import {
    Icon,
    IconButton,
    IconSize,
    Tooltip,
    TooltipPlacement,
    TooltipTriggerEvent,
} from '@spglobal/react-components';
import styled from '@emotion/styled';
import { CIRCLE_INFO_O } from '@spglobal/koi-icons';

export const BlueIcon = styled(Icon)`
    color: var(--color-icon-info);
    cursor: pointer;
`;

export const InfoIcon = styled(Icon)`
    cursor: pointer;

    .spg-button svg {
        fill: var(--color-icon-info);
    }
`;

const InfoIconButtonStyled = styled(IconButton)`
    cursor: pointer;

    .spg-button svg {
        fill: var(--color-icon-info);
    }

    &[disabled] {
        cursor: not-allowed;
    }
`;

export function InfoIconButton(props) {
    return <InfoIconButtonStyled icon={CIRCLE_INFO_O} {...props} />;
}

export function InfoTooltip({ iconClass, children }) {
    const triggerElement = (
        <InfoIcon size={IconSize.XSMALL} icon={CIRCLE_INFO_O} className={iconClass} />
    );
    return (
        <Tooltip
            triggerElement={triggerElement}
            triggerEvent={TooltipTriggerEvent.CLICK}
            width="30vw"
            isSecondary
            contentPadding={0}
            placement={TooltipPlacement.BOTTOM}
        >
            {children}
        </Tooltip>
    );
}
