import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { EDIT } from '@spglobal/koi-icons';
import {
    Card,
    FormGroup,
    H1,
    IconButton,
    Select,
} from '@spglobal/react-components';
import BreadcrumbTrail from '../BreadcrumbTrail';
import {
    ACTION,
    RISK_FACTORS_CATEGORIES_NAME_MAPPING,
} from '../../utils/constants';
import { useHierarchyContext } from '../../context/HierarchyContext';
import realAssetsService from '../../services/realAssetsService';
import { getPortfolioResourceKey } from '../../utils/rbacUtils';
import UserContext from '../../context/UserContext';
import useGetPermission from '../../hooks/useGetPermission';
import { transformDataObjForKoiSelect } from '../../utils/koiIntegrationUtils';
import { TextBodySm } from '../shared';
import * as constants from '../../utils/constants';
import { InfoTooltip } from '../shared/InfoIcon/InfoIcon';

const yearOptions = [
    { id: 2020, label: '2020', value: 2020 },
    { id: 2030, label: '2030', value: 2030 },
    { id: 2040, label: '2040', value: 2040 },
    { id: 2050, label: '2050', value: 2050 },
    { id: 2060, label: '2060', value: 2060 },
    { id: 2070, label: '2070', value: 2070 },
    { id: 2080, label: '2080', value: 2080 },
    { id: 2090, label: '2090', value: 2090 },
];
const precisionOptions = [
    { id: 1, label: '1 decimal', value: 1 },
    { id: 2, label: '2 decimal', value: 2 },
    { id: 3, label: '3 decimal', value: 3 },
    { id: 4, label: '4 decimal', value: 4 },
    { id: 5, label: '5 decimal', value: 5 },
];

export default function DashboardHeader({
    riskDataFilters,
    handleFilterChange,
    tagOptions,
    entityType,
    editEntity,
}) {
    const history = useHistory();
    const { currentPage, currentPath, customer } = useHierarchyContext();
    const { user, isLoggedIn } = useContext(UserContext);

    const [riskFactorOptions, setRiskFactorOptions] = useState([]);

    const [scenarioLevels, setScenarioLevels] = useState([]);
    const [riskFactors, setRiskFactors] = useState([]);
    const [apiCheck, setApiCheck] = useState(false);
    const [scenarioLevelOptions, setScenarioLevelOptions] = useState([]);

    // Default options
    const [defaultYearOption, setDefaultYearOption] = useState([]);
    const [defaultPrecisionOption, setDefaultPrecisionOption] = useState([]);
    const [defaultScenarioOption, setDefaultScenarioOption] = useState([]);
    const [defaultRiskFactorOption, setDefaultRiskFactorOption] = useState([]);

    const resourceType = getPortfolioResourceKey(customer?.customer_type);
    const userPermission =
        user && Object.keys(user).length > 0 && useGetPermission(user, isLoggedIn, resourceType);
    const userPrivilege = userPermission?.action;

    useEffect(() => {
        async function fetchData() {
            if (!apiCheck) {
                realAssetsService.getRiskFactors().then((riskFactorsList) => {
                    setRiskFactors(riskFactorsList);
                    const rfOptions = [];
                    Object.keys(riskFactorsList).forEach((categoryKey) => {
                        if (Array.isArray(riskFactorsList[categoryKey])) {
                            const option = {
                                id: categoryKey,
                                label:
                                    RISK_FACTORS_CATEGORIES_NAME_MAPPING[categoryKey] ||
                                    categoryKey,
                                value: categoryKey,
                                options: [],
                            };
                            option.options = riskFactorsList[categoryKey].map((rf) => ({
                                id: rf,
                                label: rf,
                                value: rf,
                            }));

                            rfOptions.push(option);
                        }
                    });
                    setRiskFactorOptions(rfOptions);

                    const allSelectedRisks = [...riskDataFilters.riskFactor[constants.RISKS]];

                    const defOptions = rfOptions.flatMap(item => {
                        return item.options.filter(option => allSelectedRisks.includes(option.value))
                    });

                    setDefaultRiskFactorOption(defOptions);
                });
                realAssetsService.getScenarioLevels().then((scenarioLevelsList) => {
                    setScenarioLevels(scenarioLevelsList.scenario_levels);
                });
                setApiCheck(true);
            }
        }

        fetchData();
        if (riskDataFilters) {
            setDefaultPrecisionOption([
                precisionOptions.find((o) => o.value === riskDataFilters.riskValuePrecision),
            ]);
            setDefaultYearOption([yearOptions.find((o) => o.value === riskDataFilters.year)]);
        }
    }, [riskDataFilters]);

    useEffect(() => {
        let isMounted = true;
        const transformedScenarioLevels = scenarioLevels.map((sl) =>
            transformDataObjForKoiSelect(sl, {
                id: 'id',
                label: 'value',
                value: 'id',
            })
        );

        if (isMounted) {
            setScenarioLevelOptions(transformedScenarioLevels);
            const scOption = transformedScenarioLevels.find(
                (o) => o.value === riskDataFilters.scenario
            );
            if (scOption) {
                setDefaultScenarioOption([scOption]);
            }
        }

        return () => {
            isMounted = false;
        };
    }, [scenarioLevels, riskDataFilters]);

    const handleEditClicked = () => {
        history.push(`${currentPath}/edit`);
    };

    // eslint-disable-next-line react/display-name
    const ScenarioInfoTooltip = React.forwardRef((props, ref) => {
        return (
            <InfoTooltip iconClass="spg-ml-xs">
                <TextBodySm className="spg-p-md">
                    SSPs (Shared Socioeconomic Pathways) are complimentary sources of scenarios
                    focused on projected socioeconomic changes to be used alongside RCPs
                    (Representative Concentration Pathways). These scenarios are based on five
                    distinct narratives for future socioeconomic development and provide a
                    consistent logic for qualitative projections of land use, energy use,
                    population, emissions and other factors. The SSPs and RCPs are then combined to
                    create final scenarios, capturing physical and socioeconomic factors.
                    Climanomics estimates risks for the following scenarios:
                    <li>
                        High Climate Change Scenario (SSP5-8.5): Low mitigation scenario in which
                        total greenhouse gas emissions triple by 2075 and global average
                        temperatures rise by 3.3-5.7 °C by 2100.
                    </li>
                    <li>
                        Medium-High Climate Change Scenario (SSP3-7.0): Limited mitigation scenario
                        in which total greenhouse gas emissions double by 2100 and global average
                        temperatures rise by 2.8-4.6 °C by 2100.
                    </li>
                    <li>
                        Medium Climate Change Scenario (SSP2-4.5): Strong mitigation scenario in
                        which total greenhouse gas emissions stabilize at current levels until 2050
                        and then decline to 2100. This scenario is expected to result in global
                        average temperatures rising by 2.1-3.5 °C by 2100.
                    </li>
                    <li>
                        Low Climate Change Scenario (SSP1-2.6): Aggressive mitigation scenario in
                        which total greenhouse gas emission reduce to net zero by 2050, resulting in
                        global average temperatures rising by 1.3-2.4 °C by 2100, consistent with
                        the goals of the Paris Agreement.
                    </li>
                </TextBodySm>
            </InfoTooltip>
        );
    });

    return (
        <div className="spg-w-100">
            <div className="spg-row">
                <div className="spg-col-11">
                    <BreadcrumbTrail/>
                </div>
                <div className="spg-col-1">
                    {editEntity && userPrivilege === ACTION.ACTION_WRITE && (
                        <IconButton
                            icon={EDIT}
                            onClick={handleEditClicked}
                            id="dashboard-header-edit"
                            data-testid="dashboard-header-edit"
                        />
                    )}
                </div>
            </div>
            <Card hasBorder hasRoundedCorner className="spg-mt-md spg-ml-md spg-mb-md">
                <div className="spg-row">
                    <div className="spg-col-6 spg-d-flex spg-align-center">
                        <H1 data-testid="dashboard-entity-name">{currentPage}</H1>
                    </div>
                    <div className="spg-col-6">
                        <div className="spg-row">
                            <div className="spg-col-4">
                                <FormGroup label="Analysis">
                                    <Select
                                        options={riskFactorOptions}
                                        isMulti
                                        autoMenuWidth={false}
                                        autoResize={false}
                                        isSearchable={false}
                                        defaultValue={defaultRiskFactorOption}
                                        onChange={(options) =>
                                            handleFilterChange([
                                                {
                                                    riskFactor: {
                                                        [constants.RISKS]: options.map(
                                                            (o) => o.value
                                                        ),
                                                        [constants.OPPORTUNITIES]: [],
                                                    },
                                                },
                                            ])
                                        }
                                    />
                                </FormGroup>
                            </div>
                            <div className="spg-col-2">
                                <FormGroup label="Decade" labelFor="year-simple-select">
                                    <Select
                                        id="year-simple-select"
                                        data-testid="year-simple-select"
                                        isMulti={false}
                                        closeOnSelection
                                        isSearchable={false}
                                        options={yearOptions}
                                        values={defaultYearOption}
                                        onChange={(options) =>
                                            handleFilterChange([{ year: options[0].value }])
                                        }
                                    />
                                </FormGroup>
                            </div>
                            <div className="spg-col-4">
                                <FormGroup
                                    label="Scenario"
                                    labelInfo={<ScenarioInfoTooltip />}
                                    labelFor="scenario-simple-select"
                                >
                                    <Select
                                        autoMenuWidth
                                        id="scenario-simple-select"
                                        data-testid="scenario-simple-select"
                                        options={scenarioLevelOptions}
                                        isSearchable={false}
                                        isMulti={false}
                                        closeOnSelection
                                        defaultValue={defaultScenarioOption}
                                        onChange={(options) =>
                                            handleFilterChange([{ scenario: options[0].value }])
                                        }
                                    />
                                </FormGroup>
                            </div>
                            <div className="spg-col-2">
                                <FormGroup
                                    label="Value Precision"
                                    labelFor="decimal-precision-select"
                                >
                                    {riskDataFilters && riskDataFilters.riskValuePrecision && (
                                        <Select
                                            id="decimal-precision-select"
                                            data-testid="decimal-precision-select"
                                            options={precisionOptions}
                                            isMulti={false}
                                            closeOnSelection
                                            isSearchable={false}
                                            values={defaultPrecisionOption}
                                            onChange={(values) =>
                                                handleFilterChange([
                                                    {
                                                        riskValuePrecision: values[0].value,
                                                    },
                                                ])
                                            }
                                        />
                                    )}
                                </FormGroup>
                            </div>
                        </div>
                    </div>
                </div>
            </Card>
        </div>
    );
}
