import React, { useEffect, useState, useCallback, useRef } from 'react';
import axios from 'axios';
import { useOktaAuth } from '@okta/okta-react';
import './App.css';
import { useLocation, useHistory } from 'react-router-dom';
import ScrollHandler from 'components/ScrollHandler';
import MainContent from 'components/Main/MainContent.jsx';
import MainAppBar from 'components/MainAppBar';
import Footer from 'components/Footer';
import MainDrawer from 'components/MainDrawer';
import PageNotFound from 'components/PageNotFound';
import { AccessTokenProvider } from 'context/AccessTokenContext';
import { CustomerConfigProvider } from 'context/CustomerConfigContext';
import { defaultRiskFilters, RiskFilterProvider } from 'context/RiskFilterContext';
import { UserProvider } from 'context/UserContext';
import { useTracking } from 'hooks/useTracking';
import Routes from 'routes';
import * as constants from 'utils/constants';
import { HierarchyProvider } from 'context/HierarchyContext';
import DialogConfirmation from 'components/DialogConfirmation';
import {
    InlineNotification,
    NotificationType,
    Button,
    NotificationProvider,
    NotificationStyle,
} from '@spglobal/react-components';
import { Purpose } from '@spglobal/koi-helpers';
import { useApiGet } from 'hooks/useApiGet';
import { DARK_THEME_CLASS_NAME, IS_DARK_THEME_LS_KEY } from './components/components.constants';

/*
  banner/announcement temporary strings
*/

const announcementUpdatesText = 'Hazard enhancements and new features are now available.';

const App = () => {
    const { authState, oktaAuth } = useOktaAuth();
    const [intervalId, setIntervalId] = useState(null);
    const [signOut, setSignOut] = useState(null);
    const [signIn, setSignIn] = useState(null);
    const { pathname } = useLocation();
    const [customerConfig, setCustomerConfig] = useState({});
    const [riskDataFilters, setRiskDataFilters] = useState(defaultRiskFilters);
    const [currentUser, setCurrentUser] = useState({});
    const [userAccessToken, setUserAccessToken] = useState('');
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [disclaimerViewed, setDisclaimerViewed] = useState(true);
    const [displayBanner, setDisplayBanner] = useState(true);
    const [displayAnnouncement, setDisplayAnnouncement] = useState(false);
    const [isDarkTheme, setDarkTheme] = useState(false);
    const { data: appConfigs } = useApiGet('api/appConfig', {});
    const banner_text_from_config = appConfigs?.results?.find((i) => i.key == 'banner_text').value;

    const getTokenAndTryAgain = useCallback(async (apiOptions) => {
        //   await getAccessTokenWithPopup({
        //     audience: constants.API_AUDIENCE,
        //     ...apiOptions,
        //   });
    }, []);

    const retrieveAccessToken = async (apiOptions) => {
        // const accessToken = await getAccessTokenSilently({
        //   audience: constants.API_AUDIENCE,
        //   ...apiOptions,
        // });
        // return accessToken;
    };

    const handleLogout = async () => {
        await oktaAuth.signOut();
    };

    const handleSetDisclaimerViewed = () => {
        setDisclaimerViewed(!disclaimerViewed);
    };

    const isExpired = (unixTimestamp) => {
        const currentDate = new Date(); // get the current date and time
        const expirationDate = new Date((unixTimestamp - 1800) * 1000);

        // if the Unix timestamp date is less than (i.e., earlier than) the current date, it is expired
        return expirationDate < currentDate;
    };

    useTracking('G-RJY3PFXK0P', currentUser);

    const handleFocus = () => {
        if (!authState?.isAuthenticated || isExpired(authState.idToken.expiresAt)) {
            setIsLoggedIn(false);
            handleLogout();
        }
    };

    const handleThemeChange = (e) => {
        setDarkTheme(!isDarkTheme);
        localStorage.setItem(IS_DARK_THEME_LS_KEY, !isDarkTheme);

        setGlobalTheme(!isDarkTheme);
    };

    useEffect(() => {
        const isDarkValue = localStorage.getItem(IS_DARK_THEME_LS_KEY);
        const isDark = isDarkValue?.length ? JSON.parse(isDarkValue) : false;
        setDarkTheme(isDark);
        setGlobalTheme(isDark);
    }, []);

    useEffect(() => {
        if (isLoggedIn) {
            (async function initializeUserData() {
                const user = await oktaAuth.getUser();
                if (user !== currentUser) {
                    setCurrentUser(user);
                }
            })();
        }
    }, [isLoggedIn]);

    useEffect(() => {
        setIsLoggedIn(true);
        const timeoutId = setTimeout(() => {
            const id = setInterval(() => {
                handleFocus();
            }, 5000);
            setIntervalId(id);
        }, 5000);

        // Clear timeout and interval on component unmount or when user logs out
        return () => {
            clearTimeout(timeoutId);
            clearInterval(intervalId);
        };
    }, [authState]);

    const handleDisplayAnnouncement = () => {
        setDisplayBanner(!displayBanner);
    };

    const history = useHistory();

    const clicked = (evt, targetLink) => {
        evt.preventDefault();

        const trueTarget = targetLink || window.location.pathname;
        history.push(trueTarget);
    };

    const handleFilterChange = (filters) => {
        const validRiskDataFilters = Object.keys(defaultRiskFilters);

        let newFilters = {
            ...riskDataFilters,
        };
        filters.forEach((filter) => {
            const filterName = Object.keys(filter).join();
            const filterValue = filter[filterName];

            if (!validRiskDataFilters.includes(filterName)) {
                console.error('Invalid riskFilter');
                return;
            }

            newFilters = {
                ...newFilters,
                [filterName]: filterValue,
            };

            if (filterName === 'currentCustomerId') {
                newFilters = {
                    ...defaultRiskFilters,
                    currentCustomerId: filterValue,
                };
            }

            if (
                filterName === 'riskFactor' &&
                Object.keys(filterValue).join() !== `${constants.RISKS},${constants.OPPORTUNITIES}`
            ) {
                const { analysisType } = riskDataFilters;
                const updatedRiskFactors = { ...riskDataFilters.riskFactor };
                const updatedRisksOrOpportunities = [...updatedRiskFactors[analysisType]];
                const findRiskFactorIndex = updatedRisksOrOpportunities.findIndex(
                    (riskFactor) => riskFactor === filterValue,
                );
                if (findRiskFactorIndex > -1) {
                    updatedRisksOrOpportunities.splice(findRiskFactorIndex, 1);
                } else {
                    updatedRisksOrOpportunities.push(filterValue);
                }
                newFilters[filterName] = {
                    ...updatedRiskFactors,
                    [analysisType]: updatedRisksOrOpportunities,
                };
            }

            if (filterName === 'tags' && !filterValue.length) {
                const updatedTags = [...riskDataFilters.tags];
                const findTagIndex = riskDataFilters.tags.findIndex(
                    (tag) => tag.id === filterValue.id,
                );
                if (findTagIndex > -1) {
                    updatedTags.splice(findTagIndex, 1);
                } else {
                    updatedTags.push(filterValue);
                }
                newFilters[filterName] = updatedTags;
            }
        });
        setRiskDataFilters(newFilters);
    };

    //   Handle bulk upload
    const abortController = useRef();

    const handleFileUpload = async (file, getFilePresignedUrl) => {
        const myHeaders = new Headers();
        myHeaders.append('Content-Type', 'text/csv');
        const formdata = new FormData();
        formdata.append('', file, '[PROXY]');
        abortController.current = new AbortController();
        const options = {
            headers: {
                'Content-Type': file.type,
            },
            signal: abortController.current.signal,
        };
        axios
            .put(getFilePresignedUrl.pre_signed_url, file, options)
            .then((result) => console.log(result))
            .catch(() => console.log('error occurred while uploading file'));
    };

    const cancelRequest = () => {
        abortController.current?.abort?.();
    };

    const obj = { handleFileUpload, cancelRequest };

    const isConsentPage = () => window.location.pathname === '/consent';

    const setGlobalTheme = (isDark) => {
        if (isDark) {
            document.body.classList.add(DARK_THEME_CLASS_NAME);
        } else {
            document.body.classList.remove(DARK_THEME_CLASS_NAME);
        }
    };

    return (
        <UserProvider
            value={{
                user: currentUser,
                isLoggedIn,
                login: signIn,
                logout: signOut,
                handleSetDisclaimerViewed,
                disclaimerViewed,
            }}
        >
            <AccessTokenProvider
                value={{
                    getTokenAndTryAgain,
                    retrieveAccessToken,
                    accessToken: userAccessToken,
                }}
            >
                <NotificationProvider type={NotificationStyle.TOAST} closable>
                    <CustomerConfigProvider value={customerConfig}>
                        <RiskFilterProvider value={{ riskDataFilters, handleFilterChange }}>
                            <HierarchyProvider pathname={pathname}>
                                <div>
                                    <ScrollHandler />
                                    {!isConsentPage() && (
                                        <InlineNotification
                                            className="spg-mb-0"
                                            isOpen={displayBanner}
                                            type={NotificationType.INFO}
                                            showIcon={false}
                                            onClose={handleDisplayAnnouncement}
                                        >
                                            <div className="spg-d-flex spg-align-center">
                                                {banner_text_from_config}
                                                <Button
                                                    className="spg-ml-xs"
                                                    purpose={Purpose.MARKETING}
                                                    onClick={(evt) => {
                                                        clicked(evt, '/whatsnew');
                                                    }}
                                                >
                                                    Learn More
                                                </Button>
                                            </div>
                                        </InlineNotification>
                                    )}
                                    <div className="spg-layout">
                                        <div className="spg-layout__header">
                                            {!isConsentPage() && (
                                                <>
                                                    <DialogConfirmation />

                                                    <MainAppBar
                                                        shouldDisplay={displayBanner}
                                                        handleDisplayAnnouncement={
                                                            handleDisplayAnnouncement
                                                        }
                                                        handleThemeChange={handleThemeChange}
                                                        isDarkTheme={isDarkTheme}
                                                    ></MainAppBar>
                                                </>
                                            )}
                                        </div>
                                        <div className="spg-layout__body">
                                            <div className="spg-layout__sidebar sidebar">
                                                {!isConsentPage() && <MainDrawer />}
                                            </div>
                                            <div className="spg-layout__main">
                                                <MainContent>
                                                    {<Routes {...obj} /> || <PageNotFound />}
                                                </MainContent>
                                                <Footer />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </HierarchyProvider>
                        </RiskFilterProvider>
                    </CustomerConfigProvider>
                </NotificationProvider>
            </AccessTokenProvider>
        </UserProvider>
    );
};

const appAuth0 = App;
export default appAuth0;
