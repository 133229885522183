import React from 'react';
import { Button, IconButton, Modal, ModalContent, ModalFooter, ModalHeader } from '@spglobal/react-components';
import { CLEAR } from '@spglobal/koi-icons';
import { Purpose } from '@spglobal/koi-helpers';
import { TextBodyMd } from '../shared';
import DeleteDialogTable from './DeleteDialogTemplate.styles';

const DeleteDialog = ({
    isDialogOpen,
    primaryMessage,
    selectedRow,
    handleDialogAction,
    showDataTable,
}) => {
    const getDataTable = (selectedRowData) => (
        <DeleteDialogTable aria-label="simple table">
            <thead>
                <tr>
                    <th>Folder Name</th>
                    <th>#Assets</th>
                    <th>Folder ID</th>
                </tr>
            </thead>
            <tbody>
                {[selectedRowData]?.map((row, index) => (
                    <tr key={index}>
                        <td>{row?.asset_folder_name || row?.name}</td>
                        <td>{row?.asset_count}</td>
                        <td>{row?.asset_folder_id}</td>
                    </tr>
                    ))}
            </tbody>
        </DeleteDialogTable>
    );

    const handleAction = (isConfirmDelete) => {
        handleDialogAction(isConfirmDelete);
    };

    return (
        <div>
            <Modal
                isOpen={isDialogOpen}
                onClose={() => handleAction(false)}
                aria-labelledby="delete-dialogue-container"
            >
                <ModalHeader
                    id="folder-group-customer-dialog-title"
                    title="Delete"
                >
                    <IconButton aria-label="close" icon={CLEAR} onClick={() => handleAction(false)} />
                </ModalHeader>

                <ModalContent>
                    {showDataTable && getDataTable(selectedRow)}
                    {primaryMessage && (
                        <TextBodyMd className="spg-text-bold">{primaryMessage}</TextBodyMd>
                    )}
                    <TextBodyMd>Are you sure you want to delete?</TextBodyMd>
                </ModalContent>

                <ModalFooter>
                    <Button
                        onClick={() => handleAction(true)}
                        purpose={Purpose.PRIMARY}
                        data-testid="delete-dialogue-confirm"
                    >
                        Confirm
                    </Button>
                    <Button
                        onClick={() => handleAction(false)}
                        purpose={Purpose.SECONDARY}
                        data-testid="delete-dialogue-cancel"
                        className="spg-ml-sm"
                    >
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default DeleteDialog;
