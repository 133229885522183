import React from 'react';
import { Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { IconButton, InputField, FormGroup } from '@spglobal/react-components';
import { TRASH, PLUS, MINUS } from '@spglobal/koi-icons';
import { Purpose } from '@spglobal/koi-helpers';
import { ErrorTextBodySm, TextBodySm } from '../shared';

export default function ImpactPathwayForm({
    ipIndex,
    impactPathway,
    coordButtonClicked,
    removePathwayButtonClicked,
    errors,
    control,
    getValues,
}) {

    return (
        <>
            {impactPathway && getValues && (
                <>
                    <div className="spg-row">
                        <div className="spg-col-8">
                            <FormGroup
                                label="Impact Pathway Name"
                                data-testid={`impactPathways-name-${ipIndex}`}
                                required
                            >
                                <Controller
                                    render={({ field }) => (
                                        <InputField {...field} />
                                    )}
                                    id={`impactPathways[${ipIndex}].name`}
                                    name={`impactPathways[${ipIndex}].name`}
                                    key={`impactPathways[${ipIndex}].name`}
                                    rules={{ required: 'Impact Pathway Name is required' }}
                                    control={control}
                                    autoFocus={ipIndex !== 0}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name={`impactPathways[${ipIndex}].name`}
                                    render={({ message }) => <ErrorTextBodySm>{message}</ErrorTextBodySm>}
                                />
                            </FormGroup>
                        </div>
                        <div className="spg-col-2 spg-col-offset-2 spg-d-flex spg-justify-center spg-align-center">
                            <IconButton
                                aria-label="remove-impact-function"
                                onClick={removePathwayButtonClicked}
                                disabled={ipIndex === 0}
                                icon={TRASH}
                                className="spg-mt-md"
                            />
                        </div>
                    </div>
                    <div className="spg-row">
                        <div className="spg-col-10" style={{ height: '90px' }}>
                            <div className="spg-d-flex spg-flex-column" style={{ gap: '4px' }}>
                                <div className="spg-d-flex" style={{ gap: '4px' }}>
                                    <div key={`cx-z0${ipIndex}`}>
                                        <TextBodySm>X</TextBodySm>
                                    </div>
                                    {impactPathway.xValues &&
                                        impactPathway.xValues.length &&
                                        impactPathway.xValues.map((curX, idx) => (
                                            <div key={`cx-${ipIndex}-${idx}`}>
                                                <Controller
                                                    render={({ field }) => (
                                                        <InputField {...field} />
                                                    )}
                                                    rules={{
                                                        required: 'X Value is required',
                                                        pattern: {
                                                            value: /^-?(0|[1-9]\d*)(\.\d+)?$/,
                                                            message: 'Must be numeric',
                                                        },
                                                    }}
                                                    name={`impactPathways[${ipIndex}].xValues[${idx}]`}
                                                    key={`impactPathways[${ipIndex}].xValues[${idx}]`}
                                                    control={control}
                                                />
                                                <ErrorMessage
                                                    errors={errors}
                                                    name={`impactPathways[${ipIndex}].xValues[${idx}]`}
                                                    render={({ message }) => (
                                                        <ErrorTextBodySm>{message}</ErrorTextBodySm>
                                                    )}
                                                />
                                            </div>
                                        ))}
                                </div>
                                <div className="spg-d-flex" style={{ gap: '4px' }}>
                                    <div key={`cy-z0${ipIndex}`}>
                                        <TextBodySm>Y</TextBodySm>
                                    </div>
                                    {impactPathway.yValues &&
                                        impactPathway.yValues.length &&
                                        impactPathway.yValues.map((curY, idx) => (
                                            <div key={`cx-${ipIndex}-${idx}`}>
                                                <Controller
                                                    render={({ field }) => (
                                                        <InputField {...field} />
                                                    )}
                                                    rules={{
                                                        required: 'Y Value is required',
                                                        pattern: {
                                                            value: /^-?(0|[1-9]\d*)(\.\d+)?$/,
                                                            message: 'Must be numeric',
                                                        },
                                                    }}
                                                    name={`impactPathways[${ipIndex}].yValues[${idx}]`}
                                                    key={`impactPathways[${ipIndex}].yValues[${idx}]`}
                                                    data-testid={`impactPathways-${ipIndex}-yValues-${idx}`}
                                                    control={control}
                                                />
                                                <ErrorMessage
                                                    errors={errors}
                                                    name={`impactPathways[${ipIndex}].yValues[${idx}]`}
                                                    render={({ message }) => (
                                                        <ErrorTextBodySm>{message}</ErrorTextBodySm>
                                                    )}
                                                />
                                            </div>
                                        ))}
                                </div>
                            </div>
                        </div>
                        <div className="spg-d-flex spg-align-center spg-justify-between spg-col-2" style={{ height: '50px'}}>
                            <IconButton
                                onClick={(e) => coordButtonClicked(e, 'add')}
                                icon={PLUS}
                                purpose={Purpose.SECONDARY}
                            />
                            <IconButton
                                onClick={(e) => coordButtonClicked(e, 'remove')}
                                icon={MINUS}
                                purpose={Purpose.SECONDARY}
                            />
                        </div>
                    </div>
                </>
            )}
        </>
    );
}
