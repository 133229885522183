import * as React from 'react';
import { FormGroup, InputField } from '@spglobal/react-components';
import { SEARCH } from '@spglobal/koi-icons';
import { useState } from 'react';
import DataTableGrid from '../../DataTable/DataTableGrid/DataTableGrid';

export default function AdminList({ tableData = [], dataLoaded = true }) {
    const [gridApi, setGridApi] = useState(null);

    const columnDefs = [
        {
            field: 'user_name',
            headerName: 'Admin Name',
            width: 200,
            filter: true,
            floatingFilter: true,
        },
        {
            field: 'user_email',
            headerName: 'Admin Email-ID',
            width: 200,
            filter: true,
            floatingFilter: true,
        },
    ];

    const handleQuickFilterTextChanged = (e) => {
        gridApi?.setQuickFilter(e.target.value);
    };

    const handleGridReady = (e) => {
        setGridApi(e.api);
    };

    return (
        <div>
            <div className="spg-row spg-d-flex spg-justify-between">
                <div className="spg-col-10">
                    <FormGroup className="spg-w-25">
                        <InputField
                            onChange={(e) => handleQuickFilterTextChanged(e)}
                            icon={SEARCH}
                        />
                    </FormGroup>
                </div>
            </div>
            <DataTableGrid
                className="cli-datatablegrid-min-height"
                columnDefs={columnDefs}
                rowData={tableData}
                onGridReady={handleGridReady}
                loading={!dataLoaded}
                pagination
            />
        </div>
    );
}
