import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { NotificationType, useNotification } from '@spglobal/react-components';
import UserContext from '../../context/UserContext';
import CustomerForm from './Components/CustomerForm';
import BreadcrumbTrail from '../BreadcrumbTrail';
import { getRole } from '../../utils/user';
import customerService from '../../services/customerService';

export default function Customers() {
    const history = useHistory();
    const { user } = useContext(UserContext);
    const { addNotification } = useNotification();

    const onSubmit = async (formData) => {
        const SUCCESS_MSG = 'Successfully created customer';
        const ERROR_CREATING_MSG = 'Error in creating customer';
        const role = getRole(user && user.SPGGroups ? user.SPGGroups : []);

        const reqParams = {
            name: formData.customerName,
            address: formData.address,
            state: formData.stateProvince,
            country: formData.country,
            poc_name: formData.pointOfContact,
            poc_email: formData.pointOfContactEmail,
            department: formData.titleDepartment,
            account_manager_name: formData.accountManager,
            account_manager_email: formData.accountManagerEmail,
            notes: formData.notes,
            asset_count: formData.noOfAssetsSubscribed,
            subscription_start_date: formData.subscriptionStartDate
                ? new Date(formData.subscriptionStartDate).getTime()
                : null,
            subscription_end_date: formData.subscriptionEndDate
                ? new Date(formData.subscriptionEndDate).getTime()
                : null,
            customer_type: formData.subscriberType,
            role,
        };

        try {
            const response = await customerService.createCustomerManage(reqParams);
            if (response.status === 201 || response.status === 200) {
                addNotification(SUCCESS_MSG, NotificationType.SUCCESS);
                return setTimeout(() => history.push('/manageCustomer'), 3000);
            }
            return addNotification(ERROR_CREATING_MSG, NotificationType.ERROR);
        } catch (err) {
            let errorMessage = ERROR_CREATING_MSG;
            if (err?.response?.data?.message) errorMessage = err?.response?.data?.message;

            return addNotification(errorMessage, NotificationType.ERROR);
        }
    };

    const defaultValues = {
        customerName: '',
        address: '',
        stateProvince: '',
        country: '',
        pointOfContact: '',
        titleDepartment: '',
        pointOfContactEmail: '',
        subscriberType: '',
        subscriptionStartDate: '',
        subscriptionEndDate: '',
        noOfAssetsSubscribed: '',
        accountManager: '',
        notes: '',
        startDate: '',
    };

    const customerFormPros = {
        onSubmit,
        defaultValues,
    };

    const userRole = getRole(user?.SPGGroups || []);

    return (
        <>
            <BreadcrumbTrail
                rootTitle="Customer Management"
                manageParent="/manageCustomer"
                manageChild="New Customer"
                history={history}
                isBoldText
            />
            <div className="spg-w-100 spg-p-md">
                {user && user.name && <CustomerForm {...customerFormPros} userRole={userRole} />}
            </div>
        </>
    );
}
