import styled from '@emotion/styled';

export const TreeViewStyled = styled.div`
  ul {
    list-style: none;
  }

  .tree-node:hover {
    background-color: var(--color-bg-hover);
  }
`;
