import React, { useState, useEffect } from 'react';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { Purpose, Size } from '@spglobal/koi-helpers';
import {
    FormGroup,
    InputField,
    Button,
    Checkbox,
    Select,
    H3,
    Spinner,
    H2,
} from '@spglobal/react-components';
import { ErrorMessage } from '@hookform/error-message';
import RiskFactors from './RiskFactors';
import AssignedImpactFunctions from './AssignedImpactFunctions';
import ImpactFunctionsModal from './ImpactFunctionsModal';
import riskFactorsService from '../../services/riskFactorsService';
import impactFunctionService from '../../services/impactFunctionService';
import assetTypeService from '../../services/assetTypeService';
import { ErrorTextBodySm } from '../shared';

const ERROR_FETCHING_DATA_MSG = 'Error fetching necessary data';

const riskFactorCategories = ['Transition Risks', 'Physical Risks', 'Opportunities'];

export default function AssetTypeForm({
    cancelClicked,
    submitClicked,
    defaultFormValues,
    displayHeader,
    handleSetMessageBoxText,
    toggleShouldDisplayMessageBox,
}) {
    const [showImpactFunctionModal, setShowImpactFunctionModal] = useState(false);
    const [allRiskFactors, setAllRiskFactors] = useState([]);
    const [allImpactFunctions, setAllImpactFunctions] = useState([]);
    const [selectedRiskFactor, setSelectedRiskFactor] = useState({});
    const [impactFunctions, setImpactFunctions] = useState([]);
    const [tcfdCategoryIds, setTcfdCategoryIds] = useState([2, 1]);
    const [allAssetTypeMetadata, setAllAssetTypeMetadata] = useState({});
    const [selectedMetadata, setSelectedMetadata] = useState({});
    const {
        resetField,
        handleSubmit,
        formState: { errors },
        control,
    } = useForm({
        defaultValues: defaultFormValues,
    });
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'impactFunctions',
        keyName: 'fieldId',
    });

    useEffect(() => {
        async function fetchData() {
            try {
                const riskFactorsData = await riskFactorsService.getAll();
                const impactFunctionsData = await impactFunctionService.getAllImpactFunctions();
                const allAssetTypeMetadataResponse =
                    await assetTypeService.getAllAssetTypeMetadata();
                const impactFunctionsDataWithDefault = impactFunctionsData?.results?.map(
                    (impactFunction) => ({ ...impactFunction, isDefault: 0 })
                );
                setAllRiskFactors(riskFactorsData);
                setAllImpactFunctions(impactFunctionsDataWithDefault);
                // Set First Default Selected Value from Physical Risk.
                setSelectedRiskFactor(
                    riskFactorsData.find((element) => {
                        if (element.tcfd_name === 'Physical Risk') return element;
                    })
                );
                setAllAssetTypeMetadata(allAssetTypeMetadataResponse);
                const tcfdCategories = [
                    ...new Set(riskFactorsData.map((riskFactor) => riskFactor.tcfd_id)),
                ]
                    .sort((a, b) => a - b)
                    ?.filter((id) => id !== 2);
                tcfdCategories?.unshift(2);
                setTcfdCategoryIds(tcfdCategories);
            } catch (error) {
                if (error.error === 'consent_required') {
                    await getTokenAndTryAgain(apiOptions);
                }
                handleSetMessageBoxText(`${ERROR_FETCHING_DATA_MSG}. ${error.message}`);
                toggleShouldDisplayMessageBox();
            }
        }
        fetchData();
    }, []);

    useEffect(() => {
        if (Object.keys(allAssetTypeMetadata).length > 0) {
            Object.keys(allAssetTypeMetadata).forEach((currentMetadataKey) => {
                const selectedId =
                    defaultFormValues &&
                    defaultFormValues.metadata &&
                    defaultFormValues.metadata[currentMetadataKey] &&
                    defaultFormValues.metadata[currentMetadataKey].id;
                if (selectedId) {
                    setSelectedMetadata((prevState) => ({
                        ...prevState,
                        [currentMetadataKey]: {
                            metadataId: selectedId,
                        },
                    }));
                }
            });
        }
    }, [allAssetTypeMetadata, defaultFormValues]);

    const noFieldId = ({ fieldId, ...rest }) => rest;

    const updateRiskFactorDefaultImpactFunction = (impactFunctionId, riskFactorId) => {
        const updatedImpactFunctions = fields.map((currentImpactFunction) => {
            const impactFunctionWithoutFieldId = noFieldId(currentImpactFunction);
            if (
                currentImpactFunction.riskFactorId === riskFactorId &&
                currentImpactFunction.isDefault === 1 &&
                currentImpactFunction.id !== impactFunctionId
            ) {
                return { ...impactFunctionWithoutFieldId, isDefault: 0 };
            }
            if (currentImpactFunction.id === impactFunctionId) {
                return { ...impactFunctionWithoutFieldId, isDefault: 1 };
            }
            return impactFunctionWithoutFieldId;
        });

        resetField('impactFunctions', { defaultValue: updatedImpactFunctions });
    };

    const handleCheckboxToggle = (impactFunction) => () => {
        const originalImpactFunction = allImpactFunctions.find((f) => f.id === impactFunction.id);
        const currentImpactFunctionIndex = fields.findIndex(
            (selectedImpactFunction) => selectedImpactFunction.id === impactFunction.id
        );
        const riskFactorAvailableImpactFunctions = fields.filter(
            (selectedImpactFunction) =>
                selectedImpactFunction.riskFactorId === impactFunction.riskFactorId
        );

        if (currentImpactFunctionIndex === -1) {
            riskFactorAvailableImpactFunctions.length === 0
                ? append({ ...originalImpactFunction, isDefault: 1 })
                : append(originalImpactFunction);
        } else {
            remove(currentImpactFunctionIndex);
        }
    };

    const handleRemoveButton = (impactFunctionIndex) => {
        let updatedImpactFunctions = [...fields];
        // grab our riskFactorId
        const { riskFactorId, isDefault } = updatedImpactFunctions[impactFunctionIndex];
        // find our new targeted default, if necessary
        if (isDefault === 1) {
            // target default IF Index
            const newDefaultIndex = updatedImpactFunctions.findIndex(
                (impactFunction, index) =>
                    impactFunction.riskFactorId === riskFactorId && index !== impactFunctionIndex
            );

            // update if there is another IF for our Risk Factor
            if (newDefaultIndex !== -1) {
                updatedImpactFunctions = updatedImpactFunctions.map((impactFunction, index) => {
                    const impactFunctionWithoutFieldId = noFieldId(impactFunction);
                    return index === newDefaultIndex
                        ? { ...impactFunctionWithoutFieldId, isDefault: 1 }
                        : impactFunctionWithoutFieldId;
                });
            }
        }

        // filter out the targeted IF
        updatedImpactFunctions = updatedImpactFunctions.filter(
            (impactFunction, index) => index !== impactFunctionIndex
        );
        resetField('impactFunctions', {
            defaultValue: updatedImpactFunctions,
        });
    };

    const toggleImpactFunctionsList = () => {
        setShowImpactFunctionModal(!showImpactFunctionModal);
        setImpactFunctions(allImpactFunctions);
    };

    const handleListItemClick = (event, index, tcfdId) => {
        let riskCategoryFactors = [];
        [tcfdId].forEach((riskCategoryId) => {
            riskCategoryFactors = allRiskFactors.filter(
                (riskFactor) => riskFactor.tcfd_id === riskCategoryId
            );
        });
        setSelectedRiskFactor(riskCategoryFactors[index]);
    };

    const onChangeFunction = (selectedValues) => {
        const selectedValue = selectedValues?.length && selectedValues[0];
        if (selectedValue) {
            setImpactFunctions(
                allImpactFunctions.filter(
                    (impactFunction) => impactFunction?.id === selectedValue?.id
                )
            );
        }
    };

    const handleSubmitCallBack = (formData, event) => {
        // add metadata to formData
        submitClicked(
            {
                ...formData,
                metadata: { ...selectedMetadata },
            },
            allAssetTypeMetadata,
            event
        );
    };

    return (
        <form noValidate="noValidate" onSubmit={handleSubmit(handleSubmitCallBack)}>
            {selectedRiskFactor && (
                <ImpactFunctionsModal
                    fields={fields}
                    allOptions={impactFunctions}
                    allImpactFunctions={allImpactFunctions}
                    selectedRiskFactor={selectedRiskFactor}
                    showImpactFunctionModal={showImpactFunctionModal}
                    handleCheckboxToggle={handleCheckboxToggle}
                    toggleImpactFunctionsList={toggleImpactFunctionsList}
                    onChangeFunction={onChangeFunction}
                />
            )}
            <div className="spg-row spg-w-100 spg-ml-0 spg-mr-0">
                <div className="spg-col-6">
                    <FormGroup label="Asset Type Name" labelFor="assetTypeName" required>
                        <Controller
                            render={({ field }) => <InputField {...field} />}
                            autoFocus
                            id="assetTypeName"
                            name="assetTypeName"
                            control={control}
                            rules={{
                                required: 'Asset Type Name is required',
                                maxLength: {
                                    value: 128,
                                    message: 'Asset Type Name exceeds max length',
                                },
                            }}
                        />
                        <ErrorMessage
                            errors={errors}
                            name="assetTypeName"
                            render={({ message }) => (
                                <ErrorTextBodySm>{message}</ErrorTextBodySm>
                            )}
                        />
                    </FormGroup>
                </div>
                <div className="spg-col-6">
                    <FormGroup
                        label="Asset Type Description"
                        labelFor="assetTypeDescription"
                        required
                    >
                        <Controller
                            render={({ field }) => <InputField {...field} />}
                            id="assetTypeDescription"
                            name="assetTypeDescription"
                            control={control}
                            rules={{
                                required: 'Asset Type Description is required',
                                maxLength: {
                                    value: 800,
                                    message: 'Asset Type Description exceeds max length',
                                },
                            }}
                        />
                        <ErrorMessage
                            errors={errors}
                            name="assetTypeDescription"
                            render={({ message }) => (
                                <ErrorTextBodySm>{message}</ErrorTextBodySm>
                            )}
                        />
                    </FormGroup>
                </div>
            </div>
            <div className="spg-row spg-row-grid-1 spg-ml-0 spg-mr-0">
                {Object.keys(allAssetTypeMetadata).length > 0 &&
                    Object.keys(allAssetTypeMetadata).map((currentMetadataKey) => {
                        const opt = allAssetTypeMetadata[currentMetadataKey].values.map(
                            (metadataValue) => ({
                                value: metadataValue.id,
                                label: metadataValue.value,
                                renderElement: (
                                    <div
                                        data-testid={`metadata-${currentMetadataKey}-${metadataValue.value}`}
                                    >
                                        {metadataValue.value}
                                    </div>
                                ),
                            })
                        );
                        const selectedId =
                            defaultFormValues &&
                            defaultFormValues.metadata &&
                            defaultFormValues.metadata[currentMetadataKey] &&
                            defaultFormValues.metadata[currentMetadataKey].id;
                        let selected;
                        if (selectedId) {
                            selected = opt.find((o) => o.value === selectedId);
                        }

                        return (
                            <div className="spg-col-3" key={currentMetadataKey}>
                                <FormGroup label={`Asset Type ${currentMetadataKey}`}>
                                    <Select
                                        closeOnSelection
                                        isMulti={false}
                                        placeholder=""
                                        onChange={(values) => {
                                            setSelectedMetadata({
                                                ...selectedMetadata,
                                                [currentMetadataKey]: {
                                                    metadataId: values[0].value,
                                                },
                                            });
                                        }}
                                        isHighlightSearchMatches={false}
                                        options={opt}
                                        defaultValue={selected ? [selected] : undefined}
                                    ></Select>
                                </FormGroup>
                            </div>
                        );
                    })}
                <div className="spg-col-2">
                    <FormGroup label="&zwnj;" labelFor="assetTypeActive">
                        <Controller
                            render={({ field }) => (
                                <Checkbox
                                    name="assetTypeActive"
                                    {...field}
                                    label="Active"
                                    defaultChecked={defaultFormValues?.assetTypeActive}
                                />
                            )}
                            edge="end"
                            id="assetTypeActive"
                            name="assetTypeActive"
                            defaultValue={defaultFormValues?.assetTypeActive}
                            control={control}
                        />
                    </FormGroup>
                </div>
            </div>
            <div>
                <div className="spg-row spg-mt-md spg-mb-md">
                    <div className="spg-col-4 spg-text-center">
                        <H2>Risk Factors</H2>
                    </div>
                    <div className="spg-col-8 spg-text-center">
                        <H2>
                            Assigned Impact Functions -{' '}
                            {selectedRiskFactor && selectedRiskFactor.name
                                ? selectedRiskFactor.name
                                : ''}
                        </H2>
                    </div>
                </div>
                <div className="spg-row">
                    <div className="spg-col-4">
                        <ul className="spg-list spg-list--unstyled" id="rf-list">
                            {allRiskFactors &&
                            allRiskFactors.length > 0 &&
                            tcfdCategoryIds &&
                            tcfdCategoryIds.length > 0 ? (
                                tcfdCategoryIds.map((riskCategoryId) => {
                                    const riskCategoryFactors = allRiskFactors.filter(
                                        (riskFactor) => riskFactor.tcfd_id === riskCategoryId
                                    );

                                    return (
                                        <React.Fragment
                                            key={`allRiskFactors-tcfd-${riskCategoryId}-riskCategory`}
                                        >
                                            <li key={`riskCategory-${riskCategoryId}`}>
                                                <H3>{riskFactorCategories[riskCategoryId - 1]}</H3>
                                            </li>
                                            <RiskFactors
                                                fields={fields}
                                                allRiskFactors={riskCategoryFactors}
                                                selectedRiskFactor={selectedRiskFactor}
                                                handleListItemClick={handleListItemClick}
                                                riskCategoryId={riskCategoryId}
                                            />
                                        </React.Fragment>
                                    );
                                })
                            ) : (
                                <Spinner size={48} />
                            )}
                        </ul>
                    </div>
                    <div className="spg-col-8" data-testid="assign-impact-function-parent">
                        {selectedRiskFactor && (
                            <AssignedImpactFunctions
                                fields={fields}
                                control={control}
                                toggleImpactFunctionsList={toggleImpactFunctionsList}
                                selectedRiskFactor={selectedRiskFactor}
                                handleRemoveButton={handleRemoveButton}
                                updateRiskFactorDefaultImpactFunction={
                                    updateRiskFactorDefaultImpactFunction
                                }
                            />
                        )}
                    </div>
                </div>

                <div className="spg-d-flex spg-w-100 spg-justify-end">
                    <Button
                        type="submit"
                        purpose={Purpose.PRIMARY}
                        size={Size.MEDIUM}
                        id="submitAssetTypeButton"
                    >
                        {`${displayHeader === 'Edit Asset Type' ? 'Update' : 'Save'} Asset Type`}
                    </Button>
                    <Button
                        className="spg-ml-md"
                        onClick={cancelClicked}
                        purpose={Purpose.SECONDARY}
                        size={Size.MEDIUM}
                    >
                        Cancel
                    </Button>
                </div>
            </div>
        </form>
    );
}
