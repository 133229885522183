import React, { useEffect, useState } from 'react';
import mapService from '../../services/mapService';
import LocationMap from './LocationMap';

const MapLoader = (props) => {
    const [googleAPIKey, setGoogleAPIKey] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchAPIKey = async () => {
            try {
                const key = await mapService.getMapKeys();
                if (typeof key.key === 'string') {
                    setGoogleAPIKey(key.key);
                } else {
                    console.error('Invalid Google API Key format');
                }
            } catch (error) {
                console.error('Error fetching Google API Key:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchAPIKey();
    }, []);

    if (loading || !googleAPIKey) {
        return <div>Loading map...</div>;
    }

    return <LocationMap {...props} googleAPIKey={googleAPIKey}/>;
};

export default MapLoader;
