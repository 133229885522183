import React, { useState, useRef, useMemo, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import throttle from 'lodash/throttle';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';

const autocompleteService = { current: null };

export default function AutocompleteGoogleMapsField({ handleChange, value, index }) {
    const [inputValue, setInputValue] = useState('');
    const [options, setOptions] = useState([]);
    const loaded = useRef(false);

    // let's set window loaded to avoid the window.google not-present error
    if (typeof window !== 'undefined' && !loaded.current) {
        loaded.current = true;
    }

    const handleTextInputChange = useCallback((event) => {
        setInputValue(event);
    }, []);

    const fetch = useMemo(
        () =>
            throttle((request, callback) => {
                autocompleteService.current.getQueryPredictions(request, callback);
            }, 200),
        [],
    );

    useEffect(() => {
        let active = true;

        if (!autocompleteService.current && window.google) {
            autocompleteService.current = new window.google.maps.places.AutocompleteService();
        }
        if (!autocompleteService.current) {
            return undefined;
        }

        if (inputValue === '') {
            setOptions([]);
            return undefined;
        }

        fetch({ input: inputValue }, (results) => {
            if (active) {
                let newOptions = [];

                if (results) {
                    newOptions = [...newOptions, ...results].map((o) => ({
                        id: o.place_id,
                        name: o.description,
                    }));
                }
                setOptions(newOptions);
            }
        });

        return () => {
            active = false;
        };
    }, [inputValue, fetch]);

    return (
        <ReactSearchAutocomplete
            id={`autocomplete-google-map-${index}`}
            items={options}
            onSearch={handleTextInputChange}
            onSelect={handleChange}
            placeholder="Search for Address"
            showNoResultsText="No options"
            styling={{
                zIndex: 99,
            }}
            className="cli-autocomplete"
        />
    );
}

AutocompleteGoogleMapsField.propTypes = {
    handleChange: PropTypes.func.isRequired,
    value: PropTypes.string,
};
