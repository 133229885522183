import React, { useState, useEffect } from 'react';
import {
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
    AreaChart,
    Area,
    CartesianGrid,
} from 'recharts';
import { Card } from '@spglobal/react-components';
import CheckboxList from '../CheckboxList';
import { formatImpactPathways } from '../../utils/formatImpactPathways';
import { HeadingSm, ParagraphLg, TextBodyLg, TextBodyMd } from '../shared';

// These colors correspond to the style guide's priority
const fillValues = ['#3D86F4', '#7759FF', '#E3AB25', '#58BCCD', '#FC8C59'];

const renderTooltipContent = ({ active, payload, label }) => {
    const total =
        payload && payload.length > 0
            ? payload.reduce((result, entry) => result + (entry.value[1] - entry.value[0]), 0)
            : 0;

    const yUnits = payload && payload.length > 0 ? payload[0].unit : '';

    return (
        <TextBodyMd className="spg-ml-xs spg-p-0">
            {`Cumulative Impact: ${total.toFixed(2)} ${yUnits}`}
        </TextBodyMd>
    );
};

// TODO: order these by magnitude of impact?
const getImpactPathwayNames = (impactFunction) => {
    const { impactPathways } = impactFunction;
    if (!impactPathways || impactPathways.length < 1) {
        return [];
    }
    return impactPathways.map((impactPathway, index) => ({
        id: impactPathway.id,
        name: impactPathway.name,
        fill: fillValues[index],
    }));
};

export default function VulnerabilityGraph({ dataToGraph, minWidth, displayHeader }) {
    const [dataPoints, setDataPoints] = useState([]);
    const [dataPointsInterval, setDataPointsInterval] = useState(0);
    const [dataTicks, setDataTicks] = useState([]);
    const [impactPathwayNames, setImpactPathwayNames] = useState([]);
    const [activeImpactPathways, setActiveImpactPathways] = useState([]);

    // initial load useEffect
    useEffect(() => {
        if (dataToGraph && dataToGraph.impactPathways) {
            const formatData = formatImpactPathways(dataToGraph.impactPathways);
            const pathwayNamesFill = getImpactPathwayNames(dataToGraph);
            setImpactPathwayNames(pathwayNamesFill);
            setActiveImpactPathways(pathwayNamesFill);
            setDataPoints(formatData);
            const pointValues = extractPointValues(formatData);
            const minInterval = getMinInterval(pointValues);
            setDataPointsInterval(minInterval);
            setDataTicks(createTicks(formatData));
        }
    }, [dataToGraph]);

    // graph reloads for impact pathway checkbox toggle, let's stack them properly
    useEffect(() => {
        if (activeImpactPathways && dataToGraph && dataToGraph.impactPathways) {
            const activeImpactPathwayIds = activeImpactPathways.map((ip) => ip.id);
            const filteredImpactPathways = dataToGraph.impactPathways.filter((impactPathway) =>
                activeImpactPathwayIds.includes(impactPathway.id)
            );
            const formatData = formatImpactPathways(filteredImpactPathways);
            setDataPoints(formatData);
            const pointValues = extractPointValues(formatData);
            const minInterval = getMinInterval(pointValues);
            setDataPointsInterval(minInterval);
            setDataTicks(createTicks(formatData));
        }
    }, [activeImpactPathways, dataToGraph]);

    const handleActivePathwaysToggle = (value) => () => {
        const currentIndex = activeImpactPathways.findIndex(
            (activeImpactPathway) => activeImpactPathway.id === value.id
        );
        const newActiveImpactPathways = [...activeImpactPathways];

        if (currentIndex === -1) {
            newActiveImpactPathways.push(value);
        } else {
            newActiveImpactPathways.splice(currentIndex, 1);
        }

        setActiveImpactPathways(newActiveImpactPathways);
    };

    const extractPointValues = (points) => {
        if (points.length === 0) {
            return [];
        }
        return points.map((point) => +point['% impact metric']).filter((xVal) => xVal); // removing zero here with filter
    };

    // using this to find the smallest difference between points supplied
    const getMinInterval = (points) => {
        const intervalBetweenPoints = points.map((point, index) =>
            index === 0 ? point : +(point - points[index - 1]).toFixed(1)
        );
        return Math.min(...intervalBetweenPoints);
    };

    const createTicks = (points) => {
        let ticks = [];
        if (points.length === 0) {
            return ticks;
        }

        const pointValues = extractPointValues(points);

        const minInterval = getMinInterval(pointValues);

        if (minInterval < 5 || minInterval === 500) {
            return points.map((point) => point['% impact metric']);
        }
        const maxPoint = Math.max(...pointValues);
        // const minPoint = points.reduce(minReducer, Infinity); //no case yet where we do not have zero
        const numberOfTicks = (maxPoint - 0) / minInterval;
        ticks = Array.from({ length: numberOfTicks + 1 }, (item, index) => index * minInterval);

        return ticks;
    };

    function CustomizedAxisTick({ x, y, stroke, payload }) {
        return (
            <g transform={`translate(${x},${y})`}>
                <text
                    x={0}
                    y={0}
                    dy={16}
                    textAnchor="start"
                    fill="#666"
                    transform={payload.value === '0' ? 'rotate(0)' : 'rotate(45)'}
                    className="spg-text"
                >
                    {payload.value}
                </text>
            </g>
        );
    }

    return (
        <Card hasBorder hasRoundedCorner>
            {displayHeader ? (
                <HeadingSm>{displayHeader}</HeadingSm>
            ) : (
                <TextBodyLg>No Data to Display</TextBodyLg>
            )}
            {dataPoints && dataTicks && activeImpactPathways && activeImpactPathways.length > 0 ? (
                <ResponsiveContainer
                    minHeight={260}
                    minWidth={minWidth}
                    className="cli-vulnerability-graph"
                >
                    <AreaChart
                        data={dataPoints}
                        margin={{
                            top: 20,
                            right: 40,
                            bottom: 40,
                            left: 40,
                        }}
                        type="monotone"
                        className="spg-text"
                    >
                        <CartesianGrid strokeDasharray="3 3" horizontal={false} />
                        <XAxis
                            dataKey="% impact metric"
                            type={
                                dataPointsInterval < 5 || dataPointsInterval === 500
                                    ? 'category'
                                    : 'number'
                            } // surprising here, as issues arise for Carbon Price domain
                            label={{
                                value: dataToGraph.xAxisLabel,
                                offset: 2,
                                position: 'bottom',
                                wordWrap: true,
                                width: 240,
                                fontSize: 12,
                            }}
                            interval={0}
                            ticks={dataTicks}
                            tick={<CustomizedAxisTick />}
                        />
                        <YAxis
                            domain={['dataMin', 'dataMax']}
                            // tickFormatter handles more precise dataPoints as needed
                            tickFormatter={(tick) =>
                                tick.toString().length < 5 ? tick : tick.toFixed(2)
                            }
                            label={{
                                value: dataToGraph.yAxisLabel || dataToGraph.yUnits || '',
                                angle: -90,
                                position: 'insideBottomLeft',
                                offset: 1,
                            }}
                        />

                        {activeImpactPathways.map((impactPathway) => (
                            <Area
                                dataKey={impactPathway.name}
                                fill={impactPathway.fill}
                                fillOpacity="1"
                                dot={{ stroke: 'none', fill: 'rgba(0,0,0,0.45)' }}
                                activeDot={false}
                                stroke={
                                    activeImpactPathways.length === 1 &&
                                    dataPoints
                                        .map((point) => point[impactPathway.name])
                                        .flat()
                                        .every((value) => value === 100)
                                        ? 'grey'
                                        : 'none'
                                }
                                key={`vulnerability-graph-${impactPathway.name}`}
                                unit={dataToGraph.yUnits}
                            />
                        ))}
                        <Tooltip
                            cursor={{ strokeDasharray: '3 3' }}
                            coordinate={{ x: 20, y: -150 }}
                            content={renderTooltipContent}
                        />
                    </AreaChart>
                </ResponsiveContainer>
            ) : (
                <ParagraphLg>Please select an Impact Pathway below to display graph.</ParagraphLg>
            )}
            <CheckboxList
                listItems={impactPathwayNames}
                checkedItems={activeImpactPathways}
                handleToggle={handleActivePathwaysToggle}
            />
        </Card>
    );
}
