import axios from 'axios';

const httpClient = axios.create();
const oktaStorage = JSON.parse(localStorage.getItem('okta-token-storage'));

httpClient.interceptors.request.use((config) => {
    const interceptedConfig = {
        ...config,
        headers: {
            ...config.headers,
            Authorization: oktaStorage?.idToken?.idToken,
        },
    };
    return interceptedConfig;
});

export { httpClient };
