import { httpClient } from '../utils/httpClient';
import { API_URL } from '../utils/constants';

const ENDPOINT = 'api/customer';

const getRealAssetsCachedRisks = async (
    customerId,
    {
        riskDataFilters,
        viewType,
        limit = 20,
        offset = 0,
        order = 'desc',
        order_by = 'relative_value',
        search_value,
        search_type,
        folder_id,
        view_id,
        asset_id,
        updateTableOnly = false,
    }
) => {
        const { riskFactor, year, scenario, analysisType } = riskDataFilters;
        let params = {
            decade: year,
            scenario_level: scenario,
            risk_filter: `[${[...riskFactor[analysisType].map((factor) => `'${factor}'`)]}]`,
            record_display_type: viewType,
            limit,
            offset,
            order,
            order_by,
            chart_data_required: updateTableOnly ? 'False' : 'True',
            graph_data_required: updateTableOnly ? 'False' : 'True',
        };
        if (search_value && search_type) params = { ...params, search_value, search_type };
        if (folder_id) params.folder_id = folder_id;
        if (view_id) params.view_id = view_id;
        if (asset_id) params.asset_id = asset_id;
        const result = await httpClient.get(`${API_URL}/${ENDPOINT}/${customerId}/aggregatedRisks`, {
            params: { ...params },
        });
        return result?.data;
};

const reaggregateFolder = async (customerId, { aggregate_manual, folder_id, flag_update }) => {
    const params = {
        folder_id,
        aggregate_manual: aggregate_manual ? 'True' : 'False',
        flag_update: flag_update ? 'True' : 'False',
    };
    if (folder_id) params.folder_id = folder_id;
    const result = await httpClient.get(`${API_URL}/${ENDPOINT}/${customerId}/aggregatedRisks`, {
        params: { ...params },
    });
    return result?.data;
};

export default { getRealAssetsCachedRisks, reaggregateFolder };
