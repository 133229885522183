import React, { useEffect, useState, useCallback } from 'react';
import { useForm, Controller } from 'react-hook-form';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { Button, FormGroup, InputField, Select, TextArea } from '@spglobal/react-components';
import { DatePicker } from '@spglobal/koi-datepicker';
import { Purpose } from '@spglobal/koi-helpers';
import { ErrorMessage } from '@hookform/error-message';
import { subscriberRoleMap } from 'utils/constants';
import { ErrorTextBodySm, HeadingMd } from '../../shared';
import rbacAdminService from '../../../services/rbacAdminService';

export default function CustomerForm({ defaultValues, action, onSubmit }) {
    const history = useHistory();
    const {
        resetField,
        setError,
        setValue,
        clearErrors,
        watch,
        formState: { errors },
        control,
        handleSubmit,
    } = useForm({ defaultValues });

    const watchFields = watch(['subscriptionStartDate', 'subscriptionEndDate', 'subscriberType']);

    const validateSubscriptionDates = (startDate, endDate) => {
        const start = new Date(startDate);
        const end = new Date(endDate);
        if (watchFields[2] !== 'demo' && moment(start).isAfter(end)) {
            setError('subscriptionEndDateLabel', {
                type: 'custom',
                message: 'Subscription End Date cannot be less than Subscription Start Date',
            });
        } else {
            clearErrors('subscriptionEndDateLabel');
            clearErrors('subscriptionStartDateLabel');
        }
    };

    const [selectedSubscriberType, setSelectedSubscriberType] = useState(defaultValues?.subscriberType || '');

    const handleSubscriberTypeChange = (newValue) => {
        setSelectedSubscriberType(newValue);
        if (defaultValues?.subscriberType !== newValue) {
            resetField('subscriptionStartDate');
            resetField('subscriptionEndDate');
            resetField('noOfAssetsSubscribed');
            if (action === 'edit' && newValue === 'demo') {
                setValue('subscriptionStartDate', null);
                setValue('subscriptionEndDate', null);
                setValue('noOfAssetsSubscribed', null);
            }
            clearErrors('subscriptionEndDateLabel');
            clearErrors('subscriptionStartDateLabel');
        }
    };

    const [customerAccountManagerData, setCustomerAccountManagerData] = useState([]);

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const reqData = {
                    user_type: 'internal',
                    list: false,
                };
                const users = await rbacAdminService.getAllUsers(reqData);
                const formattedUsers = users.map(user => ({
                    label: user.userName,
                    value: user.uid,
                    roles: user.role
                }));
                setCustomerAccountManagerData(formattedUsers);
            } catch (error) {
                console.error('Error fetching users:', error);
            }
        };
        fetchUsers();
    }, []);

    const customerSubscriberTypeData = [
        { id: 'active', value: 'active', label: 'Active' },
        { id: 'demo', value: 'demo', label: 'Demo' },
        { id: 'trial', value: 'trial', label: 'Trial' },
        { id: 'inactive', value: 'inactive', label: 'Inactive' },
    ];

    const selectedSubTypeId = defaultValues && defaultValues.subscriberType;
    let selectedSubTypeObj;
    if (selectedSubTypeId) {
        selectedSubTypeObj = customerSubscriberTypeData.find(subType => subType.id === selectedSubTypeId);
    }

    const filterAccountManagers = useCallback(() => {
        let filteredManagers = customerAccountManagerData;
        if (selectedSubscriberType !== 'demo') {
            const relevantRoles = subscriberRoleMap[selectedSubscriberType] || [];
            filteredManagers = customerAccountManagerData.filter(manager => 
                manager.roles.some(role => relevantRoles.includes(role))
            );
        }
        return filteredManagers.sort((a, b) => a.label.localeCompare(b.label));
    }, [selectedSubscriberType, customerAccountManagerData]);

    const accountManagerOptions = filterAccountManagers();

    const [selectedAccountManager, setSelectedAccountManager] = useState(null);
    useEffect(() => {
        if (defaultValues.accountManager) {
            const manager = accountManagerOptions.find(option => option.label === defaultValues.accountManager);
            if (manager) {
                setSelectedAccountManager(manager);
                setValue('accountManager', manager.label);
                setValue('accountManagerEmail', manager.value);
            }
        }
    }, [defaultValues.accountManager, accountManagerOptions, setValue]);

    
    return (
        <form noValidate="noValidate" onSubmit={handleSubmit(onSubmit)}>
            <HeadingMd>Customer Details</HeadingMd>
            <div className="spg-row">
                <div className="spg-col-12">
                    <FormGroup label="Customer Name" required>
                        <Controller
                            render={({ field }) => <InputField {...field} />}
                            control={control}
                            name="customerName"
                            defaultValue=""
                            rules={{
                                required: { value: true, message: 'Customer Name is required' },
                                maxLength: {
                                    value: 100,
                                    message: 'Customer Name exceed max length.',
                                },
                            }}
                        />
                        <ErrorMessage
                            errors={errors}
                            name="customerName"
                            render={({ message }) => <ErrorTextBodySm>{message}</ErrorTextBodySm>}
                        />
                    </FormGroup>
                </div>
            </div>
            <div className="spg-row">
                <div className="spg-col-4">
                    <FormGroup label="Address" required>
                        <Controller
                            render={({ field }) => <InputField {...field} customId="address" />}
                            name="address"
                            control={control}
                            defaultValue=""
                            rules={{
                                required: { value: true, message: 'Address is required.' },
                                maxLength: {
                                    value: 100,
                                    message: 'Address exceeds max length.',
                                },
                            }}
                        />
                        <ErrorMessage
                            errors={errors}
                            name="address"
                            render={({ message }) => <ErrorTextBodySm>{message}</ErrorTextBodySm>}
                        />
                    </FormGroup>
                </div>
                <div className="spg-col-4">
                    <FormGroup label="State/Province">
                        <Controller
                            name="stateProvince"
                            control={control}
                            render={({ field }) => (
                                <InputField {...field} customId="state-province" />
                            )}
                        />
                    </FormGroup>
                </div>
                <div className="spg-col-4">
                    <FormGroup label="Country">
                        <Controller
                            name="country"
                            control={control}
                            render={({ field }) => <InputField {...field} customId="country" />}
                        />
                    </FormGroup>
                </div>
            </div>
            <div className="spg-row">
                <div className="spg-col-6">
                    <FormGroup label="Point Of Contact" required>
                        <Controller
                            render={({ field }) => (
                                <InputField {...field} customId="point-of-contact" />
                            )}
                            name="pointOfContact"
                            control={control}
                            defaultValue=""
                            rules={{
                                required: { value: true, message: 'Point Of Contact is required' },
                            }}
                        />
                        <ErrorMessage
                            errors={errors}
                            name="pointOfContact"
                            render={({ message }) => <ErrorTextBodySm>{message}</ErrorTextBodySm>}
                        />
                    </FormGroup>
                </div>
                <div className="spg-col-6">
                    <FormGroup label="Title/Department">
                        <Controller
                            render={({ field }) => (
                                <InputField {...field} customId="title-department" />
                            )}
                            name="titleDepartment"
                            control={control}
                        />
                    </FormGroup>
                </div>
            </div>
            <div className="spg-row">
                <div className="spg-col-12">
                    <FormGroup label="Point Of Contact Email" required>
                        <Controller
                            render={({ field }) => (
                                <InputField {...field} customId="point-of-contact-email" />
                            )}
                            name="pointOfContactEmail"
                            control={control}
                            defaultValue=""
                            rules={{
                                required: {
                                    value: true,
                                    message: 'Point Of Contact Email is required',
                                },
                                pattern: {
                                    value: /\S+@\S+\.\S+/,
                                    message: 'Entered value does not match email format',
                                },
                            }}
                        />
                        <ErrorMessage
                            errors={errors}
                            name="pointOfContactEmail"
                            render={({ message }) => <ErrorTextBodySm>{message}</ErrorTextBodySm>}
                        />
                    </FormGroup>
                </div>
            </div>

            <HeadingMd>Subscription Details</HeadingMd>

            <div className="spg-row">
                <div className="spg-col-4">
                    <FormGroup label="Subscriber Type" id="subscriber-type-select" required>
                        <Controller
                            render={() => (
                                <Select
                                    options={customerSubscriberTypeData}
                                    onChange={(selectedValue) => {
                                        setValue('subscriberType', selectedValue[0].value);
                                        handleSubscriberTypeChange(selectedValue[0].value);
                                    }}
                                    isMulti={false}
                                    closeOnSelection
                                    defaultValue={
                                        selectedSubTypeObj ? [selectedSubTypeObj] : undefined
                                    }
                                />
                            )}
                            control={control}
                            name="subscriberType"
                            rules={{
                                required: { value: true, message: 'Subscriber Type is required' },
                            }}
                        />
                        <ErrorMessage
                            errors={errors}
                            name="subscriberType"
                            render={({ message }) => <ErrorTextBodySm>{message}</ErrorTextBodySm>}
                        />
                    </FormGroup>
                </div>
                <div className="spg-col-4">
                    <FormGroup label="Subscription Start Date" required>
                        <Controller
                            render={({ field }) => (
                                <DatePicker
                                    disabled={watchFields[2] === 'demo'}
                                    value={field.value}
                                    onChange={(newValue) => {
                                        setValue('subscriptionStartDate', newValue);
                                        validateSubscriptionDates(newValue, watchFields[1]);
                                    }}
                                    format="MM/DD/YYYY"
                                    placeholder="mm/dd/yyyy"
                                    allowClear
                                />
                            )}
                            name="subscriptionStartDate"
                            control={control}
                            rules={{
                                required: {
                                    value: watchFields[2] !== 'demo',
                                    message: 'Subscription Start Date is required',
                                },
                            }}
                        />
                        <ErrorMessage
                            errors={errors}
                            name="subscriptionStartDate"
                            render={({ message }) => <ErrorTextBodySm>{message}</ErrorTextBodySm>}
                        />
                    </FormGroup>
                </div>
                <div className="spg-col-4">
                    <FormGroup label="Subscription End Date" required>
                        <Controller
                            render={({ field }) => (
                                <DatePicker
                                    disabled={watchFields[2] === 'demo'}
                                    value={field.value}
                                    onChange={(newValue) => {
                                        setValue('subscriptionEndDate', newValue);
                                        validateSubscriptionDates(watchFields[0], newValue);
                                    }}
                                    format="MM/DD/YYYY"
                                    placeholder="mm/dd/yyyy"
                                    allowClear
                                />
                            )}
                            name="subscriptionEndDate"
                            control={control}
                            rules={{
                                required: {
                                    value: watchFields[2] !== 'demo',
                                    message: 'Subscription End Date is required',
                                },
                            }}
                        />
                        <ErrorMessage
                            errors={errors}
                            name="subscriptionEndDateLabel"
                            render={({ message }) => <ErrorTextBodySm>{message}</ErrorTextBodySm>}
                        />
                        <ErrorMessage
                            errors={errors}
                            name="subscriptionEndDate"
                            render={({ message }) => <ErrorTextBodySm>{message}</ErrorTextBodySm>}
                        />
                    </FormGroup>
                </div>
            </div>
            <div className="spg-row">
                <div className="spg-col-6">
                    <FormGroup label="Number Of Assets Subscribed" required>
                        <Controller
                            name="noOfAssetsSubscribed"
                            control={control}
                            render={({ field }) => (
                                <InputField
                                    {...field}
                                    customId="no-of-assets-subscribed"
                                    error={Boolean(errors.noOfAssetsSubscribed)}
                                    type="number"
                                    disabled={watchFields[2] === 'demo'}
                                />
                            )}
                            rules={{
                                required: {
                                    value: watchFields[2] !== 'demo',
                                    message: 'Number Of Assets Subscribed is required',
                                },
                                min: {
                                    value: 0,
                                    message: 'Number Of Assets Subscribed cannot be negative',
                                },
                            }}
                        />
                        <ErrorMessage
                            errors={errors}
                            name="noOfAssetsSubscribed"
                            render={({ message }) => <ErrorTextBodySm>{message}</ErrorTextBodySm>}
                        />
                    </FormGroup>
                </div>
                <div className="spg-col-6">
                    <FormGroup label="Account Manager" required>
                        <Controller
                            rules={{
                                required: { value: true, message: 'Account Manager is required' },
                            }}
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    options={accountManagerOptions}
                                    onChange={(selectedOption) => {
                                        setValue('accountManager', selectedOption[0]?.label);
                                        setValue('accountManagerEmail', selectedOption[0]?.value);
                                    }}
                                    closeOnSelection
                                    isMulti={false}
                                    defaultValue={selectedAccountManager ? [selectedAccountManager] : undefined}
                                />
                            )}
                            control={control}
                            name="accountManager"
                        />
                        <ErrorMessage
                            errors={errors}
                            name="accountManager"
                            render={({ message }) => <ErrorTextBodySm>{message}</ErrorTextBodySm>}
                        />
                    </FormGroup>
                </div>
            </div>

            <div className="spg-row">
                <div className="spg-col-12">
                    <FormGroup label="Notes">
                        <Controller
                            name="notes"
                            control={control}
                            render={({ field }) => (
                                <TextArea
                                    {...field}
                                    customId="notes"
                                    rows={4}
                                    className="spg-w-100"
                                />
                            )}
                            rules={{
                                maxLength: {
                                    value: 500,
                                    message: 'Note exceed max length.',
                                },
                            }}
                        />
                        <ErrorMessage
                            errors={errors}
                            name="notes"
                            render={({ message }) => <ErrorTextBodySm>{message}</ErrorTextBodySm>}
                        />
                    </FormGroup>
                </div>
            </div>

            <div className="spg-d-flex spg-justify-end spg-mt-md">
                <Button type="submit" purpose={Purpose.PRIMARY}>
                    Submit
                </Button>
                <Button
                    onClick={() => history.push('/manageCustomer')}
                    purpose={Purpose.SECONDARY}
                    className="spg-ml-sm"
                >
                    Cancel
                </Button>
            </div>
        </form>
    );
}
