import React, { useEffect } from 'react';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { Checkbox, Icon } from '@spglobal/react-components';
import { CHARTING_LINE } from '@spglobal/koi-icons';

export default function CheckboxList({ listItems, checkedItems, handleToggle }) {
    const [checked, setChecked] = React.useState([]);

    useEffect(() => {
        if (checkedItems) {
            setChecked(checkedItems);
        }
    }, [checkedItems]);

    return (
        <>
            {listItems &&
                listItems.length > 0 &&
                listItems.map((value, index) => {
                    const labelId = `checkbox-list-label-${index}`;

                    return (
                        <div
                            key={value.name}
                            className="spg-d-flex spg-justify-between spg-mt-md spg-mb-md spg-ml-xl spg-mr-xl"
                        >
                            <Checkbox
                                onChange={handleToggle(value)}
                                checked={
                                    checked.findIndex(
                                        (checkedItem) => checkedItem.name === value.name,
                                    ) !== -1
                                }
                                tabIndex={-1}
                                aria-labelledby={labelId}
                                label={value.name}
                            />
                            {value.key === 'total' ? (
                                <Icon
                                    icon={CHARTING_LINE}
                                    color={`${value.fill}`}
                                    className="spg-d-flex spg-align-center"
                                />
                            ) : (
                                <Icon
                                    icon={faCircle}
                                    color={`${value.fill}`}
                                    className="spg-d-flex spg-align-center"
                                />
                            )}
                        </div>
                    );
                })}
        </>
    );
}
