/* eslint-disable no-restricted-globals */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    ResponsiveContainer,
    Tooltip,
    Legend,
    XAxis,
    YAxis,
    CartesianGrid,
    LineChart,
    Line,
} from 'recharts';
import formatCurrency from 'utils/formatCurrency';
import convertToUSDMillion from 'utils/convertToUSDMillion';
import {
    Card,
    Spinner,
    TooltipTriggerEvent,
    Tooltip as KoiTooltip,
    IconSize,
    TooltipPlacement,
} from '@spglobal/react-components';
import { CIRCLE_INFO_O } from '@spglobal/koi-icons';
import { HeadingLg, HeadingXl, TextBodyLg, TextBodySm } from '../shared';
import { BlueIcon } from '../shared/InfoIcon/InfoIcon';
import { DEFAULT_SPINNER_SIZE } from '../components.constants';

const MAAL_HELPER_TEXT =
    'Modeled Average Annual Loss is the sum of climate-related expenses, decreased revenue, and/or business interruption, and is represented as the percentage (or amount) of loss relative to total asset value. It is reported annually for each decadal period. Risk values are calculated based on the CMIP-6 hazard modeling.';
const MAAG_HELPER_TEXT =
    'Modeled Average Annual Gain is the sum of climate-related financial benefits and is represented as the percentage (or amount) of gain relative to the total asset value. It is reported annually for each decadal period.';

const CustomTooltip = ({ active, payload, precision }) => {
    if (active && payload?.[0]?.payload?.y1 && payload?.[0]?.payload?.y) {
        const toolTipLabel =
            payload[0].name === 'Risk' ? 'Yearly Avg. at Risk' : 'Average Annual Gain';

        const toolTipAbsoluteValue = formatCurrency(payload[0].payload.y1, precision);

        const toolTipRelativeValue = `${payload[0].payload.y.toFixed(precision)}%`;

        return (
            <div className="custom-tooltip">
                <span className="y-label">
                    {toolTipLabel}: {toolTipAbsoluteValue}
                    {' ('}
                    {toolTipRelativeValue}
                    {')'}
                </span>
                <br />
                <span className="x-label">Decade begins: {payload[0].payload.x}</span>
            </div>
        );
    }

    return null;
};

// eslint-disable-next-line react/display-name
const ModeledAverageTooltip = React.forwardRef(({ analysisType }, ref) => {
    // eslint-disable-next-line react/display-name
    const triggerElement = (
        <BlueIcon size={IconSize.XSMALL} icon={CIRCLE_INFO_O} className="spg-mb-sm spg-mr-sm" />
    );
    return (
        <KoiTooltip
            ref={ref}
            triggerElement={triggerElement}
            triggerEvent={TooltipTriggerEvent.CLICK}
            width="30vw"
            closeDelay={5000}
            isSecondary
            contentPadding={0}
            placement={TooltipPlacement.BOTTOM}
        >
            <TextBodySm className="spg-p-md">
                {analysisType === 1 ? MAAL_HELPER_TEXT : MAAG_HELPER_TEXT}
            </TextBodySm>
        </KoiTooltip>
    );
});

export default function RiskGraph({
    riskGraphData,
    riskDataFilters,
    handleFilterChange,
    entityId,
    entityType,
}) {
    const [dataToGraph, setDataToGraph] = useState({ dataPoints: null });
    const [dataPoints, setDataPoints] = useState([{ x: 2020, y: 0 }]);
    const [percentForColor, setPercentForColor] = useState(1);
    const [displayTotal, setDisplayTotal] = useState(0);
    const [displayPercentTotal, setDisplayPercentTotal] = useState(0);

    useEffect(() => {
        setDataToGraph(riskGraphData);
    }, [riskGraphData, riskDataFilters, entityId, entityType]);

    useEffect(() => {
        if (dataToGraph && dataToGraph.absoluteValues?.length) {
            const combinedDataPoints = dataToGraph.relativeValues.map((relValue, index) => {
                const combinedValues = {};
                combinedValues.x = relValue.x;
                combinedValues.y = relValue.y;
                combinedValues.y1 = convertToUSDMillion(dataToGraph.absoluteValues[index].y);
                return combinedValues;
            });

            setDataPoints(combinedDataPoints.filter((point) => point.x !== 0));

            const yearString = riskDataFilters.year;
            const absoluteTotal = dataToGraph.absoluteValues.find(
                (element) => element.x === yearString,
            )?.y;
            setDisplayTotal(absoluteTotal);
            const relativeTotal = dataToGraph.relativeValues.find(
                (element) => element.x === yearString,
            )?.y;
            // check for NaN on initial load
            setDisplayPercentTotal(isNaN(relativeTotal) ? 0 : relativeTotal);

            const graphLinePercentage = {
                2020: 0,
                2030: 14,
                2040: 28,
                2050: 42,
                2060: 56,
                2070: 70,
                2080: 84,
                2090: 98,
            };

            setPercentForColor(graphLinePercentage[yearString]);
        }
    }, [riskDataFilters, dataToGraph]);

    const updateReChartsLine = () => {
        const reChartsLines = document.getElementsByClassName('recharts-line-curve');
        if (reChartsLines.length > 0) {
            const reChartsLine = document.getElementsByClassName('recharts-line-curve')[0];
            const reChartsLinePath = reChartsLine.getAttribute('d');
            if (reChartsLinePath && reChartsLinePath.length > 0) {
                reChartsLine.setAttribute(
                    'd',
                    `${reChartsLinePath.slice(0, reChartsLinePath.length - 1)}0`,
                );
            }
        }
    };

    if (riskDataFilters.riskFactor[1].length === 0 && riskDataFilters.riskFactor[2].length === 0) {
        return (
            <Card hasBorder hasRoundedCorner className="spg-mb-md">
                <TextBodyLg id="riskGraphRisksUnavailable">
                    There are currently no
                    {riskDataFilters.analysisType === 1 ? ' risks ' : ' opportunities '}
                    to display on the graph.
                </TextBodyLg>
            </Card>
        );
    }

    return dataToGraph?.absoluteValues?.length >= 0 ? (
        <Card hasBorder hasRoundedCorner className="spg-mb-md">
            <div>
                <div>
                    <div aria-label="Risk totals" id="riskGraphTable">
                        <div key="TotalsR1">
                            {riskDataFilters && riskDataFilters.year && (
                                <div>
                                    {`Modeled Average Annual ${
                                        riskDataFilters.analysisType === 1 ? 'Loss' : 'Gain'
                                    }`}{' '}
                                    <ModeledAverageTooltip
                                        analysisType={riskDataFilters.analysisType}
                                    />
                                    {`over ${riskDataFilters.year}-${riskDataFilters.year + 9}`}
                                </div>
                            )}
                        </div>
                        <div key="TotalsR2">
                            {riskDataFilters && (
                                <div className="spg-mt-sm">
                                    <HeadingXl>
                                        {displayPercentTotal &&
                                            `${displayPercentTotal.toFixed(
                                                riskDataFilters.riskValuePrecision,
                                            )}`}
                                        %
                                    </HeadingXl>
                                </div>
                            )}
                        </div>
                        <div key="TotalsR3">
                            {riskDataFilters && (
                                <div className="spg-mt-sm">
                                    <HeadingLg>
                                        {(displayTotal &&
                                            formatCurrency(
                                                convertToUSDMillion(displayTotal),
                                                riskDataFilters.riskValuePrecision,
                                            )) ||
                                            0}
                                    </HeadingLg>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div>
                    {dataPoints && riskDataFilters && riskDataFilters.year && (
                        <ResponsiveContainer width="100%" minHeight={300}>
                            <LineChart
                                className="spg-mb-md"
                                margin={
                                    Math.trunc(dataPoints[dataPoints.length - 1].y1).toString()
                                        .length > 5
                                        ? { top: 20, left: 45, right: 15 }
                                        : { top: 20, left: 30, right: 15 }
                                }
                                data={dataPoints}
                                onClick={(e) => {
                                    if (e) {
                                        handleFilterChange([
                                            {
                                                year: parseInt(e.activeLabel, 10),
                                            },
                                        ]);
                                    }
                                }}
                            >
                                <XAxis
                                    domain={[2020, 2090]}
                                    type="number"
                                    scale="time"
                                    dataKey="x"
                                    name="year"
                                    padding={{ left: 10, right: 10 }}
                                    ticks={[2020, 2030, 2040, 2050, 2060, 2070, 2080, 2090]}
                                />
                                <YAxis
                                    domain={[
                                        (dataMin) => Number(dataMin).toFixed(1),
                                        (dataMax) => Number(dataMax).toFixed(1),
                                    ]}
                                    type="number"
                                    yAxisId="left"
                                    tickCount={6}
                                    tickFormatter={(tick) => `$${tick.toLocaleString()}m`}
                                    dataKey="y1"
                                    orientation="left"
                                    padding={{ bottom: 15 }}
                                />
                                <YAxis
                                    domain={[
                                        (dataMin) => Number(dataMin).toFixed(3),
                                        (dataMax) => Number(dataMax).toFixed(3),
                                    ]}
                                    tickCount={6}
                                    type="number"
                                    unit="%"
                                    yAxisId="right"
                                    dataKey="y"
                                    orientation="right"
                                    padding={{ bottom: 15 }}
                                />

                                <CartesianGrid strokeDasharray="3 3" vertical={false} />

                                <Tooltip
                                    cursor={{ strokeDasharray: '3 3' }}
                                    content={
                                        <CustomTooltip
                                            precision={riskDataFilters.riskValuePrecision}
                                        />
                                    }
                                />
                                <defs>
                                    <linearGradient id="colorUv" x1="0%" y1="0" x2="100%" y2="0">
                                        <stop offset="0%" stopColor="grey" />
                                        <stop offset={`${percentForColor}%`} stopColor="grey" />
                                        <stop offset={`${percentForColor}%`} stopColor="#2085FC" />
                                        <stop
                                            offset={`${
                                                percentForColor + 100 / dataPoints.length + 2
                                            }%`}
                                            stopColor="#2085FC"
                                        />
                                        <stop
                                            offset={`${
                                                percentForColor + 100 / dataPoints.length + 2
                                            }%`}
                                            stopColor="grey"
                                        />
                                        <stop offset={`${100}%`} stopColor="grey" />
                                    </linearGradient>
                                </defs>

                                <Legend />

                                <Line
                                    dot={false}
                                    yAxisId="right"
                                    type="monotone"
                                    dataKey="y"
                                    stroke="url(#colorUv)"
                                    strokeWidth={3}
                                    activeDot={{
                                        r: 2,
                                        stroke: '#2085FC',
                                        fill: '#2085FC',
                                    }}
                                    unit="%"
                                    onAnimationEnd={updateReChartsLine}
                                    name={
                                        riskDataFilters.analysisType === 1 ? 'Risk' : 'Opportunity'
                                    }
                                />
                            </LineChart>
                        </ResponsiveContainer>
                    )}
                </div>
            </div>
        </Card>
    ) : (
        <Spinner size={DEFAULT_SPINNER_SIZE} />
    );
}

RiskGraph.propTypes = {
    entityId: PropTypes.string,
    entityType: PropTypes.number,
    handleFilterChange: PropTypes.func,
    investmentFolderId: PropTypes.number,
    riskDataFilters: PropTypes.object,
};

CustomTooltip.propTypes = {
    active: PropTypes.bool,
    payload: PropTypes.array,
    precision: PropTypes.number,
};
