import { API_URL } from '../utils/constants';
import { httpClient } from '../utils/httpClient';

const ENDPOINT = 'api/users';
const ENDPOINT_CUST_TO_USER_MAPPING = 'api/userToCustomerMapping';
const ENDPOINT_USER_TO_FOLDER_VIEW_MAPP = 'api/';

const getAllUsers = async (params) => {
    try {
        const reParam = {};
        if (params.user_type) reParam.user_type = params.user_type;
        if (Object.keys(params).includes('list')) reParam.list = params.list;

        const result = await httpClient.get(`${API_URL}/${ENDPOINT}`, { params: reParam });
        return result.data && Array.isArray(result.data) ? result.data : [];
    } catch (reason) {
        return [];
    }
};

const assignCustomerToUser = async (params) => {
    try {
        const param = {
            user_name: params.user_name,

            user_email: params.user_email,

            customer_ids: params.customer_ids,

            role: params.role,
        };
        const result = await httpClient.post(`${API_URL}/${ENDPOINT_CUST_TO_USER_MAPPING}`, param);

        return result.data;
    } catch (reason) {
        return { error: reason.response.data.message };
    }
};

const reAssignmentCustomerToUser = async (params) => {
    try {
        const param = {
            user_name: params.user_name,

            user_email: params.user_email,

            customer_ids: params.customer_ids,

            role: params.role,
        };

        const result = await httpClient.put(`${API_URL}/${ENDPOINT_CUST_TO_USER_MAPPING}`, param);
        return result.data;
    } catch (reason) {
        return { error: reason.response.data.message };
    }
};

const userToFolderViewMapping = async (params) => {
    try {
        const { customerId } = params;

        const param = {
            user_name: params.user_name,

            user_email: params.user_email,

            folder_ids: params.folder_ids,

            view_ids: params.view_ids,

            role: params.role,
        };
        const result = await httpClient.post(
            `${API_URL}/${ENDPOINT_USER_TO_FOLDER_VIEW_MAPP}customer/${customerId}/userToFolderViewMapping`,
            param
        );
        return result.data;
    } catch (reason) {
        return { error: 'Something went wrong, Try again!' };
    }
};

const editUserToFolderViewMapping = async (params) => {
    try {
        const { customerId } = params;

        const param = {
            user_name: params.user_name,

            user_email: params.user_email,

            folder_ids: params.folder_ids,

            view_ids: params.view_ids,

            role: params.role,
        };
        const result = await httpClient.put(
            `${API_URL}/${ENDPOINT_USER_TO_FOLDER_VIEW_MAPP}customer/${customerId}/userToFolderViewMapping`,
            param
        );
        return result.data;
    } catch (reason) {
        return { status: 500, message: 'Something went wrong, Try again!' };
    }
};

const getAllClientUsers = async (params) => {
    const reParam = {};
    if (params.includeAll) reParam.includeAll = params.includeAll;
    if (params.limit) reParam.limit = params.limit;
    if (params.offset) reParam.offset = params.offset;
    if (params.list) reParam.list = params.list;
    if (params.external) reParam.external = params.external;

    const customerId = params && params.customerId ? params.customerId : '';
    const result = await httpClient.get(`${API_URL}/api/customer/${customerId}/users`, {
        params: reParam,
    });
    return result.data && result.data.result ? result.data.result : [];
};

export default {
    getAllUsers,
    assignCustomerToUser,
    reAssignmentCustomerToUser,
    getAllClientUsers,
    userToFolderViewMapping,
    editUserToFolderViewMapping,
};
