import * as React from 'react';

import { useHistory } from 'react-router-dom';
import { Button, Icon, IconButton, InputField, Select } from '@spglobal/react-components';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { Purpose, Size } from '@spglobal/koi-helpers';
import { SEARCH } from '@spglobal/koi-icons';
import useGetPermission from '../../hooks/useGetPermission';
import UserContext from '../../context/UserContext';

const searchTypeOptions = [
    {
        id: 'user_name',
        value: 'user_name',
        label: 'User Name',
    },
    {
        id: 'file_name',
        value: 'file_name',
        label: 'File Name',
    },
];

const AllAssetsHeader = (props) => {
    const [searchType, setSearchType] = React.useState(searchTypeOptions[0].value);
    const [searchText, setSearchText] = React.useState('');
    const { user, isLoggedIn } = React.useContext(UserContext);
    const userAccess =
        user && Object.keys(user).length > 0
            ? useGetPermission(user, isLoggedIn, 'ProcessingStatusDashboard')
            : '';

    const history = useHistory();
    const handleSearchTypeChange = (selectedValue) => {
        setSearchType(selectedValue.value);
    };

    const onSearchChange = async (event) => {
        const searchVal = event?.target?.value;
        setSearchText(searchVal);
        if (!searchVal) {
            const result = await props.getUploadProcessingStatus(0, 10);
            props.setSearchDataState({
                searchtype: searchType,
                searchText: searchVal || '',
            });
            props.updateTableState(result);
        }
    };

    const handleSubmit = async () => {
        if (searchText) {
            const result = await props.getUploadProcessingStatus(0, 10, null, {
                searchText,
                searchtype: searchType,
            });
            props.setSearchDataState({ searchtype: searchType, searchText });
            props.updateTableState(result);
        }
    };

    const getPlaceHolder = (text) => {
        const searchTitle = searchTypeOptions.filter((item) => item.value === text);
        return searchTitle.length ? searchTitle[0].label : 'User Name';
    };

    const searchButton = (
        <IconButton icon={SEARCH} size={Size.SMALL} onClick={() => handleSubmit(searchText)} />
    );

    return (
        <div>
            <div className="spg-row">
                <div className="spg-col-9 spg-d-flex">
                    <Select
                        onChange={(selectedValues) => handleSearchTypeChange(selectedValues[0])}
                        options={searchTypeOptions}
                        defaultValue={[searchTypeOptions[0]]}
                        isMulti={false}
                        isSearchable={false}
                        closeOnSelection
                    />
                    <InputField
                        className="spg-ml-md"
                        placeholder={`Search by ${getPlaceHolder(searchType)}`}
                        value={searchText}
                        onChange={onSearchChange}
                        onKeyDown={(e) => {
                            if (e.keyCode === 13) handleSubmit(searchText);
                        }}
                        rightElement={searchButton}
                    />
                </div>
                {userAccess?.action?.includes('W') ? (
                    <div className="spg-col-3">
                        <Button
                            purpose={Purpose.PRIMARY}
                            leftIcon={<Icon icon={faUpload} />}
                            onClick={() =>
                                history.push({
                                    pathname: `/bulk-import-file/${props.customerId}`,
                                    search: `?${new URLSearchParams({
                                        customerName: props.customerName,
                                    })}`,
                                })
                            }
                        >
                            BULK UPLOAD
                        </Button>
                    </div>
                ) : (
                    <></>
                )}
            </div>
        </div>
    );
};

export default AllAssetsHeader;
