import { httpClient } from '../utils/httpClient';
import { API_URL, MOCK_DATA } from '../utils/constants';

const IMPACT_FUNCTIONS_ENDPOINT = 'api/impactFunctions';
const isMockData = !!(MOCK_DATA && MOCK_DATA == 'ACTIVE');

const getAllImpactFunctions = async () => {
    const result = await httpClient.get(`${API_URL}/${IMPACT_FUNCTIONS_ENDPOINT}?includeAll=true`);
    return result.data;
};

const getAllByHazard = async () => {
    const result = await httpClient.get(
        `${API_URL}/${IMPACT_FUNCTIONS_ENDPOINT}?queryType=hazards`
    );
    return result.data;
};

const getAllByAsset = async (assetId) => {
    const API_SOURCE = API_URL;
    const result = await httpClient.get(`${API_SOURCE}/api/asset/${assetId}/impactFunctions`);
    return result?.data?.results;
};

const getAllByAssetType = async (assetTypeId) => {
    const result = await httpClient.get(
        `${API_URL}/${IMPACT_FUNCTIONS_ENDPOINT}?queryType=assetType&id=${assetTypeId}`
    );
    return result.data;
};

const createImpactFunction = async (newImpactFunction) => {
    const result = await httpClient.post(
        `${API_URL}/${IMPACT_FUNCTIONS_ENDPOINT}`,
        newImpactFunction,
        {
            headers: { 'Content-Type': 'application/json' },
        }
    );
    return result;
};

const editImpactFunction = async (impactFunctionId, updatedImpactFunction) => {
    const result = await httpClient.put(
        `${API_URL}/${IMPACT_FUNCTIONS_ENDPOINT}/${impactFunctionId}`,
        updatedImpactFunction,
        {
            headers: { 'Content-Type': 'application/json' },
        }
    );
    return result;
};

export const getImpactFunction = async (impactFunctionId) => {
    const result = await httpClient.get(
        `${API_URL}/${IMPACT_FUNCTIONS_ENDPOINT}/${impactFunctionId}`,
        {
            headers: { 'Content-Type': 'application/json' },
        }
    );
    return result.data;
};

export default {
    getAllImpactFunctions,
    getAllByHazard,
    getAllByAsset,
    getAllByAssetType,
    createImpactFunction,
    getImpactFunction,
    editImpactFunction,
};
