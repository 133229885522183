import { RBAC_ROLES } from './constants';

//  AS SUGGESTED FROM ASHOKA CHECK THE USER GROUP ON THE GIVEN LIST AND CONSIDER THE FIRST MATCH ROLE
export function getRole(roles = []) {
    const oktaStorage = JSON.parse(localStorage.getItem('okta-token-storage'));
    roles = oktaStorage?.idToken?.claims?.SPGGroups ? oktaStorage.idToken.claims.SPGGroups : [];

    if (!roles) return '';
    for (const role of roles) {
        if (Object.keys(RBAC_ROLES).includes(role)) return role;
    }
    return '';
}

//  AS SUGGESTED FROM ASHOKA CHECK THE USER GROUP ON THE GIVEN LIST AND CONSIDER THE FIRST MATCH ROLE
export function getExistRoleFromList(roles = []) {
    if (!roles) return '';
    for (const role of roles) {
        if (Object.keys(RBAC_ROLES).includes(role)) return role;
    }
    return '';
}

export function getRoleName(role = '') {
    if (!role) return '';
    let roleName = role;
    roleName = roleName.replace('GRP_CLIM_', '');
    roleName = roleName.replace('CLIM_', '');
    return roleName;
}

export function getConcatenatedRoleNames(roles = '') {
    if (!(roles && roles?.length)) return '';

    let filterRoles = [];
    filterRoles = roles
        .filter(function (role) {
            const mappedRole = RBAC_ROLES[role];

            if (mappedRole) {
                return mappedRole;
            }
        })
        .map(function (role) {
            const mappedRole = RBAC_ROLES[role];
            return mappedRole;
        });

    return filterRoles.join(', ');
}
