import client from './clients';

const getAssetValidationErrors = async (data) => {
    const paramsData = {
        import_id: data.importId,
        offset_record: data.offset,
        limit_record: data.rowsPerPage,
    };
    const result = await client.get(
        `/customer/${data.customerId}/file/${data.fileId}/assets/validation`,
        { params: paramsData }
    );
    return result.data;
};
const updateFileProcessingStatus = (data) =>
    client.put('/customer/{id}/file/{fileId}/status', data);

const getUploadPreSignedURL = async (data) => {
    const result = await client.post(`/customer/${data.customer_id}/assets/file`, data);
    return result.data;
};
const getCustomers = async () => {
    const result = await client.get('/customers');
    return result.data;
};

const uploadBulkAssetFile = async (data) => {
    let response = {};
    try {
        response = await client.put(`/customer/${data.customerId}/file/${data.file_id}/status`, {
            status: 'FILE_UPLOAD_COMPLETED',
        });
    } catch (e) {
        throw e;
    }
    return response?.data;
};

const uploadAssetsRequests = {
    getAssetValidationErrors,
    updateFileProcessingStatus,
    getUploadPreSignedURL,
    getCustomers,
    uploadBulkAssetFile,
};

export default uploadAssetsRequests;
