import React, { useState, useEffect, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import pathParser from '../../utils/pathParser';
import AccessTokenContext from '../../context/AccessTokenContext';
import BreadcrumbTrail from '../BreadcrumbTrail';
import MessageBox from '../MessageBox';
import AssetForm from '../AssetForm';
import assetService from '../../services/assetService';
import tagService from '../../services/tagService';
import googleAnalyticsEvent from '../../utils/googleAnalyticsEvent';
import * as constants from '../../utils/constants';
import { useHierarchyContext } from '../../context/HierarchyContext';
import { CONSENT_REQUIRED_ERROR } from '../../utils/constants';

const SUCCESS_MSG = 'Successfully updated asset';
const ERROR_UPDATING_MSG = 'Error updating asset';
const ERROR_FETCHING_DATA_MSG = 'Error fetching necessary data';

export default function EditAsset({ match, entityType }) {
    const history = useHistory();
    const { getTokenAndTryAgain } = useContext(AccessTokenContext);
    const apiOptions = {
        scope: `${constants.CUSTOMER_READ_ASSET} ${constants.CUSTOMER_EDIT_ASSET}`,
    };

    const { pathname } = useLocation();
    const { asset, customer, folder } = useHierarchyContext();
    const newParseData = pathParser.getPathComponents(pathname);
    const { assets: assetId, customers } = newParseData;
    const customerId =
        newParseData && newParseData['real-assets'] ? newParseData['real-assets'] : customers;

    const action = 'edit';
    const [shouldDisplayMessageBox, setShouldDisplayMessageBox] = useState(false);
    const [messageBoxText, setMessageBoxText] = useState('');
    const [assetToEdit, setAssetToEdit] = useState({});
    const [assetFolderId, setAssetFolderId] = useState('');

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await assetService.getAssetById(
                    customerId || undefined,
                    assetId,
                    false,
                );
                const result = response ? response.results : {};
                if (result.locations) {
                    result.locations = (result.locations || []).map((loc) => ({
                        street_address: loc.street_address,
                        elevation: loc.elevation,
                        lat: loc.lat,
                        lng: loc.long,
                        id: loc.id,
                        locationId: loc.id,
                        gridSquareId: null,
                        regionId: null,
                        locationName: loc.street_address,
                        refId: null,
                        refName: null,
                        country: loc.country,
                        location_ref_id: loc.ref_id,
                    }));
                }

                if (result.asset_folder_id === 0) {
                    result.asset_folder_id = 'NA';
                }
                setAssetToEdit({
                    assetId,
                    ...result,
                    assetValue: result.value / 1000000,
                    assetTypeId: result.asset_type_id,
                });
                setAssetFolderId(result?.asset_folder_id);
            } catch (error) {
                if (error.error === 'consent_required') {
                    return getTokenAndTryAgain(apiOptions);
                }
                setMessageBoxText(`${ERROR_FETCHING_DATA_MSG}. ${error.message}`);
                setShouldDisplayMessageBox(true);
            }
        }

        if (assetId) {
            fetchData();
        }
    }, [assetId]);

    const handleSetMessageBoxText = (msg) => {
        setMessageBoxText(msg);
    };

    const toggleShouldDisplayMessageBox = () => {
        setShouldDisplayMessageBox(!shouldDisplayMessageBox);
    };

    const messageBoxDismissed = async () => {
        setShouldDisplayMessageBox(false);
        if (messageBoxText === SUCCESS_MSG) {
            history.push(`/manage/${customerId}/folders/${assetFolderId}/assets`);
        }
    };

    const cancelFunction = () => history.goBack();

    const onSubmit = async (formData) => {
        const newTags = new Set(formData.tags.filter((x) => !new Set(assetToEdit.tags).has(x)));

        const removedTags = new Set(assetToEdit.tags.filter((x) => !new Set(formData.tags).has(x)));

        if (Array.from(removedTags).length > 0) {
            await tagService.deleteTag(customerId, assetId, Array.from(removedTags));
        }
        if (Array.from(newTags).length > 0) {
            await tagService.assignTagsToAsset(customerId, assetId, Array.from(newTags));
        }

        const riskFactDetails =
            formData &&
            formData.riskFactorDetails &&
            formData.riskFactorDetails.defaultImpactFunctions &&
            formData.riskFactorDetails.defaultImpactFunctions.length
                ? formData.riskFactorDetails.defaultImpactFunctions
                : [];

        const riskFactorHazardImpacts = riskFactDetails.map((riskFact) => {
            if (riskFact.risk_factor_id) {
                return {
                    hazardMetricId: riskFact.hazard_id,
                    impactFunctionId: riskFact.impact_function_id,
                    riskFactorId: riskFact.risk_factor_id,
                    riskFactorName: riskFact.risk_factor_name,
                };
            }
            return {};
        });

        if (formData.locations) {
            formData.locations = (formData.locations || []).map((loc) => {
                if (loc.lng) {
                    loc.long = loc.lng;
                    delete loc.lng;
                }
                return loc;
            });
        }

        //  IF OLD LOC DELETED
        const deletedLoc = assetToEdit.locations
            .map((loc) => {
                if (!formData.locations.find((item) => item.locationId === loc.id)) {
                    loc.long = loc.lng;
                    loc.is_delete = true;
                    return loc;
                }
            })
            .filter((loc) => loc);

        const updatedLoc = formData?.locations.map((loc) => ({
            id: loc?.locationId ? loc?.locationId : 0,
            lat: loc.lat,
            long: loc.long,
            country: loc.country,
            street_address: loc.street_address,
            elevation: loc.elevation,
            location_ref_id: loc.location_ref_id,
        }));

        const locations = [].concat(deletedLoc, updatedLoc);

        const finalLocation = locations.map((loc) => {
            const locObj = {
                id: loc?.id,
                lat: loc.lat,
                long: loc.long,
                country: loc.country,
                street_address: loc.street_address,
                elevation: loc.elevation,
                location_ref_id: loc.location_ref_id,
            };
            if (loc.is_delete) locObj.is_delete = loc.is_delete;
            return locObj;
        });

        const newData = {
            name: formData.name,
            emissions: parseFloat(formData.emissions),
            value: parseInt(formData.assetValue * 1000000, 10).toString(),
            asset_type_id: parseInt(formData.assetTypeId, 10),
            asset_folder_id: formData.asset_folder_id === 'NA' ? 0 : formData.asset_folder_id,
            ref_id: formData.ref_id,
            locations: finalLocation,
            tags: formData.tags,
            assetId: formData.assetId,
            customerId: customerId || undefined,
            is_active: true,
            riskFactorHazardImpacts,
        };

        try {
            // Check if the reqData.location.ref_id is repeated or not
            const locationRefIds = newData.locations.map((loc) => loc.location_ref_id);
            const isLocationRefIdRepeated = locationRefIds.some(
                (refId, index) => locationRefIds.indexOf(refId) !== index,
            );

            if (isLocationRefIdRepeated) {
                setMessageBoxText(
                    'Location reference id is repeated. Please make sure all location reference ids are unique per asset.',
                );
                setShouldDisplayMessageBox(true);
                return;
            }

            const response = await assetService.update(newData);
            if (response.status === 200) {
                googleAnalyticsEvent('Update Entity', 'Asset', 'success');
                setAssetFolderId(formData?.asset_folder_id);
                setMessageBoxText(SUCCESS_MSG);
            } else {
                googleAnalyticsEvent('Update Entity', 'Asset', 'failure');

                setMessageBoxText(`${ERROR_UPDATING_MSG}. ${response.message}`);
            }
        } catch (error) {
            if (error.error === CONSENT_REQUIRED_ERROR) {
                return getTokenAndTryAgain(apiOptions);
            }
            googleAnalyticsEvent('Update Entity', 'Asset', 'error');
            const msg = error?.response?.data?.message ? error.response.data.message : '';
            setMessageBoxText(`${ERROR_UPDATING_MSG}. ${msg}`);
        }
        setShouldDisplayMessageBox(true);
    };

    return (
        <div>
            <MessageBox
                messageBoxIsVisible={shouldDisplayMessageBox}
                messageBoxTitle="Asset"
                messageBoxText={messageBoxText}
                messageBoxCallback={messageBoxDismissed}
                cancelText=""
                okText="Ok"
            />

            <BreadcrumbTrail rootTitle="Customers" />
            <div className="spg-p-md">
                {assetToEdit.name ? (
                    <AssetForm
                        cancelClicked={cancelFunction}
                        submitClicked={onSubmit}
                        messageBoxDismissed={messageBoxDismissed}
                        toggleShouldDisplayMessageBox={toggleShouldDisplayMessageBox}
                        handleSetMessageBoxText={handleSetMessageBoxText}
                        action={action}
                        defaultFormValues={assetToEdit}
                        customerId={customerId || undefined}
                    />
                ) : (
                    <p className="text-center spg-w-100">Loading Edit Form...</p>
                )}
            </div>
        </div>
    );
}
