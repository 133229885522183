import React from 'react';
import { Card } from '@spglobal/react-components';
import BreadcrumbTrail from '../../../BreadcrumbTrail';
import Image_1 from '../../assets/ATs_031822_1.png';
import Image_2 from '../../assets/ATs_031822_2.png';
import Image_3 from '../../assets/ATs_031822_3.png';

export default function AT_Updates_031822() {
    return (
        <div>
            <BreadcrumbTrail rootTitle="New Asset Types" />
            <Card hasBorder hasRoundedCorner className="spg-m-md">
                <img className="spg-ml-2xl spg-w-50" src={Image_1} alt="New Asset Types 1" />
                <img className="spg-ml-2xl spg-w-50" src={Image_2} alt="New Asset Types 2" />
                <img className="spg-ml-2xl spg-w-50" src={Image_3} alt="New Asset Types 3" />
            </Card>
        </div>
    );
}
