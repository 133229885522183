import React, { useMemo, useState } from 'react';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { Size } from '@spglobal/koi-helpers';
import { EDIT as EditIcon, TRASH } from '@spglobal/koi-icons';
import { IconButton } from '@spglobal/react-components';
import { VIEW, EDIT, RENAME, DELETE } from 'utils/constants';
import DataTableGrid from '../DataTable/DataTableGrid/DataTableGrid';

const getViewsTableColumns = (hideActions, isViewCreateEdit) => {
    const columns = [
        {
            headerName: 'Group Names',
            field: 'view_name',
            sortable: false,
            flex: 1,
            cellRenderer: 'groupNamesRenderer',
            checkboxSelection: isViewCreateEdit,
            headerCheckboxSelection: isViewCreateEdit,
            cellClass: 'cli-groups-table-cell',
        },
        {
            headerName: '#Assets',
            field: 'asset_count',
            sortable: false,
            flex: 1,
            cellRenderer: 'assetsRenderer',
        },
        {
            headerName: '#Folders',
            field: 'folders',
            sortable: false,
            flex: 1,
            cellRenderer: 'foldersRenderer',
        },
    ];

    if (!hideActions) {
        columns.push({
            headerName: 'Actions',
            field: 'action',
            sortable: false,
            flex: 0.5,
            cellRenderer: 'actionsRenderer',
            width: 80,
            maxWidth: 80,
            cellStyle: { alignItems: 'baseline' },
            cellClass: 'cli-action-cell',
        });
    }

    return columns;
};

const GroupsDataTable = (props) => {
    const {
        tableData,
        actionCallback,
        isLoading,
        totalCount,
        hideActions = false,
        selectedRowIDs,
        isViewCreateEdit = false,
        pageData,
        onPageDataChange,
        handleRowsSelection,
        selectedRow,
    } = props;
    const [gridApi, setGridApi] = useState(null);

    const handleRowClick = ({ event, data }) => {
        event.stopPropagation();
        if (Object.prototype.hasOwnProperty.call(data, 'is_assigned') ? data?.is_assigned : true) {
            actionCallback?.(VIEW, data);
        }
    };

    const handlePageSizeChange = (newPageSize) => {
        const pageInfo = {
            ...pageData,
            page: 0,
            clicked: true,
            rowsPerPage: newPageSize,
        };
        onPageDataChange(pageInfo);
    };

    const handlePageChange = (newPageIndex) => {
        const pageInfo = {
            ...pageData,
            page: newPageIndex,
            clicked: true,
            rowsPerPage: pageData?.rowsPerPage,
        };
        onPageDataChange(pageInfo);
    };

    const onRowSelected = (item, isSelected, allSelectedItems) => {
        const formattedArray = allSelectedItems.map((objData) => objData.id);
        handleRowsSelection('views', formattedArray);
    };

    const handleGridReady = (e) => {
        setGridApi(e?.api);
    };

    const handleRowDataChanged = useMemo(() => {
        gridApi?.forEachNode((node) => {
            const isSelectedRow = selectedRowIDs?.includes(node?.data?.id);
            node?.setSelected(isSelectedRow);
        });
    }, [tableData, gridApi, handleGridReady, selectedRowIDs, pageData, isLoading]);

    const isRowDisabled = (rowData) =>
        Object.prototype.hasOwnProperty.call(rowData, 'is_assigned') && !rowData?.is_assigned;

    const groupNamesCellRenderer = ({ data }) => {
        const rowData = data || {};

        return (
            <div
                className={
                    isRowDisabled(data) ? 'spg-text-secondary' : 'spg-align-center spg-flex-row'
                }
            >
                {rowData.view_name}
            </div>
        );
    };

    const assetsCellRenderer = ({ data }) => (
        <span className={isRowDisabled(data) ? 'spg-text-secondary' : ''}>{data.asset_count}</span>
    );

    const foldersCellRenderer = ({ data }) => (
        <span className={isRowDisabled(data) ? 'spg-text-secondary' : ''}>
            {data.total_folders_counts}
        </span>
    );

    const actionsCellRenderer = (actionsCellProps) => {
        const handleActionsClick = (event, actionType, params) => {
            event.stopPropagation();
            actionCallback(actionType, params?.data);
        };

        const isActionsDisabled = isRowDisabled(actionsCellProps?.data);

        return (
            <div className="spg-d-flex spg-align-center">
                <IconButton
                    aria-label="edit-view"
                    disabled={isActionsDisabled}
                    size={Size.SMALL}
                    icon={EditIcon}
                    onClick={(e) => handleActionsClick(e, EDIT, actionsCellProps)}
                    title="Edit"
                />
                <IconButton
                    aria-label="rename-view"
                    disabled={isActionsDisabled}
                    size={Size.SMALL}
                    icon={faEdit}
                    onClick={(e) => handleActionsClick(e, RENAME, actionsCellProps)}
                    title="Rename"
                />

                <IconButton
                    aria-label="delete-view"
                    disabled={isActionsDisabled}
                    size={Size.SMALL}
                    icon={TRASH}
                    onClick={(e) => handleActionsClick(e, DELETE, actionsCellProps)}
                    title="Delete"
                />
            </div>
        );
    };

    const frameworkComponents = {
        groupNamesRenderer: groupNamesCellRenderer,
        assetsRenderer: assetsCellRenderer,
        foldersRenderer: foldersCellRenderer,
        actionsRenderer: actionsCellRenderer,
    };

    const columnDefs = getViewsTableColumns(hideActions, isViewCreateEdit);
    const isRowSelectable = ({ data }) => data?.id !== selectedRow?.id;

    const restProps = isViewCreateEdit ? {
        onRowDataChanged: handleRowDataChanged
    } : {};

    return (
        <DataTableGrid
            frameworkComponents={frameworkComponents}
            rowData={tableData}
            columnDefs={columnDefs}
            onRowClicked={handleRowClick}
            pagination
            totalItemsCount={totalCount}
            onPageChange={(pageIndex) => handlePageChange(pageIndex)}
            onPageSizeChange={(newPageSize) => handlePageSizeChange(newPageSize)}
            onRowCheckboxSelected={onRowSelected}
            loading={isLoading}
            isRowSelectable={isRowSelectable}
            onGridReady={handleGridReady}
            {...restProps}
        />
    );
};

export default GroupsDataTable;
