import React from 'react';
import { Card, Tab, Tabs } from '@spglobal/react-components';
import BreadcrumbTrail from '../BreadcrumbTrail';
import InternalUserTab from './InternalUserTab/InternalUserTab';
import ExternalUserTab from './ExternalUserTab/ExternalUserTab';

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function RbacSuperAdmin() {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <>
            <BreadcrumbTrail
                rootTitle="Role Based Access Control"
                manageChild="Customer Assignment"
                isBoldText
            />
            <Card>
                <Tabs
                    alignment="left"
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                >
                    <Tab title="Internal Users" {...a11yProps(0)}>
                        <InternalUserTab />
                    </Tab>
                    <Tab title="External Users" {...a11yProps(1)}>
                        <ExternalUserTab />
                    </Tab>
                </Tabs>
            </Card>
        </>
    );
}
