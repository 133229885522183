import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { NotificationType, useNotification } from '@spglobal/react-components';
import UserContext from '../../context/UserContext';
import CustomerForm from './Components/CustomerForm';
import BreadcrumbTrail from '../BreadcrumbTrail';
import { getRole } from '../../utils/user';
import pathParser from '../../utils/pathParser';
import customerService from '../../services/customerService';

export default function Customers() {
    const history = useHistory();
    const { pathname } = useLocation();
    const { editCustomer: customerId } = pathParser.getPathComponents(pathname);
    const { user } = useContext(UserContext);
    const [defaultValues, setDefaultValue] = useState({});
    const { addNotification } = useNotification();

    const getDefaultValue = (data) => ({
        customerName: data.customer_name,
        address: data.address,
        stateProvince: data.state,
        country: data.country,
        pointOfContact: data.poc_name,
        titleDepartment: data.department,
        pointOfContactEmail: data.poc_email,
        subscriberType: data.customer_type,
        subscriptionStartDate: data?.subscription_start_date,
        subscriptionEndDate: data.subscription_end_date,
        noOfAssetsSubscribed: data?.asset_count ? data?.asset_count : '',
        accountManager: data.account_manager_name,
        notes: data.notes,
        role: data.role,
    });

    const getCustomerDetails = async () => {
        async function fetchData() {
            const { results } = await customerService.getCustomerByIdManage(customerId);
            const editDefaultValue = getDefaultValue(results);
            setDefaultValue(editDefaultValue);
        }

        return fetchData();
    };

    useEffect(() => {
        (async function fetchCustomerDetails() {
            await getCustomerDetails();
        })();
    }, []);

    const onSubmit = async (data) => {
        const SUCCESS_MSG = 'Successfully updated customer';
        const ERROR_CREATING_MSG = 'Error updating customer';
        const role = getRole(user && user.SPGGroups ? user.SPGGroups : []);
        const reqParams = {
            name: data.customerName,
            address: data.address,
            state: data.stateProvince,
            country: data.country,
            poc_name: data.pointOfContact,
            poc_email: data.pointOfContactEmail,
            department: data.titleDepartment,
            account_manager_name: data.accountManager,
            account_manager_email: data.accountManagerEmail,
            notes: data.notes,
            asset_count: data.noOfAssetsSubscribed,
            subscription_start_date: data.subscriptionStartDate
                ? new Date(data.subscriptionStartDate).getTime()
                : null,
            subscription_end_date: data.subscriptionEndDate
                ? new Date(data.subscriptionEndDate).getTime()
                : null,
            customer_type: data.subscriberType,
            role,
        };

        try {
            const response = await customerService.updateCustomerManage(customerId, reqParams);
            if (response.status === 201 || response.status === 200) {
                addNotification(SUCCESS_MSG, NotificationType.SUCCESS);
                return setTimeout(() => history.push(`/customerDetails/${customerId}`), 3000);
            }
            return addNotification(ERROR_CREATING_MSG, NotificationType.ERROR);
        } catch (err) {
            let errorMessage = ERROR_CREATING_MSG;
            if (err?.response?.data?.message) errorMessage = err?.response?.data?.message;
            return addNotification(errorMessage, NotificationType.ERROR);
        }
    };

    const customerFormPros = {
        action: 'edit',
        onSubmit,
        defaultValues,
    };

    return (
        <>
            <BreadcrumbTrail
                rootTitle="Customer Management"
                manageParent="/manageCustomer"
                manageChild={
                    defaultValues && defaultValues.customerName ? defaultValues.customerName : ''
                }
                history={history}
                isBoldText
            />
            <div className="spg-w-100 spg-p-md">
                {defaultValues && defaultValues.customerName && (
                    <CustomerForm {...customerFormPros} />
                )}
            </div>
        </>
    );
}
