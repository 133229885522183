import React from 'react';
import { FormGroup, Select } from '@spglobal/react-components';
import { SCENARIO_OPTIONS } from '../../utils/constants';

export function ScenarioSelect({
    selectedScenarios,
    setSelectedScenarios,
}) {
    const scenarioOptions = [...SCENARIO_OPTIONS];

    return (
        <FormGroup label='Select Scenario' labelFor='scenario-select' required>
            <Select
                id='scenario-select'
                defaultValue={selectedScenarios}
                options={scenarioOptions}
                onChange={setSelectedScenarios}
                isSearchable={false}
                isMulti
                placeholder=""
            />
        </FormGroup>
    );
}
