import React, { useCallback, useRef, useState } from 'react';
import axios from 'axios';
import { useDropzone } from 'react-dropzone';
import Papa from 'papaparse';
import { useHistory, useLocation } from 'react-router-dom';
import {
    Card,
    H3,
    InlineNotification,
    NotificationType,
    Spinner,
    useNotification,
} from '@spglobal/react-components';
import uploadAssetAPI from '../../api/upload-assets';
import DataService from '../../service/data.service';
import DialogBoxUpload from './DialogBoxUpload';
import pathParser from '../../utils/pathParser';
import { STANDARD_IMPORT_HEADERS } from '../../utils/constants';
import config from '../../config';
import { DEFAULT_SPINNER_SIZE } from '../../components/components.constants';
import { HeadingXs, TextBodyLg } from '../../components/shared';

export default function MyDropzone(props) {
    const [typeErrorOccured, setTypeErrorOccured] = useState(false);
    const [singleFileUploadErrorOccured, setSingleFileUploadErrorOccured] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [progress, setProgress] = useState(0);
    const [uploadCancelError, setUploadCancelError] = useState('');
    const [parseError, setParseError] = useState('');
    const [uploadedFileDetails, setUploadedFileDetails] = useState({});
    const [showLoader, setShowLoader] = useState(false);
    const history = useHistory();
    const service = new DataService();
    const {
        location: { search },
    } = history;
    const { pathname } = useLocation();
    const { 'bulk-import-file': customerId } = pathParser.getPathComponents(pathname);
    const urlParams = new URLSearchParams(window.location.search);
    const customerName = urlParams.get('customerName');

    const { addNotification } = useNotification();

    const renderError = () => {
        if (typeErrorOccured) {
            return (
                <InlineNotification isOpen closable={false} type={NotificationType.ERROR}>
                    <div>
                        <HeadingXs>File Type Error</HeadingXs>
                        <TextBodyLg>Upload Failed: Please upload a file in CSV Format</TextBodyLg>
                    </div>
                </InlineNotification>
            );
        }
        if (singleFileUploadErrorOccured) {
            return (
                <InlineNotification isOpen closable={false} type={NotificationType.ERROR}>
                    <div>
                        <HeadingXs>Multiple File Error</HeadingXs>
                        <TextBodyLg>
                            Upload Failed: Please upload 1 file only in CSV Format
                        </TextBodyLg>
                    </div>
                </InlineNotification>
            );
        }
        if (search.includes('step=1')) {
            return (
                <InlineNotification isOpen closable={false} type={NotificationType.ERROR}>
                    <div>
                        <HeadingXs>Network Error</HeadingXs>
                        <TextBodyLg>
                            Upload Failed: Please check your connection and try uploading file again
                        </TextBodyLg>
                    </div>
                </InlineNotification>
            );
        }
        if (uploadCancelError) {
            return (
                <InlineNotification isOpen closable={false} type={NotificationType.ERROR}>
                    <div>
                        <TextBodyLg>File upload cancelled</TextBodyLg>
                    </div>
                </InlineNotification>
            );
        }
        if (parseError) {
            return (
                <InlineNotification isOpen closable={false} type={NotificationType.ERROR}>
                    <div>
                        <TextBodyLg>{parseError}</TextBodyLg>
                    </div>
                </InlineNotification>
            );
        }

        return <></>;
    };

    const abortController = useRef();

    const handleBulkFileUpload = async (file, getFilePresignedUrl, requestData) => {
        setOpenDialog(true);
        const myHeaders = new Headers();
        myHeaders.append('Content-Type', 'text/csv');
        const formdata = new FormData();
        formdata.append('', file, '[PROXY]');
        abortController.current = new AbortController();

        const options = {
            headers: {
                'Content-Type': file.type,
            },
            signal: abortController.current.signal,
            onUploadProgress(progressEvent) {
                const percentCompleted = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total,
                );
                setProgress(percentCompleted);
            },
        };

        try {
            setUploadedFileDetails({ customerId, file_id: getFilePresignedUrl.file_id });
            if (config.devEnv !== 'LOCAL') {
                await axios.put(getFilePresignedUrl.pre_signed_url, file, options);
            }
            const { file_id } = getFilePresignedUrl;
            const reqData = { customerId, file_id };
            await service.uploadBulkAssetFile(reqData);
            props.setShowLoader(false);
            addNotification(
                `File upload completed. FileName: ${requestData.file_name}`,
                NotificationType.SUCCESS,
            );
            return history.push({
                pathname: `/bulk-import-track-file/${customerId}`,
                search: `?${new URLSearchParams({ customerName })}`,
            });
        } catch (e) {
            console.log('Error occurred while uploading file ==>', e);
        }
    };

    const handleClickOpen = () => {
        setOpenDialog(true);
    };

    const handleClose = () => {
        setOpenDialog(false);
    };

    const cancelRequest = async () => {
        typeof abortController.current &&
            abortController.current?.abort &&
            abortController.current.abort();
        setUploadCancelError('error');
    };

    const validateImportHeaders = (importHeaders) => {
        let missingColumns = [];
        const invalidColumns = [];
        let outOfOrder = false;

        for (let i = 0; i < importHeaders.length; i++) {
            if (!STANDARD_IMPORT_HEADERS.includes(importHeaders[i])) {
                invalidColumns.push(importHeaders[i]);
            }
            if (
                i < STANDARD_IMPORT_HEADERS.length &&
                importHeaders[i] !== STANDARD_IMPORT_HEADERS[i]
            ) {
                outOfOrder = true;
            }
        }

        missingColumns = STANDARD_IMPORT_HEADERS.filter(
            (column) => !importHeaders.includes(column),
        );

        if (missingColumns.length > 0) {
            return `The following columns are missing: ${missingColumns.join(', ')}`;
        }
        if (invalidColumns.length > 0) {
            return `Invalid column names in the file: ${invalidColumns.join(', ')}`;
        }
        if (outOfOrder) {
            return 'Column headers are out of order';
        }
        return '';
    };

    const onDrop = useCallback((acceptedFiles) => {
        setShowLoader(true);
        if (!acceptedFiles?.length) {  
            setSingleFileUploadErrorOccured(true);
            setShowLoader(false);
            return;
        }
        if (
          acceptedFiles[0]?.type !== 'text/csv' || 
          !/^[\w\s\-_()]+\.csv$/i.test(acceptedFiles[0]?.name) || 
          /\.[^.]+\.csv$/i.test(acceptedFiles[0]?.name)
      ) {
          setTypeErrorOccured(true);
          setShowLoader(false);
          return;
      }

        const reader = new FileReader();
        reader.onload = async function () {
            try {
                const results = Papa.parse(reader.result, { header: true });
                const columnValidation = validateImportHeaders(
                    results.meta.fields.map((column) => column.trim()),
                );

                if (columnValidation) {
                    setParseError(columnValidation);
                    throw new Error('CSV file does not have expected headers');
                }

                results.data = results.data.filter(
                    (row) =>
                        // check if all keys in the row have null, undefined or empty string as value
                        !Object.values(row).every((val) => val === null || val === ''),
                );

                results.data.forEach((row) => {
                    // get the 'Asset Value' field, parse it to a float, and multiply by 1,000,000
                    const assetValue = Math.round(parseFloat(row['Asset Value']) * 1000000);

                    // replace the original 'Asset Value' with the new value
                    row['Asset Value'] = assetValue.toString();
                });

                const csvData = Papa.unparse(results.data);
                const newFile = new File([csvData], acceptedFiles[0].name, {
                    type: acceptedFiles[0].type,
                });

                setTypeErrorOccured(false);
                const token = service.getUserDetails();
                const userData = {};
                if (token) {
                    const {
                        claims: { email, name, SPGGroups },
                    } = token;
                    userData.userEmail = email;
                    userData.user_name = name;
                    userData.user_role = SPGGroups;
                }

                let reqData = {};
                if (config.devEnv === 'LOCAL') {
                    reqData = {
                        customer_name: customerName,
                        user_name: userData.user_name,
                        email: userData.userEmail,
                        file_name: acceptedFiles[0].path,
                        user_role: userData.user_role.toString(),
                        customer_id: customerId,
                        file_data: results.data,
                    };
                } else {
                    reqData = {
                        customer_name: customerName,
                        user_name: userData.user_name,
                        email: userData.userEmail,
                        file_name: acceptedFiles[0].path,
                        user_role: userData.user_role.toString(),
                        customer_id: customerId,
                    };
                }

                return uploadAssetAPI.getUploadPreSignedURL(reqData).then((presignedData) => {
                    setShowLoader(false);
                    handleBulkFileUpload(newFile, presignedData, reqData);
                });
            } catch (error) {
                setShowLoader(false);
                return false;
            }
        };

        reader.readAsText(acceptedFiles[0]);
    }, []);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        multiple: false,
        maxFiles: 1,
    });

    const dialogProps = {
        handleClickOpen,
        handleClose,
        isOpen: openDialog,
        progress,
        cancelRequest,
        FileDetails: uploadedFileDetails,
    };

    return (
        <div className="spg-w-100">
            <DialogBoxUpload {...dialogProps} />
            <H3>Upload File</H3>
            <div className="spg-d-flex spg-justify-center spg-w-100 spg-mt-md">{renderError()}</div>

            {!showLoader ? (
                <Card hasBorder hasRoundedCorner className="spg-mt-md spg-mb-md">
                    <div
                        {...getRootProps({})}
                        className="spg-w-100 spg-d-flex spg-align-center spg-justify-center spg-p-2xl spg-flex-column"
                    >
                        <input {...getInputProps()} />
                        <p>Drag and drop Asset import file here, or click to select file.</p>
                        <img src="/assets/bi_cloud-upload.svg" height={86} width={86} alt="logo" />
                    </div>
                </Card>
            ) : (
                <Spinner size={DEFAULT_SPINNER_SIZE} />
            )}
            <TextBodyLg>Supported file format CSV</TextBodyLg>
        </div>
    );
}
