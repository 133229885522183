import React, { useState, useEffect } from 'react';
import riskColor from 'utils/riskColor';
import formatCurrency from 'utils/formatCurrency';
import CustomHeader from 'components/Header/CustomHeader';
import convertToUSDMillion from 'utils/convertToUSDMillion';
import DataTableGrid from '../DataTable/DataTableGrid/DataTableGrid';
import { DEFAULT_PAGE_DATA } from '../components.constants';

export default function GroupsTabPanel({
    loading,
    riskDataFilters,
    tableData,
    totalCount,
    handleClick,
    pageData,
    onPageDataChange,
}) {
    const [tableDataState, setTableDataState] = useState([]);

    // execute for when filter changes
    useEffect(() => {
        if (tableData && Array.isArray(tableData) && tableData.length > 0) {
            setTableDataState(
                tableData.map((asset) => ({
                    ...asset,
                    color: !Number.isNaN(asset?.relative_value)
                        ? riskColor.determineColor(asset.relative_value, 'relative')
                        : '#212C37',
                })),
            );
        } else {
            setTableDataState([]);
        }
    }, [tableData]);

    const handleTableSearchSubmit = (searchVal) => {
        onPageDataChange('views', {
            ...pageData,
            ...DEFAULT_PAGE_DATA,
            searchText: searchVal,
        });
    };

    const handlePageChange = (newPageIndex) => {
        onPageDataChange('views', {
            ...pageData,
            page: newPageIndex,
            clicked: true,
            rowsPerPage: pageData?.rowsPerPage,
        });
    };

    const handlePageSizeChange = (newPageSize) => {
        onPageDataChange('views', {
            ...pageData,
            page: 0,
            clicked: true,
            rowsPerPage: newPageSize,
        });
    };

    const columnDefs = [
        {
            headerName: 'Group Name',
            headerTooltip: 'Group Name',
            field: 'entity_name',
        },
        {
            headerName: 'Total Group Value (In USD millions)',
            headerTooltip: 'Total Group Value (In USD millions)',
            field: 'total_value',
            valueFormatter: (params) =>
                Number.isNaN(params.data?.total_value)
                    ? 'N/A'
                    : `${new Intl.NumberFormat(
                          'en-US',
                          { style: 'currency', currency: 'USD' },
                          { maximumSignificantDigits: 2 },
                      ).format(convertToUSDMillion(params.data?.total_value))}m`,
        },
        {
            headerName: 'Scoring Status',
            headerTooltip: 'Scoring Status',
            field: 'scoringStatus',
            tooltipValueGetter: (params) => {
                const { assetsInProgress, totalAssets } = params.data;

                return assetsInProgress
                    ? `Assets Remaining: ${assetsInProgress} of ${totalAssets}`
                    : `Complete: ${totalAssets} of ${totalAssets}`;
            },
        },
        {
            headerName: `Modeled Average Annual ${
                riskDataFilters.analysisType === 1 ? 'Loss' : 'Gain'
            }`,
            headerTooltip: `Modeled Average Annual ${
                riskDataFilters.analysisType === 1 ? 'Loss' : 'Gain'
            }`,
            children: [
                {
                    headerName: 'Relative Value',
                    headerTooltip: 'Relative Value',
                    field: 'relative_value',
                    valueFormatter: (params) =>
                        Number.isNaN(params.data?.relative_value)
                            ? 'N/A'
                            : `${params.data?.relative_value?.toFixed(
                                  riskDataFilters?.riskValuePrecision,
                              )} %`,
                },
                {
                    headerName: 'Absolute Value',
                    headerTooltip: 'Absolute Value',
                    field: 'absolute_value',
                    valueFormatter: (params) =>
                        Number.isNaN(params.data?.absolute_value)
                            ? 'N/A'
                            : formatCurrency(
                                  convertToUSDMillion(params.data?.absolute_value),
                                  riskDataFilters?.riskValuePrecision,
                              ),
                },
            ],
        },
    ];

    return (
        <div>
            <CustomHeader
                searchTextPrev={pageData?.searchText}
                searchPlaceholder="Group Name"
                handleSearchSubmit={handleTableSearchSubmit}
            />
            <DataTableGrid
                rowData={tableDataState}
                columnDefs={columnDefs}
                onRowClicked={(params) => handleClick(params.data.entityId, 'viewDetails')}
                loading={loading}
                totalItemsCount={totalCount}
                onPageChange={handlePageChange}
                onPageSizeChange={handlePageSizeChange}
            />
        </div>
    );
}
