import * as React from 'react';
import { VIEW } from '../../utils/constants';
import DataTableGrid from '../DataTable/DataTableGrid/DataTableGrid';

const CUSTOMER_COLUMNS = [
    {
        field: 'name',
        headerName: 'Customer Name',
        sortable: false,
    },
];

const CustomersDataTable = (props) => {
    const { tableData, actionCallback } = props;
    const dataPrivacyAccepted = localStorage.getItem('CLIMANOMICS_USER_CONSENT');

    const handleRowClick = (event) => {
        actionCallback(event.data, VIEW);
    };

    return (
        <>
            {dataPrivacyAccepted && (
                <DataTableGrid
                    rowData={tableData}
                    columnDefs={CUSTOMER_COLUMNS}
                    onRowClicked={handleRowClick}
                    loading={props.loading}
                />
            )}
        </>
    );
};

export default CustomersDataTable;
