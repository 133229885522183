import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import BreadcrumbTrail from '../BreadcrumbTrail';
import pathParser from '../../utils/pathParser';
import customerService from '../../services/customerService';
import CustomerDetailsForm from './Components/CustomerDetails';

export default function CustomerDetails() {
    const history = useHistory();
    const { pathname } = useLocation();
    const { customerDetails: customerId } = pathParser.getPathComponents(pathname);
    const [defaultValues, setDefaultValue] = useState({});

    const getDefaultValue = (data) => ({
            customerName: data.customer_name,
            address: data.address,
            stateProvince: data.state,
            country: data.country,
            pointOfContact: data.poc_name,
            titleDepartment: data.department,
            pointOfContactEmail: data.poc_email,
            subscriberType: data.customer_type,
            subscriptionStartDate: data.subscription_start_date,
            subscriptionEndDate: data.subscription_end_date,
            noOfAssetsSubscribed: data.asset_count,
            noOfAssetsUploaded: data.num_of_assets_uploaded,
            accountManager: data.account_manager_name,
            accountManagerEmail: data.account_manager_email,
            notes: data.notes,
            role: data.role,
        });

    const getCustomerDetails = async () => {
        async function fetchData() {
            const { results } = await customerService.getCustomerByIdManage(customerId);
            const editDefaultValue = getDefaultValue(results);
            setDefaultValue(editDefaultValue);
        }

        return fetchData();
    };

    useEffect(() => {
        (async function fetchCustomerDetails() {
            await getCustomerDetails();
        })();
    }, []);

    return (
        <>
            <BreadcrumbTrail
                rootTitle='Customer Management'
                manageParent='/manageCustomer'
                manageChild={
                    defaultValues && defaultValues.customerName ? defaultValues.customerName : ''
                }
                history={history}
                isBoldText
            />
            <div className='spg-w-100 spg-p-md'>
                <CustomerDetailsForm {...defaultValues} customerId={customerId} />
            </div>
        </>
    );
}
