import React, { useState, useEffect, useContext } from 'react';
import ExternalUserList from './ExternalUserList';
import rbacAdminService from '../../../services/rbacAdminService';
import AccessTokenContext from '../../../context/AccessTokenContext';
import DialogAdmin from '../../DialogAdmins';
import { CONSENT_REQUIRED_ERROR } from '../../../utils/constants';

export function ExternalUserTab() {
    const [dataLoaded, setDataLoaded] = useState(false);

    const [tableData, setTableData] = useState([]);

    const { getTokenAndTryAgain } = useContext(AccessTokenContext);
    const [isOpen, setIsOpen] = useState(false);
    const [action, setAction] = useState('');
    const [editUserId, setEditUserId] = useState('');

    const getUsersData = async () => {
        const reqData = {
            user_type: 'external',
            list: false,
        };

        async function fetchData() {
            try {
                const response = await rbacAdminService.getAllUsers(reqData);
                setDataLoaded(true);
                if (response) {
                    setTableData(response);
                }
                return response;
            } catch (error) {
                setDataLoaded(true);
                if (error.error === CONSENT_REQUIRED_ERROR) {
                    return getTokenAndTryAgain();
                }
            }
        }
        return fetchData();
    };

    useEffect(() => {
        (async function fetchUsersData() {
            await getUsersData();
        })();
    }, []);

    const dialogParam = {
        handleClose: () => {
            setEditUserId('');
            setIsOpen(false);
        },
        open: isOpen,
        type: 'external',
        action,
        users: tableData.length ? tableData : [],
        editUserId,
        getUsersData,
    };

    return (
        <>
            {<DialogAdmin {...dialogParam} />}
            <ExternalUserList
                tableData={tableData}
                dataLoaded={dataLoaded}
                isOpen={setIsOpen}
                action={setAction}
                editUserId={setEditUserId}
            />
        </>
    );
}

export default ExternalUserTab;
