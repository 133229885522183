import React, { useEffect, useState } from 'react';
import { FormGroup, InputField, Select } from '@spglobal/react-components';
import { RBAC_ROLES, FORM_ACTIONS } from '../../../utils/constants';
import { transformDataObjForKoiSelect } from '../../../utils/koiIntegrationUtils';

export default function User({ userData = [], onUserSelection = {}, action = '', type, editUser }) {
    const [role, setRole] = useState('');
    const [selectedUser, setSelectedUser] = useState({});
    const [userDataOptions, setUserDataOptions] = useState([]);
    const [defaultUserOption, setDefaultUserOption] = useState([]);

    useEffect(() => {
        const usersOptions = userData.map((u) => ({
            ...transformDataObjForKoiSelect(u, {
                id: 'id',
                value: 'id',
            }),
            label: u.user_name ? `${u.user_name} (${u.user_email})` : '',
        }));
        const curUserOption = usersOptions.find((o) => o.id === editUser.user_id);
        setUserDataOptions(usersOptions);
        setDefaultUserOption(curUserOption ? [curUserOption] : []);

        if (isEdit() && editUser) {
            const curUser = userData.filter((item) => item.user_email === editUser.user_email);
            if (curUser.length) {
                setSelectedUser(curUser[0]);
                setRole(curUser[0]);
                onUserSelection(curUser[0]);
            }
        }
    }, [userData]);

    const handleChange = (selectedValue) => {
        const usersSelection = userData.find((u) => u.id === selectedValue.id);

        setSelectedUser(usersSelection);
        setRole(usersSelection?.role && usersSelection.role[0] ? usersSelection.role[0] : '');
        onUserSelection(usersSelection);
    };

    const showRow = RBAC_ROLES[selectedUser && selectedUser.role ? selectedUser.role : ''];

    const isEdit = () => action === FORM_ACTIONS.EDIT;

    return (
        <>
            <div className="spg-col-6">
                <FormGroup label="User Name/Email-ID" labelFor="User">
                    <Select
                        id="User"
                        disabled={isEdit()}
                        options={userDataOptions}
                        defaultValue={defaultUserOption}
                        isMulti={false}
                        closeOnSelection
                        onChange={(values) => handleChange(values[0])}
                    />
                </FormGroup>
            </div>
            <div className="spg-col-6">
                <FormGroup label="Role" labelFor="roleType">
                    <InputField disabled value={showRow || ''} id="roleType" />
                </FormGroup>
            </div>
        </>
    );
}
