import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

const ScrollHandler = ({ location }) => {
    useEffect(() => {
        const element = document.getElementById(location.hash.slice(1, location.hash.length));
        setTimeout(() => {
            window.scrollTo({
                behavior: element ? 'smooth' : 'auto',
                top: element ? element.offsetTop - window.innerHeight * 0.1 : 0,
            });
        }, 100);
    }, [location]);

    return null;
};

export default withRouter(ScrollHandler);
