import React from 'react';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import { FormGroup } from '@spglobal/react-components';
import { getCustomerAssets } from '../../services/assetService';

export const AssetsListSelect = ({
     assetsList,
     setSelectedAsset,
     selectedCustomer,
     setAssetsList,
     searchAssetText,
     setSearchAssetText,
}) => {
    const handleSearch = async (value) => {
        setSearchAssetText(value)

        const customerAssetsResponse = await getCustomerAssets(selectedCustomer?.id, {
            offset: 0,
            limit: 20,
            search: value
        });

        if (customerAssetsResponse?.results) {
            setAssetsList(customerAssetsResponse?.results);
        }
    }

    return (
        <FormGroup label="Select Asset" labelFor="asset-tags-select" required>
            <ReactSearchAutocomplete
                id="autocomplete-single-asset"
                items={assetsList}
                onSearch={handleSearch}
                fuseOptions={{
                    keys: ['name', 'id']
                }}
                onSelect={setSelectedAsset}
                inputSearchString={searchAssetText}
                placeholder="Search for Asset"
                showNoResultsText="No options"
                styling={{
                    zIndex: 1,
                }}
                className="cli-autocomplete"
            />
        </FormGroup>
    );
};
