import { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import * as React from 'react';
import { ANGLE_LEFT } from '@spglobal/koi-icons';
import { Button, Icon, Tab, Tabs } from '@spglobal/react-components';
import { Alignment, Purpose } from '@spglobal/koi-helpers';
import { Loader } from '../../ui/core';

import PrepareFile from './PrepareFile';
import UploadFile from './UploadFile';
import pathParser from '../../utils/pathParser';
import { STEP_INDEXES, STEPS } from '../constants/import.constants';
import ReviewProcess from './ReviewProcess';
import { TextBodyMd } from '../../components/shared';

export default function UploadAssets(props) {
    const [activeStep, setActiveStep] = useState(STEP_INDEXES.prepareFile);
    const [fileId, setFileId] = useState('');
    const [importId, setImportId] = useState('');
    const [showLoader, setShowLoader] = useState(false);
    const { pathname } = useLocation();
    const { 'bulk-import-file': customerId } = pathParser.getPathComponents(pathname);
    const urlParams = new URLSearchParams(window.location.search);
    const customerName = urlParams.get('customerName');
    const history = useHistory();

    const handleNext = () => {
        setActiveStep(STEP_INDEXES.uploadFile);
    };

    const handleBack = () => {
        setActiveStep(STEP_INDEXES.prepareFile);
    };

    useEffect(() => {
        const currentStep = parseInt(urlParams.get('step'), 10);
        if (currentStep) {
            setActiveStep(STEP_INDEXES.reviewTab);
            setFileId(urlParams.get('file_id'));
            setImportId(urlParams.get('import_id'));
        }
    }, []);

    return (
        <>
            {showLoader ? (
                <Loader />
            ) : (
                <div className="spg-p-md spg-h-100">
                    <div className="spg-row">
                        <div className="spg-col-2">
                            <h2>Upload Assets</h2>
                        </div>
                        <div className="spg-col spg-col-offset-8 spg-d-flex spg-justify-end">
                            <Button
                                purpose={Purpose.SECONDARY}
                                onClick={() =>
                                    history.push({
                                        pathname: `/bulk-import-track-file/${customerId}`,
                                        search: `?${new URLSearchParams({ customerName })}`,
                                    })
                                }
                            >
                                Back To Files Dashboard
                            </Button>
                        </div>
                    </div>

                    <div className="spg-h-100">
                        <TextBodyMd>
                        This page allows you to upload data to generate either Financial Impact or Exposure Score outputs for your assets. Please follow the steps below to upload your assets. Before uploading, please refer to the table of input fields to ensure that your upload file meets all the requirements for a successful data upload.
                        </TextBodyMd>
                        <Tabs
                            isPageLevel
                            isPrimary={false}
                            alignment={Alignment.CENTER}
                            selectedTabId={activeStep}
                            onChange={(newTabId) => {
                                setActiveStep(newTabId);
                            }}
                            disabled
                            className="spg-h-100 cli-tabs-disabled "
                        >
                            {STEPS.map((label) => (
                                <Tab
                                    key={label}
                                    title={label}
                                    id={`${label}-tab`}
                                    disabled
                                    className="spg-h-100"
                                >
                                    <React.Fragment>
                                        <div>
                                            {activeStep === STEP_INDEXES.prepareFile && (
                                                <PrepareFile />
                                            )}
                                            {activeStep === STEP_INDEXES.uploadFile && (
                                                <div className="spg-h-100">
                                                    <UploadFile
                                                        className="spg-h-100"
                                                        {...props}
                                                        setShowLoader={setShowLoader}
                                                    />
                                                </div>
                                            )}
                                            {activeStep === STEP_INDEXES.reviewTab && (
                                                <ReviewProcess
                                                    fileId={fileId}
                                                    importId={importId}
                                                />
                                            )}
                                        </div>

                                        <div className="spg-d-flex spg-justify-between spg-mt-md">
                                            <Button
                                                purpose={Purpose.SECONDARY}
                                                disabled={activeStep === STEP_INDEXES.prepareFile}
                                                onClick={handleBack}
                                                leftIcon={<Icon icon={ANGLE_LEFT} />}
                                            >
                                                {activeStep === STEP_INDEXES.reviewTab
                                                    ? 'Upload Again'
                                                    : 'Back'}
                                            </Button>

                                            {activeStep === STEP_INDEXES.prepareFile && (
                                                <Button
                                                    purpose={Purpose.SECONDARY}
                                                    onClick={handleNext}
                                                >
                                                    {'Next'}
                                                </Button>
                                            )}
                                        </div>
                                    </React.Fragment>
                                </Tab>
                            ))}
                        </Tabs>
                    </div>
                </div>
            )}
        </>
    );
}
