import { httpClient } from '../utils/httpClient';
import { API_URL } from '../utils/constants';

const ASSETS_ENDPOINT = 'api/customer';

export const getAll = async ({
    investmentId,
    folderId,
    groupId,
    customerId,
    getLocations,
    getAllForUser,
    getForFolder,
    params,
}) => {
    if (getForFolder) {
        const result = await httpClient.get(`${API_URL}/${ASSETS_ENDPOINT}/${customerId}/assets`, {
            params: {
                ...params,
            },
        });

        return result.data;
    }
    const API_SOURCE = API_URL;
    let apiString = `${API_SOURCE}/${ASSETS_ENDPOINT}?`;
    if (getAllForUser) {
        apiString += `getAllForUser=${getAllForUser}`;
    } else {
        if (investmentId) apiString += `investmentId=${investmentId}&`;
        if (folderId) apiString += `folderId=${folderId}&`;
        if (groupId) apiString += `groupId=${groupId}&`;
        if (customerId) apiString += `customerId=${customerId}&`;
        if (getLocations) apiString += `getLocations=${getLocations}`;
    }

    const result = await httpClient.get(apiString);
    return result.data;
};

export const getAsset = async ({ assetId, customerId, includeDetails }) => {
    const API_SOURCE = API_URL;
    return httpClient
        .get(
            `${API_SOURCE}/${ASSETS_ENDPOINT}/${customerId}/assets/${assetId}?includeDetails=${includeDetails}`,
        )
        .then((res) => {
            const assetInfo = assetResponseMapping(res.data.results);
            return assetInfo;
        })
        .catch(() => {
            console.log('Error occurred while fetching asset');
            return [];
        });
};

const getAssetById = async (customerId, assetId) => {
    const API_SOURCE = API_URL;

    const result = await httpClient.get(
        `${API_SOURCE}/${ASSETS_ENDPOINT}/${customerId}/assets/${assetId}`,
    );
    return result.data;
};

export const createAsset = async (newAsset) => {
    const result = await httpClient.post(
        `${API_URL}/${ASSETS_ENDPOINT}/${newAsset.customerId}/assets`,
        newAsset,
    );
    return result;
};

export const update = async (updatedAsset) => {
    const { assetId, customerId } = updatedAsset;
    const result = await httpClient.put(
        `${API_URL}/${ASSETS_ENDPOINT}/${customerId}/assets/${assetId}`,
        updatedAsset,
    );
    return result;
};

export const deleteAsset = async (assetId) => {
    const result = await httpClient.put(`${API_URL}/${ASSETS_ENDPOINT}/${assetId}`);
    return result.data;
};

export const bulkDelete = async (customerId, assetIds) => {
    const result = await httpClient.put(`${API_URL}/${ASSETS_ENDPOINT}/${customerId}/assets`, {
        data: { asset_ids: assetIds },
    });
    return result;
};

export const getCustomerAssets = async (customerId, params) => {
    const result = await httpClient.get(`${API_URL}/${ASSETS_ENDPOINT}/${customerId}/assets?`, {
        params: {
            ...params,
        },
    });
    return result?.data;
};
export const getAssetDetails = async (customerId, assetId) => {
    const result = await httpClient.get(
        `${API_URL}/${ASSETS_ENDPOINT}/${customerId}/assets/${assetId}`,
    );
    return result;
};

export const getFoldersList = async (customerId, params) => {
    const result = await httpClient.get(
        `${API_URL}/${ASSETS_ENDPOINT}/${customerId}/folders?offset=0&limit=1000`,
        {
            params: {
                ...params,
            },
        },
    );
    return result?.data;
};

export const moveAssets = async (targetFolderId, assetsToMove, customerId) => {
    const data = {
        target_folder_id: targetFolderId,
        assets_moving_list: assetsToMove,
    };
    const result = await httpClient.put(
        `${API_URL}/${ASSETS_ENDPOINT}/${customerId}/assets/moveAssets`,
        data,
    );
    return result;
};

export const getAllAssetsForNavigation = async ({ customerId, folderId }) => {
    const result = await httpClient.get(
        `${API_URL}/${ASSETS_ENDPOINT}/${customerId}/assets?includeAll=true${
            folderId ? `&folder_id=${folderId}` : ''
        }`,
    );
    return result.results;
};

export const getAllAssetsForFolder = async ({ customerId, folderId, viewId }) => {
    const response = await httpClient.get(
        `${API_URL}/${ASSETS_ENDPOINT}/${customerId}/assets/locations?customer_folder_id=${folderId}&view_id=${viewId}`,
    );
    return response.data.results;
};

const assetResponseMapping = (assetResponse) => {
    const newObj = {
        id: assetResponse.asset_id,
        name: assetResponse.name,
        assetValue: assetResponse.value,
        emissions: assetResponse.emissions,
        refId: assetResponse.ref_id,
        assetTypeId: assetResponse.asset_type_id,
        assetsTorefId: { refName: '' },
        assetsTags: assetResponse.tags,
        locations: assetResponse.locations,
    };
    return newObj;
};

export default {
    getAll,
    getAsset,
    createAsset,
    update,
    deleteAsset,
    bulkDelete,
    getCustomerAssets,
    getAssetDetails,
    getFoldersList,
    moveAssets,
    getAssetById,
};
