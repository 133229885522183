import { httpClient } from '../utils/httpClient';
import { API_URL } from '../utils/constants';

const GROUPS_ENDPOINT = 'api/groups';

const getAll = async (customerId) => {
    const result = await httpClient.get(`${API_URL}/${GROUPS_ENDPOINT}?customer=${customerId}`);
    return result.data;
};

const getGroupById = async (groupId) => {
    const API_SOURCE = API_URL;

    const result = await httpClient.get(`${API_SOURCE}/${GROUPS_ENDPOINT}/${groupId}`);
    return result.data;
};

const getAllPermittedGroupIds = async () => {
    const API_SOURCE = API_URL;
    const result = await httpClient.get(`${API_SOURCE}/${GROUPS_ENDPOINT}?`);
    return result.data;
};

const create = async (customerId, groupName) => {
    const params = {
        customerId,
        groupName,
    };
    const result = await httpClient.post(`${API_URL}/${GROUPS_ENDPOINT}`, params);
    return result.data;

    // below allows for handling of 40X errors upon response from the server,
    // as opposed to immediate Network-based error display with httpClient.
    // Fetch "...will only reject on network failure or if anything prevented the request from completing."
    // More info: https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
    // const result = await fetch(ENDPOINT, {
    //   method: 'POST', // *GET, POST, PUT, DELETE, etc.
    //   mode: 'cors', // no-cors, *cors, same-origin
    //   headers: {
    //     'Content-Type': 'application/json'
    //   },
    //   body: JSON.stringify(params) // body data type must match "Content-Type" header
    // });
    // if (result.status === 403) {
    //   throw new Error(`Action ${result.statusText}`);
    // }
    // return result.json();
};

const update = async (groupId, groupName) => {
    const params = {
        groupId,
        groupName,
    };
    const result = await httpClient.put(`${API_URL}/${GROUPS_ENDPOINT}`, params);
    return result.data;
};

const deleteGroup = async (groupId) => {
    const result = await httpClient.delete(`${API_URL}/${GROUPS_ENDPOINT}?groupId=${groupId}`);
    return result.data;
};

export default {
    getAll,
    getAllPermittedGroupIds,
    create,
    update,
    deleteGroup,
    getGroupById,
};
