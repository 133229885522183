/* eslint-disable no-underscore-dangle */
// eslint-disable-next-line no-process-env
const CLIENT_ID =
    window && window._env_?.REACT_APP_OKTA_CLIENT_ID ||
    process.env.REACT_APP_OKTA_CLIENT_ID ||
    '{clientId}';
// eslint-disable-next-line no-process-env
const ISSUER =
    window && window._env_?.REACT_APP_OKTA_ISSUER ||
    process.env.REACT_APP_OKTA_ISSUER ||
    'https://{yourOktaDomain}.com/oauth2/default';
// eslint-disable-next-line no-process-env
const REDIRECT_URI =
    window && window._env_?.REACT_APP_REDIRECT_URI ||
    process.env.REACT_APP_REDIRECT_URI;
// eslint-disable-next-line no-process-env
const API_BASE_URI =
    window && window._env_?.REACT_APP_API_URL ||
    process.env.REACT_APP_API_URL;
// eslint-disable-next-line no-process-env
const SIGNED_URI = process.env.REACT_APP_DEMO_SIGNED_URI;
// eslint-disable-next-line no-process-env
const AssetTypeURI = process.env.REACT_APP_ASSET_TYPES_URI;
// eslint-disable-next-line no-process-env
const UPLOAD_CSV_TEMPLATE = '/Climanomics_asset_upload_template.csv';
// eslint-disable-next-line no-process-env
const SHOW_MOCK_DATA = process.env.REACT_APP_MOCKDATA_SWITCH;

const config = {
    oidc: {
        clientId: CLIENT_ID,
        issuer: ISSUER,
        redirectUri: REDIRECT_URI,
        scopes: ['openid', 'profile', 'email', 'groups'],
        pkce: true,
        disableHttpsCheck: true,
    },
    baseUrl: API_BASE_URI,
    signedURI: SIGNED_URI,
    AssetTypeURI,
    uploadCSVTemplate: UPLOAD_CSV_TEMPLATE,
    showMockData: parseInt(SHOW_MOCK_DATA),
    devEnv: 'dev',
};

export default config;
