import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { NotificationType, useNotification } from '@spglobal/react-components';
import pathParser from '../../utils/pathParser';
import AccessTokenContext from '../../context/AccessTokenContext';
import UserContext from '../../context/UserContext';
import CustomHeader from '../Header/CustomHeader';
import {
    bulkDelete,
    getCustomerAssets,
    getFoldersList,
    moveAssets,
} from '../../services/assetService';

import { getAssetTagsByCustomerID } from '../../services/exportService';

import * as constants from '../../utils/constants';
import AllAssetsDataTable from './AllAssetsDataTable';
import MoveAssetsDialog from '../MoveAssetsDialog';
import folderService from '../../services/folderService';
import DeleteDialog from '../DeleteDialogTemplate/DeleteDialogTemplate';
import useGetPermission from '../../hooks/useGetPermission';
import {
    ACTION,
    MOVE,
    VIEW,
    NEW_ASSET,
    EDIT,
    BULK_MOVE,
    DELETE,
    BULK_DELETE,
    TAG_SELECT, CONSENT_REQUIRED_ERROR
} from '../../utils/constants';
import { transformDataObjForKoiSelect } from '../../utils/koiIntegrationUtils';
import { DEFAULT_PAGE_DATA, DEFAULT_PAGE_SIZE } from '../components.constants';

export default function AllAssets(props) {
    const { pageData, onPageDataChange, resourceType } = props;
    const history = useHistory();
    const { pathname } = useLocation();
    const { getTokenAndTryAgain } = useContext(AccessTokenContext);
    const { user, isLoggedIn } = useContext(UserContext);
    const apiOptions = {
        scope: `${constants.CUSTOMER_READ_ASSET}`,
    };
    const { customers: customerId } = pathParser.getPathComponents(pathname);
    const userPermission =
        user && Object.keys(user).length > 0 && useGetPermission(user, isLoggedIn, resourceType);
    const userPrivilege = userPermission?.action;

    const [tagsList, setTagsList] = useState([]);
    const [selectedTags, setSelectedTags] = useState([]);

    const [dataLoaded, setDataLoaded] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [selectedRowIds, setSelectedRowIds] = useState([]);
    const [selectedRowsFolderIDs, setSelectedRowsFolderIDs] = useState([]);
    const [dialogType, setDialogType] = useState('');
    const [totalCount, setTotalCount] = useState(0);
    const [selectedAsset, setSelectedAsset] = useState();
    const [foldersList, setFoldersList] = useState([]);

    const { addNotification } = useNotification();

    const getAssetsData = async (offset = 0, limit = DEFAULT_PAGE_SIZE, searchTextVal, tags) => {
        const reqData = {
            offset,
            limit,
            tags,
        };
        if (searchTextVal?.length) {
            reqData.asset_name = searchTextVal;
        }
        async function fetchData() {
            try {
                const response = await getCustomerAssets(customerId, reqData);
                setDataLoaded(true);
                if (response?.results) {
                    setTableData(response?.results);
                    setTotalCount(response?.total_asset_count);
                }
                return response;
            } catch (error) {
                setDataLoaded(true);
                if (error.error === CONSENT_REQUIRED_ERROR) {
                    return getTokenAndTryAgain(apiOptions);
                }
                return error;
            }
        }
        return fetchData();
    };

    const getFoldersData = async (searchTextVal) => {
        const reqData = {};
        if (searchTextVal?.length) reqData.asset_folder_name = searchTextVal;
        async function fetchData() {
            try {
                const { result: data } = await getFoldersList(customerId, reqData);
                setFoldersList(data);
                return data;
            } catch (error) {
                setDataLoaded(true);
                if (error.error === CONSENT_REQUIRED_ERROR) {
                    return getTokenAndTryAgain(apiOptions);
                }
                return error;
            }
        }
        return fetchData();
    };

    const getTagsData = async (searchTextVal) => {
        async function fetchData() {
            try {
                const customerAssetTags = await getAssetTagsByCustomerID(customerId);
                const selectData = customerAssetTags.map((data) =>
                    transformDataObjForKoiSelect(data, { id: 'id', label: 'name', value: 'id' })
                );
                setTagsList(selectData);
                return customerAssetTags;
            } catch (error) {
                setDataLoaded(true);
                if (error.error === CONSENT_REQUIRED_ERROR) {
                    return getTokenAndTryAgain(apiOptions);
                }
                return error;
            }
        }
        return fetchData();
    };

    useEffect(() => {
        (async function fetchAssetsData() {
            const offset = pageData?.page * pageData?.rowsPerPage;
            const tagIds = selectedTags.map((tag) => tag.id).join(',');
            await getAssetsData(offset, pageData?.rowsPerPage, pageData?.searchText, tagIds);
            await getTagsData('');
        })();
    }, []);

    React.useEffect(() => {
        (async function updateData() {
            if (pageData?.clicked) {
                const offset = pageData?.page * pageData?.rowsPerPage;
                const tagIds = selectedTags.map((tag) => tag.id).join(',');
                getAssetsData(offset, pageData?.rowsPerPage, pageData?.searchText, tagIds);
            }
        })();
    }, [pageData, dataLoaded]);

    const handleAssetsDelete = async (isConfirm) => {
        setDialogType('');
        if (isConfirm) {
            const assetsToDelete = dialogType === DELETE ? [selectedAsset?.id] : selectedRowIds;
            const deleteResponse = await bulkDelete(customerId, assetsToDelete);
            setDataLoaded(false);
            if (deleteResponse.status === 200) {
                const offset = pageData?.page * pageData?.rowsPerPage;
                await getAssetsData(offset, pageData?.rowsPerPage);
                setDataLoaded(true);
                addNotification(deleteResponse.data.message, NotificationType.SUCCESS);
            } else {
                const offset = pageData?.page * pageData?.rowsPerPage;
                await getAssetsData(offset, pageData?.page);
                addNotification(deleteResponse.data.message, NotificationType.ERROR);
            }
        }
    };

    const handleMoveAssetsSubmit = async (isConfirm, targetFolderId) => {
        if (isConfirm) {
            const assetsToMove = dialogType === MOVE ? [selectedAsset?.id] : selectedRowIds;
            const moveResponse = await moveAssets(targetFolderId, assetsToMove, customerId);
            if (moveResponse.status === 200) {
                setDataLoaded(false);
                const offset = pageData?.page * pageData?.rowsPerPage;
                const { results: response } = await getAssetsData(
                    offset,
                    pageData?.rowsPerPage,
                    pageData?.searchText
                );
                addNotification(moveResponse?.data?.message, NotificationType.SUCCESS);
                setTableData(response);
                setDataLoaded(true);
            } else {
                addNotification(moveResponse?.data?.message, NotificationType.ERROR);
            }
            setSelectedRowIds([]);
        }
        setDialogType('');
    };

    const handleCreateNewFolder = async (folderName) => {
        const folderResponse = await folderService.create(customerId, folderName?.trim());
        if (folderResponse.status === 200) {
            addNotification(folderResponse?.message, NotificationType.SUCCESS);
            await getFoldersData('');
        } else {
            addNotification(folderResponse?.message, NotificationType.ERROR);
        }
    };

    const getDeleteDialog = () => {
        const deleteInfo =
            dialogType === DELETE
                ? `"${selectedAsset?.name}" will be deleted.`
                : 'All the selected assets on this page will be deleted.';
        return (
            <DeleteDialog
                isDialogOpen={true}
                primaryMessage={deleteInfo}
                handleDialogAction={handleAssetsDelete}
            />
        );
    };

    const handleAssetsActions = (actionType, rowDetails = {}) => {
        // Callback actions will be one of the following: 'view', 'edit', 'move' or 'delete'
        setSelectedAsset(rowDetails);
        if (actionType === VIEW) {
            history.push({
                // NOTE: Once real assets page is done revert user here
                pathname: `/real-assets/${customerId}/assets/${rowDetails.id}`,
            });
        } else if (actionType === NEW_ASSET) {
            history.push({ pathname: `/manage/${customerId}/asset` });
        } else if (actionType === EDIT) {
            history.push({
                pathname: `/manage/${customerId}/assets/${rowDetails.id}/edit`,
            });
        } else if (actionType === MOVE || actionType === BULK_MOVE) {
            setDialogType(actionType);
        } else if (actionType === DELETE || actionType === BULK_DELETE) {
            setDialogType(actionType);
        }
    };

    const handleTableSearchSubmit = (searchVal) => {
        getAssetsData(0, DEFAULT_PAGE_SIZE, searchVal);
        onPageDataChange({
            ...pageData,
            ...DEFAULT_PAGE_DATA,
            searchText: searchVal,
        });
    };

    const handleTagsFilter = (searchVal, tags) => {
        setSelectedTags(tags);
        const tagIds = tags.map((tag) => tag.id).join(',');
        getAssetsData(0, DEFAULT_PAGE_SIZE, searchVal, tagIds);
        onPageDataChange({
            ...pageData,
            ...DEFAULT_PAGE_DATA,
            searchText: searchVal,
        });
    };

    const handleRowsSelection = (selectedRows) => {
        setSelectedRowIds(selectedRows);
        updateSelectedRowsFolderIds(selectedRows);
    };

    const updateSelectedRowsFolderIds = (selectedIDs) => {
        const selectedAssetsList = tableData?.filter((asset) => selectedIDs?.includes(asset?.id));
        const selectedAssetsFolderIDs = selectedAssetsList?.map(
            (folder) => folder?.asset_folder_id
        );
        setSelectedRowsFolderIDs(selectedAssetsFolderIDs);
    };

    return (
        <>
            <CustomHeader
                searchTextPrev={pageData?.searchText}
                searchPlaceholder="Asset Name"
                handleActionClick={handleAssetsActions}
                handleSearchSubmit={handleTableSearchSubmit}
                actionsArr={[NEW_ASSET, BULK_MOVE, BULK_DELETE, TAG_SELECT]}
                multipleRowsSelected={selectedRowIds?.length > 1}
                hideActions={userPrivilege !== ACTION.ACTION_WRITE}
                tagsList={tagsList}
                handleTagsFilter={handleTagsFilter}
            />
            <AllAssetsDataTable
                tableData={tableData}
                isLoading={!dataLoaded}
                totalCount={totalCount}
                pageData={pageData}
                selectedRowIDs={selectedRowIds}
                onPageDataChange={onPageDataChange}
                actionCallback={handleAssetsActions}
                handleRowsSelection={handleRowsSelection}
                hideActions={userPrivilege !== ACTION.ACTION_WRITE}
            />
            {(dialogType === DELETE || dialogType === BULK_DELETE) && getDeleteDialog()}
            {(dialogType === MOVE || dialogType === BULK_MOVE) && (
                <MoveAssetsDialog
                    dialogTitle="Select Folder"
                    foldersList={foldersList}
                    selectedSourceFolders={
                        dialogType === MOVE
                            ? [selectedAsset?.asset_folder_id]
                            : selectedRowsFolderIDs
                    }
                    onSubmit={handleMoveAssetsSubmit}
                    onCreateFolder={handleCreateNewFolder}
                    getFoldersData={getFoldersData}
                    handleRowsSelection={handleRowsSelection}
                />
            )}
        </>
    );
}
