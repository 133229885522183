import { httpClient } from '../utils/httpClient';
import { API_URL } from '../utils/constants';

const ENDPOINT = 'api/investments';

const getAllByCustomer = async (customerId) => {
    const result = await httpClient.get(`${API_URL}/${ENDPOINT}?customer=${customerId}`);
    return result.data;
};

const getAllByGroup = async (groupId) => {
    const result = await httpClient.get(`${API_URL}/${ENDPOINT}?group=${groupId}`);
    return result.data;
};

const getAllByFolder = async (folderId) => {
    const result = await httpClient.get(`${API_URL}/${ENDPOINT}?folder=${folderId}`);
    return result.data;
};

const getAllLocations = async (folderId, investmentId) => {
    const result = await httpClient.get(
        `${API_URL}/${ENDPOINT}?folder=${folderId}&investment=${investmentId}&getLocations=true`
    );
    return result.data;
};

const getInvestment = async (investmentId) => {
    const API_SOURCE = API_URL;

    const result = await httpClient.get(`${API_SOURCE}/${ENDPOINT}/${investmentId}`);
    return result.data;
};

const createInvestment = async (newInvestment, folderId) => {
    const requestBody = {
        investment: newInvestment,
        folder: folderId,
    };
    const result = await httpClient.post(`${API_URL}/${ENDPOINT}`, requestBody, {
        headers: { 'Content-Type': 'application/json' },
    });
    return result.data;
};

const updateInvestment = async (updatedInvestment, folderId) => {
    const { id } = updatedInvestment;
    const result = await httpClient.put(`${API_URL}/${ENDPOINT}/${id}`, updatedInvestment, {
        headers: { 'Content-Type': 'application/json' },
    });
    return result.data;
};

const deleteInvestment = async ({ investmentId, folderId, deletePermanently }) => {
    const result = await httpClient.delete(
        `${API_URL}/${ENDPOINT}?investmentId=${investmentId}&folderId=${folderId}&deletePermanently=${deletePermanently}`
    );
    return result.data;
};

export default {
    getAllByCustomer,
    getAllByGroup,
    getAllByFolder,
    getAllLocations,
    getInvestment,
    createInvestment,
    updateInvestment,
    deleteInvestment,
};
