import * as React from 'react';
import {
    Icon,
    IconButton,
    InputField,
    Button,
    FormGroup,
    Tooltip,
    TooltipTriggerEvent,
    TooltipPlacement,
} from '@spglobal/react-components';
import { EDIT, PLUS, SEARCH } from '@spglobal/koi-icons';
import { useMemo, useState } from 'react';
import { Purpose, Size } from '@spglobal/koi-helpers';
import DataTableGrid from '../../DataTable/DataTableGrid/DataTableGrid';
import { getRoleName, getConcatenatedRoleNames, getExistRoleFromList } from '../../../utils/user';
import { FORM_ACTIONS, RBAC_USERS_ROLES_TYPES } from '../../../utils/constants';
import { HeadingXs } from '../../shared';

export default function InternalUserList({
    tableData = [],
    dataLoaded = true,
    isOpen,
    action,
    editUserId,
}) {
    const [gridApi, setGridApi] = useState(null);

    const handleEditClick = (params) => {
        if (params && params.data && params.data.uid) {
            isOpen(true);
            action(FORM_ACTIONS.EDIT);
            editUserId(params.data.uid);
        }
    };

    const showCustomerCount = (rowDetails) => {
        const roleNamesCount =
            rowDetails &&
            rowDetails.customer &&
            Array.isArray(rowDetails.customer) &&
            rowDetails.customer.length
                ? rowDetails.customer.length
                : 0;

        const roleName =
            rowDetails &&
            rowDetails.role &&
            Array.isArray(rowDetails.role) &&
            rowDetails.role.length
                ? getExistRoleFromList(rowDetails.role)
                : '';

        if (RBAC_USERS_ROLES_TYPES.OKTA_ROLE_ALL.some((item) => item === roleName)) {
            return 'ALL';
        }
        if (RBAC_USERS_ROLES_TYPES.OKTA_ROLE_NA.some((item) => item === roleName)) {
            return 'NA';
        }
        return roleNamesCount;
    };

    const actionCellRenderer = (params) => {
        let isBtnDisabled = false;
        const roleName = params?.data?.role ? params.data.role[0] : '';
        if (RBAC_USERS_ROLES_TYPES.OKTA_ROLE_ALL.some((item) => item === roleName)) {
            isBtnDisabled = true;
        }
        if (RBAC_USERS_ROLES_TYPES.OKTA_ROLE_NA.some((item) => item === roleName)) {
            isBtnDisabled = true;
        }

        if (params?.data?.customer?.length <= 0) {
            isBtnDisabled = true;
        }

        return (
            <IconButton
                icon={EDIT}
                disabled={isBtnDisabled}
                onClick={() => handleEditClick(params)}
                size={Size.SMALL}
            />
        );
    };

    const customerCellRenderer = (params) => {
        const triggerElement = <HeadingXs>{showCustomerCount(params.data)}</HeadingXs>;

        const customers =
            params.data.customer && Array.isArray(params.data.customer) ? params.data.customer : [];

        return (
            <Tooltip
                triggerElement={triggerElement}
                triggerEvent={TooltipTriggerEvent.HOVER}
                isSecondary
                contentPadding={0}
                withArrow={false}
                placement={TooltipPlacement.BOTTOM}
                className="spg-p-xs cli-custom-grid-tooltip"
            >
                {customers.length ? (
                    <ul className="spg-list spg-list--unstyled">
                        <li>
                            <HeadingXs>Customers</HeadingXs>
                        </li>
                        {customers.map((customer, index) => (
                            <li key={index}>{customer.name}</li>
                        ))}
                    </ul>
                ) : (
                    <></>
                )}
            </Tooltip>
        );
    };

    const columnDefs = [
        {
            field: 'userName',
            headerName: 'User Name',
            sortable: true,
            suppressSizeToFit: true,
            tooltipField: 'userName',
            floatingFilter: true,
            filter: true,
        },
        {
            field: 'email',
            headerName: 'User Email-ID',
            filter: true,
            floatingFilter: true,
            sortable: true,
            tooltipField: 'email',
        },
        {
            field: 'customer',
            headerName: 'Customers',
            disableColumnMenu: true,
            disableColumnFilter: true,
            sortable: false,
            filter: false,
            cellRenderer: 'customerColumn',
            width: 85,
            maxWidth: 85,
        },
        {
            field: 'role',
            headerName: 'Role(s)',
            filter: true,
            floatingFilter: true,
            sortable: true,
            valueGetter: (params) => getConcatenatedRoleNames(params.data.role),
            tooltipValueGetter: (params) =>
                getRoleName(params.data.role && params.data.role.length ? params.data.role[0] : ''),
        },
        {
            field: 'Action',
            headerName: 'Action',
            cellRenderer: 'actionsColumn',
            width: 75,
            maxWidth: 75,
            resizable: false,
            filter: false,
            sortable: false,
            cellStyle: { alignItems: 'baseline' },
            cellClass: 'cli-action-cell',
        },
    ];

    const components = useMemo(
        () => ({
            actionsColumn: actionCellRenderer,
            customerColumn: customerCellRenderer,
        }),
        [],
    );

    const handleAssign = () => {
        isOpen(true);
        action(FORM_ACTIONS.ADD);
    };

    const handleQuickFilterTextChanged = (e) => {
        gridApi?.setQuickFilter(e.target.value);
    };

    const handleGridReady = (e) => {
        setGridApi(e.api);
    };

    return (
        <div>
            <div className="spg-row spg-d-flex spg-justify-between">
                <div className="spg-col-10">
                    <FormGroup className="spg-w-25">
                        <InputField
                            onChange={(e) => handleQuickFilterTextChanged(e)}
                            icon={SEARCH}
                        />
                    </FormGroup>
                </div>
                <div className="spg-col-2 spg-d-flex spg-justify-end">
                    <Button
                        purpose={Purpose.SECONDARY}
                        onClick={handleAssign}
                        leftIcon={<Icon icon={PLUS} />}
                    >
                        Assign Customers
                    </Button>
                </div>
            </div>
            <DataTableGrid
                className="cli-datatablegrid-min-height"
                columnDefs={columnDefs}
                frameworkComponents={components}
                rowData={tableData}
                onGridReady={handleGridReady}
                loading={!dataLoaded}
                pagination
            />
        </div>
    );
}
