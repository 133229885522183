import client from './clients';

const getFileProcessingStatus = async (data) => {
    const result = await client.get('/customers/files/status', { params: data });
    return result.data;
};
const getAssetStatus = async (data) => {
    const { file_id } = data;
    // eslint-disable-next-line no-param-reassign
    delete data.file_id;
    const result = await client.get(`/customer/${data.customer_id}/file/${file_id}/status`);
    return result.data;
};
const deleteFileStatus = async (data) => {
    let response = {};
    try {
        response = await client.delete(
            `/customer/${data.customer_id}/file/${data.file_id}/status`,
            data
        );
    } catch (e) {
        response = e?.response;
    }
    return response?.data;
};

const postRequests = {
    getFileProcessingStatus,
    getAssetStatus,
    deleteFileStatus,
};

export default postRequests;
