import React from 'react';
import { Button, H1, Icon, Select } from '@spglobal/react-components';
import { PLUS } from '@spglobal/koi-icons';
import { SEARCH_INPUT_LABEL } from '../components.constants';

export default function Header({
    title,
    type,
    values,
    onChangeFunction,
    link,
    newButtonClicked,
    history,
    hideAddButton,
    exportButton,
    exportToCSV,
}) {
    const handleButtonClick = (e) => {
        e.preventDefault();
        // Backwards-compatible with existing screens
        if (newButtonClicked) {
            newButtonClicked();
        } else {
            history.push({ pathname: `/${link}` });
        }
    };

    return (
        <>
            <div className="spg-row spg-w-100 spg-mt-lg spg-mb-xl">
                <div className="spg-col-8">
                    <H1 data-testid={`${title}`}>{title}</H1>
                </div>
            </div>
            <div className="spg-row spg-w-100">
                <div className="spg-col-8">
                    <Select
                        id="combo-box-header"
                        options={values}
                        isMulti={false}
                        onChange={onChangeFunction}
                        closeOnSelection
                        inFieldLabel={SEARCH_INPUT_LABEL}
                        isSearchTextRemovable
                    />
                </div>
                <div className="spg-col spg-d-flex spg-justify-end">
                    {hideAddButton ? (
                        <></>
                    ) : (
                        type && (
                            <Button
                                onClick={handleButtonClick}
                                purpose="secondary"
                                id="newTypeButton"
                                className="spg-ml-md"
                                leftIcon={<Icon icon={PLUS} />}
                            >
                                New {type}
                            </Button>
                        )
                    )}
                    {exportButton ? (
                        <Button
                            onClick={exportToCSV}
                            purpose="primary"
                            id="exportButton"
                            className="spg-ml-md"
                        >
                            Export
                        </Button>
                    ) : null}
                </div>
            </div>
        </>
    );
}
