import { useEffect, useState } from 'react';
import { httpClient } from '../utils/httpClient';
import { API_URL } from '../utils/constants';

export const useApiGet = (url, params = {}) => {
    const API_SOURCE = API_URL;
    const [state, setState] = useState({
        error: null,
        loading: true,
        data: null,
    });
    const [refreshIndex, setRefreshIndex] = useState(0);

    useEffect(() => {
        (async () => {
            try {
                const res = await httpClient.get(`${API_SOURCE}/${url}`, {
                    params: {
                        ...params,
                    },
                });
                setState({
                    ...state,
                    data: await res.data,
                    error: null,
                    loading: false,
                });
            } catch (error) {
                setState({
                    ...state,
                    error,
                    loading: false,
                });
            }
        })();
    }, [refreshIndex]);

    return {
        ...state,
        refresh: () => setRefreshIndex(refreshIndex + 1),
    };
};
