const formatImpactPathways = (impactPathways) => {
    if (
        !impactPathways ||
        !impactPathways.length ||
        !impactPathways[0].xValues ||
        !impactPathways[0].yValues
    ) {
        return [];
    }

    const maxY = parseInt(
        impactPathways
            .map((impactPathway) => impactPathway.yValues.split(';'))
            .flat()
            .reduce(
                (acc, currentValue) => Math.max(acc, !isNaN(currentValue) ? currentValue : 0),
                0
            )
    );
    // TODO: confirm behavior for non-100-based impact functions (i.e. Carbon Pricing or Electricity Demand)
    const baseValue = maxY > 100 ? 0 : maxY;

    const result = impactPathways.reduce((acc, impactPathway) => {
        if (acc.length < 1) {
            impactPathway.xValues.split(';').forEach((xVal) => {
                acc.push({
                    '% impact metric': xVal,
                    offset: 0,
                });
            });
        }

        impactPathway.yValues.split(';').forEach((yValue, index) => {
            if (index < acc.length) {
                acc[index] = {
                    ...acc[index],
                    offset: acc[index].offset + (baseValue - parseFloat(yValue)),
                    [impactPathway.name]: [
                        baseValue - acc[index].offset,
                        parseFloat(yValue) - acc[index].offset,
                    ],
                };
            }
        });
        return acc;
    }, []);
    return result;
};

export { formatImpactPathways };
