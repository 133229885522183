import * as React from 'react';

import { FormGroup, Icon, IconButton, InputField, Button } from '@spglobal/react-components';
import { EDIT, EXPORT, PLUS, SEARCH } from '@spglobal/koi-icons';
import { Purpose, Size } from '@spglobal/koi-helpers';
import { useMemo, useState } from 'react';
import DataTableGrid from '../../DataTable/DataTableGrid/DataTableGrid';
import { getConcatenatedRoleNames } from '../../../utils/user';
import { FORM_ACTIONS } from '../../../utils/constants';
import { HeadingXs } from '../../shared';

export default function ExternalUserList({
    tableData = [],
    dataLoaded = true,
    isOpen,
    action,
    editUserId,
}) {
    const [gridApi, setGridApi] = useState(null);

    const handleEditClick = (params) => {
        if (params && params.data && params.data.uid) {
            isOpen(true);
            action(FORM_ACTIONS.EDIT);
            editUserId(params.data.uid);
        }
    };

    const actionCellRenderer = (params) => {
        const isBtnDisabled = !(params.data.customer && params.data.customer.name);

        return (
            <IconButton
                icon={EDIT}
                disabled={isBtnDisabled}
                onClick={() => handleEditClick(params)}
                size={Size.SMALL}
            />
        );
    };

    const customerCellRenderer = (params) => {
        const customerName =
            params.data.customer && params.data.customer.name ? params.data.customer.name : '-';
        return <HeadingXs>{customerName}</HeadingXs>;
    };

    const columnDefs = [
        {
            field: 'userName',
            headerName: 'User Name',
            tooltipField: 'userName',
            filter: true,
            sortable: true,
            floatingFilter: true,
        },
        {
            field: 'email',
            headerName: 'User Email-ID',
            filter: true,
            sortable: true,
            floatingFilter: true,
        },
        {
            field: 'customer',
            headerName: 'Customer Name',
            filter: false,
            sortable: false,
            cellRenderer: 'customerColumn',
            valueGetter: (params) =>
                params.data.customer && params.data.customer.name ? params.data.customer.name : '-',
            tooltipValueGetter: (params) =>
                params.data.customer && params.data.customer.name ? params.data.customer.name : '-',
        },
        {
            field: 'role',
            headerName: 'Role(s)',
            filter: true,
            sortable: true,
            floatingFilter: true,
            valueGetter: (params) => getConcatenatedRoleNames(params.data.role),
            tooltipValueGetter: (params) => getConcatenatedRoleNames(params.data.role),
        },
        {
            field: 'Action',
            headerName: 'Action',
            cellRenderer: 'actionsColumn',
            width: 75,
            maxWidth: 75,
            resizable: false,
            filter: false,
            sortable: false,
            cellStyle: { alignItems: 'baseline' },
            cellClass: 'cli-action-cell',
        },
    ];

    const components = useMemo(
        () => ({
            actionsColumn: actionCellRenderer,
            customerColumn: customerCellRenderer,
        }),
        [],
    );

    const handleAssign = () => {
        isOpen(true);
        action(FORM_ACTIONS.ADD);
    };

    const handleQuickFilterTextChanged = (e) => {
        gridApi?.setQuickFilter(e.target.value);
    };

    const handleGridReady = (e) => {
        setGridApi(e.api);
    };

    const exportTableDataToCSV = () => {
        gridApi?.exportDataAsCsv({
            columnKeys: ['userName-0', 'email-1', 'customer-2', 'role-3'],
            fileName: 'Climanomics.csv',
        });
    };

    return (
        <div>
            <div className="spg-row spg-d-flex spg-justify-between">
                <div className="spg-col-10">
                    <FormGroup className="spg-w-25">
                        <InputField
                            onChange={(e) => handleQuickFilterTextChanged(e)}
                            icon={SEARCH}
                        />
                    </FormGroup>
                </div>
                <div className="spg-col-2 spg-d-flex spg-justify-end">
                    <Button
                        purpose={Purpose.SECONDARY}
                        onClick={exportTableDataToCSV}
                        rightIcon={<Icon icon={EXPORT} />}
                    >
                        Export
                    </Button>
                    <Button
                        className="spg-ml-md"
                        purpose={Purpose.SECONDARY}
                        onClick={handleAssign}
                        leftIcon={<Icon icon={PLUS} />}
                    >
                        Assign Customers
                    </Button>
                </div>
            </div>
            <DataTableGrid
                className="cli-datatablegrid-min-height"
                columnDefs={columnDefs}
                pagination
                frameworkComponents={components}
                rowData={tableData}
                onGridReady={handleGridReady}
                loading={!dataLoaded}
            />
        </div>
    );
}
