import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { H5 } from '@spglobal/react-components';
import { useApiGet } from 'hooks/useApiGet';
import MapWrapper from '../LocationMap';
import { getAllAssetsForFolder, getCustomerAssets } from '../../services/assetService';
import riskColor from '../../utils/riskColor';
import { useHierarchyContext } from '../../context/HierarchyContext';

export default function InvestmentAssetsLocationMap({
    entityType,
    entityId,
    riskDataFilters,
    investmentId,
    folderId,
    viewId,
    customerId,
    tableData,
}) {
    // TEMPORARY HACK TO EXCLUDE SPECIFIC CUSTOMER W/ LARGE DATA SET TO LOAD WITHOUT MAP
    const { currentPath } = useHierarchyContext();
    const customerIdFromPath = currentPath.substring(1).split('/')?.[1];
    const { data: appConfigs } = useApiGet('api/appConfig', {});
    const locationMapThreshold = appConfigs?.results?.find(
        (i) => i.key === 'location_map_threshold',
    ).value;
    const mapProhibited =
        customerIdFromPath === '2109' ||
        customerIdFromPath === '4' ||
        customerIdFromPath === '1122';

    if (mapProhibited) {
        return <></>;
    }

    const [locations, setLocations] = useState([]);
    const [showMap, setShowMap] = useState(false);

    const addRiskInfoToInvestmentLocations = (locationsArray, assetTableData) => {
        const locationsWithColor = locationsArray.map((location) => {
            const findTableData = assetTableData.find(
                (asset) => asset.entityId === location.asset_id,
            );

            const assetObject = findTableData || {
                absoluteValue: '$0.00',
                relativeValue: '0.0 %',
            };

            return {
                ...location,
                ...assetObject,
                color: riskColor.determineColor(assetObject.relative_value, 'relative'),
            };
        });

        return locationsWithColor;
    };

    const updateLocationEntityNames = (locs, assets) => {
        locs.forEach((loc) => {
            const matchingAsset = assets.find((asset) => asset.asset_id === loc.asset_id);

            if (matchingAsset) {
                loc.entity_name = matchingAsset.asset_name;
            }
        });
        return locs;
    };

    useEffect(() => {
        async function fetchData() {
            if (entityId) {
                const assetCountData = await getCustomerAssets(customerId, { assetcount: true });
                if (
                    assetCountData.active_asset_count < locationMapThreshold ||
                    folderId ||
                    viewId
                ) {
                    const investmentAssetLocationsResponse = await getAllAssetsForFolder({
                        customerId,
                        folderId,
                        viewId,
                        getLocations: true,
                    });

                    if (investmentAssetLocationsResponse.length < locationMapThreshold) {
                        // pull locations from our Assets response
                        const investmentAssetLocations = investmentAssetLocationsResponse.reduce(
                            (accumulator, currentAsset) =>
                                accumulator.concat(currentAsset.location_list),
                            [],
                        );

                        // add risk Data to locations from our Assets response
                        const investmentAssetLocationsWithRisks = addRiskInfoToInvestmentLocations(
                            investmentAssetLocations,
                            tableData,
                            riskDataFilters,
                        );

                        const investmentAssetLocationsWithRisksAndNames = updateLocationEntityNames(
                            investmentAssetLocationsWithRisks,
                            investmentAssetLocationsResponse,
                        );

                        setShowMap(true);
                        setLocations(investmentAssetLocationsWithRisksAndNames);
                    }
                }
            }
        }
        fetchData();
    }, [
        riskDataFilters,
        entityId,
        entityType,
        folderId,
        investmentId,
        showMap,
        viewId,
        locationMapThreshold,
    ]);

    return (
        <>
            {showMap ? (
                <MapWrapper locations={locations} displayLegend={true} />
            ) : (
                <div className="spg-col-6 spg-py-lg">
                    <H5>
                        The map is temporarily unavailable for portfolio asset counts of 10,000 and
                        above to prevent performance issues in loading your portfolio.
                    </H5>
                </div>
            )}
        </>
    );
}

InvestmentAssetsLocationMap.propTypes = {
    customerId: PropTypes.string || PropTypes.number,
    entityId: PropTypes.string || PropTypes.number,
    entityType: PropTypes.number,
    folderId: PropTypes.string || PropTypes.number,
    groupId: PropTypes.number || PropTypes.string,
    handleClick: PropTypes.func,
    investmentId: PropTypes.number || PropTypes.string,
    riskDataFilters: PropTypes.object,
};
