import React from 'react';
import GenerateReportTable from '../components/GenerateReportTable';
import { ASSET_SUMMARY_REPORT, PORTFOLIO_SUMMARY_REPORT, SP_EMAIL_DOMAIN } from '../utils/constants';
import { getUserDetails } from '../utils/rbacUtils';
import { getLocalDateTime } from '../utils/generateReportUtils';

export default function SummaryReportTable({
    tableData,
    getReportList,
    updateTableState,
    count,
    pageData,
    setPageData,
    selectedCustomer,
}) {
    const { email } = getUserDetails();

    const isInternalUser = email.indexOf(SP_EMAIL_DOMAIN) !== -1;

    const getFilterCount = (row) => {
        let filterCount = 0;
        const filters = row?.filters ? row.filters : {};
        Object.keys(filters).forEach((item) => {
            // if (item === 'asset_id' && filters[item]) filterCount += 1;
            if (item === 'decade' && filters[item]) filterCount += 1;
            if (item === 'risks' && filters[item]?.length) filterCount += 1;
            if (item === 'scenario' && filters[item]?.length) filterCount += 1;
            if (item === 'summary_report_type' && filters[item]) filterCount += 1;
        });

        return filterCount;
    };

    const isPortfolioSummaryReportSelected = (data) => data?.filters?.summary_report_type === PORTFOLIO_SUMMARY_REPORT.value;
    const isAssetSummaryReportSelected = (data) => data?.filters?.summary_report_type === ASSET_SUMMARY_REPORT.value;

    const getSummaryReportSelectedValue = (data) => {
        let result = '';
        if (isPortfolioSummaryReportSelected(data)) {
            result = PORTFOLIO_SUMMARY_REPORT.label;
        } else if (isAssetSummaryReportSelected(data)) {
            result = ASSET_SUMMARY_REPORT.label
        }
        return result;
    };

    const folderAssetsValueGetter = (data) => {
        let result = '';
        if (isPortfolioSummaryReportSelected(data)) {
            result = data?.folder_ids?.length && data?.folder_names?.join(', ');
        } else if (isAssetSummaryReportSelected(data)) {
            result = `Asset ${data?.filters?.asset_name}`;
        }
        return result;
    }

    const folderAssetsTooltipValueGetter = (data) => {
        if (isPortfolioSummaryReportSelected(data)) {
            return data?.folder_names?.join(', ');
        }
        if (isAssetSummaryReportSelected(data)) {
            return data?.filters?.asset_name;
        }
    }

    const columnDefs = [
        {
            headerName: 'Report name',
            headerClass: 'summary-report-header-cell',
            field: 'file_name',
            sortable: true,
            tooltipField: 'file_name',
            cellStyle: { display: 'block', textAlign: 'center' },
        },
        {
            headerName: 'Report type',
            headerClass: 'summary-report-header-cell',
            valueGetter: ({ data }) => getSummaryReportSelectedValue(data),
            tooltipValueGetter: ({ data }) => getSummaryReportSelectedValue(data),
            sortable: true,
            cellStyle: { display: 'block', textAlign: 'center' },
        },
        {
            headerName: 'Folders/Assets',
            headerClass: 'summary-report-header-cell',
            valueGetter: ({ data }) => folderAssetsValueGetter(data),
            tooltipValueGetter: ({ data }) => folderAssetsTooltipValueGetter(data),
            sortable: true,
            cellStyle: { display: 'block', textAlign: 'center' },
        },
        {
            headerName: 'Filters used',
            headerClass: 'summary-report-header-cell',
            sortable: true,
            cellRenderer: 'filtersRenderer',
            maxWidth: 130,
            cellStyle: { alignItems: 'end', justifyContent: 'center' },
        },
        {
            headerName: 'Generated By',
            headerClass: 'summary-report-header-cell',
            field: 'user_email',
            sortable: true,
            tooltipField: 'user_email',
            cellStyle: { display: 'block', textAlign: 'center' },
        },
        {
            headerName: 'Generated On',
            headerClass: 'summary-report-header-cell',
            valueGetter: ({ data }) => new Date(data?.created_at).toLocaleDateString(),
            sortable: true,
            tooltipValueGetter: ({ data }) => getLocalDateTime(data),
            maxWidth: 130,
            cellStyle: { display: 'block', textAlign: 'center' },
        },
        {
            headerName: 'Status',
            headerClass: 'summary-report-header-cell',
            cellRenderer: 'fileStatusRenderer',
            maxWidth: 110,
            cellStyle: { alignItems: 'baseline', justifyContent: 'center' },
            cellClass: 'cli-action-cell',
        },
        {
            headerName: ' ',
            field: ' ',
            cellRenderer: 'refreshRenderer',
            maxWidth: 50,
            cellStyle: { justifyContent: 'center', alignItems: 'baseline' },
            cellClass: 'cli-action-cell',
        },
    ];

    if (isInternalUser) {
        columnDefs.unshift({
            headerName: 'Customer Name',
            headerClass: 'summary-report-header-cell',
            field: 'customer_name',
            sortable: true,
            tooltipField: 'customer_name',
            cellStyle: { display: 'block', textAlign: 'center' },
        })
    }

    return (
        <GenerateReportTable
            tableData={tableData}
            getReportList={getReportList}
            updateTableState={updateTableState}
            count={count}
            pageData={pageData}
            setPageData={setPageData}
            columnDefs={columnDefs}
            selectedCustomer={selectedCustomer}
            getFilterCount={getFilterCount}
            isSummaryReportTable
        />
    );
}
