import React from 'react';

import './HazardVulnerabilityDrawer.scss';

import { H4, IconButton } from '@spglobal/react-components';
import { CLEAR } from '@spglobal/koi-icons';
import VulnerabilityGraph from '../VulnerabilityGraph';
import DecadalHazardGraph from '../DecadalHazardGraph';
import { TextBodyLg } from '../shared';

export default function HazardVulnerabilityDrawer({
    open,
    handleClose,
    drawerTitle,
    riskDataFilters,
    vulnerabilityDataToGraph,
    assetId,
    hazardMetricId,
    riskFactorName,
    locations,
    hazardOnly,
}) {
    const hVDrawerWidth = 650;
    return (
        <>
            <div aria-hidden="true" />
            <div tabIndex="-1" className={`drawer ${open && 'animate'} ${!open && 'hidden'} right`}>
                <div className="spg-row spg-p-md ">
                    <div className="spg-col-11 spg-d-flex spg-justify-center spg-align-center">
                        <H4 data-testid="drawer-graph-title">{drawerTitle}</H4>
                    </div>
                    <div className="spg-col-1">
                        <IconButton
                            aria-label="close-hvr-drawer"
                            icon={CLEAR}
                            onClick={handleClose}
                        />
                    </div>
                </div>
                <div>
                    <div>
                        <div className="spg-p-md">
                            {riskDataFilters && hazardMetricId && (
                                <DecadalHazardGraph
                                    riskDataFilters={riskDataFilters}
                                    hazardMetricId={hazardMetricId}
                                    riskFactorName={riskFactorName}
                                    minWidth={hVDrawerWidth * 0.85}
                                    locations={locations}
                                />
                            )}
                        </div>
                        <div className="spg-p-md">
                            {vulnerabilityDataToGraph && !hazardOnly && (
                                <VulnerabilityGraph
                                    dataToGraph={vulnerabilityDataToGraph}
                                    minWidth={hVDrawerWidth * 0.85}
                                    displayHeader="Vulnerability (Impact Function)"
                                />
                            )}
                            {hazardOnly && (
                                <TextBodyLg className="spg-flex spg-p-md spg-col-12 spg-align-center">
                                    <p className="spg-text-center spg-w-100 spg-pl-lg spg-pr-lg spg-pt-xs">
                                        The Vulnerability graph for this hazard and this asset is
                                        not yet available as impact functions are still in the
                                        process of being developed. The Vulnerability graph and the
                                        breakdown by Impact Pathways will be available once impact
                                        functions are implemented in Climanomics.
                                    </p>
                                </TextBodyLg>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
