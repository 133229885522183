import { ARROW_UP, CANCEL, CHECK, CLOCK, NO_CONNECTION } from '@spglobal/koi-icons';
import { badgeAppearance } from '@spglobal/react-components';
import { faExclamation } from '@fortawesome/free-solid-svg-icons';
import { FILE_PROCESSING_STATUS } from './import.constants';

export const UPLOAD_STATUS_BADGE_CONFIG_MAP = {};
UPLOAD_STATUS_BADGE_CONFIG_MAP[FILE_PROCESSING_STATUS.FILE_UPLOAD_INPROGRESS] = {
    icon: ARROW_UP,
    badgeType: badgeAppearance.blue,
    iconColor: 'white',
};
UPLOAD_STATUS_BADGE_CONFIG_MAP[FILE_PROCESSING_STATUS.FILE_UPLOAD_FAILED] = {
    icon: CANCEL,
    badgeType: badgeAppearance.darkRed,
    iconColor: 'white',
};
UPLOAD_STATUS_BADGE_CONFIG_MAP[FILE_PROCESSING_STATUS.FILE_UPLOAD_COMPLETED] = {
    icon: ARROW_UP,
    badgeType: badgeAppearance.blue,
    iconColor: 'white',
};
UPLOAD_STATUS_BADGE_CONFIG_MAP[FILE_PROCESSING_STATUS.ASSET_VALIDATION_INPROGRESS] = {
    icon: ARROW_UP,
    badgeType: badgeAppearance.blue,
    iconColor: 'white',
};
UPLOAD_STATUS_BADGE_CONFIG_MAP[FILE_PROCESSING_STATUS.ASSET_VALIDATION_FAILED] = {
    icon: NO_CONNECTION,
    badgeType: badgeAppearance.deepRed,
    iconColor: 'white',
};
UPLOAD_STATUS_BADGE_CONFIG_MAP[FILE_PROCESSING_STATUS.PARSER_EXCEPTION] = {
    icon: NO_CONNECTION,
    badgeType: badgeAppearance.deepRed,
    iconColor: 'white',
};
UPLOAD_STATUS_BADGE_CONFIG_MAP[FILE_PROCESSING_STATUS.ASSET_VALIDATION_COMPLETED] = {
    icon: CLOCK,
    badgeType: badgeAppearance.yellow,
    iconColor: 'black',
};
UPLOAD_STATUS_BADGE_CONFIG_MAP[FILE_PROCESSING_STATUS.RISK_SCORING_INPROGRESS] = {
    icon: CLOCK,
    badgeType: badgeAppearance.yellow,
    iconColor: 'black',
};
UPLOAD_STATUS_BADGE_CONFIG_MAP[FILE_PROCESSING_STATUS.RISK_SCORING_PARTIALLY_SUCCEEDED] = {
    icon: faExclamation,
    badgeType: badgeAppearance.deepRed,
    iconColor: 'white',
};
UPLOAD_STATUS_BADGE_CONFIG_MAP[FILE_PROCESSING_STATUS.RISK_SCORING_FAILED] = {
    icon: faExclamation,
    badgeType: badgeAppearance.deepRed,
    iconColor: 'white',
};
UPLOAD_STATUS_BADGE_CONFIG_MAP[FILE_PROCESSING_STATUS.RISK_SCORING_COMPLETED] = {
    icon: CHECK,
    badgeType: badgeAppearance.thirteenth,
    iconColor: 'white',
};
UPLOAD_STATUS_BADGE_CONFIG_MAP[FILE_PROCESSING_STATUS.RESCORING_INPROGRESS] = {
    icon: CLOCK,
    badgeType: badgeAppearance.yellow,
    iconColor: 'black',
};
UPLOAD_STATUS_BADGE_CONFIG_MAP[FILE_PROCESSING_STATUS.RESCORING_FAILED] = {
    icon: faExclamation,
    badgeType: badgeAppearance.deepRed,
    iconColor: 'white',
};
UPLOAD_STATUS_BADGE_CONFIG_MAP[FILE_PROCESSING_STATUS.RESCORING_PARTIALLY_SUCCEEDED] = {
    icon: faExclamation,
    badgeType: badgeAppearance.deepRed,
    iconColor: 'white',
};
UPLOAD_STATUS_BADGE_CONFIG_MAP[FILE_PROCESSING_STATUS.RESCORING_SUCCEEDED] = {
    icon: CHECK,
    badgeType: badgeAppearance.thirteenth,
    iconColor: 'white',
};
UPLOAD_STATUS_BADGE_CONFIG_MAP[FILE_PROCESSING_STATUS.RESCORING_AGGREGATION_FAILED] = {
    icon: faExclamation,
    badgeType: badgeAppearance.deepRed,
    iconColor: 'white',
};
UPLOAD_STATUS_BADGE_CONFIG_MAP[FILE_PROCESSING_STATUS.RESCORING_AGGREGATION_INPROGRESS] = {
    icon: CLOCK,
    badgeType: badgeAppearance.yellow,
    iconColor: 'black',
};
UPLOAD_STATUS_BADGE_CONFIG_MAP[FILE_PROCESSING_STATUS.AGGREGATION_FAILED] = {
    icon: faExclamation,
    badgeType: badgeAppearance.deepRed,
    iconColor: 'white',
};
UPLOAD_STATUS_BADGE_CONFIG_MAP[FILE_PROCESSING_STATUS.AGGREGATION_INPROGRESS] = {
    icon: CLOCK,
    badgeType: badgeAppearance.yellow,
    iconColor: 'black',
};
