import React from 'react';
import { Icon, InlineNotification, Link } from '@spglobal/react-components';
import { ANGLE_RIGHT } from '@spglobal/koi-icons';

export default function ProductAlert() {
    return (
        <InlineNotification type="info" showIcon={false} isOpen closable={false}>
            <div className="spg-d-flex spg-align-center spg-justify-between">
                <div>
                    Contact us to get trial access, upgrade to full access, or to extend a trial
                    period.
                </div>
                <Link
                    aria-label="Get in touch"
                    href="https://www.theclimateservice.com/contact"
                    target="_blank"
                    rel="noreferrer"
                >
                    <Icon icon={ANGLE_RIGHT} />{' '}
                </Link>
            </div>
        </InlineNotification>
    );
}
