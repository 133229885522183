/**
 * Transforms a data object for use in the Koi Select component.
 * This function maps the specified value from the data object to the 'label' and 'value' properties of the transformed object.
 * @param {Object} dataObj - The original data object to transform. Must contain 'id' property
 * @param {{id: string, label: string, value: string}} valueKeyMap - Map: which property of object should be mapped to the target output
 * @returns {Object} The transformed data object with 'id', 'label' and 'value' properties.
 */

export const transformDataObjForKoiSelect = (dataObj, valueKeyMap) => ({
    id: dataObj[valueKeyMap.id || 'id'],
    label: dataObj[valueKeyMap.label || 'label'],
    value: dataObj[valueKeyMap.value || 'value'],
});

export const isEmptyObject = (obj) => Object.keys(obj).length === 0;
