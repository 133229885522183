import React from 'react';
import { HeadingSm, HeadingXs, TextBodyMd } from '../shared';

export default function LocationCard({ location, locationIndex }) {
    const locationRowValues = {
        lat: 'Latitude',
        long: 'Longitude',
        elevation: 'Elevation',
        street_address: 'Street Address',
        state_province: 'State/Province',
        city: 'City/Town',
        postal_code: 'Postal Code',
        country: 'Country',
        ref_id: 'Location Reference ID',
    };
    if (location) {
        return (
            <>
                <tr className="spg-mt-md">
                    <td align="center" colSpan={2}>
                        <HeadingSm><em>{`Location ${locationIndex + 1} Details`}</em></HeadingSm>
                    </td>
                </tr>
                {location &&
                    Object.keys(locationRowValues).map((locationKey, index) => {
                        if (locationKey !== 'id') {
                            return (
                                <tr key={`location-tableItem-${locationKey}-${index}`}>
                                    <td>
                                        <HeadingXs>{locationRowValues[locationKey]}</HeadingXs>
                                    </td>
                                    <td data-testid={`location-${locationKey}-${locationIndex}`}>
                                        {!location[locationKey] && location[locationKey] !== 0 ? (
                                            <TextBodyMd><em>{locationKey} not present</em></TextBodyMd>
                                        ) : locationKey === 'refName' && location[locationKey] ? (
                                            location[locationKey]
                                        ): locationKey === 'elevation' && location[locationKey] ? (
                                            <TextBodyMd>{`${location[locationKey]} meters above local mean sea level`}</TextBodyMd>
                                        ) : (
                                            <TextBodyMd>{location[locationKey]}</TextBodyMd>
                                        )}
                                    </td>
                                </tr>
                            );
                        }
                        return <></>;
                    })}
            </>
        );
    }
    return <></>;
}
