import React from 'react';
import {
    Button,
    Modal,
    ModalContent,
    ModalFooter,
    ModalHeader,
    Select,
    Checkbox,
    TableGrid,
} from '@spglobal/react-components';
import { Size } from '@spglobal/koi-helpers';
import { transformDataObjForKoiSelect } from '../../../utils/koiIntegrationUtils';

export default function ImpactFunctionsModal({
    showImpactFunctionModal,
    fields,
    allOptions,
    toggleImpactFunctionsList,
    selectedRiskFactor,
    handleCheckboxToggle,
    allImpactFunctions,
    onChangeFunction,
}) {
    const originOptions = allImpactFunctions
        .filter((impactFunction) => impactFunction.riskFactorId === selectedRiskFactor.id)
        .map((o) => ({
            ...transformDataObjForKoiSelect(o, {
                id: 'id',
                label: 'name',
                value: 'id',
            }),
            riskFactorId: o.riskFactorId,
        }));

    const options = allOptions
        .filter((impactFunction) => impactFunction.riskFactorId === selectedRiskFactor.id)
        .map((o) => ({
            ...transformDataObjForKoiSelect(o, {
                id: 'id',
                label: 'name',
                value: 'id',
            }),
            riskFactorId: o.riskFactorId,
        }));

    const columnDefs = [
        {
            path: 'name.value',
            title: 'Name',
        },
        {
            title: 'Selected',
            path: 'selected',
        },
    ];

    const data = options.map((impactFunction, index) => ({
        name: {
            value: impactFunction.label,
        },
        selected: (
            <Checkbox
                data-testid={`impact-function-row-${index}-checkbox`}
                onChange={handleCheckboxToggle(impactFunction)}
                inputProps={{
                    'aria-label': 'select impact function',
                }}
                checked={
                    fields.findIndex(
                        (selectedImpactFunction) => selectedImpactFunction.id === impactFunction.id
                    ) !== -1
                }
            />
        ),
    }));

    return (
        <Modal isOpen={showImpactFunctionModal} aria-labelledby="form-dialog-title">
            <ModalHeader id="form-dialog-title" title="Add Impact Functions"></ModalHeader>
            <ModalContent>
                <Select
                    id="combo-box-demo"
                    options={originOptions}
                    isMulti={false}
                    componentSize={Size.MEDIUM}
                    onChange={onChangeFunction}
                    closeOnSelection
                    inFieldLabel="Search:"
                    isSearchTextRemovable
                />
                <TableGrid hasHeader={false} columns={columnDefs} data={data} />
            </ModalContent>
            <ModalFooter>
                <Button
                    type="button"
                    purpose="primary"
                    active={true}
                    onClick={toggleImpactFunctionsList}
                    data-testid="impact-function-modal-close-button"
                >
                    Close
                </Button>
            </ModalFooter>
        </Modal>
    );
}
