import React from 'react';
import { FormGroup, Select } from '@spglobal/react-components';
import { REPORT_TYPE_OPTIONS as reportTypeOptions } from '../../utils/constants';

export function ReportTypeSelect({
     selectedReportType,
     setSelectedReportType,
     setSelectedAsset,
     setSelectedFolders,
}) {
    const handleReportTypeChange = (values) => {
        setSelectedReportType(values);
        setSelectedAsset({});
        setSelectedFolders([]);
    };

    return (
        <FormGroup label="Select Report Type" labelFor="report-type-select" required>
            <Select
                id="report-type-select"
                defaultValue={selectedReportType}
                options={reportTypeOptions}
                onChange={handleReportTypeChange}
                isSearchable={false}
                isMulti={false}
                closeOnSelection
                placeholder=""
            />
        </FormGroup>
    );
}
