import * as React from 'react';
import { RBAC_ROLES, FORM_ACTIONS, RBAC_USERS_ROLES_TYPES } from '../../../utils/constants';
import DataTableGrid from '../../DataTable/DataTableGrid/DataTableGrid';
import { useMemo, useState } from 'react';
import {
    Button,
    FormGroup,
    Icon,
    IconButton,
    InputField,
    Tooltip,
    TooltipPlacement,
    TooltipTriggerEvent,
} from '@spglobal/react-components';
import { EDIT, EXPORT, PLUS, SEARCH } from '@spglobal/koi-icons';
import { Purpose } from '@spglobal/koi-helpers';
import { HeadingXs } from '../../shared';

export default function UserList({ tableData = [], dataLoaded = true, isOpen, action, editUser }) {
    const [gridApi, setGridApi] = useState(null);

    const handleEditClick = (params) => {
        if (params && params.data && params.data.user_id) {
            isOpen(true);
            action(FORM_ACTIONS.EDIT);
            editUser(params.data);
        }
    };

    const columnDefs = [
        {
            field: 'user_name',
            headerName: 'User Name',
            width: 150,
            tooltipField: 'user_name',
            filter: true,
            floatingFilter: true,
        },
        {
            field: 'user_email',
            headerName: 'User Email-ID',
            width: 150,
            tooltipField: 'user_email',
            filter: true,
            floatingFilter: true,
        },
        {
            field: 'folder_names_list',
            headerName: '#Folders',
            type: 'number',
            cellRenderer: 'foldersCellRenderer',
            disableColumnMenu: true,
            disableColumnFilter: true,
            sortable: false,
            filter: false,
            width: 85,
            maxWidth: 85,
            valueGetter: (params) => params.data.folder_names_list?.map((f) => f.folder_name).join(', '),
        },
        {
            field: 'view_names_list',
            headerName: '#Views',
            type: 'number',
            cellRenderer: 'viewsCellRenderer',
            disableColumnMenu: true,
            disableColumnFilter: true,
            sortable: false,
            filter: false,
            width: 85,
            maxWidth: 85,
            valueGetter: (params) =>
                params.data.view_names_list?.map((v) => v.view_name).join(', '),
        },
        {
            field: 'role',
            headerName: 'Role',
            width: 150,
            tooltipField: 'role',
            filter: true,
            floatingFilter: true,
            valueGetter: (params) => RBAC_ROLES[params.data.role] || params.data.role,
        },
        {
            field: 'Action',
            headerName: 'Action',
            cellRenderer: 'actionsColumn',
            tooltipValueGetter: () => 'Edit',
            width: 75,
            maxWidth: 75,
            resizable: false,
            filter: false,
            sortable: false,
            cellStyle: { alignItems: 'baseline' },
            cellClass: 'cli-action-cell',
        },
    ];

    const handleAssign = () => {
        isOpen(true);
        action(FORM_ACTIONS.ADD);
    };

    const FoldersCellRenderer = (params) => {
        const triggerElement = (
            <HeadingXs>{params.data.no_of_folders ? params.data.no_of_folders : 0}</HeadingXs>
        );
        const folders =
            params.data.folder_names_list && Array.isArray(params.data.folder_names_list)
                ? params.data.folder_names_list
                : [];

        return (
            <Tooltip
                triggerElement={triggerElement}
                triggerEvent={TooltipTriggerEvent.HOVER}
                isSecondary
                contentPadding={0}
                withArrow={false}
                placement={TooltipPlacement.BOTTOM}
                className="spg-p-xs cli-custom-grid-tooltip"
            >
                <ul className="spg-list spg-list--unstyled">
                    <li>
                        <HeadingXs>Folders</HeadingXs>
                    </li>
                    {folders.map((f, index) => (
                        <li key={index}>{f.folder_name}</li>
                    ))}
                </ul>
            </Tooltip>
        );
    };

    const ViewsCellRenderer = (params) => {
        const triggerElement = (
            <HeadingXs>{params.data.no_of_views ? params.data.no_of_views : 0}</HeadingXs>
        );
        const views =
            params.data.view_names_list && Array.isArray(params.data.view_names_list)
                ? params.data.view_names_list
                : [];

        return (
            <Tooltip
                triggerElement={triggerElement}
                triggerEvent={TooltipTriggerEvent.HOVER}
                isSecondary
                contentPadding={0}
                withArrow={false}
                placement={TooltipPlacement.BOTTOM}
                className="spg-p-xs cli-custom-grid-tooltip"
            >
                <ul className="spg-list spg-list--unstyled">
                    <li>
                        <HeadingXs>Groups</HeadingXs>
                    </li>
                    {views.map((v, index) => (
                        <li key={index}>{v.view_name}</li>
                    ))}
                </ul>
            </Tooltip>
        );
    };

    const ActionCellRenderer = (params) => {
        const isDisabled = params.data.no_of_folders === 0 && params.data.no_of_views === 0;

        return (
            <IconButton icon={EDIT} disabled={isDisabled} onClick={() => handleEditClick(params)} />
        );
    };

    const components = useMemo(
        () => ({
            actionsColumn: ActionCellRenderer,
            viewsCellRenderer: ViewsCellRenderer,
            foldersCellRenderer: FoldersCellRenderer,
        }),
        [],
    );

    const handleQuickFilterTextChanged = (e) => {
        gridApi?.setQuickFilter(e.target.value);
    };

    const handleGridReady = (e) => {
        setGridApi(e.api);
    };

    const exportTableDataToCSV = () => {
        gridApi?.exportDataAsCsv({
            columnKeys: ['user_name-0', 'user_email-1', 'folder_names_list-2', 'view_names_list-3', 'role-4'],
            fileName: 'Climanomics.csv',
        });
    };

    return (
        <div>
            <div className="spg-row spg-d-flex spg-justify-between">
                <div className="spg-col-10">
                    <FormGroup className="spg-w-25">
                        <InputField
                            onChange={(e) => handleQuickFilterTextChanged(e)}
                            icon={SEARCH}
                        />
                    </FormGroup>
                </div>
                <div className="spg-col-2 spg-d-flex spg-justify-end">
                    <Button
                        purpose={Purpose.SECONDARY}
                        onClick={exportTableDataToCSV}
                        rightIcon={<Icon icon={EXPORT} />}
                    >
                        Export
                    </Button>
                    <Button
                        className="spg-ml-md"
                        purpose={Purpose.SECONDARY}
                        onClick={handleAssign}
                        leftIcon={<Icon icon={PLUS} />}
                    >
                        Assign Folders/Groups
                    </Button>
                </div>
            </div>
            <DataTableGrid
                className="cli-datatablegrid-min-height"
                columnDefs={columnDefs}
                frameworkComponents={components}
                rowData={tableData}
                onGridReady={handleGridReady}
                loading={!dataLoaded}
                pagination
            />
        </div>
    );
}
