import { getRole } from '../utils/user';
import { GroupAndResourceMapping, ACTION } from '../utils/constants';

function _getUserResourcePermissions(userGroup, resourceType) {
    // CHECK PERMISSIONS AND SET IT'S VALUE
    let permission = null;
    GroupAndResourceMapping()[userGroup].forEach((itemResource) => {
        if (itemResource.resource.some((rItem) => resourceType === rItem)) {
            permission = itemResource.access;
        }
    });
    return permission;
}

function useGetPermission(user, isLoggedIn, resource, customerId) {
    const userGroup = getRole(user && user.SPGGroups ? user.SPGGroups : []);

    if (!userGroup) return { action: 'NOT ALLOWED' };

    if (!isLoggedIn) return { action: 'NOT ALLOWED' };

    let permission = 'NOT ALLOWED';
    permission = _getUserResourcePermissions(userGroup, resource);
    permission = permission?.toUpperCase();

    let resPermission = 'NOT ALLOWED';

    switch (permission) {
        case 'R/W-ALL':
            resPermission = ACTION.ACTION_WRITE;
            break;
        case 'R/W*':
            resPermission = ACTION.ACTION_WRITE;
            break;
        case 'R*':
            resPermission = ACTION.ACTION_READ;
            break;
        case 'R-ALL':
            resPermission = ACTION.ACTION_READ;
            break;
        case 'TRAIL-ALL':
            resPermission = ACTION.ACTION_WRITE;
            break;
        case 'ACTIVE-ALL':
            resPermission = ACTION.ACTION_WRITE;
            break;
        case 'R/W-ACTIVE-ALL':
            resPermission = ACTION.ACTION_WRITE;
            break;
        case 'R/W-YES-*':
            resPermission = ACTION.ACTION_WRITE;
            break;
        default:
            resPermission = permission;
    }

    return { action: resPermission };
}

export default useGetPermission;
