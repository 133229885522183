import React from 'react';
import dayjs from 'dayjs';
import { Badge } from '@spglobal/react-components';
import { DATE_FORMAT } from '../utils/constants';
import GenerateReportModal from '../components/GenerateReportModal';

export default function ExportSummaryModal(props) {
    const {
        selectedCustomer,
        selectedOutput,
        selectedRange,
        selectedCustomRange,
        selectedScenarios,
        selectedFactors,
        selectedFolders,
        selectedDecades,
        selectedDataPoints,
        selectedAssetTags,
        onConfirm,
    } = props;

    const getExportDetails = () => {
        const selectedDateRange =
            selectedRange?.value !== 'CUSTOM'
                ? selectedRange?.label
                : `${dayjs(new Date(selectedCustomRange?.fromDate)).format(
                    DATE_FORMAT
                )} - ${dayjs(new Date(selectedCustomRange?.toDate)).format(DATE_FORMAT)}`;

        return [
            { label: 'Customer:', value: selectedCustomer?.label },
            {
                label: 'Selected Folders:',
                value: selectedFolders?.map((item) => item?.label).join(', '),
            },
            { label: 'Selected Output:', value: selectedOutput?.label },
            {
                label: 'Selected Scenarios:',
                value: selectedScenarios?.map((item) => item?.label).join(', '),
            },
            {
                label: 'Selected Decade:',
                value: selectedDecades?.map((item) => item?.label).join(', '),
            },
            {
                label: 'Selected Risk factors:',
                value: selectedFactors?.map((item) => item?.label).join(', '),
            },
            {
                label: 'Other Datapoints:',
                value: selectedDataPoints?.length ? selectedDataPoints.map((item) => item?.label).join(', ') : 'NA',
            },
            {
                label: 'Selected Tags:',
                value: selectedAssetTags?.length ? selectedAssetTags.map((item, index) => <Badge key={index}>{item?.label}</Badge>) : 'NA',
            },
            { label: 'Selected Date Range:', value: selectedDateRange },
        ];
    };

    return (
        <GenerateReportModal tableOptions={getExportDetails()} onConfirm={onConfirm} />
    );
};
