import React from 'react';
import { Card } from '@spglobal/react-components';
import BreadcrumbTrail from '../../../BreadcrumbTrail';
import Image_1 from '../../assets/IFs_081721_1.png';
import Image_2 from '../../assets/IFs_081721_2.png';
import Image_3 from '../../assets/IFs_081721_3.png';
import Image_4 from '../../assets/IFs_081721_4.png';
import Image_5 from '../../assets/IFs_081721_5.png';

export default function Updates_081721() {
    return (
        <div>
            <BreadcrumbTrail rootTitle="Updates to Impact Functions" />
            <Card hasBorder hasRoundedCorner className="spg-m-md">
                <img
                    className="spg-ml-2xl spg-w-50"
                    src={Image_1}
                    alt="Update to Impact Functions 1"
                />
                <img
                    className="spg-ml-2xl spg-w-50"
                    src={Image_2}
                    alt="Update to Impact Functions 2"
                />
                <img
                    className="spg-ml-2xl spg-w-50"
                    src={Image_3}
                    alt="Update to Impact Functions 3"
                />
                <img
                    className="spg-ml-2xl spg-w-50"
                    src={Image_4}
                    alt="Update to Impact Functions 4"
                />
                <img
                    className="spg-ml-2xl spg-w-50"
                    src={Image_5}
                    alt="Update to Impact Functions 5"
                />
            </Card>
        </div>
    );
}
