import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { Purpose } from '@spglobal/koi-helpers';
import { Button, H1, Icon } from '@spglobal/react-components';
import { EDIT } from '@spglobal/koi-icons';
import UserContext from '../../../context/UserContext';
import useGetPermission from '../../../hooks/useGetPermission';
import { ACTION, ALLOWED_CUSTOMER_EDIT_ROLES } from '../../../utils/constants';
import { getRole } from '../../../utils/user';
import { ParagraphLg } from '../../shared';
import { CustomerDetailsTable } from '../CustomerManagement.styles';

export default function CustomerDetailsForm(props) {
    const history = useHistory();
    const {
        customerName,
        address,
        stateProvince,
        country,
        pointOfContact,
        titleDepartment,
        pointOfContactEmail,
        subscriberType,
        subscriptionStartDate,
        subscriptionEndDate,
        noOfAssetsSubscribed,
        noOfAssetsUploaded,
        accountManager,
        notes,
        accountManagerEmail,
    } = props;

    const { user: userContextDetails, isLoggedIn } = React.useContext(UserContext);

    const userAccess =
        userContextDetails && Object.keys(userContextDetails).length > 0
            ? useGetPermission(userContextDetails, isLoggedIn, 'Customers')
            : '';
    const actions =
        userAccess?.action === ACTION.ACTION_READ
            ? [ACTION.ACTION_READ]
            : userAccess?.action === ACTION.ACTION_WRITE
            ? [ACTION.ACTION_READ, ACTION.ACTION_EDIT]
            : [];

    const isAllowed = () => {
        // LOGIN USER AND CREATED CUTOMER USE IS SAME THEN ALLOWED
        // LOGIN USER IS IN ALLOWED_CUSTOMER_EDIT_ROLES THEN ALLOED
        if (
            userContextDetails?.email?.toLowerCase() === accountManagerEmail?.toLowerCase() ||
            ALLOWED_CUSTOMER_EDIT_ROLES.includes(getRole())
        ) {
            return true;
        }
        return false;
    };

    return (
        <>
            <div className="spg-row">
                <div className="spg-col-9">
                    <H1>Customer Details:</H1>
                </div>
                <div className="spg-d-flex spg-justify-end spg-col-3">
                    {isAllowed() && actions.includes(ACTION.ACTION_EDIT) && (
                        <Button
                            leftIcon={<Icon icon={EDIT} />}
                            onClick={() =>
                                setTimeout(
                                    () => history.push(`/editCustomer/${props.customerId}`),
                                    3000,
                                )
                            }
                            purpose={Purpose.SECONDARY}
                            data-testid="edit-hazard-button"
                            className="spg-mr-md"
                        >
                            Edit
                        </Button>
                    )}
                </div>
            </div>

            <CustomerDetailsTable>
                <tbody>
                    <tr>
                        <td>Customer Name:</td>
                        <td>{customerName}</td>
                        <td>Title:</td>
                        <td>{titleDepartment}</td>
                    </tr>
                    <tr>
                        <td>Address:</td>
                        <td>{address}</td>
                        <td>Account Manager:</td>
                        <td>{accountManager}</td>
                    </tr>
                    <tr>
                        <td>State:</td>
                        <td>{stateProvince}</td>
                        <td>Number Of Assets Subscribed:</td>
                        <td>{subscriberType === 'demo' ? 'NA' : noOfAssetsSubscribed}</td>
                    </tr>
                    <tr>
                        <td>Country:</td>
                        <td>{country}</td>
                        <td>No Of Assets Uploaded/Created:</td>
                        <td>{noOfAssetsUploaded}</td>
                    </tr>
                    <tr>
                        <td>Point Of Contact:</td>
                        <td>{pointOfContact}</td>
                        <td>Subscription Start Date:</td>
                        <td>
                            {subscriberType === 'demo'
                                ? 'NA'
                                : new Date(subscriptionStartDate).toLocaleDateString()}
                        </td>
                    </tr>
                    <tr>
                        <td>Point Of Contact Email:</td>
                        <td>{pointOfContactEmail}</td>
                        <td>Subscription End Date:</td>
                        <td>
                            {subscriberType === 'demo'
                                ? 'NA'
                                : new Date(subscriptionEndDate).toLocaleDateString()}
                        </td>
                    </tr>
                </tbody>
            </CustomerDetailsTable>
            <ParagraphLg className="spg-pl-xs">Notes: {notes}</ParagraphLg>
        </>
    );
}
