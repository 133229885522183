import { httpClient } from '../utils/httpClient';
import { API_URL } from '../utils/constants';

const ENDPOINT = 'api/hazards';

const getAll = async () => {
    const API_SOURCE = API_URL;

    const result = await httpClient.get(`${API_SOURCE}/${ENDPOINT}`);
    return result.data;
};

const getHazard = async (hazardId) => {
    const result = await httpClient.get(`${API_URL}/${ENDPOINT}/${hazardId}`);
    return result.data && result.data.results ? result.data.results : [];
};

const editHazard = async (hazardId, updatedHazard) => {
    const result = await httpClient.put(`${API_URL}/${ENDPOINT}/${hazardId}`, updatedHazard);
    return result;
};

export default { getAll, getHazard, editHazard };
