import React from 'react';
import { H3, Divider } from '@spglobal/react-components';
import styled from '@emotion/styled';

const MyProductsHeader = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 1.875rem;
`;

const MyProductsDivider = styled(Divider)`
    flex: 1 1 0;
    margin-left: 1.5rem;
    min-width: 0;
`;

const H3UpperCased = styled(H3)`
    text-transform: uppercase;
    color: var(--color-text-secondary);
`;

export default function ProductHeader({ headline }) {
    return (
        <MyProductsHeader data-testid="product-header-container">
            <H3UpperCased data-testid="product-header-headline">
                {headline && headline}
            </H3UpperCased>
            <MyProductsDivider data-testid="product-header-divider" />
        </MyProductsHeader>
    );
}
