import React, { useContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { IconButton } from '@spglobal/react-components';
import { EDIT, EYE_OPEN } from '@spglobal/koi-icons';
import { Size } from '@spglobal/koi-helpers';
import UserContext from '../../context/UserContext';
import AccessTokenContext from '../../context/AccessTokenContext';
import impactFunctionService from '../../services/impactFunctionService';
import Header from '../Header';
import BreadcrumbTrail from '../BreadcrumbTrail';
import * as constants from '../../utils/constants';
import useGetPermission from '../../hooks/useGetPermission';
import { ParagraphLg } from '../shared';
import { transformDataObjForKoiSelect } from '../../utils/koiIntegrationUtils';
import { ACTION, CONSENT_REQUIRED_ERROR } from '../../utils/constants';
import DataTableGrid from '../DataTable/DataTableGrid/DataTableGrid';

export default function ImpactFunctions() {
    const history = useHistory();
    const { user, isLoggedIn } = useContext(UserContext);
    const { getTokenAndTryAgain } = useContext(AccessTokenContext);
    const apiOptions = {
        scope: `${constants.CUSTOMER_READ_IMPACT_FUNCTION}`,
    };
    const [impactFunctions, setImpactFunctions] = useState([]);
    const [tableData, setTableData] = useState(impactFunctions);
    const [dataLoaded, setDataLoaded] = useState(false);

    const userPermission =
        user &&
        Object.keys(user)?.length > 0 &&
        useGetPermission(user, isLoggedIn, 'ImpactFunctionsLibrary');

    const userPrivileges =
        userPermission?.action === constants.ACTION.ACTION_READ
            ? [constants.ACTION.ACTION_READ]
            : userPermission?.action === constants.ACTION.ACTION_WRITE
            ? [constants.ACTION.ACTION_READ, constants.ACTION.ACTION_EDIT]
            : [];

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await impactFunctionService.getAllImpactFunctions();
                setImpactFunctions(response?.results);
                setTableData(response?.results);
                setDataLoaded(true);
            } catch (error) {
                setDataLoaded(true);
                if (error.error === CONSENT_REQUIRED_ERROR) {
                    await getTokenAndTryAgain(apiOptions);
                }
            }
        }
        fetchData();
    }, []);

    const handleTableSearch = (selectedValues) => {
        setTableData(impactFunctions.filter((df) => df.id === selectedValues[0].id));
    };

    const values = impactFunctions.map((v) =>
        transformDataObjForKoiSelect(v, { id: 'id', label: 'name', value: 'id' }),
    );

    const handleDataTableAction = (action, entityId) => {
        // Callback actions will be one of the following: 'view', 'edit', or 'delete'
        if (action === constants.VIEW || action === 'preview') {
            history.push({
                pathname: `/impact-functions/${entityId}`,
            });
        }
        if (action === constants.EDIT) {
            history.push({
                pathname: `/impact-functions/${entityId}/edit`,
            });
        }
    };

    const actionCellRenderer = (props) => {
        const impactFunctionId = props?.data?.id;

        return (
            <>
                {userPrivileges && userPrivileges.indexOf(ACTION.ACTION_READ) >= 0 && (
                    <IconButton
                        size={Size.SMALL}
                        icon={EYE_OPEN}
                        onClick={() => handleDataTableAction(constants.VIEW, impactFunctionId)}
                    />
                )}
                {userPrivileges && userPrivileges.indexOf(ACTION.ACTION_EDIT) >= 0 && (
                    <IconButton
                        size={Size.SMALL}
                        icon={EDIT}
                        onClick={() => handleDataTableAction(constants.EDIT, impactFunctionId)}
                    />
                )}
            </>
        );
    };

    const columnDefs = [
        {
            headerName: 'Impact Function',
            field: 'name',
            sortable: true,
            tooltipField: 'name',
            cellStyle: { display: 'block' },
        },
        {
            headerName: 'Hazard',
            field: 'hazardName',
            sortable: true,
            tooltipField: 'hazardName',
            cellStyle: { display: 'block' },
        },
        {
            headerName: ' ',
            field: ' ',
            cellRenderer: 'actionsColumn',
            width: 60,
            maxWidth: 60,
            cellStyle: { justifyContent: 'center', alignItems: 'baseline' },
            cellClass: 'cli-action-cell',
        },
    ];

    const frameworkComponents = {
        actionsColumn: actionCellRenderer,
    };

    return (
        <>
            <BreadcrumbTrail rootTitle="Impact Functions" />
            <div className="spg-w-100 spg-p-md">
                <Header
                    title="Impact Functions"
                    type="Impact Function"
                    values={values}
                    onChangeFunction={handleTableSearch}
                    link="impact-function"
                    history={history}
                    hideAddButton={!userPrivileges?.includes(constants.ACTION.ACTION_EDIT)}
                />
                {impactFunctions.length > 0 && (
                    <ParagraphLg marginLg>
                        S&amp;P Global Climanomics software platform currently has{' '}
                        {impactFunctions.length} impact functions.
                    </ParagraphLg>
                )}

                <DataTableGrid
                    frameworkComponents={frameworkComponents}
                    rowData={tableData}
                    columnDefs={columnDefs}
                    pagination
                    loading={!dataLoaded}
                />
            </div>
        </>
    );
}
