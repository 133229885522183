import {
    CUSTOMER_ENTITY,
    GROUP_ENTITY,
    FOLDER_ENTITY,
    INVESTMENT_ENTITY,
    ASSET_ENTITY,
} from './constants';

function getNextRealAssetPath({
    entityType,
    currentEntityIds,
    selectedRowID,
    currentViewId,
    urlParams,
}) {
    let path = '';
    switch (entityType) {
        case 'allAssets': {
            path = generateRealAssetsPath('allAssets', {
                ...currentEntityIds,
                assetId: selectedRowID,
            });
            break;
        }
        case 'folderAssets': {
            path = generateRealAssetsPath('folderAssets', {
                ...currentEntityIds,
                folderId: selectedRowID,
            });
            break;
        }
        case 4:
        case 'folderAssetDetails': {
            path = generateRealAssetsPath('folderAssetDetails', {
                ...currentEntityIds,
                assetId: selectedRowID,
            });
            break;
        }
        case 7:
        case 'viewDetails': {
            path = generateRealAssetsPath(
                'viewDetails',
                { ...currentEntityIds, viewId: selectedRowID, urlParams },
                currentViewId
            );
            break;
        }
        case 'viewFolderDetails': {
            path = generateRealAssetsPath(
                'viewFolderDetails',
                { ...currentEntityIds, folderId: selectedRowID, urlParams },
                currentViewId
            );
            break;
        }
        case 'viewAssetDetails': {
            path = generateRealAssetsPath(
                'viewAssetDetails',
                { ...currentEntityIds, assetId: selectedRowID, urlParams },
                currentViewId
            );
            break;
        }
        default:
            path = generateRealAssetsPath('allAssets', {
                ...currentEntityIds,
                assetId: selectedRowID,
            });
            break;
    }
    return path;
}

function generateRealAssetsPath(
    entityType,
    {
        customerId = null,
        groupId = null,
        folderId = null,
        investmentId = null,
        assetId = null,
        viewId = null,
        urlParams = null,
    },
    currentViewId
) {
    const urlSlug = 'real-assets';
    let viewList = urlParams?.get('viewList');
    if (viewId && currentViewId) {
        if(viewList){
            if(!viewList.split(",").includes(currentViewId))
                viewList = (viewList += `,${currentViewId}`)
        }else{
            viewList = currentViewId
        }
    }
    const search = viewList ? `?${new URLSearchParams({ viewList })}` : null;
    switch (entityType) {
        case 'allAssets':
            return { path: `/${urlSlug}/${customerId}/assets/${assetId}` };
        case 'folderAssets':
            return { path: `/${urlSlug}/${customerId}/folders/${folderId}` };
        case 'folderAssetDetails':
            return { path: `/${urlSlug}/${customerId}/folders/${folderId}/assets/${assetId}` };
        case 'viewDetails':
            return { path: `/${urlSlug}/${customerId}/views/${viewId}`, search };
        case 'viewFolderDetails':
            return {
                path: `/${urlSlug}/${customerId}/views/${viewId}/folders/${folderId}`,
                search,
            };
        case 'viewAssetDetails':
            return {
                path: `/${urlSlug}/${customerId}/views/${viewId}/folders/${folderId}/assets/${assetId}`,
                search,
            };
        default:
            return { path: `/${urlSlug}/${customerId}/assets/${assetId}` };
    }
}

function getNextLevelPath({ entityType, currentEntityIds, nextEntityId }) {
    const options = {
        [CUSTOMER_ENTITY]: generatePath(GROUP_ENTITY, {
            ...currentEntityIds,
            groupId: nextEntityId,
        }),
        [GROUP_ENTITY]: generatePath(FOLDER_ENTITY, {
            ...currentEntityIds,
            folderId: nextEntityId,
        }),
        [FOLDER_ENTITY]: generatePath(INVESTMENT_ENTITY, {
            ...currentEntityIds,
            investmentId: nextEntityId,
        }),
        [INVESTMENT_ENTITY]: generatePath(ASSET_ENTITY, {
            ...currentEntityIds,
            assetId: nextEntityId,
        }),
    };

    return options[entityType];
}

function generatePath(
    entityType,
    { customerId = null, groupId = null, folderId = null, investmentId = null, assetId = null }
) {
    const urlSlug = 'real-assets';
    const options = {
        [CUSTOMER_ENTITY]: `/${urlSlug}/${customerId}`,
        [GROUP_ENTITY]: `/${urlSlug}/${customerId}/groups/${groupId}`,
        [FOLDER_ENTITY]: `/${urlSlug}/${customerId}/groups/${groupId}/folders/${folderId}`,
        [INVESTMENT_ENTITY]: `/${urlSlug}/${customerId}/groups/${groupId}/folders/${folderId}/investments/${investmentId}`,
        [ASSET_ENTITY]: `/${urlSlug}/${customerId}/groups/${groupId}/folders/${folderId}/investments/${investmentId}/assets/${assetId}`,
    };

    return options[entityType];
}

function generateBreadcrumbs(entityType, entityNames, entityIds) {
    const breadcrumbs = [
        CUSTOMER_ENTITY,
        GROUP_ENTITY,
        FOLDER_ENTITY,
        INVESTMENT_ENTITY,
        ASSET_ENTITY,
    ].map((entityType) => ({
        title: entityNames[entityType],
        linkDetails: generatePath(entityType, entityIds),
        level: entityType,
    }));
    const breadcrumbsWithPortfolio = [
        {
            title: 'All Portfolios',
            linkDetails: '/',
            level: null,
        },
        ...breadcrumbs,
    ];
    // Location's magic number is 1, and we only represent breadcrumbs through asset - hence entityType subtract 2 for 0-based JS array index.
    return breadcrumbsWithPortfolio.slice(0, breadcrumbsWithPortfolio.length - (entityType - 2));
}

export { getNextLevelPath, generateBreadcrumbs, getNextRealAssetPath };
