import React, { useState } from 'react';
import { Purpose } from '@spglobal/koi-helpers';
import { CLEAR } from '@spglobal/koi-icons';
import {
    Button,
    IconButton,
    Modal,
    ModalContent,
    ModalFooter,
    ModalHeader,
    NotificationType,
    useNotification,
} from '@spglobal/react-components';
import AssignmentForm from './ClientComponent/AssignmentForm';
import rbacAdminService from '../../services/rbacAdminService';
import { FORM_ACTIONS } from '../../utils/constants';

export default function DialogClientAdmin(props) {
    const [user, setUser] = useState('');
    const [folders, setFolders] = useState([]);
    const [views, setViews] = useState([]);
    const { addNotification } = useNotification();

    const resetState = () => {
        setUser('');
        setFolders([]);
        setViews([]);
    };

    const onUserSelection = (userObj) => {
        setUser(userObj);
    }
    const onFoldersSelection = (folderObj) => setFolders(folderObj);
    const onViewsSelection = (viewObj) => setViews(viewObj);

    const handleSubmit = async () => {
        if (props.action === FORM_ACTIONS.ADD) {
            const isUserExist = props.users.filter((item) => item.user_email === user.user_email);
            if (isUserExist.length) {
                const isUserNew =
                    isUserExist[0].no_of_folders === 0 && isUserExist[0].no_of_views === 0;
                if (!isUserNew) {
                    props.handleClose();
                    addNotification(
                        'User already exist and has folders/view assignment, please select edit user',
                        NotificationType.ERROR,
                    );
                    return;
                }
            }
        }

        const params = {
            user_name: user && user.user_name ? user.user_name : '',

            user_email: user && user.user_email ? user.user_email : '',

            folder_ids: folders
                .map((folder) => {
                    if (folder && folder.uncategorized_folder) {
                        return 0;
                    }

                    return folder.asset_folder_id;
                })
                .filter((item) => item !== undefined),
            view_ids: views.map((view) => view.id),

            role: user.role ? user.role : '',

            customerId: props.customerId,
        };

        let response;
        if (props.action === FORM_ACTIONS.ADD) {
            response = await rbacAdminService.userToFolderViewMapping(params);
        } else if (props.action === FORM_ACTIONS.EDIT) {
            response = await rbacAdminService.editUserToFolderViewMapping(params);
        }
        props.getUsersData();
        props.handleClose();
        resetState();
        if (response.error || response.status === 405 || response.status === 500) {
            addNotification(response.message, NotificationType.ERROR);
            return;
        }
        addNotification(response.message ? response.message : '', NotificationType.SUCCESS);
    };

    const isAssignmentAllowed = () => {
        if (props.action === FORM_ACTIONS.EDIT && user && user.user_name && user.user_email) {
            return true;
        }

        return !!(
            (folders
                .map((folder) => {
                    if (folder.uncategorized_folder) return folder.folder_id;
                    return folder.asset_folder_id;
                })
                .filter((item) => item !== undefined).length ||
                views.map((view) => view.id).length) &&
            user.user_name &&
            user.user_email &&
            props.customerId
        );
    };

    const setProps = {
        onUserSelection,
        onFoldersSelection,
        onViewsSelection,
        ...props,
    };

    const handleClose = () => {
        resetState();
        return props.handleClose();
    };

    return (
        <>
            <Modal isOpen={props.open} onClose={() => handleClose()}>
                <ModalHeader title="Assign Folders/Groups">
                    <IconButton aria-label="close" icon={CLEAR} onClick={handleClose} />
                </ModalHeader>
                <ModalContent>
                    <AssignmentForm {...setProps} />
                </ModalContent>
                <ModalFooter>
                    {isAssignmentAllowed() && (
                        <Button purpose={Purpose.PRIMARY} onClick={handleSubmit}>
                            Assign
                        </Button>
                    )}
                    <Button
                        purpose={Purpose.SECONDARY}
                        type="button"
                        onClick={handleClose}
                        className="spg-ml-sm"
                    >
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
}
