import React, { useEffect, useContext } from 'react';
import SelectUsers from './SelectUsers';
import AssignCustomerDropdown from './AssignCustomerDropdown';
import rbacAdminService from '../../../services/rbacAdminService';
import customerService from '../../../services/customerService';
import AccessTokenContext from '../../../context/AccessTokenContext';
import UserContext from '../../../context/UserContext';
import { getRole } from '../../../utils/user';
import { CONSENT_REQUIRED_ERROR } from '../../../utils/constants';

export default function AssignmentForm(props) {
    const { user: userContextDetails } = useContext(UserContext);
    const { getTokenAndTryAgain } = useContext(AccessTokenContext);
    const [customersData, setCustomersData] = React.useState([]);
    const [dataLoaded, setDataLoaded] = React.useState(false);
    const [userData, setUserData] = React.useState([]);

    const getCustomersData = async () => {
        async function fetchData() {
            try {
                // GET  USERS
                const reqData = {
                    user_type: props.type,
                    list: true,
                };
                const responseUsers = await rbacAdminService.getAllUsers(reqData);
                if (responseUsers) {
                    setUserData(responseUsers);
                }

                // GET CUSTOMERS
                const reqCustomer = {
                    rbac: 'True',
                    user_email: userContextDetails.email,
                    user_role: getRole(
                        userContextDetails && userContextDetails.SPGGroups
                            ? userContextDetails.SPGGroups
                            : [],
                    ),
                };
                const resCustomer = await customerService.getAll(reqCustomer);
                if (resCustomer) {
                    let resCustomerData = resCustomer?.results ? resCustomer.results : [];
                    if (props.type === 'internal') {
                        // FOR INTERNAL USER FILTER ACTIVE AND TRAIL CUSTOMERS REMOVED ALL DEMO CUSTOMER FROM THE LIST AS THEY HAVE DEFAULT ACCESS
                        resCustomerData = resCustomerData.filter(
                            (cust) =>
                                cust.customer_type === 'active' || cust.customer_type === 'trial',
                        );
                    }
                    setCustomersData(resCustomerData);
                }

                setDataLoaded(true);
                return responseUsers;
            } catch (error) {
                setDataLoaded(true);
                if (error.error === CONSENT_REQUIRED_ERROR) {
                    return getTokenAndTryAgain(apiOptions);
                }
            }
        }
        return fetchData();
    };

    useEffect(() => {
        (async function fetchCustomersData() {
            await getCustomersData();
        })();
    }, []);

    return (
        <div>
            <div>
                <SelectUsers userData={userData} {...props} />
            </div>
            <div>
                <AssignCustomerDropdown
                    userData={userData}
                    onCustomersSelection={props.onCustomersSelection}
                    customersData={customersData}
                    {...props}
                />
            </div>
        </div>
    );
}
