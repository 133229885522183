import React, { useState, useEffect, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { NotificationType, useNotification } from '@spglobal/react-components';
import AccessTokenContext from '../../context/AccessTokenContext';
import UserContext from '../../context/UserContext';
import CustomHeader from '../Header/CustomHeader';
import BreadcrumbTrail from '../BreadcrumbTrail';
import DeleteDialog from '../DeleteDialogTemplate/DeleteDialogTemplate';
import pathParser from '../../utils/pathParser';
import MoveAssetsDialog from '../MoveAssetsDialog';
import googleAnalyticsEvent from '../../utils/googleAnalyticsEvent';
import { getAll, bulkDelete, moveAssets } from '../../services/assetService';
import folderService from '../../services/folderService';
import * as constants from '../../utils/constants';
import useGetPermission from '../../hooks/useGetPermission';
import AssetsDataTable from './AssetsDataTable';
import { DEFAULT_PAGE_SIZE } from '../components.constants';
import { CONSENT_REQUIRED_ERROR } from '../../utils/constants';

const {
    CUSTOMER_READ_ASSET,
    CUSTOMER_EDIT_ASSET,
    VIEW,
    MOVE,
    EDIT,
    NEW_ASSET,
    BULK_MOVE,
    DELETE,
    BULK_DELETE,
} = constants;

export default function Assets() {
    const history = useHistory();
    const { pathname } = useLocation();
    const hideActions = pathname.includes('views');
    const { getTokenAndTryAgain } = useContext(AccessTokenContext);
    const { user, isLoggedIn } = useContext(UserContext);
    const apiOptions = {
        scope: `${CUSTOMER_READ_ASSET} ${CUSTOMER_EDIT_ASSET}`,
    };
    const {
        customers: customerId,
        folders: folderId,
        views: viewId,
    } = pathParser.getPathComponents(pathname);
    const userPermission =
        user &&
        Object.keys(user).length > 0 &&
        useGetPermission(user, isLoggedIn, 'AllAssetsLibrary');
    const userPrivilege = userPermission?.action;

    const [dataLoaded, setDataLoaded] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [foldersList, setFoldersList] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [searchText, setSearchText] = useState('');
    const [selectedRowIds, setSelectedRowIds] = useState([]);
    const [selectedAsset, setSelectedAsset] = useState({});
    const [resetPagination, setResetPagination] = useState(false);
    const [showDialogType, setShowDialogType] = useState('');
    const { addNotification } = useNotification();

    const getAssetsData = async (offset = 0, limit = DEFAULT_PAGE_SIZE, searchTextVal) => {
        const reqData = {
            offset,
            limit,
            folder_id: folderId,
        };
        if (searchTextVal?.length) {
            reqData.asset_name = searchTextVal;
        }

        async function fetchData() {
            try {
                const response = await getAll({
                    customerId,
                    folderId,
                    getForFolder: true,
                    params: reqData,
                });
                setDataLoaded(true);
                if (response?.results) {
                    setTableData(response?.results);
                    setTotalCount(response?.total_asset_count);
                }
                return response;
            } catch (error) {
                setDataLoaded(true);
                if (error.error === CONSENT_REQUIRED_ERROR) {
                    return getTokenAndTryAgain(apiOptions);
                }
                return error;
            }
        }
        return fetchData();
    };

    const getFoldersData = async (searchTextVal) => {
        const reqData = {
            offset: 0,
            limit: 1000,
        };
        if (searchTextVal?.length) reqData.asset_folder_name = searchTextVal;

        async function fetchData() {
            try {
                const { result: data } = await folderService.getAll(customerId, reqData);
                const foldersData = [];
                if (data) {
                    setFoldersList(data);
                }
                return data;
            } catch (error) {
                if (error.error === CONSENT_REQUIRED_ERROR) {
                    return getTokenAndTryAgain(apiOptions);
                }
                return error;
            }
        }
        return fetchData();
    };

    useEffect(() => {
        (async function fetchAssetsData() {
            await getAssetsData(0, DEFAULT_PAGE_SIZE, '');
        })();
    }, []);

    const handleTableSearchSubmit = (searchVal) => {
        getAssetsData(0, DEFAULT_PAGE_SIZE, searchVal);
        setSearchText(searchVal);
    };

    const handleAssetDelete = async (isDeleteConfirmed) => {
        if (isDeleteConfirmed) {
            let msg =
                showDialogType === DELETE
                    ? `The ${selectedAsset.name} asset has been successfully removed.`
                    : 'Selected assets has been successfully removed.';
            const requestBody = showDialogType === DELETE ? [selectedAsset.id] : selectedRowIds;
            let notificationType = NotificationType.SUCCESS;
            try {
                await bulkDelete(customerId, requestBody);
                await getAssetsData(0, DEFAULT_PAGE_SIZE, searchText);
                setResetPagination(true);
            } catch (err) {
                msg = err?.response?.data ? err.response.data : 'Error deleting asset';
                notificationType = NotificationType.ERROR;
            }
            addNotification(msg, notificationType);
            googleAnalyticsEvent('Delete Entity', 'Asset', notificationType);
        }
        setShowDialogType('');
        setSelectedAsset({});
        // setSelectedRowIds([]);
    };

    const handleMoveAssetsSubmit = async (isConfirmed, destinationFolder) => {
        if (isConfirmed) {
            let msg =
                showDialogType === MOVE
                    ? `The ${selectedAsset.name} asset has been successfully moved.`
                    : 'Selected assets has been successfully moved.';
            const assetsToMove = showDialogType === MOVE ? [selectedAsset.id] : selectedRowIds;
            let notificationType = NotificationType.SUCCESS;
            try {
                const response = await moveAssets(destinationFolder, assetsToMove, customerId);
                await getAssetsData(0, DEFAULT_PAGE_SIZE, searchText);
                setResetPagination(true);
                if (response?.data?.message) msg = response.data.message;
            } catch (err) {
                msg = err?.data?.message ? err.data.message : 'Error moving asset';
                notificationType = NotificationType.ERROR;
            }
            addNotification(msg, notificationType);
            googleAnalyticsEvent('Move Entity', 'Asset', notificationType);
        }
        setShowDialogType('');
    };

    const handleAssetsActions = (actionType, rowDetails = {}) => {
        // Callback actions will be one of the following: 'view', 'edit', or 'delete'
        setSelectedAsset(rowDetails);
        if (actionType === VIEW) {
            history.push({
                // NOTE: Once real assets page is done revert user here
                pathname: `/real-assets/${customerId}/folders/${folderId}/assets/${rowDetails.id}`,
            });
        } else if (actionType === NEW_ASSET) {
            if (hideActions) {
                history.push({
                    pathname: `/manage/${customerId}/views/${viewId}/folders/${folderId}/asset`,
                });
            } else {
                history.push({ pathname: `/manage/${customerId}/folders/${folderId}/asset` });
            }
        } else if (actionType === EDIT) {
            history.push({
                pathname: `/manage/${customerId}/folders/${folderId}/assets/${rowDetails.id}/edit`,
            });
        } else if (actionType === MOVE || actionType === BULK_MOVE) {
            setShowDialogType(actionType);
        } else if (actionType === DELETE || actionType === BULK_DELETE) {
            setShowDialogType(actionType);
        }
    };

    const getDeleteDialog = () => {
        const deleteInfo =
            showDialogType === DELETE
                ? `"${selectedAsset?.name}" will be deleted.`
                : 'All selected assets will be deleted';
        return (
            <DeleteDialog
                isDialogOpen={true}
                primaryMessage={deleteInfo}
                handleDialogAction={handleAssetDelete}
                selectedRow={selectedAsset}
            />
        );
    };

    const handleCreateNewFolder = async (folderName) => {
        const folderResponse = await folderService.create(customerId, folderName?.trim());
        if (folderResponse?.status === 200) {
            addNotification(folderResponse?.message, NotificationType.SUCCESS);
            await getFoldersData();
        } else {
            addNotification(folderResponse?.message, NotificationType.ERROR);
        }
    };

    const handleRowsSelection = (selectedRows) => {
        setSelectedRowIds(selectedRows);
    };

    return (
        <>
            <BreadcrumbTrail
                className="common-breadcrumbs"
                rootTitle="Customers"
                history={history}
            />
            <div className="spg-w-100 spg-p-md">
                <CustomHeader
                    searchPlaceholder="Asset Name"
                    handleActionClick={handleAssetsActions}
                    handleSearchSubmit={handleTableSearchSubmit}
                    actionsArr={[NEW_ASSET, BULK_MOVE, BULK_DELETE]}
                    multipleRowsSelected={selectedRowIds?.length > 1}
                    hideActions={userPrivilege !== constants.ACTION.ACTION_WRITE}
                />
                <AssetsDataTable
                    tableData={tableData}
                    searchText={searchText}
                    resetPagination={resetPagination}
                    actionCallback={handleAssetsActions}
                    updateAssetsTableData={getAssetsData}
                    isLoading={!dataLoaded}
                    totalCount={totalCount}
                    handleRowsSelection={handleRowsSelection}
                    hideActions={hideActions || userPrivilege !== constants.ACTION.ACTION_WRITE}
                />
                {(showDialogType === DELETE || showDialogType === BULK_DELETE) && getDeleteDialog()}
                {(showDialogType === MOVE || showDialogType === BULK_MOVE) && (
                    <MoveAssetsDialog
                        dialogTitle="Select Folder"
                        foldersList={foldersList}
                        selectedSourceFolders={[Number(folderId)]}
                        onSubmit={handleMoveAssetsSubmit}
                        onCreateFolder={handleCreateNewFolder}
                        getFoldersData={getFoldersData}
                        handleRowsSelection={handleRowsSelection}
                    ></MoveAssetsDialog>
                )}
            </div>
        </>
    );
}
