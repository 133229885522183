import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { faArrowCircleRight } from '@fortawesome/free-solid-svg-icons';
import { IconButton } from '@spglobal/react-components';
import { Size } from '@spglobal/koi-helpers';
import { EDIT as EditIcon, TRASH } from '@spglobal/koi-icons';
import { MOVE, EDIT, DELETE } from '../../utils/constants';
import { DEFAULT_PAGE_DATA } from '../components.constants';
import DataTableGrid from '../DataTable/DataTableGrid/DataTableGrid';

const getAssetsTableColumns = (actionCallback, hideActions = false) => {
    const columns = [
        {
            headerName: 'Asset Names',
            field: 'name',
            sortable: false,
            flex: 1,
        },
    ];

    if (!hideActions) {
        columns.unshift({
            checkboxSelection: true,
            headerCheckboxSelection: true,
            width: 25,
            maxWidth: 25,
        });

        columns.push({
            headerName: 'Actions',
            field: 'action',
            sortable: false,
            flex: 1,
            cellRenderer: 'actionsRenderer',
            width: 80,
            maxWidth: 80,
            cellStyle: { alignItems: 'baseline' },
            cellClass: 'cli-action-cell',
        });
    }

    return columns;
};

const AssetsDataTable = (props) => {
    const {
        tableData,
        actionCallback,
        handleRowsSelection,
        updateAssetsTableData,
        isLoading,
        totalCount,
        hideActions,
    } = props;
    const [pageData, setPageData] = React.useState(DEFAULT_PAGE_DATA);
    const history = useHistory();

    React.useEffect(() => {
        (async function updateData() {
            const { searchText } = props;
            if (pageData?.clicked) {
                const offset = pageData?.page * pageData?.rowsPerPage;
                updateAssetsTableData(offset, pageData?.rowsPerPage, searchText);
            }
        })();
    }, [pageData]);

    const handleRowClick = ({ data }) => {
        const url = window.location.href;
        const route = url.substring(url.indexOf('/manage'));
        const newRoute = route.replace('/manage', '/real-assets');
        history.push({ pathname: `${newRoute}/${data?.id}` });
    };

    const handlePageSizeChange = (newPageSize) => {
        const pageInfo = {
            page: 0,
            clicked: true,
            rowsPerPage: newPageSize,
        };
        setPageData(pageInfo);
    };

    const handlePageChange = (newPageIndex) => {
        const pageInfo = {
            page: newPageIndex,
            clicked: true,
            rowsPerPage: pageData?.rowsPerPage,
        };
        setPageData(pageInfo);
    };

    const dataPrivacyAccepted = localStorage.getItem('CLIMANOMICS_USER_CONSENT');

    React.useEffect(() => {
        setPageData(DEFAULT_PAGE_DATA);
    }, [props.searchText || props.resetPagination]);

    const onRowSelected = (item, isSelected, allSelectedItems) => {
        const formattedArray = allSelectedItems.map((objData) => objData.id);
        handleRowsSelection(formattedArray);
    };

    const actionsCellRenderer = (actionsCellProps) => {
        const handleActionsClick = (event, actionType, params) => {
            event.stopPropagation();
            actionCallback(actionType, params?.data);
        };

        return (
            <>
                <IconButton
                    aria-label="edit-asset"
                    size={Size.SMALL}
                    icon={EditIcon}
                    onClick={(e) => handleActionsClick(e, EDIT, actionsCellProps)}
                    title="Edit"
                />

                <IconButton
                    aria-label="move-asset"
                    size={Size.SMALL}
                    icon={faArrowCircleRight}
                    onClick={(e) => handleActionsClick(e, MOVE, actionsCellProps)}
                    title="Move"
                />

                <IconButton
                    aria-label="delete-asset"
                    size={Size.SMALL}
                    icon={TRASH}
                    onClick={(e) => handleActionsClick(e, DELETE, actionsCellProps)}
                    title="Delete"
                />
            </>
        );
    };

    const frameworkComponents = {
        actionsRenderer: actionsCellRenderer,
    };

    const columnDefs = getAssetsTableColumns(hideActions);

    return (
        <div>
            {dataPrivacyAccepted && (
                <DataTableGrid
                    frameworkComponents={frameworkComponents}
                    rowData={tableData}
                    columnDefs={columnDefs}
                    totalItemsCount={totalCount}
                    onRowClicked={handleRowClick}
                    onPageSizeChange={(newPageSize) => handlePageSizeChange(newPageSize)}
                    onPageChange={(pageIndex) => handlePageChange(pageIndex)}
                    onRowCheckboxSelected={onRowSelected}
                    loading={isLoading}
                    pagination
                    resetPagination={props.resetPagination}
                />
            )}
        </div>
    );
};

export default AssetsDataTable;
