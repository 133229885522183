import React from 'react';
import { FormGroup, Select } from '@spglobal/react-components';
import { ANALYSIS_TYPE_OPTIONS as analysisTypeOptions } from '../../utils/constants';

export const AnalysisTypeSelect = ({
    selectedAnalysisType,
    setSelectedAnalysisType,
}) => {
    const options = analysisTypeOptions.map((option) => ({
        ...option,
        disabled: option.label === 'Opportunities'
    }));

    const handleAnalysisTypeChange = (values) => {
        setSelectedAnalysisType(values)
    };

    return (
        <FormGroup label="Select Analysis Type" labelfor="analysis-type-select" required>
            <Select
                id="analysis-type-select"
                defaultValue={selectedAnalysisType}
                options={options}
                onChange={handleAnalysisTypeChange}
                isSearchable={false}
                isMulti
                placeholder=""
            />
        </FormGroup>
    );
};
