import React, { useState, useEffect, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { H1, Button, Icon } from '@spglobal/react-components';
import { DUPLICATE, EDIT } from '@spglobal/koi-icons';
import { Purpose } from '@spglobal/koi-helpers';
import AccessTokenContext from '../../context/AccessTokenContext';
import UserContext from '../../context/UserContext';
import BreadcrumbTrail from '../BreadcrumbTrail';
import VulnerabilityGraph from '../VulnerabilityGraph';
import impactFunctionService from '../../services/impactFunctionService';
import pathParser from '../../utils/pathParser';
import useGetPermission from '../../hooks/useGetPermission';
import * as constants from '../../utils/constants';
import { HeadingMd, ParagraphLg } from '../shared';

export default function ImpactFunctionDetails() {
    const history = useHistory();
    const { pathname } = useLocation();
    const { 'impact-functions': impactFunctionId } = pathParser.getPathComponents(pathname);
    const { user, isLoggedIn } = useContext(UserContext);
    const { getTokenAndTryAgain } = useContext(AccessTokenContext);
    const apiOptions = {
        scope: `${constants.CUSTOMER_READ_IMPACT_FUNCTION}`,
    };
    const [impactFunction, setImpactFunction] = useState({});
    const userPermission = useGetPermission(user, isLoggedIn, 'ImpactFunctionsLibrary');
    const userPrivileges =
        userPermission?.action === constants.ACTION.ACTION_READ
            ? [constants.ACTION.ACTION_READ]
            : userPermission?.action === constants.ACTION.ACTION_WRITE
            ? [constants.ACTION.ACTION_READ, constants.ACTION.ACTION_EDIT]
            : [];

    useEffect(() => {
        let isSubscribed = true;
        async function fetchData() {
            try {
                const impactFunctionResponse = await impactFunctionService.getImpactFunction(
                    impactFunctionId
                );
                if (isSubscribed) {
                    setImpactFunction(impactFunctionResponse);
                }
            } catch (error) {
                if (error.error === 'consent_required') {
                    await getTokenAndTryAgain(apiOptions);
                }
            }
        }
        fetchData();
        return () => (isSubscribed = false);
    }, [impactFunctionId]);

    const editClicked = (e) => {
        e.preventDefault();
        history.push(`/impact-functions/${impactFunctionId}/edit`);
    };

    const copyClicked = (e) => {
        e.preventDefault();
        history.push(`/impact-function?copy=${impactFunctionId}`);
    };

    return (
        <>
            <BreadcrumbTrail
                rootTitle="Impact Functions"
                manageParent="/impact-functions"
                manageChild={impactFunction.name}
            />
            <div className="spg-w-100 spg-p-md">
                <div className="spg-row">
                    <div className="spg-col-8">
                        <H1 data-testid="impact-function-details-name">
                            {impactFunction && impactFunction.name}
                        </H1>
                    </div>

                    <div className="spg-d-flex spg-justify-end spg-col-4">
                        {userPrivileges?.includes(constants.ACTION.ACTION_EDIT) && (
                            <Button
                                leftIcon={<Icon icon={DUPLICATE} />}
                                onClick={copyClicked}
                                purpose={Purpose.SECONDARY}
                                data-testid="copy-impact-function-button"
                                className="spg-mr-md"
                            >
                                Create a Copy
                            </Button>
                        )}

                        {userPrivileges?.includes(constants.ACTION.ACTION_EDIT) && (
                            <Button
                                leftIcon={<Icon icon={EDIT} />}
                                onClick={editClicked}
                                purpose={Purpose.SECONDARY}
                                data-testid="edit-impact-function-button"
                                className="spg-mr-md"
                            >
                                Edit
                            </Button>
                        )}
                    </div>
                </div>

                <ParagraphLg>
                    Hazard Type: {impactFunction && impactFunction.hazardName}
                </ParagraphLg>
                <ParagraphLg>
                    Risk Factor Type: {impactFunction && impactFunction.riskFactorName}
                </ParagraphLg>
                <ParagraphLg>{impactFunction && impactFunction.description}</ParagraphLg>

                <HeadingMd className='spg-pt-xs spg-pb-md'>Impact Pathways</HeadingMd>
                {impactFunction && impactFunction.impactPathways ? (
                    <div className='spg-row'>
                        <div className='spg-col-8'>
                            <VulnerabilityGraph dataToGraph={impactFunction} displayHeader='' />
                        </div>
                    </div>
                ) : (
                    <ParagraphLg>Loading Impact Pathways...</ParagraphLg>
                )}
            </div>
        </>
    );
}
