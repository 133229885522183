import styled from '@emotion/styled';

export const RiskFactor = styled.div`
    cursor: pointer;
    &:hover {
        background-color: var(--color-bg-hover);
    }
    &.disabled {
        pointer-events: none;
        color: var(--color-text-disabled);
    }
`;
