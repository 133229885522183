import * as constants from './constants';

export default function getRelevantEntityId(
    entityType,
    { customerId, groupId, folderId, investmentId, assetId, viewId }
) {
    const options = {
        [constants.CUSTOMER_ENTITY]: customerId,
        [constants.GROUP_ENTITY]: groupId,
        [constants.FOLDER_ENTITY]: folderId,
        [constants.INVESTMENT_ENTITY]: investmentId,
        [constants.ASSET_ENTITY]: assetId,
        [constants.VIEW_ENTITY]: viewId,
    };
    return options[entityType];
}
