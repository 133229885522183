import React from 'react';
import { OktaAuth } from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';
import { BrowserRouter } from 'react-router-dom';
import * as constants from '../utils/constants';

const dataPrivacyAccepted = localStorage.getItem(constants.USER_CONSENT);

const code = new URLSearchParams(document.location.search).get('code');

const OktaProvider = ({ children }) => {
    const oktaConfig = constants.OKTA_CONFIG;
    const oktaAuth = new OktaAuth(oktaConfig);

    if (!dataPrivacyAccepted && !code && window.location.href !== 
      `${window.location.origin}/consent`) {
        window.location.href = `${window.location.origin}/consent`;
    }

    const restoreOriginalUri = async (_oktaAuth) => {
        if (!dataPrivacyAccepted && code) {
            window.location.href = `${window.location.origin}/consent`;
        } else {
            window.location.href = `${window.location.origin}/`;
        }
    };

    return (
        <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
            <BrowserRouter>{children}</BrowserRouter>
        </Security>
    );
};

export default OktaProvider;
