import React, { useState, useEffect } from 'react';
import ExportTable from './ExportTable';
import ExportForm from './ExportForm';
import BreadcrumbTrail from '../components/BreadcrumbTrail';
import { TableDataContextProvider } from '../components/shared';
import { DEFAULT_PAGE_DATA } from '../components/components.constants';
import { addDynamicStyles } from '../utils/generateReportUtils';
import { getReports } from '../services/exportService';

export default function ExportPage() {
    const [pageData, setPageData] = useState(DEFAULT_PAGE_DATA);
    const [tableData, setTableData] = useState([]);
    const [count, setCount] = useState(0);
    const [selectedCustomer, setSelectedCustomer] = useState({});

    const getExportList = async (offset, limit, refreshData, searchData, sortData) => {
        let res = [];
        const reqData = {
            offset,
            limit,
            customerId: selectedCustomer.id,
            report_type: 'list_report',
        };
        if (searchData) reqData[`${searchData.searchtype}`] = searchData.searchText;
        if (sortData) {
            reqData.sort_field = sortData.sort_field;
            reqData.order_by = sortData.order_by;
        }
        if (refreshData) {
            res = refreshData;
            const result = addDynamicStyles(res);
            return result;
        }
        res = await getReports(reqData);
        const totalCount = res?.count;
        setCount(totalCount);
        const results = addDynamicStyles(res?.results);
        return results;
    };

    useEffect(() => {
        try {
            (async function retrieveData() {
                if (selectedCustomer?.id) {
                    const data = await getExportList(0, pageData.rowsPerPage);
                    setTableData(data);
                }
            })();
        } catch (e) {
            console.log('error occured in fetching export list: ', e);
        }
    }, [selectedCustomer]);

    const updateTableState = (data) => {
        // this we need to do because for a state with array, the component only re renders when we assign new array to the state,
        // instead of modifying the current array of the state.
        const updatedState = [...data];
        setTableData(updatedState);
    };

    return (
        <>
            <BreadcrumbTrail rootTitle="Export Climate Data" />
            <div className="spg-w-100 spg-p-md">
                    <ExportForm
                        selectedCustomer={selectedCustomer}
                        setSelectedCustomer={setSelectedCustomer}
                        getExportList={getExportList}
                        updateTableState={updateTableState}
                        pageData={pageData}
                    />
                <TableDataContextProvider exportTableData={tableData}>
                    <ExportTable
                        selectedCustomer={selectedCustomer}
                        tableData={tableData}
                        getExportList={getExportList}
                        updateTableState={updateTableState}
                        count={count}
                        pageData={pageData}
                        setPageData={setPageData}
                    />
                </TableDataContextProvider>
            </div>
        </>
    );
}
