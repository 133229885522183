import React, { useEffect, useState } from 'react';
import { FormGroup, InputField, Select } from '@spglobal/react-components';
import { getExistRoleFromList, getRoleName } from '../../../utils/user';
import { transformDataObjForKoiSelect } from '../../../utils/koiIntegrationUtils';
import { FORM_ACTIONS } from '../../../utils/constants';

export default function User({ userData = [], onUserSelection, action, type, editUserId }) {
    const [role, setRole] = useState('');
    const [selectedUser, setSelectedUser] = useState('');
    const [userDataOptions, setUserDataOptions] = useState([]);
    const [defaultUserOption, setDefaultUserOption] = useState([]);

    useEffect(() => {
        const usersOptions = userData.map((u) => ({
            ...transformDataObjForKoiSelect(u, {
                id: 'uid',
                value: 'uid',
            }),
            label: u.email && u.userName ? `${u.userName} (${u.email})` : '',
        }));
        const curUserOption = usersOptions.find((o) => o.id === editUserId);
        setUserDataOptions(usersOptions);
        setDefaultUserOption(curUserOption ? [curUserOption] : []);

        const curUser = userData.find((item) => item.uid === editUserId);

        if (isEdit() && type === 'internal' && editUserId) {
            if (curUser) {
                setSelectedUser(curUser);
                setRole(curUser);
                onUserSelection(curUser);
            }
        }
        if (isEdit() && type === 'external' && editUserId) {
            if (curUser) {
                setSelectedUser(curUser);
                setRole(curUser);
                onUserSelection(curUser);
            }
        }
    }, [userData]);

    const getExternalUserRole = (rolesArr) => {
        if (!Array.isArray(rolesArr)) return getRoleName(rolesArr);

        const name = getExistRoleFromList(rolesArr);
        return getRoleName(name);
    };

    const handleChange = (selectedValue) => {
        const usersSelection = userData.find((u) => u.uid === selectedValue.id);

        setSelectedUser(usersSelection);
        setRole(usersSelection?.role && usersSelection.role[0] ? usersSelection.role[0] : '');
        onUserSelection(usersSelection);
    };

    const isEdit = () => action === FORM_ACTIONS.EDIT;

    return (
        <>
            <div>
                <FormGroup label="User Name/Email-ID" labelFor="User">
                    <Select
                        id="User"
                        disabled={isEdit()}
                        options={userDataOptions}
                        defaultValue={defaultUserOption}
                        isMulti={false}
                        closeOnSelection
                        onChange={(values) => handleChange(values[0])}
                    />
                </FormGroup>
            </div>
            <div>
                <FormGroup label="Role Type" labelFor="roleType">
                    <InputField
                        id="roleType"
                        disabled
                        value={
                            isEdit() && selectedUser.role && selectedUser.role[0]
                                ? getExternalUserRole(selectedUser.role)
                                : getExternalUserRole(role)
                        }
                    />
                </FormGroup>
            </div>
        </>
    );
}
