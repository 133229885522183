import styled from '@emotion/styled';

const DeleteDialogTable = styled.table`
    width: 100%;
    font: var(--font-body-md);
    padding-bottom: var(--size-space-lg);

    & th,
    & td {
        width: calc(100%/3);
        text-align: left;
    }
`;

export default DeleteDialogTable;
