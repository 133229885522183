import React, { useContext, useState } from 'react';
import styled from '@emotion/styled';
import { Button, H3, Radio, RadioGroup, Link } from '@spglobal/react-components';
import { useHistory } from 'react-router-dom';
import UserContext from 'context/UserContext';
import { USER_CONSENT } from 'utils/constants';
import { TextBodyLg, TextBodyMd } from '../shared';

const Grid = styled.div`
    padding-left: 100px;
`;

const ConsentButton = styled(Button)`
    margin-top: 50px;
    float: right;
`;

const ConsentUserInfo = styled(TextBodyLg)`
    margin-bottom: 8px;
`;

const ConsentHeader = styled(H3)`
    margin-bottom: 8px;
`;

export default function Consent() {
    const history = useHistory();
    const [consent, setConsent] = useState(null);
    const { user, logout } = useContext(UserContext);
    const [shouldDisplayPrivacy, setShouldDisplayPrivacy] = useState(false);
    const userConsent = window.localStorage.getItem(USER_CONSENT);

    if (userConsent) {
        const consentData = atob(userConsent).split('|');
        if (consentData[0] === user.email) {
            window.location.href = `${window.location.origin}/`;
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (consent === '1') {
            const consentString = btoa(`${user.email}|${consent}`);
            window.localStorage.setItem(USER_CONSENT, consentString);
            history.push('/');
        } else if (consent === '0') {
            setShouldDisplayPrivacy(true);
        }
    };

    const handleConsentSelectionChange = (e) => {
        setConsent(e.target.value);
    };

    const UserDetails = () => (
        <>
            <ConsentHeader>S&P Global Climanomics</ConsentHeader>
            <ConsentUserInfo>
                {!shouldDisplayPrivacy && <>User ID : {user.email}</>}
            </ConsentUserInfo>
        </>
    );

    const ConsentAcceptLabel = () => (
        <>
            I consent to S&P Global collecting and storing my personal information to enable access
            to Climanomics, subject to the{' '}
            <Link
                target="_blank"
                href="https://www.spglobal.com/en/privacy/privacy-policy-english"
                rel="noreferrer"
                isVisitedEnabled={false}
            >
                S&P Global Privacy Policy
            </Link>
            .
        </>
    );

    const ConsentRejectLabel = () => (
        <>I decline to having my personal information collected and stored.</>
    );

    return (
        <Grid className="spg-pt-4xl">
            {!shouldDisplayPrivacy && (
                <div className="spg-w-50">
                    <form onSubmit={handleSubmit}>
                        <UserDetails />
                        <RadioGroup
                            name="consent"
                            selectedValue={consent}
                            onChange={handleConsentSelectionChange}
                        >
                            <Radio label={<ConsentAcceptLabel />} value="1" />
                            <Radio label={<ConsentRejectLabel />} value="0" />
                        </RadioGroup>

                        <ConsentButton type="submit" purpose="primary" id="submitAssetButton">
                            SUBMIT
                        </ConsentButton>
                    </form>
                </div>
            )}
            {shouldDisplayPrivacy && (
                <div className="spg-w-50">
                    <UserDetails />
                    <TextBodyMd>
                        The collection and storage of personal information such as email address is
                        required to access Climanomics. Please review the{' '}
                        <Link
                            target="_blank"
                            href="https://www.spglobal.com/en/privacy/privacy-policy-english"
                            rel="noreferrer"
                            isVisitedEnabled={false}
                        >
                            S&P Global Privacy Policy
                        </Link>{' '}
                        for a comprehensive explanation of how we protect your personal information,
                        or you may contact the Privacy Compliance team at{' '}
                        <a href="mailto:privacy@spglobal.com">privacy@spglobal.com</a>
                    </TextBodyMd>
                    <ConsentButton
                        type="submit"
                        purpose="primary"
                        onClick={() => {
                            setShouldDisplayPrivacy(false);
                        }}
                    >
                        BACK
                    </ConsentButton>
                </div>
            )}
        </Grid>
    );
}
