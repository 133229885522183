import React, { createContext, useContext, useState, useEffect } from 'react';

export const HierarchyContext = createContext();
export const useHierarchyContext = () => useContext(HierarchyContext);

export const HierarchyProvider = (props) => {
    const { pathname } = props;
    const [customer, setCustomer] = useState({});
    const [group, setGroup] = useState({});
    const [folder, setFolder] = useState({});
    const [investment, setInvestment] = useState({});
    const [asset, setAsset] = useState({});
    const [view, setView] = useState({});
    const [currentPage, setCurrentPage] = useState('');
    const currentPath = pathname;

    useEffect(() => {
        if (asset.name) {
            setCurrentPage(asset.name);
        } else if (investment.name) {
            setCurrentPage(investment.name);
        } else if (folder.name) {
            setCurrentPage(folder.name);
        } else if (group.name) {
            setCurrentPage(group.name);
        } else if (customer.name) {
            setCurrentPage(customer.name);
        } else if (view.name) {
            setCurrentPage(view.name);
        }
    }, [asset.name, investment.name, folder.name, group.name, customer.name, view.name]);

    return (
        <HierarchyContext.Provider
            value={{
                customer,
                setCustomer,
                group,
                setGroup,
                folder,
                setFolder,
                investment,
                setInvestment,
                asset,
                setAsset,
                view,
                setView,
                currentPage,
                currentPath,
            }}
        >
            {props.children}
        </HierarchyContext.Provider>
    );
};
