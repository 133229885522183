import React, { useState, useEffect } from 'react';
import {
    Button,
    IconButton,
    Modal,
    ModalContent,
    ModalFooter,
    ModalHeader,
    Icon, Spinner, ModalManager
} from '@spglobal/react-components';
import { CLEAR, PLUS } from '@spglobal/koi-icons';
import { Purpose } from '@spglobal/koi-helpers';
import CustomHeader from '../Header/CustomHeader';
import CreateUpdateEntityDialog from '../CreateUpdateEntityDialog';
import { NEW_FOLDER } from '../../utils/constants';
import { DEFAULT_SPINNER_SIZE } from '../components.constants';

export default function MoveAssetsDialog(props) {
    const {
        dialogTitle,
        onSubmit,
        selectedSourceFolders,
        foldersList,
        onCreateFolder,
        getFoldersData,
        handleRowsSelection,
    } = props;
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [dialogType, setDialogType] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [folderNameText, setFolderNameText] = useState('');
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [errorHelperText, setErrorHelperText] = useState('');

    useEffect(() => {
        (async function fetchData() {
            await getFoldersData('');
            setIsLoading(false);
        })();
    }, []);

    const handleListItemClick = (event, index) => {
        setSelectedIndex(index);
    };

    const handleSubmit = (isConfirm) => {
        onSubmit(isConfirm, selectedIndex);
        handleRowsSelection?.([]);
    };

    const handleNewFolderClick = (isConfirm) => {
        if (isConfirm) {
            setDialogType(NEW_FOLDER);
        } else {
            setDialogType('');
            setFolderNameText('');
            setShowErrorMessage(false);
            setErrorHelperText('');
        }
    };

    const isFolderNameInvalid = () => {
        let showErr = false;
        let errText = '';
        if (folderNameText?.length === 0) {
            showErr = true;
            errText = 'Please enter folder name between length 1 to 256';
        } else if (
            foldersList.some(
                (folder) =>
                    folder?.asset_folder_name.toLowerCase() ===
                    folderNameText?.trim()?.toLowerCase()
            )
        ) {
            showErr = true;
            errText = 'Folder name already exists';
        } else if (folderNameText?.length > 256) {
            showErr = true;
            errText = 'Folder name length cannot exceed 256 characters';
        }

        if (showErr) {
            setShowErrorMessage(showErr);
            setErrorHelperText(errText);
        }
        return showErr;
    };

    const handleNewFolderSubmit = () => {
        if (isFolderNameInvalid()) return;
        onCreateFolder(folderNameText);
        setDialogType('');
    };

    const handleTableSearchSubmit = (searchVal) => {
        getFoldersData(searchVal);
    };

    return (
        <ModalManager>
            <Modal
                isOpen
                onClose={() => handleSubmit(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <ModalHeader title={dialogTitle} id="alert-dialog-title">
                    <IconButton icon={CLEAR} onClick={() => handleSubmit(false)} />
                </ModalHeader>

                <ModalContent>
                    <CustomHeader
                        searchPlaceholder="Folder Name"
                        handleSearchSubmit={handleTableSearchSubmit}
                    />
                    {!isLoading && foldersList?.length ? (
                        <div aria-label="main mailbox folders" className="spg-d-flex spg-flex-column">
                            {foldersList.map((folder) => (
                                <Button
                                    purpose={Purpose.MINIMAL}
                                    disabled={
                                        selectedSourceFolders?.includes(
                                            folder?.asset_folder_id
                                        ) ||
                                        (folder.hasOwnProperty('is_assigned') &&
                                            !folder?.is_assigned)
                                    }
                                    key={folder?.asset_folder_id}
                                    active={selectedIndex === folder?.asset_folder_id}
                                    onClick={(event) =>
                                        handleListItemClick(event, folder?.asset_folder_id)
                                    }
                                    className="spg-justify-start"
                                >
                                    {folder.asset_folder_name}
                                </Button>
                            ))}
                        </div>
                    ) : (
                        <div className="spg-d-flex spg-justify-center spg-m-md">
                            <Spinner size={DEFAULT_SPINNER_SIZE} />
                        </div>
                    )}
                </ModalContent>
                <ModalFooter className="spg-d-flex spg-justify-between">
                    <Button
                        purpose={Purpose.PRIMARY}
                        onClick={() => handleNewFolderClick(true)}
                        leftIcon={<Icon icon={PLUS} />}
                    >
                        NEW FOLDER
                    </Button>
                    <Button
                        purpose={Purpose.SECONDARY}
                        onClick={() => handleSubmit(true)}
                        autoFocus
                        disabled={selectedIndex === null}
                    >
                        SELECT
                    </Button>
                </ModalFooter>
            </Modal>

            {dialogType === NEW_FOLDER && (
                <CreateUpdateEntityDialog
                    dialogIsVisible={dialogType === NEW_FOLDER}
                    dialogDismissed={() => handleNewFolderClick(false)}
                    dialogOkClicked={handleNewFolderSubmit}
                    dialogText={{ title: 'New Folder' }}
                    textToDisplay={folderNameText}
                    setTextToDisplay={setFolderNameText}
                    showErrorMessage={showErrorMessage}
                    helpText={errorHelperText}
                    setShowErrorMessage={setShowErrorMessage}
                    setErrorHelperText={setErrorHelperText}
                    primaryButtonLabel="Create"
                />
            )}
        </ModalManager>
    );
}
