import { httpClient } from '../utils/httpClient';
import { API_URL } from '../utils/constants';

const ENDPOINT = 'api/assetTypes';
const ENDPOINT_Details = 'api/assetTypes/impactfunctions';

const getAll = async (params) => {
    const result = await httpClient.get(`${API_URL}/${ENDPOINT}/${params}`);
    return result.data;
};

const getAllDetails = async (queryParams = {}) => {
    const result = await httpClient.get(`${API_URL}/${ENDPOINT_Details}`, { params: queryParams });
    return result.data ? result.data : [];
};

const createAssetType = async (newAssetType) => {
    const result = await httpClient.post(`${API_URL}/${ENDPOINT}`, newAssetType);
    return result;
};

const editAssetType = async (assetTypeId, updatedAssetType) => {
    const result = await httpClient.put(`${API_URL}/${ENDPOINT}/${assetTypeId}`, updatedAssetType);
    return result;
};

const getAllAssetTypeMetadata = async () => {
    const result = await httpClient.get(`${API_URL}/${ENDPOINT}/metadata`);
    return result.data;
};

const getAssetType = async (assetTypeId) => {
    const result = await httpClient.get(`${API_URL}/${ENDPOINT}/${assetTypeId}`);
    return result?.data?.results;
};

export default {
    getAll,
    getAllDetails,
    createAssetType,
    editAssetType,
    getAssetType,
    getAllAssetTypeMetadata,
};
