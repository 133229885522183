import styled from '@emotion/styled';

export const CustomerDetailsTable = styled.table`
    font-size: var(--size-font-5);
    margin-top: var(--size-space-lg);

    & td {
        width: 20%;
    }

    & td:nth-of-type(even) {
        font-weight: var(--font-weight-bold);
    }
`;
