import { httpClient } from '../utils/httpClient';
import { API_URL } from '../utils/constants';

const ASSET_TAGS_ENDPOINT = 'api/assetTags';
const TAGS_ENDPOINT = 'api/customer';

export const getAll = async (entityType, entityId) => {
    const result = await httpClient.get(
        `${API_URL}/${ASSET_TAGS_ENDPOINT}?entityType=${entityType}&entityId=${entityId}`
    );
    return result.data;
};

export const getTagByCustomerId = async (customerId) => {
    const result = await httpClient.get(`${API_URL}/${TAGS_ENDPOINT}/${customerId}/tags`);
    return result.data;
};

export const getTagByAssetId = async (customerId, assetId) => {
    const result = await httpClient.get(`${API_URL}/${TAGS_ENDPOINT}/${customerId}/asset/${assetId}/tags`);
    return result.data;
};

export const createTag = async (tags, customerId) => {
    const result = await httpClient.post(`${API_URL}/${TAGS_ENDPOINT}/${customerId}/tags`, tags);
    return result;
}

export const assignTagsToAsset = async (customerId, assetId, tags) => {
    const params = {
        tags
    }
    const result = await httpClient.post(`${API_URL}/${TAGS_ENDPOINT}/${customerId}/asset/${assetId}/tags`, params);
    return result;
}

export const updateTag = async (tag, customerId) => {
    const result = await httpClient.put(`${API_URL}/${TAGS_ENDPOINT}/${customerId}/tags`, tag);
    return result;
}

export const deleteTag = async (customerId, assetId, tags) => {
    const params = {
        tags,
    };
    const result = await httpClient.put(`${API_URL}/${TAGS_ENDPOINT}/${customerId}/asset/${assetId}/tags`, params);
    return result;
}

export default {
    getAll,
    getTagByCustomerId,
    getTagByAssetId,
    createTag,
    assignTagsToAsset,
    updateTag,
    deleteTag,
};
