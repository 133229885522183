import { httpClient } from '../utils/httpClient';
import { API_URL } from '../utils/constants';

const RISK_FACTORS_LIST_ENDPOINT = 'api/riskFactors';
const SCENARIO_LEVELS_ENDPOINT = 'api/scenarioLevels';
const DECADES_LIST_ENDPOINT = 'api/decades';

const getRiskFactors = async () => {
    const result = await httpClient.get(`${API_URL}/${RISK_FACTORS_LIST_ENDPOINT}`);
    return result.data;
};
const getScenarioLevels = async () => {
    const result = await httpClient.get(`${API_URL}/${SCENARIO_LEVELS_ENDPOINT}`);
    return result.data;
};

export default { getRiskFactors, getScenarioLevels };
