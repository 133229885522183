import React from 'react';
import { LINK } from '@spglobal/koi-icons';
import { Card, useNotification, NotificationType, IconButton } from '@spglobal/react-components';
import BreadcrumbTrail from '../BreadcrumbTrail';
import image1 from './assets/images/image1.png';
import image2 from './assets/images/image2.png';
import image9 from './assets/images/image9.png';
import image10 from './assets/images/image10.png';
import image11 from './assets/images/image11.png';
import image12 from './assets/images/image12.png';
import image13 from './assets/images/image13.png';
import image14 from './assets/images/image14.png';
import image15 from './assets/images/image15.png';
import image16 from './assets/images/image16.png';
import image17 from './assets/images/image17.png';
import image18 from './assets/images/image18.png';
import image19 from './assets/images/image19.png';
import image20 from './assets/images/image20.png';
import image21 from './assets/images/image21.png';
import image22 from './assets/images/image22.png';
import image23 from './assets/images/image23.png';
import image24 from './assets/images/image24.png';

export default function Methodology() {
    const { addNotification } = useNotification();

    const copyToClipboard = (str) => {
        const el = document.createElement('textarea');
        el.value = str;
        el.setAttribute('readonly', '');
        el.style.position = 'absolute';
        el.style.left = '-9999px';
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        addNotification('link copied to clipboard', NotificationType.INFO);
    };

    return (
        <div className="spg-p-md">
            <BreadcrumbTrail rootTitle="Climanomics&reg; Methodology" />
            <div>
                <h1 className="spg-heading spg-heading--xlarge spg-mt-md" id="introduction">
                    INTRODUCTION
                    <IconButton
                        aria-label="Climanomics&reg; Methodology Introduction link"
                        onClick={(e) => {
                            e.preventDefault();
                            copyToClipboard(
                                `${window.location.origin}/methodology/real-assets#introduction`,
                            );
                        }}
                        icon={LINK}
                    ></IconButton>
                </h1>
                <h2 className="spg-heading spg-heading--large spg-mt-md">Context</h2>

                <p className="spg-text spg-text-paragraph">
                    The release of the Taskforce on Climate Related Financial Disclosures (TCFD)
                    report highlighted the importance of climate change as a driver of material
                    financial risks for companies and investors that should be assessed, disclosed
                    and managed.
                </p>
                <p className="spg-text spg-text-paragraph">
                    The TCFD categorizes the financial risks posed by climate change as Physical
                    Risk (both acute and chronic) and Transition Risks (including policy and legal
                    risks, technology risk, market risk and reputational risk).
                </p>
                <p className="spg-text spg-text-paragraph">
                    Physical risks resulting from climate change can be acute (driven by an event
                    such as a flood or storm) or chronic (arising from longer term shifts in climate
                    patterns) and may have financial implications for organizations such as damage
                    to assets, interruption of operations and disruption to supply chains.
                </p>
                <p className="spg-text spg-text-paragraph">
                    S&amp;P Global Sustainable1 launched a suite of Climate Change Physical Risk
                    Analytics solutions to the market in 2019, offering an asset-based approach to
                    the assessment of physical risk at the real asset, company and portfolio level.
                    This suite of solutions was enhanced in 2022 with the integration of the latest
                    available climate change models and data, and expansion of the range of hazards
                    and scenarios covered.
                </p>
                <p className="spg-text spg-text-paragraph">
                    This report describes the methodologies, models and datasets that underpin the
                    Climanomics&reg; platform solution for real asset climate risk analysis.
                    Climanomics&reg; is a risk analytics platform that calculates the financial impact
                    of climate risk on physical assets and aggregates up to the portfolio level.
                    Analysis spans across eight decades for four emissions scenarios and covers any
                    location on the planet.
                </p>
                <p className="spg-text spg-text-paragraph">
                    Climanomics&reg; is designed to support a range of use cases, including risk
                    management, sustainable investing, strategic decision-making, as well as
                    compliance and reporting.
                </p>

                <div>
                    <h2
                        className="spg-heading spg-heading--large spg-mt-md"
                        id="climate-risk-methodological-framework"
                    >
                        The Climanomics&reg; Climate Risk Methodological Framework
                        <IconButton
                            aria-label="Climanomics&reg; Methodology Introduction link"
                            onClick={(e) => {
                                e.preventDefault();
                                copyToClipboard(
                                    `${window.location.origin}/methodology/real-assets#climate-risk-methodological-framework`,
                                );
                            }}
                            icon={LINK}
                        ></IconButton>
                    </h2>
                </div>

                <div>
                    <p className="spg-text spg-text-paragraph">
                        S&amp;P Global Sustainable 1 Climanomics&reg; platform enables users to quantify
                        the future financial Impacts of climate change by:
                    </p>
                    <ul className="spg-list">
                        <li>
                            Integrating terabytes of climate and socioeconomic data on
                            climate-related hazards
                        </li>
                        <li>Driving econometric models with hazard inputs and business data</li>
                        <li>
                            Translating risk into financial terms to provide decision-relevant
                            insights.
                        </li>
                    </ul>
                    <p className="spg-text spg-text-paragraph">
                        The Climanomics&reg; physical risk methodology and outputs are fully aligned
                        with the TCFD framework, shown in the figure below.
                    </p>
                </div>

                <figure>
                    <img
                        src={image1}
                        alt="Task Force on Climate-Related Financial Disclosures (TCFD) framework"
                    />
                    <figcaption>
                        Figure 1: TCFD Framework<a title="" href="#ftn1"><sup>[1]</sup></a>
                    </figcaption>
                </figure>

                <p className="spg-text spg-text-paragraph">
                    The purpose of this document is to provide a general introduction to the
                    Climanomics&reg; methodology. Core to this methodology are the concepts of hazard,
                    vulnerability, and risk:
                </p>
                <ul className="spg-list">
                    <li>
                        <strong>Hazard</strong> - Changes in environmental or economic conditions
                        associated with climate change. These are expressed as specific metrics that
                        change through time.
                    </li>
                    <li>
                        <strong>Vulnerability</strong> - Responses of an asset or entity to changes
                        in the climate-related hazards. These are sensitive to the levels of the
                        hazard metrics.
                    </li>
                    <li>
                        <strong>Risk</strong> - Financial measures of impacts induced by the hazards
                        via the vulnerabilities. This is based on the combination of the degree of
                        vulnerability (at a given hazard level) and the valuation of an asset.
                    </li>
                </ul>

                <p className="spg-text spg-text-paragraph">
                    These concepts, and their application in the Climanomics&reg; framework, are
                    described in detail in the following sections.
                </p>
            </div>
            <div>
                <h1 className="spg-heading spg-heading--xlarge spg-mt-md" id="modeling-methodology">
                    MODELING METHODOLOGY
                    <IconButton
                        aria-label="modeling-methodology"
                        onClick={(e) => {
                            e.preventDefault();
                            copyToClipboard(
                                `${window.location.origin}/methodology/real-assets#modeling-methodology`,
                            );
                        }}
                        icon={LINK}
                    ></IconButton>
                </h1>
                <h2 className="spg-heading spg-heading--large spg-mt-md">
                    PRINCIPLES OF HAZARD-CHANGE MODELING
                </h2>
            </div>
            <p className="spg-text spg-text-paragraph">
                The Climanomics&reg; hazard modeling reflects the climate-related change in the level
                of hazard exposure of an asset over time, relative to a historical baseline. Each
                hazard is associated with a specific metric, which defines how the hazard is
                measured and expressed. The data underlying each hazard metric is sourced from a
                variety of climate models and other data sources.
            </p>

            <p className="spg-text spg-text-paragraph">
                The Climanomics&reg; platform assumes complete adaptation to climate conditions
                prevalent in the last half of the 20th Century. This establishes the reference level
                for risk and zero risk is assumed when hazards are at historical levels.
                Climanomics&reg; thus estimates the additional risk that is attributable to climate
                change, relative to a world without climate-related changes in hazard levels.
            </p>
            <h2 id="physical-hazards">
                PHYSICAL HAZARD MODELING
                <IconButton
                    aria-label="Climanomics&reg; Methodology Physical Hazard Modeling link"
                    onClick={(e) => {
                        e.preventDefault();
                        copyToClipboard(
                            `${window.location.origin}/methodology/real-assets#physical-hazards`,
                        );
                    }}
                    icon={LINK}
                ></IconButton>
            </h2>
            <p className="spg-text spg-text-paragraph">
                The Climanomics&reg; platform processes and analyzes atmospheric data related to
                temperature, precipitation, drought, wildfire, as well as other data related to
                coastal flooding, tropical cyclones, water stress, and fluvial-basin flooding in
                order to provide a rigorous estimate of risk under various conditions. This section
                addresses each of the physical hazards modeled, as well as the data used.
            </p>
            <p className="spg-text spg-text-paragraph">
                <em>Table 1. Climanomics&reg; Physical Hazard Coverage</em>
            </p>
            <Card hasBorder hasRoundedCorner>
                <table className="spg-table">
                    <thead>
                        <tr>
                            <th>PHYSICAL HAZARD</th>
                            <th>HAZARD TYPE</th>
                            <th>BASELINE PERIOD</th>
                            <th>GEOGRAPHIC COVERAGE</th>
                            <th>RESOLUTION</th>
                            <th>UNDERLYING VARIABLES</th>
                            <th>SOURCE</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Extreme Temperature</td>
                            <td>Chronic</td>
                            <td>1980-2000</td>
                            <td>Global</td>
                            <td>25km</td>
                            <td>Temperature</td>
                            <td>
                                CMIP6 <a title="" href="#ftn2"><sup>[2]</sup></a>; NEX-GDDP{' '}
                                <a title="" href="#ftn2"><sup>[2]</sup></a>{' '}
                            </td>
                        </tr>
                        <tr>
                            <td>Drought</td>
                            <td>Chronic</td>
                            <td>1980-2000</td>
                            <td>Global</td>
                            <td>25km</td>
                            <td>Temperature, Precipitation</td>
                            {/* TODO: check if below is correctly formatted */}
                            <td>
                                CMIP<a title="" href="#ftn6"><sup>[6]</sup></a>; NEX-GDDP
                            </td>
                        </tr>
                        <tr>
                            <td>Wildfire</td>
                            <td>Chronic</td>
                            <td>1980-2000</td>
                            <td>Global</td>
                            <td>25km</td>
                            <td>Temperature, Precipitation</td>
                            {/* TODO: check if below is correctly formatted */}
                            <td>
                                CMIP<a title="" href="#ftn6"><sup>[6]</sup></a>; NEX-GDDP
                            </td>
                        </tr>
                        <tr>
                            <td>Water Stress</td>
                            <td>Chronic</td>
                            <td>1950-2010</td>
                            <td>Global</td>
                            <td>Variable (approximately 50-100km)</td>
                            <td>Withdrawal, Available Renewable Water Supply</td>
                            <td>
                                World Resources Institute (WRI)
                                <a title="" href="#ftn3"><sup>[3]</sup></a>
                            </td>
                        </tr>
                        <tr>
                            <td>Coastal Flooding</td>
                            <td>Acute</td>
                            <td>1986-2005</td>
                            <td>Global</td>
                            <td>30*30m</td>
                            <td>Sea level rise, storm tide</td>
                            <td>
                                Kopp et al., 2014
                                <a title="" href="#ftn4"><sup>[4]</sup></a>; Muis et al., 2016
                                <a title="" href="#ftn5"><sup>[5]</sup></a>
                            </td>
                        </tr>
                        <tr>
                            <td>Fluvial Basin Flooding</td>
                            <td>Acute</td>
                            <td>1950-2000</td>
                            <td>Global</td>
                            <td>25 km</td>
                            <td>
                                Annual frost days, consecutive dry days, maximum 5-day
                                precipitation, basin area, slope, impervious surface area, and
                                lake-storage area in each basin
                            </td>
                            <td>
                                CMIP6; NEX-GDDP; World Wildlife Fund (WWF) HydroBASINS
                                <a title="" href="#ftn6"><sup>[6]</sup></a>
                            </td>
                        </tr>
                        <tr>
                            <td>Tropical Cyclone</td>
                            <td>Acute</td>
                            <td>1950-1980</td>
                            <td>Global</td>
                            <td>25km </td>
                            <td>Sea surface temperature</td>
                            <td>
                                NASHM <a title="" href="#ftn7"><sup>[7]</sup></a>
                            </td>
                        </tr>
                        <tr>
                            <td>Pluvial Flooding</td>
                            <td>Acute</td>
                            <td>1950-2000</td>
                            <td>Global</td>
                            <td>25km </td>
                            <td>Precipitation</td>
                            <td>CMIP6; NEX-GDDP</td>
                        </tr>
                    </tbody>
                </table>
            </Card>
            <p className="spg-text spg-text-paragraph">
                The data sources used do not presently allow for complete alignment of baselines, so
                some small distortion of relative importance may be present. This is not considered
                to be significant for screening-level analysis at this time but will be addressed in
                upcoming research and development.
            </p>
            <p className="spg-text spg-text-paragraph">
                The metrics used to represent each of these hazards are discussed below.
            </p>
            <h2 id="cmip6-climate-models" className="spg-mt-md">
                INTRODUCTION TO THE CMIP6 CLIMATE MODELS
                <IconButton
                    aria-label="cmip6-climate-models"
                    onClick={(e) => {
                        e.preventDefault();
                        copyToClipboard(
                            `${window.location.origin}/methodology/real-assets#cmip6-climate-models`,
                        );
                    }}
                    icon={LINK}
                ></IconButton>
            </h2>
            <p className="spg-text spg-text-paragraph">
                As described in Table 1, the majority of the climate data underpinning Climanomics&reg;
                is derived from the Coupled Model Intercomparison Project (CMIP) run by the World
                Climate Research Programme
                <a title="" href="#ftn8"><sup>[8]</sup></a>. The latest version of Climanomics&reg;,
                released in 2023, is based on CMIP6, which integrates many of the latest advances in
                climate change science. The CMIP6 models were developed in support of the Sixth
                Assessment Report of the Intergovernmental Panel on Climate Change (IPCC AR6).
                Climanomics&reg; also leverages downscaled CMIP6 datasets provided by the NASA Earth
                Exchange (NEX), enabling an enhancement of the resolution of analysis for many
                hazards from 100x100km to ~25x25km spatial resolution.
            </p>
            <p className="spg-text spg-text-paragraph">
                The following sections explain some of the most important advantages of the CMIP6
                climate models and the differences compared to CMIP5 which are relevant to
                Climanomics&reg;.
            </p>
            <h3 className="spg-heading spg-heading--medium">
                Leveraging an Increased Number of Climate Models
            </h3>
            <p className="spg-text spg-text-paragraph">
                A total of 49 modeling institutions contributed a total of 132 climate models to
                CMIP6, compared to 28 institutions contributing 61 models in CMIP5. Integration of a
                broader range of modelling institutions and models, each with their own strengths
                and weaknesses, helps to improve the performance of the project as a whole and helps
                scientists identify which trends in the data are due to model biases and which are
                not. In the aggregate, this allows for more robust modelling of climate change
                hazards and their potential impacts on real assets.
            </p>
            <h3 className="spg-heading spg-heading--medium">Higher Climate Sensitivity</h3>
            <p className="spg-text spg-text-paragraph">
                Climate sensitivity is a measure of the relationship between changes in global CO2
                concentrations and changes in global temperatures. Most previous climate model data,
                including data from CMIP5, found that a doubling of atmospheric CO2 would result in
                a 1.5-4.5°C rise in global temperatures. However, more recent research that feeds
                into CMIP6 finds that a doubling of atmospheric greenhouse gas emissions would
                result in larger changes in global temperatures, ranging from 1.8-5.6°C. Integration
                of the best available science on climate sensitivity is important to ensure that
                future climate change impacts are not underestimated.
            </p>
            <h3 className="spg-heading spg-heading--medium">New Climate Scenarios</h3>
            <p className="spg-text spg-text-paragraph">
                The CMIP5 climate modelling framework was structured around four Representative
                Concentration Pathway (RCP) scenarios. The RCP scenarios are driven primarily by
                projections of changes in factors such as greenhouse gas emissions and land use
                change, which directly impact radiative forcing, or the amount of excess energy in
                the Earth’s system. Since these scenarios centered around radiative forcing, they
                were named for the amount of forcing (in the standard units of W/m2) projected for
                the year 2100 (RCP2.6, RCP4.5, RCP6.0, and RCP8.5).
            </p>
            <p className="spg-text spg-text-paragraph">
                In the CMIP6 framework, a complementary set of scenarios focused on projecting
                socioeconomic changes was developed to be used alongside the RCPs. These new
                scenarios, Shared Socioeconomic Pathways (SSPs), are based on five distinct
                narratives for future socioeconomic development. The narratives provide a consistent
                logic for the qualitative projections of land use, energy use, population,
                emissions, and other factors, embedded within the scenario. The five pathways and
                their underlying narratives are described in Table 2.
            </p>

            <ul className="spg-list">
                <p className="spg-text spg-text-paragraph">
                    Table 2: CMIP6 Shared Socioeconomic Pathways
                </p>
                <ul className="spg-list">
                    <li>
                        SSP1 Sustainability - Taking the Green Road
                        <ul className="spg-list">
                            <li>Sustainable development is prioritized</li>
                            <li>
                                Emphasis shifts from economic development to broader human
                                well-being
                            </li>
                            <li>
                                High levels of education and health investment lead to low
                                inequality
                            </li>
                        </ul>
                    </li>
                </ul>
                <p className="spg-text spg-text-paragraph">{'     '}</p>
                <ul className="spg-list">
                    <li>
                        SSP2 Middle of the Road
                        <ul className="spg-list">
                            <li>Historical trends generally persist</li>
                            <li>
                                Work to address sustainable development goals is slow and somewhat
                                inconsistent globally
                            </li>
                        </ul>
                    </li>
                </ul>
                <p className="spg-text spg-text-paragraph">{'     '}</p>
                <ul className="spg-list">
                    <li>
                        SSP3 Regional Rivalry - A Rocky Road
                        <ul className="spg-list">
                            <li>
                                Resurgent nationalism causes countries to prioritize domestic issues
                            </li>
                            <li>Economic development slows and inequalities persist</li>
                            <li>Environmental concerns are deprioritized</li>
                        </ul>
                    </li>
                </ul>
                <p className="spg-text spg-text-paragraph">{'     '}</p>
                <ul className="spg-list">
                    <li>
                        SSP4 Inequality - A Road Divided
                        <ul className="spg-list">
                            <li>
                                A gap in global wealth and education between societies widens over
                                time
                            </li>
                            <li>Conflict and unrest become more common</li>
                            <li>
                                Technological and sustainable development accelerates among the
                                globally connected but flounders elsewhere
                            </li>
                        </ul>
                    </li>
                </ul>
                <p className="spg-text spg-text-paragraph">{'     '}</p>
                <ul className="spg-list">
                    <li>
                        SSP5 Fossil-fueled Development - Taking the Highway
                        <ul className="spg-list">
                            <li>
                                Rapid technological and social development fueled by wide
                                exploitation of fossil fuels
                            </li>
                            <li>Resource- and energy-intensive lifestyles adopted globally</li>
                        </ul>
                    </li>
                </ul>
            </ul>

            <p className="spg-text spg-text-paragraph">
                CMIP6 also adds a new RCP, RCP7.0, which falls between RCP4.5 and RCP8.5 and offers
                a plausible projection of the outcome of current efforts to mitigate climate change.
                In addition, RCP6.0 has been replaced with SSP3-7.0 in the new CMIP6 version of
                Climanomics&reg;. The SSPs and RCPs are combined to create a final set of scenarios for
                input into the CMIP6 models. The combined SSP/RCP scenarios capture both physical
                and socioeconomic factors (Figure 2). For example, SSP1-2.6 simulates a global
                society that focuses on sustainable development (SSP1) and is able to hold radiative
                forcing levels to 2.6 W/m2 in 2100 (RCP2.6).
            </p>

            <figure>
                <img src={image9} alt="Figure 2: CMIP6 SSP and RCP Combinations" />
                <figcaption>
                    Figure 2: CMIP6 SSP and RCP Combinations
                    <a title="" href="#ftn9"><sup>[9]</sup></a><a title="" href="#ftn10"><sup>[10]</sup></a>
                </figcaption>
            </figure>
            <h1 className="spg-heading spg-heading--xlarge" id="climate-hazard-modeling">
                <strong>CLIMATE HAZARD MODELING</strong>
                <IconButton
                    aria-label="climate-hazard-modeling"
                    onClick={(e) => {
                        e.preventDefault();
                        copyToClipboard(
                            `${window.location.origin}/methodology/real-assets#climate-hazard-modeling`,
                        );
                    }}
                    icon={LINK}
                ></IconButton>
            </h1>
            <p className="spg-text spg-text-paragraph">
                The following sections describe the methods and datasets used to derive the
                projections for each of the eight climate change hazards covered by the
                Climanomics&reg; platform.
            </p>
            <p className="spg-text spg-text-paragraph">
                <strong>Extreme Heat, Drought and Wildfire</strong>
            </p>
            <p className="spg-text spg-text-paragraph">
                The extreme heat, drought, and wildfire hazards are modelled utilizing temperature
                and precipitation data from CMIP6 and downscaled projections provided by the NASA
                Earth Exchange Global Daily Downscaled Projections (NEX-GDDP) project. The NEX-GDDP
                dataset offers global downscaled climate change data at 25x25km resolution across a
                range of scenarios. Sustainable 1 integrates relevant climate modelling parameters
                of relevance to each hazard across all available CMIP6 models.
            </p>
            <p className="spg-text spg-text-paragraph">
                The extreme heat, drought and wildfire hazards are calculated as follows:
            </p>
            <p className="spg-text spg-text-paragraph spg-ml-md">
                <i>Extreme Heat:</i> There are three measures of extreme heat used in analysis of
                financial impact. Tx90p, the percentage of days per year with a maximum temperature
                that exceeds the 90th percentile of the local historical baseline daily maximum
                temperature; Tx90pAbsChng representing the absolute change in 90th percentile
                maximum temperature vs. a historical baseline; and Tx50pAbsChg representing the
                absolute change in median maximum temperature vs. a historical baseline. These
                hazard variables are derived directly from each downscaled CMIP6 model, then
                averaged across models and years in a projected decade.
            </p>
            <p className="spg-text spg-text-paragraph spg-ml-md">
                <i>Drought:</i> The drought hazard is derived from the Self-Calibrating Palmer
                Drought Index (scPDSI)
                <a title="" href="#ftn11"><sup>[11]</sup></a>, as computed by a package from the
                University of Nebraska. scPDSI utilizes Available Water Content (AWC) as an input,
                in addition to temperature and precipitation from the downscaled CMIP6. The hazard
                variable in a projected year is the number of days with scPDSI less than or equal to
                the historical 10th percentile, and these are averaged over projected decades. The
                spatial grid is the global 0.25° downscaled CMIP6 grid.
            </p>
            <p className="spg-text spg-text-paragraph spg-ml-md">
                <i>Wildfire:</i> The wildfire hazard is derived from the wildfire Z-index, which,
                like drought, is computed using the University of Nebraska scPDSI package. AWC is
                also an input variable, in addition to downscaled-CMIP6 projected temperature and
                precipitation. The Z-index is a measure of low moisture anomalies and is therefore a
                relevant predictor for wildfire probability. As with psPDSI, the hazard variable for
                Z-index in a projected year is the number of days with Z-index less than or equal to
                the historical 10th percentile, and these are averaged over projected decades. The
                spatial grid is again the global 25x25km downscaled CMIP6 grid.
            </p>
            <p className="spg-text spg-text-paragraph spg-ml-md">
                Since the wildfire hazard represents wildfire conditions rather than the occurrence
                of wildfire events, a land cover mask derived from the Copernicus Global Land
                Service dataset
                <a title="" href="#ftn12"><sup>[12]</sup></a> is applied to differentiate areas
                which contain burnable vegetation and those that do not. The land cover mask is
                applied at 300x300m resolution and sets the wildfire hazard to zero in locations
                where less than 20% of the wildfire hazard pixel is covered with burnable
                vegetation.
            </p>
            <strong>Coastal Flooding</strong>
            <p className="spg-text spg-text-paragraph">
                Climanomics&reg; applies a statistical model of coastal flood depths in order to
                estimate the projected frequency of baseline 100-year flood depth by decade to 2090s
                under four climate scenarios. Historical storm-tide (surge plus tide) levels at 9
                return periods from the GTSR global hydrodynamic system are combined with Sea-Level
                Rise (SLR) projections (Kopp et al) to model flood levels in coastal regions. A
                flood-water path-finding algorithm is applied to determine the interior points that
                are subject to flooding in response to different coastal water levels. The algorithm
                makes use of topographic elevation data at 30-meter resolution over the US and
                90-meter resolution elsewhere, and this defines the resolution of the coastal flood
                analysis. From the resulting flood depths at different return periods, we compute
                the projected frequency of the historical baseline 100-yr flood depth as the primary
                hazard variable. Note that components of the Kopp SLR data use CMIP5 inputs, not
                CMIP6. To include certain CMIP6 scenarios, we have applied an interpolation
                procedure to the CMIP5-based SLR.
            </p>
            <strong>Fluvial Flooding</strong>
            <p className="spg-text spg-text-paragraph">
                Climanomics&reg; applies a statistical model of fluvial-basin flood volumes and depth
                that estimates changes in return period for the historical 100-year flood in each
                catchment basin. The 10-yr and 100-yr return-period (RP) river discharges are
                related statistically to seven variables (“covariates”) based on a published
                analysis of historical data over the USA. Four covariates are topographic in nature
                (river drainage basin area, basin-mean channel slope, basin water storage expressed
                as fractional area comprised of lakes and ponds, and basin impervious surface
                fractional area) and three are climatological (5-day precipitation maxima and
                numbers of consecutive dry days and frosts days). For future decades, the climate
                covariates are derived from the downscaled CMIP6 dataset. The projected 10-yr and
                100-yr discharges are interpolated to obtain the projected frequency (reciprocal of
                RP) corresponding to the baseline 100-yr discharge. The grid for the analysis is
                comprised of the geometric intersection of the downscaled CMIP6 0.25° grid and the
                topographic-data grid of irregular drainage-basin polygons (HydroAtlas level 12).
            </p>
            <p className="spg-text spg-text-paragraph">
                The fluvial-basin flooding model includes flooding from streams and natural
                water-flow networks within the basin in which an asset is located. Thus, the current
                model doesn’t account for differential asset characteristics such as elevation
                properties because the hazard value for assets are reflected at basin-scale.
            </p>
            <strong>Tropical Cyclone</strong>
            <p className="spg-text spg-text-paragraph">
                The Tropical Cyclone (TC) hazard is calculated via a statistical-stochastic model
                that simulates the lifecycle of TCs, trained on historical TC track data in each of
                the world’s TC-sustaining ocean basins. Included in the training are statistical
                relationships between TC variability and sea-surface temperature (SST). For future
                decades, SST data directly from 10 CMIP6 models are used to drive new TC simulations
                in future climate states. The TC metric derived from the simulations is annual rate
                of category 3 and higher TCs in 0.25° grid cells globally. Due to rapidly increasing
                uncertainty, TCs projections are only made through the 2040s. Subsequent decades are
                held at the 2040s value
            </p>
            <strong>Water Stress</strong>
            <p className="spg-text spg-text-paragraph">
                In order to model water stress, Climanomics&reg; uses location-specific data from WRI’s
                Aqueduct 3.0.
                <a title="" href="#ftn13"><sup>[13]</sup></a> Climanomics&reg; incorporates the
                current Baseline Water Stress metric from this dataset, as well as the projections
                for the 2020s through the 2040s. Climanomics&reg; translates the baseline water stress
                indicator and the projected changes in water stress level into a fractional
                indicator that ranges from zero to one, with higher numbers indicating higher levels
                of water stress.
            </p>
            <p className="spg-text spg-text-paragraph">
                The hazard metric is a fractional value corresponding to the midpoint of the ranges
                in the Aqueduct risk categories (so-called raw values). For example, if the WRI
                Baseline Water Stress metric raw-value range is 10-20% (low-medium risk),
                Climanomics&reg; uses the midpoint, 15%, expressed as a decimal, 0.15. For future
                projections, this Baseline value is multiplied by the increase or decrease factors
                in the WRI projections. These factors range from a 2x decrease to a 2.8x increase
                and correspond to multipliers ranging from 0.5 to 2.8.
            </p>
            <strong>Pluvial Flooding</strong>
            <p className="spg-text spg-text-paragraph">
                In order to model Pluvial flooding, a form of climate hazard which is driven by
                extreme precipitation, Climanomics&reg; uses daily precipitation data from an ensemble
                of NEX-GDDP-downscaled CMIP6 models (25-km resolution worldwide). Climanomics&reg;
                applies the statistical model of Generalized Extreme Value analysis to determine the
                Intensity of extreme rare events. The model uses a simplifying assumption that
                topography and natural or artificial drainage capacity Is constant in time thus It
                enables avoiding the necessity of using high-resolution topographic or drainage data
            </p>
            <p className="spg-text spg-text-paragraph">
                This generation of Pluvial hazard modelling is limited to projections of annual
                frequency of the historical baseline 100-year precipitation rate which relates to
                the pluvial hazard metric of annual frequency of 100-year flood depth.
            </p>
            <h1
                className="spg-heading spg-heading--xlarge spg-mt-md"
                id="impact-modeling-for-physical-hazards"
            >
                IMPACT MODELING FOR PHYSICAL HAZARDS
                <IconButton
                    aria-label="IMPACT MODELING FOR PHYSICAL HAZARDS"
                    onClick={(e) => {
                        e.preventDefault();
                        copyToClipboard(
                            `${window.location.origin}/methodology/real-assets#impact-modeling-for-physical-hazards`,
                        );
                    }}
                    icon={LINK}
                ></IconButton>
            </h1>
            <p className="spg-text spg-text-paragraph">
                The following section describes the methodologies and data applied to quantify the
                financial impacts arising from changing exposure to climate change hazards.
            </p>
            <div>
                <div>
                    <h2 className="spg-heading spg-heading--large spg-mt-md">Impact Functions</h2>
                </div>
                <p className="spg-text spg-text-paragraph">
                    The Climanomics&reg; vulnerability methodology models direct financial impacts that
                    each hazard is expected to have on each asset type. Each asset type’s
                    vulnerability is characterized based on the specific ways (“impact pathways”) in
                    which a particular asset type is impacted by a given climate hazard. Finally,
                    impact functions, composed of impact pathways, are assigned to model the risk
                    based on the hazard and vulnerability. Climanomics&reg; has developed an extensive
                    library of detailed impact functions based on peer-reviewed published research
                    and papers published by government and industry sources; these are considered
                    Tier 1, or specific functions. In order to cover the broadest possible range of
                    asset types, the impact function library also consists of Tier 2, or general,
                    functions which have been assigned by comparing a given asset type with others
                    and selecting the most appropriate function among those available or using a
                    broader range of literature to estimate impacts. Over time, S&amp;P Global
                    Sustainable1 plans to update general (Tier 2) functions in order to achieve
                    greater specificity.
                </p>
                <p className="spg-text spg-text-paragraph">
                    Impact functions estimate the financial losses - including revenue, operating
                    expenses, and capital expenditures - that a hazard of varying intensity would
                    cause to a specific class of asset. A hazard might cause harm via diverse
                    impacts, which would require multiple impact pathways to characterize. For
                    example, high maximum daily temperatures at a manufacturing facility could drive
                    up cooling costs, degrade the HVAC system, and reduce the productivity of
                    employees working inside.
                </p>
            </div>
            <div>
                <h2 className="spg-heading spg-heading--large spg-mt-md">Asset Structure</h2>
                <p className="spg-text spg-text-paragraph">
                    Impact functions may be applied to a point, spatial, or linear asset:
                </p>
                <ul className="spg-list">
                    <li>
                        <strong>Point: </strong>Assets whose locations are associated with a
                        specific latitude and longitude.
                    </li>
                    <li>
                        <strong>Spatial: </strong>Assets that typically cover a large area and are
                        assessed through a number of sampled points. Climate risk for these assets
                        is the sum of climate risk at each point.
                    </li>
                    <li>
                        <strong>Linear: </strong>Assets assessed through a series of latitudinal and
                        longitudinal points. Climate risk for these assets is the sum of climate
                        risk at each point.
                    </li>
                </ul>
                <p className="spg-text spg-text-paragraph">
                    For spatial and linear assets, the risk to the asset itself is calculated as a
                    sum of risks across sampled points. Sample points are points selected by
                    Climanomics&reg; clients to represent the area or path of a non-point asset for
                    example, a 10km power transmission line can potentially be divided into 10-point
                    locations. Weighting of sample points can be applied by entering each point as a
                    separate asset in Climanomics&reg;. For example, when assessing a railroad (linear
                    asset), impact functions would be assigned to sampled points ranging from track,
                    stations, and junctions; varying asset values / importance weighting would be
                    applied to stations and junctions in contrast to track points.
                </p>
            </div>
            <div>
                <h2 className="spg-heading spg-heading--large spg-mt-md">
                    Financial Materiality Perspectives
                </h2>
                <p className="spg-text spg-text-paragraph">
                    Within the Climanomics&reg; platform, impact functions are modeled from three
                    financial perspectives, based on ownership of assets and investment structure of
                    financial instruments:
                </p>

                <ul className="spg-list">
                    <li>
                        <strong>Investor-Owner: </strong>This perspective reflects a situation in
                        which an investment manager owns the asset and leases it out to others. The
                        implication is that the investment manager is financially impacted by direct
                        damage to the building and rental income, but not for damage to tenants’
                        equipment/inventory or revenue losses. Investor-Owner impact functions
                        include operating expenses related to facility maintenance or utilities to
                        account for the potential impact on investors, despite the fact that tenants
                        are typically responsible for these expenses.
                    </li>

                    <li>
                        <strong>Owner-Occupier:</strong> This perspective captures impacts that
                        accrue to a company that owns and uses the building. The implication is that
                        Owner-Occupiers are responsible for financial impacts spanning revenues,
                        operating expenses, and capital expenditures related to employee
                        productivity, cooling costs, and direct building damages.
                    </li>
                    <li>
                        <strong>Tenant:</strong>This perspective reflects tenants of buildings, who
                        lease either an entire building or a space from Investor-Owners. The
                        implication is that Tenants are responsible for financial impacts related to
                        revenue and operating expenses, but not direct building damage (which would
                        be paid for by the Investor-Owner).
                    </li>
                    <li>
                        <strong>Manufacturer-Supplier:</strong>This perspective reflects the
                        manufacturer who produces goods in a facility and supplies them to a
                        Company; however, the Company does not own or lease the building. The main
                        financial impacts stem from a disruption in operations at the facility,
                        resulting in a reduction of goods to be sold for profit.
                    </li>
                </ul>
                <p className="spg-text spg-text-paragraph">
                    In some cases, impact functions from multiple perspectives are included in
                    analyses to capture impacts to both income and investments. For real estate
                    investors, for example, both the Investor-Owner and Tenant perspectives are
                    often relevant. While real estate investors themselves function as
                    investor-owners who are not responsible for operating expenses related to
                    facility maintenance or utilities, which are passed through to tenants,
                    understanding the tenant perspective sheds light on potential vulnerability
                    tipping points which impact rental income and, via net operating income, overall
                    return on investment.
                </p>
            </div>
            <div>
                <h2 className="spg-heading spg-heading--large spg-mt-md">
                    Locational and Geographic Considerations
                </h2>
                <p className="spg-text spg-text-paragraph">
                    Some impact functions are specified by locational and geographic considerations,
                    namely whether the asset is located in a rural or urban area, and whether it is
                    located in a tropical or temperate region.
                </p>
                <p className="spg-text spg-text-paragraph">
                    For some crops, impact functions have been developed that distinguish between
                    the physiological response of crops in tropical versus temperate climates. While
                    this is not currently available for all crops, Climanomics&reg; will continue to
                    incorporate such geographic considerations over time and as literature becomes
                    available. For analysis within the platform, tropical and temperate are defined
                    as:
                </p>
                <ul className="spg-list">
                    <li>
                        <strong>Tropical: </strong>The area of the tropics surrounding the equator
                        from 23&deg;N to 23&deg;S.
                    </li>
                    <li>
                        <strong>Temperate: </strong>The region extending north from 23&deg;N and
                        south from 23&deg;S.
                    </li>
                </ul>
            </div>
            <div>
                <h2 className="spg-heading spg-heading--large spg-mt-md">
                    Impact Function Coverage
                </h2>
                <p className="spg-text spg-text-paragraph">
                    For investment portfolios, Climanomics&reg; begins the impact function modeling
                    process (outlined in the diagram below) by identifying the underlying entity of
                    each financial instrument. For a corporate bond, for example, Climanomics&reg;
                    requires inputs on the company of interest, information about company’s assets
                    (offices, data centers, manufacturing facilities, etc) and, wherever possible,
                    information on whether the company owns or leases each asset. Impact functions
                    are then assigned based on asset and ownership type, for each hazard
                    Climanomics&reg; currently covers about 290 asset types as described in Table 5
                    (Appendix B)
                </p>
                <p className="spg-text spg-text-paragraph">
                    <img
                        className="spg-w-100"
                        src={image2}
                        alt="Impact function modeling process for financial instruments flowchart."
                    />
                    <span>
                        Figure 3: Impact function modeling process for financial instruments
                    </span>
                </p>
            </div>
            <h1
                className="spg-heading spg-heading--xlarge spg-mt-md"
                id="impact-modeling-for-transition-risk"
            >
                IMPACT MODELING FOR TRANSITION RISK
                <IconButton
                    aria-label="IMPACT MODELING FOR TRANSITION RISK"
                    onClick={(e) => {
            e.preventDefault();
            copyToClipboard(
                `${window.location.origin}/methodology/real-assets#impact-modeling-for-transition-risk`,
            );
                    }}
                    icon={LINK}
                ></IconButton>
            </h1>
            <div className="spg-text">
                <p>Climanomics&reg; incorporates modeling of the following transition hazards,
                    associated with a global transition to a low-carbon economy, via the metrics described below.</p>
                <h2><strong><em>Carbon Pricing</em></strong></h2>
                <p>This transition risk is related to policies and regulations that may impose a carbon price through such mechanisms as
                    carbon taxes or emissions trading<a title="" href="#ftn14"><sup>[14]</sup></a>.
                    Climanomics&reg; utilizes carbon price projections from the underlying data of the Shared Socioeconomic
                    Pathways (SSPs) models used by the IPCC.
                    An SSP is one of a collection of pathways that describe alternative futures of
                    socio-economic development<a title="" href="#ftn15"><sup>[15]</sup></a>.</p>
                <p>The latest SSP data (including the data for IPCC 1.5C) is specific for 5 different regions in each scenario.
                    For a full list of the countries included in each region, please see Appendix A.</p>
                <ul>
                    <li>OECD = Includes the OECD 90 and EU member states and candidates.</li>
                    <li>REF = Countries from the Reforming Economies of Eastern Europe and the Former Soviet Union.</li>
                    <li>ASIA = The region includes most Asian countries
                        with the exception of the Middle East, Japan and Former Soviet Union states.</li>
                    <li>MAF = This region includes the countries of the Middle East and Africa.</li>
                    <li>LAM = This region includes the countries of Latin America and the Caribbean.</li>
                </ul>
                <p>Climanomics&reg; links each asset to the appropriate country and then to the region.<br></br>
                    Climanomics&reg; also maintains at least three levels of geographic specificity for each asset (city/state/country),
                    which will allow additional granularity as carbon-price projections become more detailed over time
                    (e.g., UK vice France, or California vice Montana).
                    Specially tailored price projections could also be used if there are issues that a customer would like to explore.</p>
                <p>For the platform&rsquo;s High scenario, the system uses a carbon price model based on
                    the Shared Socioeconomic Pathways<a title="" href="#ftn16"><sup>[16]</sup></a> 
                    (SSP) scenario SSP3-60<a title="" href="#ftn17"><sup>[17]</sup></a>,
                    which assumes high challenges to both adaptation and mitigation.
                    The price varies across the five regions and through time, with the range of values being from approximately $8/ton to
                    approximately $82/ton by 2100. Mid-century prices are approximately $29/ton.
                    For each location, the system assigns a likely carbon price; combined with GHG emissions data for each asset,
                    Climanomics&reg; calculates the carbon pricing risk.</p>
                <p>For RCP6.0, TCS uses interpolated carbon prices based on scenarios SSP3-60 and SSP3-45.
                    Prices range from approximately $8/ton to $180/ton by 2100. Mid-century prices are around $50/ton.</p>
                <p>For the platform&rsquo;s Medium scenario, the system uses a carbon price model based on the SSP scenario SSP3-45.
                    Prices range from approximately $8/ton to approximately $440/ton by 2100. Mid-century prices are approximately $65/ton.</p>
                <p>Lastly, the Low scenario is based on carbon prices from SSP3-34.
                    Prices range from approximately $8/ton to approximately $880/ton by 2100.
                    Mid-century prices are approximately $180/ton.</p>
                <h2><strong><em>Litigation</em></strong></h2>
                <p>Corporations face increasing costs to defend against climate-related litigation.
                    Claims include failure to mitigate, adapt, and disclose risks in reference to
                    various local and sovereign laws and regulations.
                    At present, in the absence of robust litigation-risk data, Climanomics&reg; uses temperature extremes
                    to provide a forcing function for future litigation risks,
                    with the assumption of proportionality between legal and regulatory actions.
                    Climanomics&reg; applies the localized frequency of daily maximum temperature
                    above the 90th percentile, as compared to the baseline period (1950-1999),
                    as discussed above. This is coupled with impact functions reflecting high, medium, and low levels of litigation impacts.</p>
                <h2><strong><em>Reputational Damage</em></strong></h2>
                <p>Perception of an organization&apos;s &quot;social license to operate&quot;
                    can affect supplier prices, employee costs, consumer demand, and shareholder value.
                    Multiple stakeholders should be considered during an analysis of reputational risk.
                    At present, in the absence of robust reputation-risk data, Climanomics&reg; uses temperature extremes to provide a
                    forcing function for future reputational risks. Climanomics&reg; applies the localized frequency of daily maximum
                    temperature above the 90th percentile, as compared to the baseline period (1950-1999), as discussed above.</p>
                <p>As part of on-going research and development, the use of supplementary metrics that
                    may affect reputation is also being considered.</p>
                <h2><strong><em>New Technology</em></strong></h2>
                <p>In the transition to a lower-carbon economy, technological advancements may cause financial impacts.
                    To the extent that new technologies reduce competitiveness, production efficiency, or demand,
                    they can lead to impaired or stranded assets. At present, in the absence of robust technology-risk data,
                    Climanomics&reg; uses temperature extremes to provide a forcing function for future technology risks.
                    Climanomics&reg; applies the localized frequency of daily maximum temperature above the 90th percentile,
                    as compared to the baseline period (1950-1999), as discussed above.</p>
                <h2><strong><em>Market</em></strong></h2>
                <p>In the transition to a lower-carbon economy, both supply and demand for products andservices can be affected.
                    At present, in the absence of standardized data on how markets may respond in the face of such a far-reaching change,
                    Climanomics&reg; uses temperature extremes to provide a forcing function for future market risks.
                    Climanomics&reg; applies the localized frequency of daily maximum temperature above the 90th percentile,
                    as compared to the baseline period (1950-1999), as discussed above.</p>
                <p style={{ color: '#d6002b' }}>The Transition Risk model is currently under review and may be enhanced in the coming months.</p>
            </div>
            <div>
                <h1
                    className="spg-heading spg-heading--xlarge spg-mt-md"
                    id="business-data-and-risk"
                >
                    BUSINESS DATA AND RISK MODELING
                    <IconButton
                        aria-label="Climanomics&reg; Methodology Business Data and Risk Modeling link"
                        onClick={(e) => {
                            e.preventDefault();
                            copyToClipboard(
                                `${window.location.origin}/methodology/real-assets#business-data-and-risk`,
                            );
                        }}
                        icon={LINK}
                    ></IconButton>
                </h1>
                <p className="spg-text spg-text-paragraph">
                    For corporate climate risk analyses, Climanomics&reg; gathers business data from
                    customers to inform the vulnerability modeling and risk calculation portions of
                    the analysis.
                </p>
                <div>
                    <h2 className="spg-heading spg-heading--large spg-mt-md">Business Data</h2>
                    <p className="spg-text spg-text-paragraph">
                        Climanomics&reg; ingests business data regarding operations (Corporate
                        customers) or holdings (Investment Managers).
                    </p>

                    <p className="spg-text spg-text-paragraph">
                        In general, there are four characteristics of business data that are
                        critical to informing the analysis.
                    </p>
                    <ul className="spg-list">
                        <li>
                            <strong>Asset Type </strong>Asset type determines which impact function
                            is selected to model vulnerability. Impact functions are composed of
                            impact pathways that are governed by asset characteristics such as
                            structure and building codes; requirements for heating and cooling; and
                            annual revenue.
                        </li>
                        <li>
                            <strong>Asset Ownership </strong>For the purposes of selecting the
                            correct impact functions, it is best to determine whether an asset is
                            leased or owned, or, if appropriate, percentage ownership. This
                            distinction determines the extent to which, for example, a customer’s
                            balance sheet would be impacted by climate risk.
                        </li>
                        <li>
                            <strong>Asset Location </strong>During the data intake process,
                            Climanomics&reg; provides the flexibility to a customer to enter
                            longitude/latitude coordinate or a street address which will be geocoded
                            to longitude/latitude automatically. .
                        </li>
                        <li>
                            <strong>Asset Value </strong>Several measures of asset value can be
                            assessed through the Climanomics&reg; platform, depending on what is most
                            relevant to each customer. During the data collection phase,
                            Climanomics&reg; works with each customer to determine which measure of
                            value will be most useful e.g., replacement value, total insured value
                            or market value.
                        </li>
                    </ul>
                </div>
                <div>
                    <h2 className="spg-heading spg-heading--large spg-mt-md">Risk Calculations</h2>
                    <p className="spg-text spg-text-paragraph">
                        Climanomics&reg; quantifies the direct financial impacts caused by climate
                        change in a metric known as Modeled Average Annual Loss (MAAL). MAAL is the
                        sum of climate-related expenses, decreased revenue, and/or business
                        interruption. MAAL is reported annually for each decadal period.
                    </p>

                    <p className="spg-text spg-text-paragraph">
                        For each hazard metric, MAAL is calculated from the ensemble mean of daily
                        values averaged over an entire decade. For example, the value listed as
                        “2030” represents the average of the daily values for the 2030s (2030-2039)
                        for all available models. Confidence intervals are not yet displayed in the
                        Climanomics&reg; platform as it is still being determined how best to make this
                        additional information decision relevant.
                    </p>

                    <p className="spg-text spg-text-paragraph">
                        Other notable features of Climanomics&reg; outputs include:
                    </p>

                    <ol className="spg-list">
                        <li>
                            <strong>Results are presented in absolute and relative terms</strong>
                            <p className="spg-text spg-text-paragraph">
                                Absolute risk (in USD$M) is a function of hazard, vulnerability, and
                                asset value. This reflects the expected financial impacts in dollar
                                terms. A very valuable asset with low hazard exposure and
                                vulnerability could still hold substantial risk due to the high
                                asset value.
                            </p>
                            <p className="spg-text spg-text-paragraph">
                                Relative risk (in %) is a function of hazard and vulnerability.
                                Reported as a percent of asset value (calculated as MAAL/asset
                                value), it provides a perspective on exposure and vulnerability
                                across assets, independent of their value. It is possible for
                                low-value assets to have high relative risk compared to more
                                valuable assets.
                            </p>
                        </li>

                        <li>
                            <strong>MAAL relative to the baseline (or historical) period</strong>
                            <p className="spg-text spg-text-paragraph">
                                Climanomics&reg; outputs convey the expected delta in financial risk
                                due to climate change. Thus, all outputs are reported relative to a
                                historical baseline, which is specific to each hazard. The
                                historical baselines for each hazard are described earlier in this
                                document.
                            </p>
                        </li>

                        <li>
                            <strong>Reported for a decadal period.</strong>
                            <p className="spg-text spg-text-paragraph">
                                Climanomics&reg; MAAL outputs are applicable for a specific decadal
                                period, e.g., the 2020s, defined as 2020-2029 (applicable for every
                                decade). To calculate risk for an entire decade, one would multiply
                                the reported MAAL for a particular decade by 10.
                            </p>
                            <p className="spg-text spg-text-paragraph">
                                The MAAL outputs are currently not discounted because it has been
                                found that, due to the myriad factors that may affect the discount
                                rate, customers typically prefer to receive the “raw” outputs from
                                the Climanomics&reg; software, and then discount in their own models if
                                they so choose. This question is being investigated as part of
                                ongoing research and development efforts.
                            </p>
                        </li>
                    </ol>
                </div>
            </div>
            <div>
                <h1
                    className="spg-heading spg-heading--xlarge spg-mt-md"
                    id="appendix-a-countries-included-in-ssp-region"
                >
                    APPENDIX A. Countries included in SSP region
                    <IconButton
                        aria-label="APPENDIX A. Countries included in SSP region"
                        onClick={(e) => {
                            e.preventDefault();
                            copyToClipboard(
                                `${window.location.origin}/methodology/real-assets#appendix-a-countries-included-in-ssp-region`,
                            );
                        }}
                        icon={LINK}
                    ></IconButton>
                </h1>
                <p className="spg-text spg-text-paragraph">
                    Includes the OECD 90 and EU member states and candidates.
                </p>
                <p className="spg-text spg-text-paragraph">
                    Albania, Australia, Austria, Belgium, Bosnia and Herzegovina, Bulgaria, Canada,
                    Croatia, Cyprus, Czech Republic, Denmark, Estonia, Finland, France, Germany,
                    Greece, Guam, Hungary, Iceland, Ireland, Italy, Japan, Latvia, Lithuania,
                    Luxembourg, Malta, Montenegro, Netherlands, New Zealand, Norway, Poland,
                    Portugal, Puerto Rico, Romania, Serbia, Slovakia, Slovenia, Spain, Sweden,
                    Switzerland, The former Yugoslav Republic of Macedonia, Turkey, United Kingdom,
                    United States of America
                </p>
                <h3 className="spg-heading spg-heading--medium">REF</h3>
                <p className="spg-text spg-text-paragraph">
                    Countries from the Reforming Economies of Eastern Europe and the Former Soviet
                    Union.
                </p>
                <p className="spg-text spg-text-paragraph">
                    Armenia, Azerbaijan, Belarus, Georgia, Kazakhstan, Kyrgyzstan, Republic of
                    Moldova, Russian Federation, Tajikistan, Turkmenistan, Ukraine, Uzbekistan
                </p>

                <h3 className="spg-heading spg-heading--medium">ASIA</h3>
                <p className="spg-text spg-text-paragraph">
                    The region includes most Asian countries with the exception of the Middle East,
                    Japan and Former Soviet Union states.
                </p>
                <p className="spg-text spg-text-paragraph">
                    Afghanistan, Bangladesh, Bhutan, Brunei Darussalam, Cambodia, China (incl. Hong
                    Kong and Macao, excl. Taiwan) Democratic People&apos;s Republic of Korea, Fiji,
                    French Polynesia, India, Indonesia, Lao People&apos;s Democratic Republic,
                    Malaysia, Maldives, Micronesia (Fed. States of), Mongolia, Myanmar, Nepal, New
                    Caledonia, Pakistan, Papua New Guinea, Philippines, Republic of Korea, Samoa,
                    Singapore, Solomon Islands, Sri Lanka, Taiwan, Thailand, Timor-Leste, Vanuatu,
                    Viet Nam
                </p>

                <h3 className="spg-heading spg-heading--medium">MAF</h3>
                <p className="spg-text spg-text-paragraph">
                    This region includes the countries of the Middle East and Africa.
                </p>
                <p className="spg-text spg-text-paragraph">
                    Algeria, Angola, Bahrain, Benin, Botswana, Burkina Faso, Burundi, Cameroon, Cape
                    Verde, Central African Republic, Chad, Comoros, Congo, Côte d&apos;Ivoire,
                    Democratic Republic of the Congo, Djibouti, Egypt, Equatorial Guinea, Eritrea,
                    Ethiopia, Gabon, Gambia, Ghana, Guinea, Guinea-Bissau, Iran (Islamic Republic
                    of), Iraq, Israel, Jordan, Kenya, Kuwait, Lebanon, Lesotho, Liberia, Libyan Arab
                    Jamahiriya, Madagascar, Malawi, Mali, Mauritania, Mauritius, Mayotte, Morocco,
                    Mozambique, Namibia, Niger, Nigeria, Occupied Palestinian Territory, Oman,
                    Qatar, Rwanda, Réunion, Saudi Arabia, Senegal, Sierra Leone, Somalia, South
                    Africa, South Sudan, Sudan, Swaziland, Syrian Arab Republic, Togo, Tunisia,
                    Uganda, United Arab Emirates, United Republic of Tanzania, Western Sahara,
                    Yemen, Zambia, Zimbabwe
                </p>

                <h3 className="spg-heading spg-heading--medium">LAM</h3>
                <p className="spg-text spg-text-paragraph">
                    This region includes the countries of Latin America and the Caribbean.
                </p>
                <p className="spg-text spg-text-paragraph">
                    Argentina, Aruba, Bahamas, Barbados, Belize, Bolivia (Plurinational State of),
                    Brazil, Chile, Colombia, Costa Rica, Cuba, Dominican Republic, Ecuador, El
                    Salvador, French Guiana, Grenada, Guadeloupe, Guatemala, Guyana, Haiti,
                    Honduras, Jamaica, Martinique, Mexico, Nicaragua, Panama, Paraguay, Peru,
                    Suriname, Trinidad and Tobago, United States Virgin Islands, Uruguay, Venezuela
                    (Bolivarian Republic of).
                </p>
            </div>
            <div>
                <h1 className="spg-heading spg-heading--xlarge spg-mt-md" id="appendix-b-tables">
                    APPENDIX B. Tables
                    <IconButton
                        aria-label="APPENDIX B. Countries included in SSP region"
                        onClick={(e) => {
                            e.preventDefault();
                            copyToClipboard(
                                `${window.location.origin}/methodology/real-assets#appendix-b-tables`,
                            );
                        }}
                        icon={LINK}
                    ></IconButton>
                </h1>
                <h3 className="spg-heading spg-heading--medium spg-mt-md">
                    Table 4: Climate Change Hazard Modelling Changes - Legacy Climanomics&reg; vs
                    Climanomics&reg; 2023 Release
                </h3>

                <Card hasBorder hasRoundedCorner>
                    <table className="spg-table">
                        <thead>
                            <tr>
                                <th colSpan={3}>
                                    <h4>Legacy Climanomics&reg; (pre-2023)</h4>
                                    <h4>Underlying climate data: CMIP5</h4>
                                </th>
                                <th colSpan={4}>
                                    <h4>Upgraded Climanomics&reg; (June 2023)</h4>
                                    <h4>Underlying climate data: CMIP6</h4>
                                </th>
                            </tr>
                            <tr>
                                <th>Hazard Name</th>
                                <th>Hazard Metric</th>
                                <th>Modelling Approach</th>
                                <th>Spatial Resolution</th>
                                <th>Hazard Metric</th>
                                <th>Model Changes/Enhancements</th>
                                <th>Spatial Resolution</th>
                            </tr>
                        </thead>
                        <tbody className="spg-table-tbody">
                            <tr>
                                <td>Coastal Flood</td>
                                <td>Annual Frequency of historical 100-year flood</td>
                                <td>
                                    ‘Bathtub’ approach to modelling coastal inundation due to sea
                                    level rise
                                </td>
                                <td>Variable, determined by Google-Earth elevation sources.</td>
                                <td>No Change</td>
                                <td>
                                    Enhanced ‘bathtub’ modelling incorporating a flood-water
                                    path-finding algorithm to determine the interior points that are
                                    subject to flooding in response to different coastal water
                                    levels.
                                </td>
                                <td>~30m (US); ~90m (rest of world)</td>
                            </tr>
                            <tr>
                                <td>Wildfire</td>
                                <td>
                                    Annual probability of exceeding historical 90th percentile
                                    wildfire conditions using the Palmer Z-index, relative to a
                                    historical baseline{' '}
                                </td>
                                <td>
                                    On demand processing model with no capability to differentiate
                                    risks for assets in urban areas and in bodies of water,
                                    calculated on the hazard metric
                                </td>
                                <td>25km</td>
                                <td>No Change</td>
                                <td>
                                    Preprocessed method using a land use mask to modify wildfire
                                    risk results, calculated on the hazard metric
                                </td>
                                <td>25km</td>
                            </tr>
                            <tr>
                                <td>Tropical Cyclone</td>
                                <td>
                                    Annual frequency of a category 3 or higher (Cat3+) TC passing
                                    within 50km of a location
                                </td>
                                <td>
                                    The model’s coverage was limited to assets in the North Atlantic
                                    (NA) and the Northwest Pacific (NW) basins with hazard
                                    projections extending to 2030 for NA and 2040 for NW.{' '}
                                </td>
                                <td>25km</td>
                                <td>No Change</td>
                                <td>
                                    The updated model’s global coverage encompasses the remaining
                                    key basins with hazard projections extending to 2040.{' '}
                                </td>
                                <td>25km</td>
                            </tr>
                            <tr>
                                <td>Pluvial Flooding</td>
                                <td>NA</td>
                                <td>NA</td>
                                <td>NA</td>
                                <td>
                                    Projected frequency of the historical base-line 100-year flood
                                    depth
                                </td>
                                <td>NA</td>
                                <td>25km</td>
                            </tr>
                            <tr>
                                <td>Fluvial Flooding</td>
                                <td>
                                    Projected frequency of the historical base-line 100-year flood
                                    depth
                                </td>
                                <td>
                                    The 10-yr and 100-yr return-period (RP) river discharges are
                                    related statistically to seven covariates, four of which are
                                    topographic in nature and three are climatological. For future
                                    decades, the climate covariates are derived from the downscaled
                                    CMIP6 dataset.
                                </td>
                                <td>HydroAtlas Level 12 Basin (approx. 25km)</td>
                                <td>No Change</td>
                                <td>NA</td>
                                <td>HydroAtlas Level 12 Basin (approx. 25km)</td>
                            </tr>
                            <tr>
                                <td>Extreme Temperature</td>
                                <td>Tx90p Tx90pAbsChng Tx50pAbsChg</td>
                                <td>
                                    CMIP6 temperature projections averaged across models and years
                                    per decade
                                </td>
                                <td>25km </td>
                                <td>No Change</td>
                                <td>NA</td>
                                <td>25km </td>
                            </tr>
                            <tr>
                                <td>Drought</td>
                                <td>
                                    Annual frequency of days with the self-calibrating Palmer
                                    Drought Severity Index (scPDSI) less than or equal to the
                                    historical 10th percentile
                                </td>
                                <td>
                                    Annual frequency of days with the self-calibrating Palmer
                                    Drought Severity Index (scPDSI) less than or equal to the
                                    historical 10th percentile
                                </td>
                                <td>25km </td>
                                <td>No Change</td>
                                <td>NA</td>
                                <td>25km </td>
                            </tr>
                            <tr>
                                <td>Water Stress</td>
                                <td>
                                    Projected future ratio of water withdrawals to total renewable
                                    water supply in a given area.
                                </td>
                                <td>
                                    Water stress projections adapted from the World Resources
                                    Institute Aqueduct dataset
                                </td>
                                <td>Basin Level </td>
                                <td>No Change</td>
                                <td>NA</td>
                                <td>Basin Level </td>
                            </tr>
                        </tbody>
                    </table>
                </Card>
                <h3 className="spg-heading spg-heading--medium spg-mt-md">
                    Table 5: Climanomics&reg; Asset Type Coverage
                </h3>
                <figure>
                    <img
                        src={image10}
                        alt="Task Force on Climate-Related Financial Disclosures (TCFD) framework"
                    />
                </figure>
                <figure>
                    <img
                        src={image11}
                        alt="Task Force on Climate-Related Financial Disclosures (TCFD) framework"
                    />
                </figure>
                <figure>
                    <img
                        src={image12}
                        alt="Task Force on Climate-Related Financial Disclosures (TCFD) framework"
                    />
                </figure>
                <figure>
                    <img
                        src={image13}
                        alt="Task Force on Climate-Related Financial Disclosures (TCFD) framework"
                    />
                </figure>
                <figure>
                    <img
                        src={image14}
                        alt="Task Force on Climate-Related Financial Disclosures (TCFD) framework"
                    />
                </figure>
                <figure>
                    <img
                        src={image15}
                        alt="Task Force on Climate-Related Financial Disclosures (TCFD) framework"
                    />
                </figure>
                <figure>
                    <img
                        src={image16}
                        alt="Task Force on Climate-Related Financial Disclosures (TCFD) framework"
                    />
                </figure>
                <figure>
                    <img
                        src={image17}
                        alt="Task Force on Climate-Related Financial Disclosures (TCFD) framework"
                    />
                </figure>
                <figure>
                    <img
                        src={image18}
                        alt="Task Force on Climate-Related Financial Disclosures (TCFD) framework"
                    />
                </figure>
                <figure>
                    <img
                        src={image19}
                        alt="Task Force on Climate-Related Financial Disclosures (TCFD) framework"
                    />
                </figure>
                <figure>
                    <img
                        src={image20}
                        alt="Task Force on Climate-Related Financial Disclosures (TCFD) framework"
                    />
                </figure>
                <figure>
                    <img
                        src={image21}
                        alt="Task Force on Climate-Related Financial Disclosures (TCFD) framework"
                    />
                </figure>
                <figure>
                    <img
                        src={image22}
                        alt="Task Force on Climate-Related Financial Disclosures (TCFD) framework"
                    />
                </figure>
                <figure>
                    <img
                        src={image23}
                        alt="Task Force on Climate-Related Financial Disclosures (TCFD) framework"
                    />
                </figure>
                <figure>
                    <img
                        src={image24}
                        alt="Task Force on Climate-Related Financial Disclosures (TCFD) framework"
                    />
                </figure>
                <div>
                    <hr align="left" size="1" width="33%" />
                    <h3 className="spg-heading spg-heading--medium spg-mt-md">Footnotes:</h3>
                    <div className="spg-text spg-text-paragraph">
                        <p id="ftn1"><a href="#_ftnref1"><sup>[1]</sup>
                        </a> <a className="spg-link" href="https://www.fsb-tcfd.org/publications/final-recommendations-report/" rel="nofollow">
                            https://www.fsb-tcfd.org/publications/final-recommendations-report/</a></p>
                        <p id="ftn2"><a href="#_ftnref2"><sup>[2]</sup>
                        </a> Further information on the NEX-GDDP dataset is available at{' '}
                            <a className="spg-link" href="https://www.nccs.nasa.gov/services/data-collections/land-based-products/nex-gddp"
                                rel="nofollow"
                            >
                                https://www.nccs.nasa.gov/services/data-collections/land-based-products/nex-gddp</a>.</p>
                        <p id="ftn3"><a href="#_ftnref3"><sup>[3]</sup>
                        </a> The Aqueduct Water Stress Projections database is licensed
                            under a Creative Commons Attribution International 4.0 License and is based upon analysis from Luck,
                            M., M. Landis, F. Gassert. 2015. “Aqueduct Water Stress Projections: Decadal projections of water supply
                            and demand using CMIP5 GCMs.” Washington, DC: World Resources Institute. See also Hofste, R., S. Kuzma, S.
                            Walker, E.H. Sutanudjaja, et. al. 2019. “Aqueduct 3.0: Updated Decision- Relevant Global Water Risk Indicators.”{' '}
                            <a className="spg-link" href="https://www.wri.org/resources/maps/aqueduct-water-risk-atlas" rel="nofollow">
                                https://www.wri.org/resources/maps/aqueduct-water-risk-atlas</a></p>
                        <p id="ftn4"><a href="#_ftnref4"><sup>[4]</sup>
                        </a> Kopp, R. E., R. M. Horton, C. M. Little, J. X. Mitrovica, M.
                            Oppenheimer, D. J. Rasmussen, B. H. Strauss, and C. Tebaldi (2014), Probabilistic 21st and
                            22nd century sea-level projections at a global network of tide-gauge sites, Earth’s Future, 2, 383–406,
                            doi:10.1002/2014EF000239.</p>
                        <p id="ftn5"><a href="#_ftnref5"><sup>[5]</sup>
                        </a> S. Muis, et al, “A global reanalysis of storm surges and extreme sea levels”,
                            Nature Comm., DOI: 10.1038/ncomms11969, 2016.</p>
                        <p id="ftn6"><a href="#_ftnref6"><sup>[6]</sup>
                        </a> <a className="spg-link" href="https://hydrosheds.org/images/inpages/HydroBASINS_TechDoc_v1c.pdf" rel="nofollow">
                            https://hydrosheds.org/images/inpages/HydroBASINS_TechDoc_v1c.pdf</a></p>
                        <p id="ftn7"><a href="#_ftnref7"><sup>[7]</sup>
                        </a> Hall and Jewson, Statistical modeling of North Atlantic tropical cyclone tracks. Tellus, 59A, 485-498 (2007);
                            Hall and Yonekura, North-American tropical cyclone landfall and SST: A statistical model study.
                            J. Climate, 26, 8422-8439 (2013). Hall, Kossin, Thompson and McMahon, U.S. tropical cyclone
                            activity in the 2030s based on projected changes in tropical sea surface temperature,
                            J. Climate., 34, 1321-1335 (2021).</p>
                        <p id="ftn8"><a href="#_ftnref8"><sup>[8]</sup>
                        </a> WCRP. 2013. Coupled Model Intercomparison Project. [Online].
                            <a className="spg-link" href="https://www.wcrp-climate.org/wgcm-cmip" rel="nofollow">
                                https://www.wcrp-climate.org/wgcm-cmip</a></p>
                        <p id="ftn9"><a href="#_ftnref9"><sup>[9]</sup>
                        </a> Adapted from O&apos;Neill et al., 2016, the matrix shows how the SSP narratives
                            and RCP emission scenarios are combined in CMIP6.
                            The dark blue boxes show scenarios that were prioritized in CMIP6.
                            These are the scenarios used in the updated Climanomics®.
                            The light blue boxes show other scenarios considered important,
                            but lower priority in CMIP6. The white boxes show the SSP/RCP combinations that are possible
                            (some forcing targets were found to be impossible for some SSP narratives).</p>
                        <p id="ftn10"><a href="#_ftnref10"><sup>[10]</sup></a> Tier 1 or dark blue boxes are scenarios that are available in
                            Climanomics®. Tier 2 or light blue boxes are additional material scenarios of interest to scientific community
                            as well as white boxes are other possible scenarios.</p>
                        <p id="ftn11"><a href="#_ftnref11"><sup>[11]</sup></a> Wells, Nathan., Goddard, Steve, Hayes, Michael J., 2004. A
                            Self-Calibrating Palmer Drought Severity Index. Journal of Climate 17(12):2335-2351.</p>
                        <p id="ftn12"><a href="#_ftnref12"><sup>[12]</sup></a> <a className="spg-link"
                            href="https://cds.climate.copernicus.eu/cdsapp#!/dataset/satellite-land-cover?tab=overview"
                            rel="nofollow"
                                                                               // eslint-disable-next-line max-len
                                                                               >https://cds.climate.copernicus.eu/cdsapp#!/dataset/satellite-land-cover?tab=overview</a>
                        </p>
                        <p id="ftn13"><a href="#_ftnref13"><sup>[13]</sup>
                        </a> World Resources Institute Aqueduct database projections use
                            CMIP5 GCMs and not CMIP6 GCMs as of release date of this document.</p>
                        <p id="ftn14"><a href="#_ftnref14"><sup>[14]</sup>
                        </a> See, for example, The World Bank, “Pricing Carbon”, at{' '}
                            <a href="https://www.worldbank.org/en/programs/pricing-carbon">
                                https://www.worldbank.org/en/programs/pricing-carbon</a>.</p>
                        <p id="ftn15"><a href="#_ftnref15"><sup>[15]</sup>
                        </a> More detail is available at <a href="https://unfccc.int/sites/default/files/part1_iiasa_rogelj_ssp_poster.pdf">
                            https://unfccc.int/sites/default/files/part1_iiasa_rogelj_ssp_poster.pdf</a></p>
                        <p id="ftn16"><a href="#_ftnref16"><sup>[16]</sup>
                        </a> <a href="https://unfccc.int/sites/default/files/part1_iiasa_rogelj_ssp_poster.pdf">
                            https://unfccc.int/sites/default/files/part1_iiasa_rogelj_ssp_poster.pdf</a></p>
                        <p id="ftn17"><a href="#_ftnref17"><sup>[17]</sup>
                        </a> <a href="https://www.sciencedirect.com/science/article/pii/S0959378016300838">
                            https://www.sciencedirect.com/science/article/pii/S0959378016300838</a></p>
                    </div>
                </div>
            </div>
            <h3 className="spg-heading spg-heading--medium spg-mt-md">Disclaimer</h3>
            <p className="spg-text spg-text-paragraph">
                This content (including any information, data, analyses, opinions, ratings,
                scores, and other statements) (“Content”) has been prepared solely for
                information purposes and is owned by or licensed to S&amp;P Global and/or its
                affiliates (collectively, “S&amp;P Global”).{' '}
            </p>
            <p className="spg-text spg-text-paragraph">
                This Content may not be modified, reverse engineered, reproduced or distributed
                in any form by any means without the prior written permission of S&amp;P Global.{' '}
            </p>
            <p className="spg-text spg-text-paragraph">
                You acquire absolutely no rights or licenses in or to this Content and any
                related text, graphics, photographs, trademarks, logos, sounds, music, audio,
                video, artwork, computer code, information, data and material therein, other
                than the limited right to utilize this Content for your own personal, internal,
                non-commercial purposes or as further provided herein.{' '}
            </p>
            <p className="spg-text spg-text-paragraph">
                Any unauthorized use, facilitation or encouragement of a third party’s
                unauthorized use (including without limitation copy, distribution, transmission
                or modification) of this Content or any related information is not permitted
                without S&amp;P Global’s prior consent and shall be deemed an infringement,
                violation, breach or contravention of the rights of S&amp;P Global or any applicable
                third-party (including any copyright, trademark, patent, rights of privacy or
                publicity or any other proprietary rights).{' '}
            </p>
            <p className="spg-text spg-text-paragraph">
                A reference to a particular investment or security, a score, rating or any
                observation concerning an investment or security that is part of this Content is
                not a recommendation to buy, sell or hold such investment or security, does not
                address the suitability of an investment or security and should not be relied on
                as investment advice.{' '}
            </p>
            <p className="spg-text spg-text-paragraph">
                S&amp;P Global shall have no liability, duty or obligation for or in connection with
                this Content, any other related information (including for any errors,
                inaccuracies, omissions or delays in the data) and/or any actions taken in
                reliance thereon. In no event shall S&amp;P Global be liable for any special,
                incidental, or consequential damages, arising out of the use of this Content
                and/or any related information.{' '}
            </p>
            <p className="spg-text spg-text-paragraph">
                The S&P and S&amp;P Global logos are trademarks of S&amp;P Global registered in many
                jurisdictions worldwide. You shall not use any of S&amp;P Global&apos;s trademarks,
                trade names or service marks in any manner, and in no event in a manner
                accessible by or available to any third party. You acknowledge that you have no
                ownership or license rights in or to any of these names or marks.{' '}
            </p>
            <p className="spg-text spg-text-paragraph">
                See additional Disclaimers at{' '}
                <a className="spg-link" href="https://www.spglobal.com/en/terms-of-use">
                    https://www.spglobal.com/en/terms-of-use
                </a>
                .
            </p>
            <p className="spg-text spg-text-paragraph">
                Copyright&copy; 2023 S&amp;P Global Inc. All rights reserved.{' '}
            </p>
        </div>
    );
}
