import React, { useEffect, useContext } from 'react';
import folderService from 'services/folderService';
import viewService from 'services/viewService';
import AccessTokenContext from 'context/AccessTokenContext';
import * as constants from 'utils/constants';

import rbacAdminService from 'services/rbacAdminService';
import { CONSENT_REQUIRED_ERROR } from 'utils/constants';
import AssignGroupsDropdown from './AssignGroupsDropdown';
import AssignFoldersDropdown from './AssignFoldersDropdown';
import SelectUsers from './SelectUsers';

export default function AssignmentForm(props) {
    const { getTokenAndTryAgain } = useContext(AccessTokenContext);
    const [userData, setUserData] = React.useState([]);
    const [folderData, setFolderData] = React.useState([]);
    const [viewData, setViewData] = React.useState([]);
    const [dataLoaded, setDataLoaded] = React.useState(false);
    const apiOptions = {
        scope: `${constants.CUSTOMER_READ_ASSET} ${constants.CUSTOMER_EDIT_ASSET}`,
    };

    const getAllData = async () => {
        async function fetchData() {
            try {
                setDataLoaded(true);
                // GET USERS
                const reqUser = {
                    customerId: props.customerId,
                    list: true,
                    external: true,
                };
                let resUsers = await rbacAdminService.getAllClientUsers(reqUser);
                if (resUsers) {
                    // SHOW EXTERNAL USER EXEPT CLIM ADMIN AS IT HAS DEFAULT ACCESS TO ALL FOLDER AND VIEWS
                    resUsers = resUsers.filter((user) => user.role !== 'CLIM_ADMIN');
                    setUserData(resUsers || []);
                }

                // GET FOLDERS
                const reqFolder = {
                    includeAll: true,
                };
                const resFolder = await folderService.getAll(
                    props.customerId ? props.customerId : 1,
                    reqFolder,
                );
                if (resFolder) {
                    setFolderData(resFolder?.result ? resFolder.result : []);
                }

                // GET VIEWS
                const reqView = {
                    includeAll: true,
                };
                const resView = await viewService.getAll(
                    props.customerId ? props.customerId : 1,
                    reqView,
                );
                if (resView) {
                    setViewData(resView?.results ? resView.results : []);
                }

                return resUsers;
            } catch (error) {
                setDataLoaded(true);
                if (error.error === CONSENT_REQUIRED_ERROR) {
                    return getTokenAndTryAgain(apiOptions);
                }
                return error;
            }
        }
        return fetchData();
    };

    useEffect(() => {
        (async function fetchAllData() {
            await getAllData();
        })();
    }, []);

    return (
        <div>
            <div className="spg-row">
                <SelectUsers userData={userData} {...props} />
            </div>
            <div className="spg-row">
                <div className="spg-col-6">
                    <AssignFoldersDropdown
                        onFoldersSelection={props.onFoldersSelection}
                        folderData={folderData}
                        {...props}
                    />
                </div>
                <div className="spg-col-6">
                    <AssignGroupsDropdown
                        onViewsSelection={props.onViewsSelection}
                        viewData={viewData}
                        {...props}
                    />
                </div>
            </div>
        </div>
    );
}
