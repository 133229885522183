import React from 'react';
import { Controller } from 'react-hook-form';
import { FormGroup, H5, InputField } from '@spglobal/react-components';
import { TextBodyMd } from '../shared';

export default function HazardToImpactSelector({
    hazardsForAssetType,
    impactFunctionsForAssetType,
    hazardChangedCallback,
    control,
    fields,
    errors,
    AssetTypeDetails,
}) {
    return (
        <div>
            <div className="spg-row">
                <div className="spg-col-3">
                    <H5>Risk Factors</H5>
                </div>
                <div className="spg-col-4">
                    <H5>Hazard</H5>
                </div>
                <div className="spg-col-4">
                    <H5>Impact Function</H5>
                </div>
            </div>
            <div>
                {AssetTypeDetails.map((riskFactor, index) => (
                    <div className="spg-row" key={index} data-testid={`riskFactorRow-${index}`}>
                        <div className="spg-col-3">
                            <FormGroup data-testid={`riskFactor-name-${index}`}>
                                <Controller
                                    render={({ field }) => (
                                        <TextBodyMd {...field}>
                                            {riskFactor.risk_factor_name}
                                        </TextBodyMd>
                                    )}
                                    name={riskFactor.risk_factor_name}
                                    key={index}
                                    variant="h6"
                                    align="left"
                                    control={control}
                                    defaultValue={riskFactor.risk_factor_name}
                                />
                            </FormGroup>
                        </div>
                        <div className="spg-col-4">
                            <FormGroup
                                label={riskFactor.hazard_name}
                                data-testid={`riskFactor-hazard-${index}`}
                            >
                                <Controller
                                    render={({ field }) => (
                                        <InputField
                                            {...field}
                                            value={
                                                riskFactor.hazard_name ? riskFactor.hazard_name : ''
                                            }
                                            disabled
                                        />
                                    )}
                                    name={riskFactor.hazard_name}
                                    key={index}
                                    control={control}
                                />
                            </FormGroup>
                        </div>
                        <div className="spg-col-4">
                            <FormGroup
                                label={riskFactor.impact_function_name}
                                data-testid={`riskFactor-impactFunction-${index}`}
                            >
                                <Controller
                                    render={({ field }) => (
                                        <InputField
                                            {...field}
                                            disabled
                                            value={
                                                riskFactor.impact_function_name
                                                    ? riskFactor.impact_function_name
                                                    : ''
                                            }
                                        />
                                    )}
                                    name={riskFactor.impact_function_name}
                                    key={index}
                                    control={control}
                                />
                            </FormGroup>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}
