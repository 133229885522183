import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { TAB_INDEXES } from 'utils/constants';
import { Tab, Tabs } from '@spglobal/react-components';
import AllAssetsTabPanel from './AllAssetsTabPanel';
import FoldersTabPanel from './FoldersTabPanel';
import GroupsTabPanel from './GroupsTabPanel';
import { DEFAULT_PAGE_DATA } from '../components.constants';

export default function DashboardRiskTabs({
    riskDataFilters,
    tableData,
    totalCount,
    handleClick,
    updateCurrentPageData,
    loading,
}) {
    const history = useHistory();
    const params = new URLSearchParams(window.location.search);
    const defaultActiveTab = params.get('activeTab');
    const activeTabIndex = TAB_INDEXES[defaultActiveTab];
    const [assetsPageData, setAssetsPageData] = useState({
        ...DEFAULT_PAGE_DATA,
        searchText: '',
        order: 'desc',
        orderBy: 'relative_value',
        searchType: 'asset',
        viewType: 'assets',
    });
    const [foldersPageData, setFoldersPageData] = useState({
        ...DEFAULT_PAGE_DATA,
        searchText: '',
        order: 'desc',
        orderBy: 'relative_value',
        searchType: 'folder',
        viewType: 'folders',
    });
    const [viewsPageData, setViewsPageData] = useState({
        ...DEFAULT_PAGE_DATA,
        searchText: '',
        order: 'desc',
        orderBy: 'entity_name',
        searchType: 'view',
        viewType: 'views',
    });

    const onTabChange = (newTabName) => {
        let activeTab = '';
        switch (newTabName) {
            case TAB_INDEXES.allAssets:
                activeTab = 'allAssets';
                updateCurrentPageData({ ...assetsPageData, viewType: 'assets' });
                break;
            case TAB_INDEXES.folders:
                activeTab = 'folders';
                updateCurrentPageData({ ...foldersPageData, viewType: 'folders' });
                break;
            case TAB_INDEXES.views:
                activeTab = 'views';
                updateCurrentPageData({ ...viewsPageData, viewType: 'views' });
                break;
            default:
                activeTab = 'allAssets';
                updateCurrentPageData({ ...assetsPageData, viewType: 'assets' });
                break;
        }
        history.push({ search: `?activeTab=${activeTab}` });
    };

    const handlePageDataChange = (type, updatedData) => {
        switch (type) {
            case 'allAssets':
                setAssetsPageData(updatedData);
                break;
            case 'folders':
                setFoldersPageData(updatedData);
                break;
            case 'views':
                setViewsPageData(updatedData);
                break;
            default:
                setAssetsPageData(updatedData);
                break;
        }
        updateCurrentPageData({ ...updatedData, updateTableOnly: true });
    };

    const TABS_CONFIG = [{ label: 'All Assets' }, { label: 'Folders' }, { label: 'Groups' }];

    return (
        <div>
            <Tabs selectedTabId={activeTabIndex} onChange={onTabChange}>
                <Tab id={TAB_INDEXES.allAssets} title={TABS_CONFIG[0].label}>
                    <AllAssetsTabPanel
                        loading={loading}
                        riskDataFilters={riskDataFilters}
                        tableData={tableData}
                        totalCount={totalCount}
                        handleClick={handleClick}
                        pageData={assetsPageData}
                        onPageDataChange={handlePageDataChange}
                    />
                </Tab>
                <Tab id={TAB_INDEXES.folders} title={TABS_CONFIG[1].label}>
                    <FoldersTabPanel
                        loading={loading}
                        riskDataFilters={riskDataFilters}
                        tableData={tableData}
                        totalCount={totalCount}
                        handleClick={handleClick}
                        pageData={foldersPageData}
                        onPageDataChange={handlePageDataChange}
                    />
                </Tab>
                <Tab id={TAB_INDEXES.views} title={TABS_CONFIG[2].label}>
                    <GroupsTabPanel
                        loading={loading}
                        riskDataFilters={riskDataFilters}
                        tableData={tableData}
                        totalCount={totalCount}
                        handleClick={handleClick}
                        pageData={viewsPageData}
                        onPageDataChange={handlePageDataChange}
                    />
                </Tab>
            </Tabs>
        </div>
    );
}
