import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { Button, FormGroup, InputField, H1 } from '@spglobal/react-components';
import { Purpose } from '@spglobal/koi-helpers';
import { ErrorTextBodySm, TextAreaStyled, TextBodySm } from '../shared';

export default function HazardForm({
    cancelClicked,
    submitClicked,
    displayHeader,
    defaultFormValues,
}) {
    const {
        handleSubmit,
        formState: { errors },
        control,
        getValues,
        watch,
    } = useForm({
        defaultValues: defaultFormValues,
    });

    watch('hazardDescription');
    watch('unavailableMessage');

    return (
        <>
            <H1>{displayHeader || ''}</H1>

            {defaultFormValues && (
                <form onSubmit={handleSubmit(submitClicked)}>
                    <div className="spg-row">
                        <div className="spg-col-6">
                            <FormGroup label="Hazard Name" required>
                                <Controller
                                    render={({ field }) => (
                                        <InputField {...field} />
                                    )}
                                    id="hazardName"
                                    name="hazardName"
                                    control={control}
                                    rules={{
                                        required: 'Hazard name is required',
                                        maxLength: {
                                            value: 128,
                                            message: 'Hazard name exceed max length.',
                                        },
                                    }}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="hazardName"
                                    render={({ message }) => (
                                        <ErrorTextBodySm>{message}</ErrorTextBodySm>
                                    )}
                                />
                            </FormGroup>
                        </div>
                        <div className="spg-col-6">
                            <FormGroup label="Hazard Label">
                                <Controller
                                    render={({ field }) => (
                                        <InputField {...field} />
                                    )}
                                    id="hazardLabel"
                                    name="hazardLabel"
                                    control={control}
                                    rules={{
                                        maxLength: {
                                            value: 128,
                                            message: 'Hazard Label exceed max length.',
                                        },
                                    }}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="hazardLabel"
                                    render={({ message }) => (
                                        <ErrorTextBodySm>{message}</ErrorTextBodySm>
                                    )}
                                />
                            </FormGroup>
                        </div>
                    </div>
                    <div className="spg-row">
                        <div className="spg-col-12">
                            <FormGroup label="Hazard description">
                                <Controller
                                    render={({ field }) => (
                                        <TextAreaStyled {...field} maxRows={8} />
                                    )}
                                    id="hazardDescription"
                                    name="hazardDescription"
                                    control={control}
                                    rules={{
                                        maxLength: {
                                            value: 3000,
                                            message: 'Hazard description exceed max length.',
                                        },
                                    }}
                                />
                                <TextBodySm className="spg-mt-xs">
                                    {`${getValues('hazardDescription')?.length || 0} used of 3000 characters.`}
                                </TextBodySm>

                                <ErrorMessage
                                    errors={errors}
                                    name="hazardDescription"
                                    render={({ message }) => (
                                        <ErrorTextBodySm>{message}</ErrorTextBodySm>
                                    )}
                                />
                            </FormGroup>
                        </div>
                    </div>
                    <div className="spg-row">
                        <div className="spg-col-12">
                            <FormGroup label="Hazard unavailable message">
                                <Controller
                                    render={({ field }) => (
                                        <TextAreaStyled {...field} maxRows={8} />
                                    )}
                                    id="unavailableMessage"
                                    name="unavailableMessage"
                                    control={control}
                                    rules={{
                                        maxLength: {
                                            value: 256,
                                            message:
                                                'Hazard unavailable message exceed max length.',
                                        },
                                    }}
                                />
                                <TextBodySm className="spg-mt-xs">
                                    {`${getValues('unavailableMessage')?.length || 0} used of 256 characters.`}
                                </TextBodySm>

                                <ErrorMessage
                                    errors={errors}
                                    name="unavailableMessage"
                                    render={({ message }) => (
                                        <ErrorTextBodySm>{message}</ErrorTextBodySm>
                                    )}
                                />
                            </FormGroup>
                        </div>
                    </div>
                    <div className="spg-row">
                        <div className="spg-col-6">
                            <FormGroup label="X Axis Label">
                                <InputField
                                    disabled
                                    id="xAxisLabel"
                                    name="xAxisLabel"
                                    value="Decade"
                                />
                            </FormGroup>
                        </div>
                        <div className="spg-col-6">
                            <FormGroup label="X Axis Units">
                                <InputField
                                    disabled
                                    id="xAxisUnits"
                                    name="xAxisUnits"
                                    value="Decade"
                                />
                            </FormGroup>
                        </div>
                    </div>
                    <div className="spg-row">
                        <div className="spg-col-8">
                            <FormGroup label="Y Axis Label" required>
                                <Controller
                                    render={({ field }) => (
                                        <InputField {...field} />
                                    )}
                                    id="yAxisLabel"
                                    name="yAxisLabel"
                                    rules={{
                                        required: 'Y Axis Label is required',
                                        maxLength: {
                                            value: 128,
                                            message: 'Y Axis Label exceed max length.',
                                        },
                                    }}
                                    control={control}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="yAxisLabel"
                                    render={({ message }) => (
                                        <ErrorTextBodySm>{message}</ErrorTextBodySm>
                                    )}
                                />
                            </FormGroup>
                        </div>
                        <div className="spg-col-4">
                            <FormGroup label="Y Axis Units" required>
                                <Controller
                                    render={({ field }) => (
                                        <InputField {...field} />
                                    )}
                                    id="yAxisUnits"
                                    name="yAxisUnits"
                                    control={control}
                                    rules={{
                                        required: 'Y Axis Units are required',
                                        maxLength: {
                                            value: 32,
                                            message: 'Y Axis Units exceed max length.',
                                        },
                                    }}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="yAxisUnits"
                                    render={({ message }) => (
                                        <ErrorTextBodySm>{message}</ErrorTextBodySm>
                                    )}
                                />
                            </FormGroup>
                        </div>
                    </div>
                    <div className="spg-d-flex spg-justify-end spg-mt-md">
                        <Button id="saveHazardButton" type="submit" purpose={Purpose.PRIMARY}>
                            Save Hazard
                        </Button>
                        <Button onClick={cancelClicked} purpose={Purpose.SECONDARY} className="spg-ml-sm">
                            Cancel
                        </Button>
                    </div>
                </form>
            )}
        </>
    );
}
