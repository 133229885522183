import React, { useState, useEffect, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import riskService from 'services/riskService';
import { NotificationType, useNotification } from '@spglobal/react-components';
import AccessTokenContext from '../../context/AccessTokenContext';
import UserContext from '../../context/UserContext';
import folderService from '../../services/folderService';
import FoldersDataTable from './FoldersDataTable';
import CustomHeader from '../Header/CustomHeader';
import DeleteDialog from '../DeleteDialogTemplate/DeleteDialogTemplate';
import CreateUpdateEntityDialog from '../CreateUpdateEntityDialog';
import pathParser from '../../utils/pathParser';
import googleAnalyticsEvent from '../../utils/googleAnalyticsEvent';
import * as constants from '../../utils/constants';
import useGetPermission from '../../hooks/useGetPermission';
import { DEFAULT_PAGE_DATA, DEFAULT_PAGE_SIZE } from '../components.constants';
import { CONSENT_REQUIRED_ERROR } from '../../utils/constants';

export default function Folders(props) {
    const { pageData, onPageDataChange, resourceType } = props;
    const history = useHistory();
    const { pathname } = useLocation();
    const { getTokenAndTryAgain } = useContext(AccessTokenContext);
    const { user, isLoggedIn } = useContext(UserContext);
    const apiOptions = {
        scope: `${constants.CUSTOMER_READ_HIERARCHY} ${constants.CUSTOMER_EDIT_HIERARCHY}`,
    };
    const { customers: customerId } = pathParser.getPathComponents(pathname);
    const userPermission =
        user && Object.keys(user).length > 0 && useGetPermission(user, isLoggedIn, resourceType);
    const userPrivilege = userPermission?.action;

    const [dataLoaded, setDataLoaded] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [shouldDisplayDialog, setShouldDisplayDialog] = useState(false);
    const [dialogText, setDialogText] = useState({ title: '', message: '' });
    const { addNotification } = useNotification();
    const [folderText, setFolderText] = useState('');
    const [selectedRow, setSelectedRow] = useState(null);
    const [totalCount, setTotalCount] = useState(0);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [errorHelperText, setErrorHelperText] = useState('');
    const [aggregationDisabled, setAggregationDisabled] = useState(false);

    React.useEffect(() => {
        (async function updateData() {
            if (pageData?.clicked) {
                const offset = pageData?.page * pageData?.rowsPerPage;
                await getFoldersData(offset, pageData?.rowsPerPage, pageData?.searchText);
            }
        })();
    }, [pageData]);

    const getFoldersData = async (offset = 0, limit = DEFAULT_PAGE_SIZE, searchTextVal) => {
        const reqData = { offset, limit };
        if (searchTextVal?.length) reqData.asset_folder_name = searchTextVal;
        async function fetchData() {
            try {
                const response = await folderService.getAll(customerId, reqData);
                setDataLoaded(true);
                if (response) {
                    setTableData(response?.result);
                    setTotalCount(response?.count);
                }
                return response?.result;
            } catch (error) {
                setDataLoaded(true);
                if (error.error === CONSENT_REQUIRED_ERROR) {
                    return getTokenAndTryAgain(apiOptions);
                }
            }
        }
        return fetchData();
    };

    useEffect(() => {
        (async function fetchFoldersData() {
            const offset = pageData?.page * pageData?.rowsPerPage;
            await getFoldersData(offset, pageData?.rowsPerPage, pageData?.searchText);
        })();
    }, []);

    const handleTableSearchSubmit = (searchVal) => {
        getFoldersData(0, DEFAULT_PAGE_SIZE, searchVal);
        onPageDataChange({
            ...pageData,
            ...DEFAULT_PAGE_DATA,
            searchText: searchVal,
        });
    };

    const handleFolderAggregtion = async (rowDetails) => {
        let msg = 'Folder successfully aggregated';
        let type = NotificationType.SUCCESS;
        try {
            const reqData = {
                aggregate_manual: true,
                folder_id: rowDetails.asset_folder_id,
                flag_update: true,
            };

            await riskService.reaggregateFolder(customerId, reqData);
        } catch (error) {
            msg =
                error?.response?.status === 401
                    ? `Error: ${error?.response?.data}`
                    : 'Error: Folder aggregation failed.';
            type = NotificationType.ERROR;
        }
        setAggregationDisabled(false);
        addNotification(msg, type);
    };

    const handleFolderActions = (actionType, rowDetails = {}) => {
        // Callback actions will be one of the following: 'view', 'NEW_FOLDER', 'RENAME', or 'DELETE'
        setSelectedRow(rowDetails);
        if (actionType === constants.VIEW) {
            history.push({
                pathname: `/manage/${customerId}/folders/${rowDetails.asset_folder_id}/assets`,
            });
        } else if (actionType === constants.NEW_FOLDER) {
            showNewFolderDialog();
        } else if (actionType === constants.NEW_ASSET) {
            history.push({ pathname: `/manage/${customerId}/folders/asset` });
        } else if (actionType === constants.RENAME) {
            setFolderText(rowDetails?.asset_folder_name);
            showNewFolderDialog(rowDetails);
        } else if (actionType === constants.DELETE) {
            setShowDeleteDialog(true);
        } else if (actionType === constants.AGGREGATE) {
            setAggregationDisabled(true);
            handleFolderAggregtion(rowDetails);
        }
    };

    const handleFolderDelete = async (isDeleteConfirmed) => {
        setShowDeleteDialog(false);
        if (isDeleteConfirmed) {
            let msg;
            if (selectedRow.asset_folder_name == "Uncategorized") {
                msg = 'Assets successfully deleted';
            } else {
                msg = 'Folder and its assets successfully deleted';
            }
            let type = NotificationType.SUCCESS;
            try {
                await folderService.deleteFolder(customerId, selectedRow.asset_folder_id);
                await getFoldersData(0, DEFAULT_PAGE_SIZE, pageData?.searchText);
                setSelectedRow(null);
                googleAnalyticsEvent('Delete Entity', 'Folder', 'success');
            } catch (error) {
                if (error.error === CONSENT_REQUIRED_ERROR) {
                    return getTokenAndTryAgain(apiOptions);
                }
                msg =
                    error?.response?.status === 401
                        ? `Error: ${error?.response?.data}`
                        : 'Error: Folder delete failed.';
                type = NotificationType.ERROR;
            }
            onPageDataChange({
                ...pageData,
                ...DEFAULT_PAGE_DATA,
            });
            addNotification(msg, type);
        }
    };

    const showNewFolderDialog = (renameFolderData) => {
        if (renameFolderData) {
            setDialogText({ title: 'Rename Folder' });
            setShouldDisplayDialog(true);
        } else {
            setSelectedRow(null);
            setDialogText({ title: 'New Folder' });
            setShouldDisplayDialog(true);
        }
    };

    const isFolderNameInvalid = () => {
        let showErr = false;
        let errText = '';
        if (folderText?.length === 0) {
            showErr = true;
            errText = 'Please enter folder name between length 1 to 256';
        } else if (
            tableData.some(
                (folder) =>
                    folder?.asset_folder_name?.toLowerCase() === folderText?.trim()?.toLowerCase(),
            )
        ) {
            showErr = true;
            errText = 'Folder name already exists';
        } else if (folderText?.length > 256) {
            showErr = true;
            errText = 'Folder name length cannot exceed 256 characters';
        }

        if (showErr) {
            setShowErrorMessage(showErr);
            setErrorHelperText(errText);
        }
        return showErr;
    };

    const handleCreateOrRenameFolder = async () => {
        if (isFolderNameInvalid()) return;

        let msg = selectedRow ? 'Folder successfully updated' : 'Folder successfully created';
        let type = NotificationType.SUCCESS;
        try {
            setShouldDisplayDialog(false);
            const newFolderName = folderText?.trim();
            setFolderText('');

            if (selectedRow) {
                await folderService.update(customerId, selectedRow.asset_folder_id, newFolderName);
                await getFoldersData(0, DEFAULT_PAGE_SIZE, pageData?.searchText);
                setSelectedRow(null);
                googleAnalyticsEvent('Update Entity', 'Folder', 'success');
            } else {
                await folderService.create(customerId, newFolderName);
                await getFoldersData(0, DEFAULT_PAGE_SIZE, pageData?.searchText);
                googleAnalyticsEvent('Create Entity', 'Folder', 'success');
            }
        } catch (error) {
            if (error.error === CONSENT_REQUIRED_ERROR) {
                return getTokenAndTryAgain(apiOptions);
            }
            msg = error.response
                ? `Error: ${error.response.data.title}`
                : `Error: ${error.message}`;
            type = NotificationType.ERROR;
        }
        onPageDataChange({
            ...pageData,
            ...DEFAULT_PAGE_DATA,
        });
        addNotification(msg, type);
    };

    const dismissDialog = () => {
        setFolderText('');
        setShouldDisplayDialog(false);
        setShowErrorMessage(false);
        setErrorHelperText('');
    };

    return (
        <>
            <CustomHeader
                searchTextPrev={pageData?.searchText}
                searchPlaceholder="Folder Name"
                handleActionClick={handleFolderActions}
                handleSearchSubmit={handleTableSearchSubmit}
                actionsArr={[constants.NEW_FOLDER]}
                hideActions={userPrivilege !== constants.ACTION.ACTION_WRITE}
            />
            <FoldersDataTable
                tableData={tableData}
                isLoading={!dataLoaded}
                totalCount={totalCount}
                pageData={pageData}
                onPageDataChange={onPageDataChange}
                actionCallback={handleFolderActions}
                hideActions={userPrivilege !== constants.ACTION.ACTION_WRITE}
                aggregationDisabled={aggregationDisabled}
            />
            <CreateUpdateEntityDialog
                dialogIsVisible={shouldDisplayDialog}
                dialogDismissed={dismissDialog}
                dialogOkClicked={handleCreateOrRenameFolder}
                dialogText={dialogText}
                textToDisplay={folderText}
                setTextToDisplay={setFolderText}
                showErrorMessage={showErrorMessage}
                helpText={errorHelperText}
                setShowErrorMessage={setShowErrorMessage}
                setErrorHelperText={setErrorHelperText}
                primaryButtonLabel={dialogText.title === 'New Folder' ? 'Create' : 'Rename'}
            />
            <DeleteDialog
                isDialogOpen={showDeleteDialog}
                primaryMessage={
                    selectedRow?.asset_count > 0
                        ? `All assets from "${selectedRow?.asset_folder_name}" folder will be deleted.`
                        : ''
                }
                handleDialogAction={handleFolderDelete}
                selectedRow={selectedRow}
                showDataTable
            />
        </>
    );
}
