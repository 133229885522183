import React, { useMemo, useRef, useState } from 'react';
import { throttle } from 'lodash';
import { Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import MapWrapper from 'components/LocationMap';
import AutocompleteGoogleMaps from 'components/AutocompleteGoogleMapsField';
import { Button, FormGroup, H5, InputField, IconButton } from '@spglobal/react-components';
import { Purpose } from '@spglobal/koi-helpers';
import { TRASH } from '@spglobal/koi-icons';
import { ErrorTextBodySm } from '../shared';

const geocodeService = { current: null };

function LocationForm({ fields, control, setValue, remove, append, errors, displayAddLocation }) {
    const loaded = useRef(false);
    const [mapLocations, setMapLocations] = useState(fields);

    // let's set window loaded to avoid the window.google not-present error
    if (typeof window !== 'undefined' && !loaded.current) {
        loaded.current = true;
    }

    const fetch = useMemo(
        () =>
            throttle((request, callback) => {
                geocodeService.current.geocode(request, callback);
            }, 200),
        [],
    );

    if (fields.length !== mapLocations.length) {
        setMapLocations(fields);
    }

    const handleAddLocation = () => {
        append({
            locationName: '',
            lat: 35.994,
            lng: -78.8986,
            elevation: 123.139,
            street_address: '',
            city: '',
            state_province: '',
            postal_code: '',
            country: '',
            locationId: 0,
            location_ref_id: '',
        });
    };
    const hasGeocodeService = () => {
        if (!geocodeService.current && window.google) {
            geocodeService.current = new window.google.maps.Geocoder();
        }

        return geocodeService.current;
    };

    const handleAddressChange = (index, address) => {
        if (!hasGeocodeService()) {
            return;
        }

        if (!address || address === '') {
            return;
        }

        fetch({ address }, (results) => {
            const { geometry } = results[0];
            const { address_components } = results[0];

            const addressData = {};
            address_components.forEach((component) => {
                if (component.types.includes('street_number')) {
                    addressData.street_number = component.long_name;
                } else if (component.types.includes('route')) {
                    addressData.street_name = `${addressData.street_number} ${component.long_name}`;
                } else if (
                    component.types.includes('locality') ||
                    component.types.includes('sublocality')
                ) {
                    addressData.city = component.long_name;
                } else if (component.types.includes('administrative_area_level_1')) {
                    addressData.state_province = component.long_name;
                } else if (component.types.includes('country')) {
                    addressData.country = component.long_name;
                } else if (component.types.includes('postal_code')) {
                    addressData.postal_code = component.long_name;
                }
            });

            const latLng = {
                ...geometry.location.toJSON(),
            };

            const updatedLocations = mapLocations.map((location, i) => {
                if (i === index) {
                    return {
                        ...location,
                        lat: latLng.lat,
                        lng: latLng.lng,
                        street_address: addressData.street_name,
                        city: addressData.city,
                        state_province: addressData.state_province,
                        country: addressData.country,
                        postal_code: addressData.postal_code,
                    };
                }
                return location;
            });

            setMapLocations(updatedLocations);

            setValue(`locations[${index}].lat`, latLng.lat, { shouldValidate: true });
            setValue(`locations[${index}].lng`, latLng.lng, { shouldValidate: true });
            setValue(`locations[${index}].street_address`, addressData.street_name, {
                shouldValidate: true,
            });
            setValue(`locations[${index}].city`, addressData.city, { shouldValidate: true });
            setValue(`locations[${index}].state_province`, addressData.state_province, {
                shouldValidate: true,
            });
            setValue(`locations[${index}].country`, addressData.country, { shouldValidate: true });
            setValue(`locations[${index}].postal_code`, addressData.postal_code, {
                shouldValidate: true,
            });
        });
    };

    const handleLocationReferenceIDChange = (location_ref_id, index) => {
        const updatedLocations = mapLocations.map((location, i) => {
            if (i === index) {
                return {
                    ...location,
                    location_ref_id,
                };
            }
            return location;
        });

        setMapLocations(updatedLocations);

        setValue(`locations[${index}].location_ref_id`, location_ref_id, { shouldValidate: true });
    };

    const handleCoordinateChange = (coordinateValue, type, index) => {
        if (!hasGeocodeService()) {
            return;
        }

        const parsedCoordinateValue = parseFloat(coordinateValue);

        if (!parsedCoordinateValue || Number.isNaN(parsedCoordinateValue)) {
            return;
        }
        const oppositeType = type === 'lat' ? 'lng' : 'lat';
        if (
            mapLocations[index][type] === parsedCoordinateValue ||
            mapLocations[index][oppositeType] === ''
        ) {
            return;
        }

        const newLat = type === 'lat' ? parsedCoordinateValue : mapLocations[index].lat;
        const newLng = type === 'lng' ? parsedCoordinateValue : mapLocations[index].lng;

        const updatedLocations = mapLocations.map((location, i) => {
            if (i === index) {
                return {
                    ...location,
                    lat: newLat,
                    lng: newLng,
                };
            }
            return location;
        });

        setMapLocations(updatedLocations);

        setValue(`locations[${index}].lat`, newLat, { shouldValidate: true });
        setValue(`locations[${index}].lng`, newLng, { shouldValidate: true });
    };

    return (
        <div>
            <H5>Location details</H5>
            {mapLocations && (
                <MapWrapper
                    locations={mapLocations.map((location) => ({
                        ...location,
                        lat: parseFloat(location.lat),
                        lng: parseFloat(location.lng),
                    }))}
                />
            )}
            {mapLocations &&
                mapLocations.length > 0 &&
                mapLocations.map((location, index) => (
                    <div key={location.id}>
                        <div className="spg-row spg-mb-md">
                            <div className={displayAddLocation ? 'spg-col-11' : 'spg-col-12'}>
                                <Controller
                                    render={({ field }) => (
                                        <AutocompleteGoogleMaps
                                            {...field}
                                            handleChange={(value) => {
                                                if (value?.name) {
                                                    handleAddressChange(index, value.name);
                                                }
                                            }}
                                        />
                                    )}
                                    name={`locations[${index}].address`}
                                    data-testid={`location-address-${index}`}
                                    defaultValue={location.address}
                                    control={control}
                                    index={index}
                                />
                            </div>

                            {displayAddLocation && (
                                <div className="spg-col-1">
                                    <IconButton
                                        icon={TRASH}
                                        aria-label="remove location"
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            remove(index);
                                        }}
                                        disabled={mapLocations.length < 2}
                                        data-testid={`location-remove-${index}`}
                                    />
                                </div>
                            )}
                        </div>
                        <div className="spg-row">
                            <div className="spg-col-3">
                                <FormGroup
                                    label="Latitude"
                                    feedbackText="Number between -90 and 90"
                                >
                                    <Controller
                                        render={({ field }) => (
                                            <InputField
                                                onChange={(e) => {
                                                    handleCoordinateChange(
                                                        e.target.value,
                                                        'lat',
                                                        index,
                                                    );
                                                    field.onChange(e); // Ensure React Hook Form's internal state updates correctly
                                                }}
                                                value={field.value}
                                                inputRef={field.ref}
                                            />
                                        )}
                                        name={`locations[${index}].lat`}
                                        data-testid={`location-lat-${index}`}
                                        defaultValue={location.lat}
                                        control={control}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: 'Location Latitude is required',
                                            },
                                            max: {
                                                value: 90,
                                                message: 'Location Latitude exceed max length.',
                                            },
                                            min: {
                                                value: -90,
                                                message: 'Location Latitude exceed min length.',
                                            },
                                            pattern: {
                                                value: /[+-]?([0-9]*[.])?[0-9]+/,
                                                message: 'Must be numeric',
                                            },
                                        }}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name={`locations[${index}].lat`}
                                        render={({ message }) => (
                                            <ErrorTextBodySm>{message}</ErrorTextBodySm>
                                        )}
                                    />
                                </FormGroup>
                            </div>
                            <div className="spg-col-3">
                                <FormGroup
                                    label="Longitude"
                                    feedbackText="Number between -180 and 180"
                                >
                                    <Controller
                                        render={({ field }) => (
                                            <InputField
                                                onChange={(e) => {
                                                    handleCoordinateChange(
                                                        e.target.value,
                                                        'lng',
                                                        index,
                                                    );
                                                    field.onChange(e); // Ensure React Hook Form's internal state updates correctly
                                                }}
                                                value={field.value}
                                                inputRef={field.ref}
                                                type="number"
                                            />
                                        )}
                                        name={`locations[${index}].lng`}
                                        data-testid={`location-lng-${index}`}
                                        defaultValue={location.lng}
                                        control={control}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: 'Location Longitude is required',
                                            },
                                            max: {
                                                value: 180,
                                                message: 'Location Longitude exceed max length.',
                                            },
                                            min: {
                                                value: -180,
                                                message: 'Location Longitude exceed min length.',
                                            },
                                            pattern: {
                                                value: /[+-]?([0-9]*[.])?[0-9]+/,
                                                message: 'Must be numeric',
                                            },
                                        }}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name={`locations[${index}].lng`}
                                        render={({ message }) => (
                                            <ErrorTextBodySm>{message}</ErrorTextBodySm>
                                        )}
                                    />
                                </FormGroup>
                            </div>
                            <div className="spg-col-3">
                                <FormGroup
                                    label="Location Reference ID"
                                >
                                    <Controller
                                        render={({ field }) => (
                                            <InputField
                                                onChange={(e) => {
                                                    handleLocationReferenceIDChange(e.target.value, index);
                                                    field.onChange(e); // Ensure React Hook Form's internal state updates correctly
                                                }}
                                                value={field.value}
                                                inputRef={field.ref}
                                            />
                                        )}
                                        name={`locations[${index}].location_ref_id`}
                                        data-testid={`location-location_ref_id-${index}`}
                                        defaultValue={location.ref_id}
                                        control={control}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name={`locations[${index}].location_ref_id`}
                                        render={({ message }) => (
                                            <ErrorTextBodySm>{message}</ErrorTextBodySm>
                                        )}
                                    />
                                </FormGroup>
                            </div>
                            <div className="spg-col-3">
                                <FormGroup
                                    data-testid={`location-locationId-${index}`}
                                    className="spg-d-hidden"
                                >
                                    <Controller
                                        render={({ field }) => <InputField {...field} />}
                                        name={`locations[${index}].locationId`}
                                        key={`locations[${index}].locationId`}
                                        control={control}
                                        defaultValue={location.locationId}
                                        disabled
                                    />
                                </FormGroup>
                                <FormGroup
                                    data-testid={`location-locationName-${index}`}
                                    className="spg-d-hidden"
                                >
                                    <Controller
                                        render={({ field }) => <InputField {...field} />}
                                        name={`locations[${index}].locationName`}
                                        key={`locations[${index}].locationName`}
                                        control={control}
                                        defaultValue={location.locationName}
                                        disabled
                                    />
                                </FormGroup>
                            </div>
                        </div>
                    </div>
                ))}
            <div>
                {displayAddLocation && (
                    <div className="spg-d-flex spg-justify-start">
                        <Button
                            type="button"
                            aria-label="Add another location"
                            onClick={handleAddLocation}
                            data-testid="location-add-button"
                            purpose={Purpose.SECONDARY}
                        >
                            Add another location
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
}
export default React.memo(LocationForm);
