import React, { useState, useEffect, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { NotificationType, useNotification, Card, H5 } from '@spglobal/react-components';
import InvestmentAssetsLocationMap from 'components/InvestmentAssetsLocationMap';
import { getNextRealAssetPath } from 'utils/generateBreadcrumbs';
import customerService from 'services/customerService';
import { TAB_INDEXES, CUSTOMER_READ_ASSET, CONSENT_REQUIRED_ERROR } from 'utils/constants';
import riskService from 'services/riskService';
import pathParser from 'utils/pathParser';
import getRelevantEntityId from 'utils/getRelevantEntityId';
import AccessTokenContext from 'context/AccessTokenContext';
import RiskFilterContext from 'context/RiskFilterContext';
import RiskGraph from 'components/RiskGraph';
import HazardImpactViewer from 'components/HazardImpactViewer';
import RealAssetsDashboardTable from 'components/RealAssetsDashboardTable';
import DashboardHeader from 'components/DashboardHeader';
import DashboardRiskTabs from 'components/DashboardRiskTabs';
import Disclaimer from 'components/Disclaimer';

import { DEFAULT_PAGE_DATA } from '../components.constants';

export default function RealAssetsDashboard({ match, entityType }) {
    const history = useHistory();
    const { pathname } = useLocation();
    const { 'real-assets': urlCustomerId } = pathParser.getPathComponents(pathname);
    const params = new URLSearchParams(window.location.search);

    const apiOptions = {
        scope: `${CUSTOMER_READ_ASSET}`,
    };
    const { getTokenAndTryAgain } = useContext(AccessTokenContext);
    const { riskDataFilters, handleFilterChange } = useContext(RiskFilterContext);
    const pathComponents = pathParser.getPathComponents(pathname);
    const investmentId = pathComponents.investments;
    const folderId = pathComponents.folders;
    const viewId = pathComponents.views;
    const customerId = pathComponents['real-assets'];
    const [assetsSubscribed, setAssetsSubscribed] = useState(0);
    const [entityId, setEntityId] = useState();
    const [isMounted, setIsMounted] = useState(false);
    const [currentPageData, setCurrentPageData] = useState(null);
    const [chartData, setChartData] = useState([]);
    const [graphData, setGraphData] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [assetLevelData, setAssetLevelData] = useState([]);
    const [totalCount, setTotalCount] = useState([]);
    const [aggregationStatusData, setAggregationStatusData] = useState({});
    const [loading, setLoading] = useState(true);
    const needDisclaimers = ['1242'];
    const { addNotification } = useNotification();

    const filters = {
        ...riskDataFilters,
        customerId:
            match.params.customerId && match.params.customerId !== riskDataFilters.currentCustomerId
                ? urlCustomerId
                : '',
    };

    const fetchRealAssets = async (reqData) => {
        try {
            // Need to get response data for the table
            const response = await riskService.getRealAssetsCachedRisks(customerId, reqData);

            if (response) {
                if (!reqData?.updateTableOnly) {
                    setChartData(response?.chart_data);
                    setGraphData(response?.graph_data);
                }

                // If the tab selected is folder or group, then fetch the asset level data
                if (
                    response?.table_data[0]?.type === 'folder' ||
                    response?.table_data[0]?.type === 'view'
                ) {
                    reqData.viewType = 'assets';
                    const asset_response = await riskService.getRealAssetsCachedRisks(
                        customerId,
                        reqData,
                    );
                    setAssetLevelData(asset_response?.table_data);
                    setTableData(response?.table_data);
                } else {
                    setAssetLevelData(null);
                    setTableData(response?.table_data);
                }
                setTotalCount(response?.count);

                const aggregationStatus = response?.aggregation_status;
                setAggregationStatusData(aggregationStatus);

                if (response?.aggregation_status?.aggregationStatus === 'In Progress') {
                    addNotification(
                        'The composition of your folders has changed. Please wait while we rescore your portfolio.',
                        NotificationType.WARNING,
                    );
                }
            }
            setIsMounted(true);
            setLoading(false);
            return response;
        } catch (error) {
            setTableData([]);
            setTotalCount(0);
            console.error('Error fetching aggregated risks data: ', error);
            setLoading(false);
            return error;
        }
    };

    const fetchCustomer = async () => {
        async function fetchData() {
            const { results } = await customerService.getCustomerByIdManage(customerId);
            setAssetsSubscribed(results.asset_count);
        }
        return fetchData();
    };

    const getAggregatedRisksData = () => {
        setLoading(true);
        const {
            searchText,
            searchType,
            viewType,
            rowsPerPage,
            page,
            order,
            orderBy,
            updateTableOnly = false,
        } = currentPageData;
        const offset = page * rowsPerPage;
        const reqData = {
            riskDataFilters,
            viewType,
            offset,
            limit: rowsPerPage,
            order,
            order_by: orderBy,
            updateTableOnly,
        };
        if (searchText?.length) {
            reqData.search_type = searchType;
            reqData.search_value = searchText;
        }
        if (folderId) reqData.folder_id = folderId;
        if (viewId) reqData.view_id = viewId;

        return fetchRealAssets(reqData);
    };

    useEffect(() => {
        if (currentPageData) {
            getAggregatedRisksData();
        }
    }, [viewId, currentPageData]);

    useEffect(() => {
        const updatedData = { ...currentPageData, updateTableOnly: false };
        setCurrentPageData(updatedData);
    }, [riskDataFilters]);

    const setDefaultTabData = (activeTabIndex) => {
        const pageData = {
            ...DEFAULT_PAGE_DATA,
            searchText: '',
            order: 'desc',
            orderBy: 'relative_value',
            searchType: 'asset',
        };
        switch (activeTabIndex) {
            case TAB_INDEXES.allAssets:
                setCurrentPageData({ ...pageData, viewType: 'assets' });
                break;
            case TAB_INDEXES.folders:
                setCurrentPageData({ ...pageData, searchType: 'folder', viewType: 'folders' });
                break;
            case TAB_INDEXES.views:
                setCurrentPageData({
                    ...pageData,
                    searchType: 'view',
                    viewType: 'views',
                    orderBy: 'entity_name',
                });
                break;
            default:
                setCurrentPageData({ ...pageData, viewType: 'assets' });
                break;
        }
    };

    const handleCurrentPageData = () => {
        const pageData = {
            ...DEFAULT_PAGE_DATA,
            searchText: '',
            order: 'desc',
            orderBy: 'relative_value',
            searchType: 'asset',
        };
        if (entityType === 4) {
            setCurrentPageData({ ...pageData, viewType: 'assets' });
        } else if (entityType === 7) {
            setCurrentPageData({
                ...pageData,
                searchType: 'view',
                viewType: 'views',
                orderBy: 'entity_name',
            });
        }
    };

    useEffect(() => {
        async function fetchData() {
            try {
                const activeTab = params.get('activeTab');
                if (activeTab?.length) {
                    setDefaultTabData(TAB_INDEXES[activeTab]);
                } else {
                    handleCurrentPageData();
                }
                const currentEntityId = getRelevantEntityId(entityType, match.params);
                setEntityId(currentEntityId);
            } catch (error) {
                if (error.error === CONSENT_REQUIRED_ERROR) {
                    await getTokenAndTryAgain(apiOptions);
                }
            }
        }
        fetchData();
        fetchCustomer();
    }, []);

    const handleRiskTableRowClick = (selectedRowID, tabEntityType = null) => {
        const { path, search } = getNextRealAssetPath({
            entityType: tabEntityType || entityType,
            selectedRowID,
            currentEntityIds: match.params,
            currentViewId: viewId,
            urlParams: params,
        });
        history.push({ pathname: path, search });
    };

    return (
        <div>
            <DashboardHeader
                riskDataFilters={filters}
                handleFilterChange={handleFilterChange}
                tagOptions={[]}
                entityType={entityType}
            />
            <div className="spg-row">
                {needDisclaimers.includes(urlCustomerId) && <Disclaimer />}
            </div>

            <div
                className="spg-row spg-ml-0 spg-mr-0 spg-mb-md"
                style={{
                    pointerEvents:
                        aggregationStatusData &&
                        aggregationStatusData.aggregationStatus === 'In Progress'
                            ? 'none'
                            : 'auto',
                    opacity:
                        aggregationStatusData &&
                        aggregationStatusData.aggregationStatus === 'In Progress'
                            ? 0.5
                            : 1,
                }}
            >
                <div className="spg-col-4 spg-pl-md">
                    <div>
                        {isMounted ? (
                            <RiskGraph
                                riskGraphData={
                                    isMounted && graphData?.length === 0
                                        ? { absoluteValues: [] }
                                        : graphData?.dataToGraphResults
                                }
                                riskDataFilters={filters}
                                handleFilterChange={handleFilterChange}
                                entityId={entityId}
                                entityType={entityType}
                            />
                        ) : (
                            <H5>Currently processing climate risk...</H5>
                        )}
                    </div>
                    <div>
                        {isMounted ? (
                            <HazardImpactViewer
                                hazardImpactChartData={chartData?.dataToChartResults}
                                riskDataFilters={filters}
                                handleFilterChange={handleFilterChange}
                                viewType="folder"
                                entityType={entityType}
                                entityId={entityId}
                            />
                        ) : (
                            <H5>Currently processing climate risk...</H5>
                        )}
                    </div>
                </div>
                <div className="spg-col-8">
                    <Card hasBorder hasRoundedCorner>
                        <div>
                            {isMounted ? (
                                <InvestmentAssetsLocationMap
                                    entityType={entityType}
                                    entityId={entityId}
                                    riskDataFilters={filters}
                                    investmentId={investmentId}
                                    folderId={folderId}
                                    viewId={viewId}
                                    customerId={customerId}
                                    tableData={assetLevelData || tableData}
                                />
                            ) : (
                                !isMounted && <H5>Currently processing climate risk...</H5>
                            )}
                            {isMounted ? (
                                entityType === 6 ? (
                                    <DashboardRiskTabs
                                        loading={loading}
                                        riskDataFilters={filters}
                                        tableData={tableData}
                                        totalCount={totalCount}
                                        handleClick={handleRiskTableRowClick}
                                        updateCurrentPageData={setCurrentPageData}
                                    />
                                ) : (
                                    <RealAssetsDashboardTable
                                        loading={loading}
                                        riskDataFilters={filters}
                                        entityType={entityType}
                                        tableData={tableData}
                                        totalCount={totalCount}
                                        handleClick={handleRiskTableRowClick}
                                        pageData={currentPageData}
                                        updateCurrentPageData={setCurrentPageData}
                                    />
                                )
                            ) : (
                                <H5>Currently processing climate risk...</H5>
                            )}
                        </div>
                    </Card>
                </div>
            </div>
        </div>
    );
}
