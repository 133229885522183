import React, { useContext, useEffect, useState } from 'react';
import { Card, Tab, Tabs } from '@spglobal/react-components';
import { Alignment } from '@spglobal/koi-helpers';
import { useHistory, useLocation } from 'react-router-dom';
import pathParser from '../../utils/pathParser';
import AccessTokenContext from '../../context/AccessTokenContext';
import rbacAdminService from '../../services/rbacAdminService';
import UserTab from './UserTab/UserTab';
import AdminTab from './AdminTab/AdminTab';
import { CONSENT_REQUIRED_ERROR } from '../../utils/constants';
import BreadcrumbTrail from '../BreadcrumbTrail';

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function RbacClientAdmin() {
    const { pathname } = useLocation();
    const { 'rbac-client-admin': urlCustomerId } = pathParser.getPathComponents(pathname);

    const [value, setValue] = React.useState(0);
    const [dataLoaded, setDataLoaded] = useState(false);

    const [tableData, setTableData] = useState([]);
    const { getTokenAndTryAgain } = useContext(AccessTokenContext);
    const history = useHistory();

    const getUsersData = async () => {
        const reqData = {
            customerId: urlCustomerId,
            includeAll: true,
        };
        async function fetchData() {
            try {
                const response = await rbacAdminService.getAllClientUsers(reqData);
                setDataLoaded(true);
                if (response) {
                    setTableData(response);
                }
                return response;
            } catch (error) {
                setDataLoaded(true);
                if (error.error === CONSENT_REQUIRED_ERROR) {
                    await getTokenAndTryAgain(apiOptions);
                }
            }
        }
        return fetchData();
    };

    useEffect(() => {
        (async function fetchUsersData() {
            await getUsersData();
        })();
    }, []);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <>
            <BreadcrumbTrail history={history} rootTitle={' '} manageChild="Assign Folders & Groups" />
            <Card>
                <Tabs
                    alignment={Alignment.LEFT}
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                >
                    <Tab title="Users" {...a11yProps(0)}>
                        <UserTab
                            tableData={tableData}
                            dataLoaded={dataLoaded}
                            customerId={urlCustomerId}
                            getUsersData={getUsersData}
                        />
                    </Tab>
                    <Tab title="Admin" {...a11yProps(1)}>
                        <AdminTab customerId={urlCustomerId} />
                    </Tab>
                </Tabs>
            </Card>
        </>
    );
}
