import React from 'react';
import { useLocation } from 'react-router-dom';
import { FormGroup, InputField, Button, Icon } from '@spglobal/react-components';
import { DOWNLOAD } from '@spglobal/koi-icons';
import { Purpose } from '@spglobal/koi-helpers';
import config from '../../config';
import DataService from '../../service/data.service';

import AssetFieldTable from './AssetFieldTable';
import pathParser from '../../utils/pathParser';
import { HeadingMd, ParagraphLg, TextBodyMd } from '../../components/shared';

export default function MultipleSelectPlaceholder(props) {
    const service = new DataService();
    const { pathname } = useLocation();
    const { 'bulk-import-file': customerId } = pathParser.getPathComponents(pathname);
    const urlParams = new URLSearchParams(window.location.search);
    const customerName = urlParams.get('customerName');

    const downloadTemplate = () => {
        let csv_template = config.uploadCSVTemplate;
        const uri = csv_template;
        if (csv_template[0] === '/') {
            csv_template = csv_template.slice(1);
        }
        service.downloadFile(uri, csv_template);
    };

    const downloadAssetTypes = () => {
        const assetTypesURI = `${config.baseUrl}/api/download/assetTypes`;
        service.downloadFile(assetTypesURI, 'assetTypes.csv');
    };

    return (
        <div>
            <div>
                <HeadingMd>Customer Details</HeadingMd>
                <FormGroup label="Customer Name" labelFor="customer-name">
                    <InputField
                        id="customer-name"
                        value={customerName}
                        disabled
                        aria-label="Without label"
                    />
                </FormGroup>
            </div>

            <div>
                <ParagraphLg>Step 1 | Prepare your upload file</ParagraphLg>
                <ol type="1">
                    {' '}
                    <li>
                        <TextBodyMd>
                            Download the upload template (click button below) and fill out the csv template with your asset details.
                        </TextBodyMd>
                    </li>
                    <li>
                        <TextBodyMd>Ensure that all required fields are filled out</TextBodyMd>
                    </li>
                    <li>
                        <TextBodyMd>
                            All fields must be in the format specified in the table below
                        </TextBodyMd>
                    </li>
                    <li>
                        <TextBodyMd>
                            For faster calculation of aggregated financial impact results, 
                            please limit the number of assets assigned to a folder ID to 50,000.
                        </TextBodyMd>
                    </li>
                    <li>
                        <TextBodyMd>
                            Climanomics can ingest up to 1 million asset locations in a single upload session. 
                            If you are uploading more than 1 million asset locations, please allow the platform 
                            to ingest the first million asset locations before
                            starting a new upload session. You are able to check the status of your upload in the Import File Dashboard.
                        </TextBodyMd>
                    </li>
                </ol>
            </div>
            <div className="spg-pb-md">
                <Button
                    purpose={Purpose.PRIMARY}
                    leftIcon={<Icon icon={DOWNLOAD} />}
                    onClick={downloadTemplate}
                >
                    Download Template
                </Button>
            </div>
            <AssetFieldTable downloadAssetTypes={downloadAssetTypes} />
        </div>
    );
}
