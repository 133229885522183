import React from 'react';
import { Card } from '@spglobal/react-components';
import BreadcrumbTrail from '../../../BreadcrumbTrail';
import Image_1 from '../../assets/IFs_051821_1.png';
import Image_2 from '../../assets/IFs_051821_2.png';
import Image_3 from '../../assets/IFs_051821_3.png';
import Image_4 from '../../assets/IFs_051821_4.png';
import Image_5 from '../../assets/IFs_051821_5.png';
import Image_6 from '../../assets/IFs_051821_6.png';
import Image_7 from '../../assets/IFs_051821_7.png';
import Image_8 from '../../assets/IFs_051821_8.png';
import Image_9 from '../../assets/IFs_051821_9.png';
import Image_10 from '../../assets/IFs_051821_10.png';
import Image_11 from '../../assets/IFs_051821_11.png';
import Image_12 from '../../assets/IFs_051821_12.png';
import Image_13 from '../../assets/IFs_051821_13.png';
import Image_14 from '../../assets/IFs_051821_14.png';

export default function Updates_051821() {
    return (
        <div>
            <BreadcrumbTrail rootTitle="Updates to Impact Functions" />
            <Card hasBorder hasRoundedCorner className="spg-m-md">
                <img
                    className="spg-ml-2xl spg-w-50"
                    src={Image_1}
                    alt="Update to Impact Functions 1"
                />
                <img
                    className="spg-ml-2xl spg-w-50"
                    src={Image_2}
                    alt="Update to Impact Functions 2"
                />
                <img
                    className="spg-ml-2xl spg-w-50"
                    src={Image_3}
                    alt="Update to Impact Functions 3"
                />
                <img
                    className="spg-ml-2xl spg-w-50"
                    src={Image_4}
                    alt="Update to Impact Functions 4"
                />
                <img
                    className="spg-ml-2xl spg-w-50"
                    src={Image_5}
                    alt="Update to Impact Functions 5"
                />
                <img
                    className="spg-ml-2xl spg-w-50"
                    src={Image_6}
                    alt="Update to Impact Functions 6"
                />
                <img
                    className="spg-ml-2xl spg-w-50"
                    src={Image_7}
                    alt="Update to Impact Functions 7"
                />
                <img
                    className="spg-ml-2xl spg-w-50"
                    src={Image_8}
                    alt="Update to Impact Functions 8"
                />
                <img
                    className="spg-ml-2xl spg-w-50"
                    src={Image_9}
                    alt="Update to Impact Functions 9"
                />
                <img
                    className="spg-ml-2xl spg-w-50"
                    src={Image_10}
                    alt="Update to Impact Functions 10"
                />
                <img
                    className="spg-ml-2xl spg-w-50"
                    src={Image_11}
                    alt="Update to Impact Functions 11"
                />
                <img
                    className="spg-ml-2xl spg-w-50"
                    src={Image_12}
                    alt="Update to Impact Functions 12"
                />
                <img
                    className="spg-ml-2xl spg-w-50"
                    src={Image_13}
                    alt="Update to Impact Functions 13"
                />
                <img
                    className="spg-ml-2xl spg-w-50"
                    src={Image_14}
                    alt="Update to Impact Functions 14"
                />
            </Card>
        </div>
    );
}
