import { FormGroup, Select } from '@spglobal/react-components';
import React from 'react';
import { DECADE_OPTIONS } from '../../utils/constants';

export function DecadeSelect({
    selectedDecades,
    setSelectedDecades,
    isMulti = true,
    isDisabled = false
}) {
    const decadeOptions = [...DECADE_OPTIONS];

    const restSelectProps = isMulti ? {
        isMulti: true
    } : {
        isMulti: false,
        closeOnSelection: true
    };

    return (
        <FormGroup label="Select Decade" labelFor="decade-select" required>
            <Select
                id="decade-select"
                defaultValue={selectedDecades}
                options={decadeOptions}
                onChange={setSelectedDecades}
                isSearchable={false}
                placeholder=""
                {...restSelectProps}
                disabled = {isDisabled}
            />
        </FormGroup>
    );
}
