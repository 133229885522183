import React, { useState, useEffect } from 'react';

import { Capsule, FormGroup, Select } from '@spglobal/react-components';
import { FORM_ACTIONS } from '../../../utils/constants';
import { TextBodyLg } from '../../shared';
import { transformDataObjForKoiSelect } from '../../../utils/koiIntegrationUtils';

export default function AssignFoldersDropdown({
    user,
    users,
    folderData,
    onFoldersSelection,
    action,
    type,
    editUser,
}) {
    const [selectedFolders, setSelectedFolders] = useState([]);
    const [folderDataOptions, setFolderDataOptions] = useState([]);

    useEffect(() => {
        const folderOptions = folderData?.map((f) => ({
            ...transformDataObjForKoiSelect(f, {
                id: 'asset_folder_id',
                value: 'asset_folder_id',
            }),
            label: f.uncategorized_folder || `${f.asset_folder_name} (#Assets: ${f.asset_count})`,
            selectedLabel: f.uncategorized_folder || f.asset_folder_name,
        }));

        setFolderDataOptions(folderOptions);

        if (action === FORM_ACTIONS.EDIT && editUser) {
            const editFolders = editUser.folder_names_list;

            const curFolders = folderData.filter((custItem) =>
                editFolders.some((folder) => folder.folder_id === custItem.asset_folder_id),
            );

            setSelectedFolders(
                curFolders.map((f) => ({
                    ...transformDataObjForKoiSelect(f, {
                        id: 'asset_folder_id',
                        value: 'asset_folder_id',
                    }),
                    label:
                        f.uncategorized_folder ||
                        `${f.asset_folder_name} ( #Assets: ${f.asset_count})`,
                    selectedLabel: f.uncategorized_folder || f.asset_folder_name,
                })),
            );
            onFoldersSelection(curFolders);
        }
    }, [editUser, folderData]);

    const handleChange = (selectedValues /* IOption[] */) => {
        const selectedIds = selectedValues.map((v) => v.id);
        const foldersSelection = folderData.filter((f) => selectedIds.includes(f.asset_folder_id));

        setSelectedFolders(selectedValues);
        onFoldersSelection(foldersSelection);
    };

    const handleDelete = (folder /* IOption */) => () => {
        const newSelectedFolders = selectedFolders.filter((item) => item.id !== folder.id);
        const selectedIds = newSelectedFolders.map((v) => v.id);
        const foldersSelection = folderData.filter((f) => selectedIds.includes(f.asset_folder_id));

        setSelectedFolders(newSelectedFolders);
        onFoldersSelection(foldersSelection);
    };

    const isCustDisabled = () => !!(user && user.role && user.role === 'developer');

    return (
        <>
            <div>
                <FormGroup
                    id="demo-multiple-checkbox-label"
                    label="Folder Name"
                    labelFor="customer-checkbox"
                >
                    <Select
                        isSearchable
                        labelId="demo-multiple-checkbox-label"
                        id="customer-checkbox"
                        disabled={isCustDisabled(user)}
                        options={folderDataOptions}
                        isMulti
                        defaultValue={selectedFolders}
                        onChange={handleChange}
                        formatSelectedValues={(selectedValue) => selectedValue.selectedLabel}
                    />
                </FormGroup>
            </div>
            <div className="spg-row spg-p-md">
                {!isCustDisabled() && (
                    <div>
                        {selectedFolders.map((customer, i) => (
                            <Capsule onClose={handleDelete(customer)} key={i} className="spg-m-2xs">
                                {customer.selectedLabel}
                            </Capsule>
                        ))}
                        <TextBodyLg className="spg-mt-md">
                            Total Folders : {selectedFolders.length}
                        </TextBodyLg>
                    </div>
                )}
                {isCustDisabled() && (
                    <div className="spg-p-md">
                        <Capsule>All Customers</Capsule>
                    </div>
                )}
            </div>
        </>
    );
}
